import * as React from 'react';
import { SegmentedItemStyled, SegmentedStyled } from './segmented.component.style';

export interface SegmentedProps extends React.PropsWithChildren {
  onChange?: (index) => any;
}

export interface SegmentedState {
  currentIndex: number;
}

export class Segmented extends React.Component<SegmentedProps, SegmentedState> {
  static Item = SegmentedItemStyled;

  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }

  onPress(index) {
    return () => {
      this.setState({ currentIndex: index });

      if (this.props.onChange) {
        this.props.onChange(index);
      }
    };
  }

  render() {
    return <SegmentedStyled>{this.renderChildren()}</SegmentedStyled>;
  }

  private renderChildren() {
    return React.Children.map(this.props.children, (child: any, index: number) => {
      return React.cloneElement(child, {
        selected: this.state.currentIndex === index,
        onClick: this.onPress(index),
      });
    });
  }
}
