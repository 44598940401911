import * as React from 'react';
import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import { TextAreaStyled } from './text-area-field.component.style';

export interface TextAreaFieldProps extends FieldPropsInterface, React.InputHTMLAttributes<HTMLTextAreaElement> {
  cols?: number;
  rows?: number;
}

export class TextAreaField extends Field<TextAreaFieldProps, FieldStateInterface> {
  constructor(props: TextAreaFieldProps) {
    super(props);
    this.state = { value: '', errors: [] };
  }

  render() {
    const {
      attachToGroup,
      attachToForm,
      accept,
      onValueChange,
      type,
      onChange,
      value,
      cols,
      rows,
      onFocusChange,
      ...other
    } = this.props;

    return (
      <TextAreaStyled
        value={this.state.value || ''}
        onChange={this.changeText}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        cols={cols ? cols : 4}
        rows={rows ? rows : 10}
        {...other}
      />
    );
  }

  changeText = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const value: string = event.currentTarget.value;

    if (this.state && value === this.state.value) {
      return;
    }

    this.setState({ value });

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  private handleFocus = () => {
    this.props.onFocusChange(true, this.state.value);
  };

  private handleBlur = () => {
    this.props.onFocusChange(false, this.state.value);
  };
}
