import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VaccineBySpecialNeedQueryVariables = Types.Exact<{
  specialNeed: Types.Scalars['String']['input'];
}>;

export type VaccineBySpecialNeedQuery = {
  __typename?: 'Queries';
  VaccineBySpecialNeed?: Array<{
    __typename?: 'VaccineBySpecialNeedResponse';
    family: { __typename?: 'VaccineFamilyType'; id: string; name: string };
    vaccines: Array<{
      __typename?: 'VaccineType';
      id: string;
      name: string;
      brand?: string | null;
      description?: string | null;
      isAdministeredOnPublicClinics: boolean;
      isAdministeredOnPrivateClinics: boolean;
      mouthDropCount: number;
      injectionCount: number;
      ages?: Array<number | null> | null;
      comment?: string | null;
      laboratory?: string | null;
      specialNeed?: string | null;
      priority?: number | null;
      isLiveAttenuated: boolean;
      recommendationComment?: string | null;
      order?: number | null;
      vaccineFamilyId?: number | null;
    } | null>;
  } | null> | null;
};

export const VaccineBySpecialNeedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VaccineBySpecialNeed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'specialNeed' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'VaccineBySpecialNeed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'specialNeed' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'specialNeed' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'family' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VaccineFamilyFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vaccines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VaccineFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VaccineFamilyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VaccineFamilyType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VaccineFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VaccineType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdministeredOnPublicClinics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdministeredOnPrivateClinics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mouthDropCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'injectionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'laboratory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialNeed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLiveAttenuated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendationComment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vaccineFamilyId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VaccineBySpecialNeedQuery, VaccineBySpecialNeedQueryVariables>;
