import { CookieConsentContainer } from '@app/components/cookie-consent';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AccountStore } from '@app/modules/account/account.store';
import { ADMINISTRATION_PLACES_ROUTE } from '@app/modules/administration-places/administration-places.route';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { reaction } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { AuthenticationStore, SIGN_UP_MAP_KEY } from './authentication.store';
import { DoctorInput } from '@app/resource/graphql/base-schema';
import { IMUNOCARD_APPOINTMENT_PATH } from '@app/modules/appointment-imunocard/appointment-imunocard.route';
import { HeaderSmart } from '@app/components/header.smart-component';
import { Separator } from '@components/obj.grid';
import { FooterContainerView } from '@components/pag.home/obj.footer/footer.container';
import { isVacinaTheme } from 'config/theme';
import { SignupForm } from './signup-form.component';

export interface SignupViewProps {
  onSubmit: (data: DoctorInput) => void;
}

// Hotjar
declare var hj: any;

@Observer()
export class SignupView extends React.Component<SignupViewProps> {
  private reactionDisposers = [];

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private router?: Router = Container.get(Router);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
    this.reactionDisposers.push(reaction(() => this.authenticationStore.loggedIn, this.onLogin));
  }

  componentDidMount() {
    this.analyticsService.pageView('signup');
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    return (
      <div>
        {isVacinaTheme && <CookieConsentContainer />}
        <HeaderSmart
          home={false}
          logged={this.authenticationStore?.loggedIn || false}
        />
        <Separator />
        <Separator />
        <SignupForm
          onSubmit={this.handleSubmit}
          loading={this.authenticationStore.loadingMap.get(SIGN_UP_MAP_KEY)}
          error={
            this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)
              ? this.authenticationStore.errorMessageMap.get(SIGN_UP_MAP_KEY)
              : undefined
          }
        />
        <FooterContainerView />
      </div>
    );
  }

  onLogin = () => {
    if (this.authenticationStore.loggedIn) {
      if (!this.accountStore.routeAfterLogin) {
        this.router.push(isVacinaTheme ?
          APPOINTMENT_PATH : IMUNOCARD_APPOINTMENT_PATH);
      } else if (this.accountStore.routeAfterLogin === ADMINISTRATION_PLACES_ROUTE) {
        this.router.push(ADMINISTRATION_PLACES_ROUTE);
        this.accountStore.routeAfterLogin = null;
      } else {
        this.router.push(isVacinaTheme ?
          APPOINTMENT_PATH : IMUNOCARD_APPOINTMENT_PATH);
      }
    }
  };

  private handleSubmit = (doctor: DoctorInput) => {
    this.props.onSubmit(doctor);
  };
}
