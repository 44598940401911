import { CookieConsentContainer } from '@app/components/cookie-consent';
import { HeaderSmart } from '@app/components/header.smart-component';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AuthenticationStore, UDPATE_PASSWORD_MAP_KEY } from '@app/modules/authentication/authentication.store';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { HOME_PATH } from '@app/modules/home/home.path';
import { UpdatePasswordMutation } from '@app/resource/graphql/generated';
import { Button, LinkButton } from '@components/atm.button';
import { Logo } from '@components/atm.logo';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { Display, InputLabel } from '@components/atm.typography';
import { HeaderLogoLinkStyled } from '@components/mol.header/header.component.style';
import { PasswordLength } from '@components/obj.constants';
import { ContentWrapper } from '@components/obj.content-wrapper';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { isVacinaTheme } from 'config/theme';
import { reaction } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';

export type UpdatePasswordViewProps = PropsBase<UpdatePasswordMutation>;

const forgotPassUrl = `${LOGIN_PATH}?forgot=true`;

// TODO: TEMPLATE
@GraphQL('update-password.mutation')
@Observer()
export class UpdatePasswordView extends React.Component<UpdatePasswordViewProps, any> {
  private router?: Router = Container.get(Router);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  private code: string;
  private email: string;
  private dateInMs: number;
  private queryParams;
  private reactionDisposers = [];

  constructor(props) {
    super(props);
    this.reactionDisposers.push(
      reaction(() => this.authenticationStore.successMap.get(UDPATE_PASSWORD_MAP_KEY), this.handleSuccess),
    );
    this.state = { errorMessage: '', passwordValue: '' };
  }

  componentDidMount() {
    this.authenticationStore.isUpdatePasswordExpired(this.dateInMs);
    this.queryParams = new URLSearchParams(this.props.location.search);
    this.code = this.queryParams.get('code');
    this.email = decodeURIComponent(this.queryParams.get('email'));
    this.dateInMs = this.queryParams.get('date');
    if (!this.code || !this.email) {
      this.router.push(forgotPassUrl);
    }

    this.analyticsService.pageView('redefinir_senha', true);
    // https://support.google.com/analytics/answer/6366371?hl=en
    this.analyticsService.set(window.location.href.split('?')[0]);
  }

  render() {
    return (
      <div>
        <HeaderSmart home={false} logged={this.authenticationStore?.loggedIn || false} />
        <ContentWrapper>
          <Form onSubmit={this.handleSubmit}>
            {isVacinaTheme && <CookieConsentContainer />}
            <Grid>
              <Row $middle='xs' center='xs'>
                <Col xs={12} sm={8} md={6}>
                  <Row center='xs' $mb={true}>
                    <Col xs={12}>
                      <If cond={process.env.THEME === 'vacina'}>
                        <HeaderLogoLinkStyled href={HOME_PATH}>
                          <Logo colored={true} mt={true} />
                        </HeaderLogoLinkStyled>
                      </If>
                      <Display>Alterar senha</Display>
                    </Col>
                  </Row>
                  <Row start='xs' $mb={true}>
                    <If
                      cond={
                        this.authenticationStore.errorMap.get(UDPATE_PASSWORD_MAP_KEY) || this.state.errorMessage !== ''
                      }
                    >
                      <Col xs={12}>
                        <Notification
                          type='error'
                          message={this.authenticationStore.errorMessageMap.get(UDPATE_PASSWORD_MAP_KEY)}
                        >
                          <div>
                            Tente resetar sua senha novamente,
                            <LinkButton expanded={false} href={forgotPassUrl}>
                              aqui{' '}
                            </LinkButton>
                          </div>
                        </Notification>
                      </Col>
                    </If>
                  </Row>

                  <Row start='xs' $mb={true}>
                    <If cond={this.authenticationStore.successMap.get(UDPATE_PASSWORD_MAP_KEY)}>
                      <Col xs={12}>
                        <Notification
                          type='success'
                          message={this.authenticationStore.successMessageMap.get(UDPATE_PASSWORD_MAP_KEY)}
                        />
                      </Col>
                    </If>
                  </Row>

                  <Row start='xs' $mb={true}>
                    <Col xs={12}>
                      <InputLabel>Nova senha</InputLabel>
                      <FieldValidator
                        name='newPassword'
                        validators={[
                          Validators.Required('Campo é obrigatório'),
                          Validators.MaxLength(
                            PasswordLength.Max,
                            `A senha deve ter menos de ${PasswordLength.Max} caracteres`,
                          ),
                          Validators.MinLength(
                            PasswordLength.Min,
                            `A senha deve ter pelo menos ${PasswordLength.Min} caracteres`,
                          ),
                          Validators.PasswordRegex(`A senha deve ter pelo menos uma letra e um número.`),
                        ]}
                        validatorPlaceholder={`A senha deve ter entre ${PasswordLength.Min} e
                          ${PasswordLength.Max} caracteres e pelo menos uma letra e um número.`}
                      >
                        <TextField
                          type='password'
                          onValueChange={this.onPasswordChange}
                          maxLength={PasswordLength.Max}
                        />
                      </FieldValidator>
                    </Col>
                  </Row>
                  <Row start='xs' $mb={true}>
                    <Col xs={12}>
                      <InputLabel>Repetir nova senha</InputLabel>
                      <FieldValidator
                        name='confirmNewPassword'
                        validators={[
                          Validators.Required('Campo é obrigatório'),
                          Validators.MaxLength(
                            PasswordLength.Max,
                            `A senha deve ter menos de ${PasswordLength.Max} caracteres`,
                          ),
                          Validators.MinLength(
                            PasswordLength.Min,
                            `A senha deve ter pelo menos ${PasswordLength.Min} caracteres`,
                          ),
                          Validators.PasswordRegex(`A senha deve ter pelo menos uma letra e um número.`),
                          Validators.IsNotEqualToField(this.state.passwordValue, 'Senhas devem ser iguais'),
                        ]}
                        validatorPlaceholder={`A senha deve ter entre ${PasswordLength.Min} e
                          ${PasswordLength.Max} caracteres e pelo menos uma letra e um número.`}
                      >
                        <TextField type='password' maxLength={PasswordLength.Max} />
                      </FieldValidator>
                    </Col>
                  </Row>
                  <Button
                    kind='primary'
                    type='submit'
                    expanded={true}
                    loading={this.authenticationStore.loadingMap.get(UDPATE_PASSWORD_MAP_KEY)}
                  >
                    Alterar senha
                  </Button>
                </Col>
              </Row>
            </Grid>
          </Form>
          <Separator />
        </ContentWrapper>
      </div>
    );
  }

  private onPasswordChange = (value: any) => {
    this.setState({ passwordValue: value });
  };

  private handleSubmit = formData => {
    if (formData.errors.length === 0) {
      this.authenticationStore.updatePassword(
        {
          code: this.code,
          email: this.email,
          password: formData.data.newPassword,
        },
        this.props.mutate,
      );
    }
  };

  private handleSuccess = () => {
    if (this.authenticationStore.successMap.get(UDPATE_PASSWORD_MAP_KEY)) {
      setTimeout(() => {
        this.router.push(LOGIN_PATH);
      }, 2000);
    }
  };
}
