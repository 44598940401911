import * as React from 'react';
import { Route } from 'react-router';
import { TermsView } from '@app/modules/terms/terms.view';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const TERMS_PATH = '/termos-de-uso';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Termos de uso`,
  meta: [
    {
      name: 'description',
      content: 'Confira os termos de uso do Vacina Certa.',
    },
  ],
};
const Terms = SEO(config)(TermsView);

export const TermsRoute = <Route path={TERMS_PATH} exact={true} component={Terms} key='terms' />;
