import { Notification } from '@components/atm.notification';
import * as React from 'react';
import FlashDispatcherService, { FlashDispatcherListener, FlashMessageData } from './flash-dispatcher.service';
import { FlashWrapperStyled } from './flash-wrapper.component.style';

interface FlashWrapperState {
  messageQueue: FlashMessageData[];
}

const flashDispatcher = FlashDispatcherService;
/**
 * It is a wrapper that shows Flash Message on the window.
 * It also manages a queue of messages by listening to flashDispatcher
 */
export class FlashWrapper extends React.Component<any, FlashWrapperState> implements FlashDispatcherListener {
  private timeout: Record<number, NodeJS.Timeout> = {};

  constructor(props) {
    super(props);
    this.state = {
      messageQueue: [],
    };
  }

  componentDidMount() {
    flashDispatcher.setListener(this);
  }

  componentWillUnmount() {
    flashDispatcher.setListener(null);
  }

  render() {
    return (
      <FlashWrapperStyled>
        {this.state.messageQueue.slice(-3).map((item, index) => (
          <Notification
            type={item.type}
            message={item.message}
            key={`${item.time.getTime().toString()}`}
            onClose={this.handleMessageCloseHOF(index)}
          />
        ))}
      </FlashWrapperStyled>
    );
  }

  handleMessageCloseHOF = (index: number) => () => this.closeMessage(index);

  closeMessage = (index: number) => {
    clearTimeout(this.timeout[index]);
    const messageQueue = this.state.messageQueue;
    messageQueue.splice(index, 1);
    this.setState({ messageQueue });
  };

  // FlashDispatcherListener
  onReceiveMessage = (data: FlashMessageData) => {
    const messageQueue = this.state.messageQueue;

    const messageIsAlreadyAdded = messageQueue.find(m => m.message === data.message && m.type === data.type);
    if (messageIsAlreadyAdded) {
      return;
    }

    const indexAdded = messageQueue.length;
    messageQueue.push(data);

    this.setState({ messageQueue });

    const timeToCloseMessageAutomatically = 10 * 1000;
    this.timeout[indexAdded] = setTimeout(() => {
      this.closeMessage(indexAdded);
    }, timeToCloseMessageAutomatically);
  };
}
