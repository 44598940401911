import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import {
  AuthenticationStore,
  UDPATE_PASSWORD_AS_ADMIN_MAP_KEY,
} from '@app/modules/authentication/authentication.store';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Container } from 'typedi';
import { HOME_PATH } from '../home/home.path';
import { NotificationType } from '@components/atm.notification';
import { ResetDoctorEmailView } from './reset-email-password.view';
import { UpdateEmailAsAdminMutation } from '@app/resource/graphql/generated';
import { UpdateEmailAsAdminInput } from '@app/resource/graphql/base-schema';

export type ResetDoctorEmailDataProps = PropsBase<UpdateEmailAsAdminMutation>;

@GraphQL('update-email-as-admin.mutation')
@Observer()
export class ResetDoctorEmailData extends React.Component<ResetDoctorEmailDataProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  render() {
    if (!this.authenticationStore.isAdminUser()) {
      return <Redirect to={HOME_PATH} />;
    }

    const submitLoading = this.authenticationStore.loadingMap.get(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY);
    const notification = {
      message:
        this.authenticationStore.successMessageMap.get(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY) ||
        this.authenticationStore.errorMessageMap.get(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY),
      type:
        (this.authenticationStore.successMap.get(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY) && 'success') ||
        ((this.authenticationStore.errorMessageMap.get(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY) &&
          'error') as NotificationType),
    };

    return (
      <ResetDoctorEmailView onSubmit={this.callMutation} submitLoading={submitLoading} notification={notification} />
    );
  }

  componentWillUnmount() {
    this.authenticationStore.resetErrorState();
  }

  private callMutation = (input: UpdateEmailAsAdminInput) => {
    this.authenticationStore.updateEmailAsAdmin(input, this.props.mutate);
  };
}
