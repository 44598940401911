import { ProgressCell, ProgressStatus } from '@components/atm.imunocard-progress';
import * as React from 'react';
import { ProgressStyled } from './imunocard-progress.component.style';

export interface ImunocardProgressProps {
  steps: string[];
  currentStep: number;
  onMobile?: boolean;
  onClick?: (step: number) => void;
}

export const ImunocardProgress = (props: ImunocardProgressProps) => {
  function status(step: number, currentStep: number): ProgressStatus {
    if (step < currentStep) {
      return 'past';
    } else if (step === currentStep) {
      return 'current';
    } else {
      return 'future';
    }
  }

  function onClick(param: number, e: Event) {
    e.stopPropagation();
    if (status(param, props.currentStep) === 'past') {
      props.onClick(param);
    }
    return;
  }

  return (
    <ProgressStyled>
      {props.steps.map((step, index) => (
        <ProgressCell
          key={index}
          number={(index + 1).toString()}
          text={step}
          status={status(index + 1, props.currentStep)}
          onClick={onClick.bind(this, index + 1)}
        />
      ))}
    </ProgressStyled>
  );
};
