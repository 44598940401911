import * as React from 'react';
import {
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItemWrapper,
  AccordionTitle,
} from './accordion.component.style';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface AccordionProps {
  title: string;
  expanded: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ title, expanded, onClick, children }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = React.useState('0px');

  const measureHeight = () => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(`${height}px`);
    }
  };

  React.useEffect(() => {
    if (expanded) {
      measureHeight();
    } else {
      setContentHeight('0px');
    }
  }, [expanded, children]);

  return (
    <AccordionItemWrapper>
      <AccordionHeader onClick={onClick}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon icon={faChevronDown} expanded={expanded} />
      </AccordionHeader>

      <AccordionContent maxHeight={contentHeight} ref={contentRef} expanded={expanded}>
        {children}
      </AccordionContent>
    </AccordionItemWrapper>
  );
};
