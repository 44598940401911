import * as React from 'react';
import { TableStyled, TBodyStyled, TDStyled, THeadStyled, THStyled, TRStyled } from './table.component.style';

interface TRProps extends React.HTMLProps<HTMLTableRowElement> {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
}

export const Table: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLTableElement>>> = ({ children }) => (
  <TableStyled>{children}</TableStyled>
);

export const THead: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLTableSectionElement>>> = ({ children }) => (
  <THeadStyled>{children}</THeadStyled>
);

export const TBody: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLTableSectionElement>>> = ({ children }) => (
  <TBodyStyled>{children}</TBodyStyled>
);

export const TH: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLTableCellElement>>> = ({ children }) => (
  <THStyled>{children}</THStyled>
);

export const TR: React.FC<React.PropsWithChildren<TRProps>> = ({ children, disabled, onClick }) => (
  <TRStyled disabled={disabled} onClick={!disabled ? onClick : undefined}>
    {children}
  </TRStyled>
);

export const TD: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLTableCellElement>>> = ({ children }) => (
  <TDStyled>{children}</TDStyled>
);
