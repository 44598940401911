import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { SelectedVaccines } from '@app/modules/appointment-imunocard/vaccine-selection.view';

@Service()
export class ImunocardAppointmentStore {
  @observable
  imunocard: string | null = null;

  @observable
  ageGroup: string | null = null;

  @observable
  vaccines: SelectedVaccines[] = [];

  @action
  setImunocard = (imunocard: string) => {
    this.imunocard = imunocard;
  }

  @action
  setAgeGroup = (ageGroup: string) => {
    this.ageGroup = ageGroup;
  }

  @action
  setVaccines = (vaccines: SelectedVaccines[]) => {
    this.vaccines = vaccines;
  }

  @action
  resetVaccines = () => {
    this.vaccines = [];
  }

  @action
  cleanStore = () => {
    this.imunocard = null;
    this.ageGroup = null;
    this.vaccines = [];
  }
}
