import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ApplicationStore } from '@app/modules/app/application.store';
import { Notification, NotificationType } from '@components/atm.notification';
import { Display } from '@components/atm.typography';
import { MenuItems } from '@components/mol.header/header.model';
import { Form, FormData } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStore } from '../appointment/appointment.store';
import { FindUserForm, FindUserFormData } from '@components/org.find-user/find-user.component';
import { OptOutUserInput } from '@app/resource/graphql/base-schema';

export interface OptOutUserViewProps {
  submitLoading: boolean;
  notification: {
    type: NotificationType;
    message?: string;
  };
  onSubmit: (data: OptOutUserInput) => void;
}

@Observer()
export class OptOutUserView extends React.Component<OptOutUserViewProps, any> {
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.applicationStore.currentMenuItem = MenuItems.optOut;
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    this.analyticsService.pageView('resetar_senhar_doutor');
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Grid>
          <Row center='xs'>
            <Col xs={12} sm={8} md={6}>
              <Display>Opt-out de médico</Display>

              {this.props.notification.type === 'success' && (
                <Notification type='success' message={this.props.notification.message} />
              )}
              <Separator />

              <FindUserForm
                loading={this.props.submitLoading}
                errorMessage={this.props.notification.type === 'error' && this.props.notification.message}
                onSubmit={this.handleSubmit}
              />
            </Col>
          </Row>
        </Grid>
      </Form>
    );
  }

  private handleSubmit = (formData: FormData<FindUserFormData>) => {
    this.analyticsService.event('opt_out_user_submit_form', 'click');
    if (formData.errors.length === 0) {
      this.props.onSubmit({
        email: formData.data.email,
        crm: formData.data.crm,
        uf: formData.data.state,
      });
    }
  };
}
