import * as React from 'react';

export const useTooltip = (isActive: boolean, isMobile: boolean) => {
  const [isTooltipOpen, setTooltipOpen] = React.useState(false);
  const [tooltipPosition, setTooltipPosition] = React.useState<{ top: number; left: number } | null>(null);
  const iconRef = React.useRef<HTMLSpanElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const updateTooltipPosition = React.useCallback(() => {
    if (iconRef.current && tooltipRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const tooltipHeight = tooltipRef.current.offsetHeight || 0;
      const tooltipWidth = tooltipRef.current.offsetWidth || 0;

      if (tooltipHeight && tooltipWidth) {
        setTooltipPosition({
          top: rect.top - tooltipHeight - 20,
          left: window.innerWidth <= 768 ? rect.left - 196 : rect.left - 28,
        });
      }
    }
  }, []);

  const toggleTooltip = React.useCallback(() => {
    if (!isTooltipOpen) {
      requestAnimationFrame(() => {
        updateTooltipPosition();
      });
    }
    setTooltipOpen(prev => !prev);
  }, [isTooltipOpen, updateTooltipPosition]);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        iconRef.current &&
        !iconRef.current.contains(event.target as Node)
      ) {
        setTooltipOpen(false);
      }
    },
    []
  );

  React.useEffect(() => {
    if (isTooltipOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTooltipOpen, handleClickOutside]);

  React.useEffect(() => {
    if (isActive && isMobile) {
      requestAnimationFrame(() => {
        updateTooltipPosition();
      });
    }
    if (!isActive) {
      setTooltipOpen(false);
    }
  }, [isActive, isMobile, updateTooltipPosition]);

  React.useEffect(() => {
    if (tooltipRef.current) {
      const observer = new MutationObserver(() => {
        updateTooltipPosition();
      });

      observer.observe(tooltipRef.current, { childList: true, subtree: true, attributes: true });

      return () => observer.disconnect();
    }
  }, [updateTooltipPosition]);

  React.useEffect(() => {
    const handleResize = () => {
      if (isTooltipOpen) {
        updateTooltipPosition();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isTooltipOpen, updateTooltipPosition]);

  return { isTooltipOpen, tooltipPosition, toggleTooltip, iconRef, tooltipRef };
};
