// tslint:disable: max-line-length
import { HomeImunocardBodyStyled } from '@components/pag.home';
import { Col, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import {
  TestimonialCellStyled,
  TestimonialImgImunocardStyled,
  TestimonialRowBodyImunocardStyled,
} from './testimonial-row-imunocard.style';
import { HomeImunocardGridStyled } from '../home-imunocard-grid.style';
// tslint:enable: max-line-length

export interface TestimonialRowImunocardProps {
  img: string;
  message: string;
  name: string;
  occupation: string;
  showNavigationButton: boolean;
}

export class TestimonialRowImunocard extends React.Component<TestimonialRowImunocardProps> {
  constructor(props: TestimonialRowImunocardProps) {
    super(props);
  }

  render() {
    return (
      <HomeImunocardGridStyled $noPadding={true} $showNavigationButton={this.props.showNavigationButton}>
        <Row center='xs' middle='xs'>
          <Col xs={12} sm={12} md={8}>
            <TestimonialCellStyled>
              <Row $mb={true}>
                <Col xs={12}>
                  <HomeImunocardBodyStyled center={true}>{this.props.message}</HomeImunocardBodyStyled>
                </Col>
              </Row>
              <Separator />
              <Row start='xs' middle='xs'>
                <Col xs={2}>
                  <TestimonialImgImunocardStyled src={this.props.img} />
                </Col>
                <Col xs={10}>
                  <TestimonialRowBodyImunocardStyled>
                    {this.props.name}
                  </TestimonialRowBodyImunocardStyled>
                  <TestimonialRowBodyImunocardStyled $subtext={true}>
                    {this.props.occupation}
                  </TestimonialRowBodyImunocardStyled>
                </Col>
              </Row>
            </TestimonialCellStyled>
          </Col>
        </Row>
      </HomeImunocardGridStyled>
    );
  }
}
