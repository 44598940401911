import * as React from 'react';
import { Route } from 'react-router';
import { ResetDoctorPasswordData } from '@app/modules/authentication/reset-doctor-password.data';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const RESET_DOCTOR_PASSWORD_PATH = '/resetar-senha-doutor';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Resetar senha de médico`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Altere senha de médico no Vacina Certa.',
    },
  ],
};
const ResetDoctorPassword = SEO(config)(ResetDoctorPasswordData);

export const ResetDoctorPasswordRoute = (
  <Route path={RESET_DOCTOR_PASSWORD_PATH} exact={true} component={ResetDoctorPassword} key='resetDoctorPassword' />
);
