import styled from 'styled-components';
import { Border, Color, Spacing } from '@components/obj.constants';

export const ImunocardListContainerStyled = styled.div`
  background-color: ${Color.White};
  border-radius: ${Border.radius};
  border: 1px solid ${Color.NeutralGrayXSoft};
  margin-top: ${Spacing.Small};
  padding: ${Spacing.XLarge} ${Spacing.Large};
  margin-left: auto;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding: ${Spacing.Medium};
  }
`;
