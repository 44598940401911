import * as React from 'react';

import {
  ProgressCellBadgeStyled,
  ProgressCellChecked,
  ProgressCellNumberStyled,
  ProgressCellStyled,
  ProgressCellTextStyled,
} from './imunocard-progress-cell.component.style';

export type ProgressStatus = 'past' | 'current' | 'future';

export interface ProgressCellProps {
  number: string;
  text: string;
  status: ProgressStatus;
  onClick: () => void;
}

export class ProgressCell extends React.Component<ProgressCellProps, any> {
  constructor(props: ProgressCellProps) {
    super(props);
  }

  render() {
    return (
      <ProgressCellStyled {...this.props}>
        <ProgressCellBadgeStyled {...this.props}>
          <ProgressCellChecked status={this.props.status} />
          <ProgressCellNumberStyled {...this.props}>{this.props.number}</ProgressCellNumberStyled>
        </ProgressCellBadgeStyled>
        <ProgressCellTextStyled {...this.props}>{this.props.text}</ProgressCellTextStyled>
      </ProgressCellStyled>
    );
  }
}
