import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetFaqsQueryVariables = Types.Exact<{
  category: Types.FaqCategoryType;
  page: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
}>;

export type GetFaqsQuery = {
  __typename?: 'Queries';
  GetFaqs?: {
    __typename?: 'GetFaqResponseType';
    totalFaq?: number | null;
    totalPages?: number | null;
    faq?: Array<{
      __typename?: 'FAQType';
      id?: string | null;
      question?: string | null;
      answer?: string | null;
      category?: Types.FaqCategoryType | null;
      reference?: string | null;
    } | null> | null;
  } | null;
};

export const GetFaqsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFaqs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FaqCategoryType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GetFaqs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pageSize' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'faq' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FAQType' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalFaq' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FAQType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FAQType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFaqsQuery, GetFaqsQueryVariables>;
