import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisV, faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  CardContainer,
  CardContent,
  CardDropdownItem,
  CardDropdownMenu,
  CardIcon,
  CardImunocard,
  CardTitle,
  CardType,
} from './recommendation-card.component.style';

interface RecommendationCardProps {
  patientName: string;
  imunocard: string;
  type: string;
  onDownload: () => void;
  onPrint: () => void;
  onOpenModal: () => void;
}

export const RecommendationCard: React.FC<RecommendationCardProps> = ({
  patientName,
  imunocard,
  type,
  onDownload,
  onPrint,
  onOpenModal,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState({ top: 0, left: 0 });

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();

    const rect = e.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.top + window.scrollY + 20,
      left: rect.left + window.scrollX - 115,
    });

    setIsMenuOpen(!isMenuOpen);
  };

  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDownload();
    setIsMenuOpen(false);
  };

  const handlePrint = (e: React.MouseEvent) => {
    e.stopPropagation();
    onPrint();
    setIsMenuOpen(false);
  };

  const handleCardClick = () => {
    if (!isMenuOpen) {
      onOpenModal();
    }
  };

  return (
    <CardContainer onClick={handleCardClick}>
      <CardContent>
        <CardTitle>{patientName}</CardTitle>
        <CardImunocard>{imunocard}</CardImunocard>
        <CardType>{type}</CardType>
      </CardContent>
      <CardIcon onClick={toggleMenu}>
        <FontAwesomeIcon icon={faEllipsisV} />
        {isMenuOpen && (
          <CardDropdownMenu position={menuPosition}>
            <CardDropdownItem onClick={handleDownload}>
              <FontAwesomeIcon icon={faDownload} /> Baixar
            </CardDropdownItem>
            <CardDropdownItem onClick={handlePrint}>
              <FontAwesomeIcon icon={faPrint} /> Imprimir
            </CardDropdownItem>
          </CardDropdownMenu>
        )}
      </CardIcon>
    </CardContainer>
  );
};
