import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Color, Spacing } from '../obj.constants';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface CarouselStyledProps {
  showNavigationButton?: boolean;
  disabled?: boolean;
  $minHeight?: number;
}

export const CarouselStyled = styled.div<CarouselStyledProps>`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  min-height: ${(props: CarouselStyledProps) => `${props.$minHeight}px` || 0};
`;

export const CarouselWrapperStyled = styled.div<CarouselStyledProps>`
  overflow-x: hidden;
  overflow-y: hidden;
  ${(props: CarouselStyledProps) =>
    props.showNavigationButton &&
    `
    position: relative;
  `}
`;

interface CarouselNavigationButtonStyledProps {
  additionalarrowtranslation: number;
  additionalpadding: number;
  disabled: boolean;
  onClick?: () => void;
  name?: string;
  isImunocard?: boolean;
}

export const CarouselNavigationButtonStyled = styled(FontAwesomeIcon).attrs<CarouselNavigationButtonStyledProps>(props => ({
  icon: faAngleDown,
  size: props.isImunocard ? '1x' : '3x',
}))<CarouselNavigationButtonStyledProps>`
  box-sizing: content-box;
  position: absolute;
  padding: ${props =>
    props.isImunocard
      ? + parseInt(Spacing.Large, 0)
      : (props.additionalpadding || 0) + parseInt(Spacing.XLarge, 0)}px;
  top: 50%;
  z-index: 80;
  color: ${props => (props.disabled ? Color.GrayLight : Color.White)};
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.3));
  ${props => props.isImunocard && `
    border-radius: 50%;
    background-color: ${Color.Primary};
  `}

  &:first-child {
    left: ${parseInt(Spacing.XSmall, 0)}px;
    transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(90deg);
      left: ${parseInt(Spacing.Small, 0)}px;
    }
    @media all and (min-width: 64em) {
      left: ${parseInt(Spacing.XXXLarge, 0)}px;
    }
    @media all and (min-width: 75em) {
      left: 120px;
    }
    @media all and (min-width: 85em) {
      left: 170px;
    }
    @media all and (min-width: 90em) {
      left: 250px;
    }
    @media all and (min-width: 95em) {
      left: 300px;
    }
    @media all and (min-width: 100em) {
      left: 340px;
    }
    @media all and (min-width: 110em) {
      left: 370px;
    }
  }

  &:last-child {
    right: ${parseInt(Spacing.XSmall, 0)}px;
    transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(-90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(-90deg);
      right: ${parseInt(Spacing.Small, 0)}px;
    }
    @media all and (min-width: 64em) {
      right: ${parseInt(Spacing.XXXLarge, 0)}px;
    }
    @media all and (min-width: 75em) {
      right: 120px;
    }
    @media all and (min-width: 85em) {
      right: 170px;
    }
    @media all and (min-width: 90em) {
      right: 250px;
    }
    @media all and (min-width: 95em) {
      right: 300px;
    }
    @media all and (min-width: 100em) {
      right: 340px;
    }
    @media all and (min-width: 110em) {
      right: 370px;
    }
  }
`;
