import { H3 } from '@components/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { HomeImunocardBodyStyled } from '@components/pag.home/home-body.style';

export const HowItWorksCellImunocardStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${Spacing.XXXLarge};
  background-color: ${Color.Secondary};
  border-radius: ${Spacing.XXLarge};
  margin-bottom: ${Spacing.XXLarge};
  @media only screen and (max-width: 75em) {
    padding: ${Spacing.XLarge};
  }
`;

export const HowItWorksH3Styled = styled(H3)`
  color: ${Color.CallToAction};
  font-size: ${FontSize.Medium};
  font-weight: ${FontWeight.Bold};
  line-height: ${Spacing.XLarge};
  text-align: left;
  margin-bottom: ${Spacing.Large};
  @media only screen and (max-width: 75em) {
    text-align: center;
  }
`;

export const HowItWorksImageCellImunocardStyled = styled.div<{ $img: string }>`
  background-image: url(${props => props.$img});
  width: 240px;
  height: 240px;
  @media only screen and (max-width: 75em) {
    margin-bottom: ${Spacing.XXLarge};
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HowItWorksCellBodyStyled = styled(HomeImunocardBodyStyled)`
  @media only screen and (max-width: 75em) {
    text-align: center;
  }
`;
