import ApolloClient from 'apollo-client';
import { ContextSetter, setContext } from 'apollo-link-context/lib';
import { createHttpLink } from 'apollo-link-http/lib/httpLink';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory/lib/inMemoryCache';
import { Container, Service } from 'typedi';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { ApolloLink } from 'apollo-link';

// TODO: move this class to a package folder. sub-folder extension?
@Service()
export class ApolloClientBuilderService {
  build(url: string, middleware?: ContextSetter, ssr: boolean = false) {
    const errorLink = onError(({ networkError }) => {
      if (networkError) {
        if ((networkError as any).statusCode === 401) {
          Container.get(AuthenticationStore).logOut();
        }
      }
    });
    const updateTokenLink = new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        const context = operation.getContext();
        const {
          response: { headers },
        } = context;

        const token = headers?.get('Authorization');
        if (token) {
          Container.get(AuthenticationStore).updateToken(token);
        }

        return response;
      });
    });
    const httpLink = createHttpLink({ uri: url + 'graphql' });

    let chainedLink = ApolloLink.from([errorLink, updateTokenLink, httpLink]);

    if (middleware) {
      chainedLink = setContext(middleware).concat(chainedLink);
    }

    const client = new ApolloClient({
      ssrMode: ssr,
      link: chainedLink,
      cache: new InMemoryCache(),
    });

    return client;
  }
}
