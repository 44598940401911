import { DocumentNode } from 'graphql';
import { Service } from 'typedi';

// TODO: Move this class to a package folder. Something related to extensions
@Service()
export class GraphQLDocsService {
  private graphQLDocs: { [key: string]: any };

  constructor() {
    this.graphQLDocs = {
      'login.mutation': require('../../resource/graphql/generated/login.mutation').LoginDocument,
      'all-default-recommendations.query': require('../../resource/graphql/generated/all-default-recommendations.query')
        .AllDefaultRecommendationsDocument,
      'default-recommendation-delete.mutation':
        require('../../resource/graphql/generated/default-recommendation-delete').DefaultRecommendationDeleteDocument,
      'default-recommendation-update.mutation':
        require('../../resource/graphql/generated/default-recommendation-update').DefaultRecommendationUpdateDocument,
      'default-recommendation-create.mutation':
        require('../../resource/graphql/generated/default-recommendation-create').DefaultRecommendationCreateDocument,
      'all-recommendations.query': require('../../resource/graphql/generated/all-recommendations.query')
        .AllRecommendationsDocument,
      'recommendation-create.mutation': require('../../resource/graphql/generated/recommendation-create.mutation')
        .RecommendationCreateDocument,
      'recommendation-update.mutation': require('../../resource/graphql/generated/recommendation-update.mutation')
        .RecommendationUpdateDocument,
      'all-vaccine-families-by-age.query': require('../../resource/graphql/generated/all-vaccine-families-by-age.query')
        .AllVaccineFamiliesByAgeDocument,
      'vaccine-by-special-need.query': require('../../resource/graphql/generated/vaccine-by-special-need.query')
        .VaccineBySpecialNeedDocument,
      'vaccine-by-family.query': require('../../resource/graphql/generated/vaccine-by-family.query')
        .VaccineByFamilyDocument,
      'change-password.mutation': require('../../resource/graphql/generated/change-password.mutation')
        .ChangePasswordDocument,
      'delete-user-data.mutation': require('../../resource/graphql/generated/delete-user-data.mutation')
        .DeleteUserDocument,
      'doctor.query': require('../../resource/graphql/generated/doctor.query').DoctorDocument,
      'forgot-password.mutation': require('../../resource/graphql/generated/forgot-password.mutation')
        .ForgotPasswordDocument,
      'opt-out-user.mutation': require('../../resource/graphql/generated/opt-out-user.mutation').OptOutUserDocument,
      'all-user-data.mutation': require('../../resource/graphql/generated/all-user-data.mutation')
        .AllUserDataRequestDocument,
      'update-password-as-admin.mutation': require('../../resource/graphql/generated/update-password-as-admin.mutation')
        .UpdatePasswordAsAdminDocument,
      'update-email-as-admin.mutation': require('../../resource/graphql/generated/update-email-as-admin.mutation')
        .UpdateEmailAsAdminDocument,
      'user-create.mutation': require('../../resource/graphql/generated/user-create.mutation').UserCreateDocument,
      'update-password.mutation': require('../../resource/graphql/generated/update-password.mutation')
        .UpdatePasswordDocument,
      'user-update.mutation': require('../../resource/graphql/generated/user-update.mutation').UserUpdateDocument,
      'contact.mutation': require('../../resource/graphql/generated/contact.mutation').ContactDocument,
      'doctor-recommendations.query': require('../../resource/graphql/generated/doctor-recommendations.query')
        .DoctorRecommendationsDocument,
      'list-categories-recommendation.query':
        require('../../resource/graphql/generated/list-categories-recommendation.query')
          .ListCategoriesRecommendationDocument,
      'get-faqs.query': require('../../resource/graphql/generated/get-faqs.query').GetFaqsDocument,
      'get-doctor-imunocard-count.query': require('../../resource/graphql/generated/get-doctor-imunocard-count.query')
        .GetDoctorImunocardCountDocument,
    };
  }

  getDocument(documentName: string): DocumentNode {
    if (documentName.indexOf('.query') !== -1) {
      return this.getQuery(documentName);
    } else if (documentName.indexOf('.mutation') !== -1) {
      return this.getMutation(documentName);
    }

    throw new Error('GraphQL document not found in GraphQLDocsService. Verify document name');
  }

  private getQuery(queryName: string): DocumentNode {
    const document = this.graphQLDocs[queryName];
    if (!document) {
      throw new Error(`GraphQL document not found in GraphQLDocsService. Verify document name: ${queryName}`);
    }
    return document;
  }

  private getMutation(mutationName: string): DocumentNode {
    const document = this.graphQLDocs[mutationName];
    if (!document) {
      throw new Error(`GraphQL document not found. Verify document name: ${mutationName}`);
    }
    return document;
  }
}
