import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { H3 } from '@components/atm.typography';
import { isVacinaTheme } from 'config/theme';

export const checkFamilyGroupPadding = Spacing.XLarge;

export const CheckFamilyGroupStyled = styled.div`
  padding: ${isVacinaTheme ? checkFamilyGroupPadding : 0};
  border-top: 1px solid ${isVacinaTheme ? Color.GrayXLight : 'transparent'};
`;

export const CheckFamilyH3Styled = styled(H3)`
  margin-bottom: ${Spacing.Large};
  @media all and (min-width: 48em) {
    margin-bottom: 0;
    margin-top: ${isVacinaTheme ? 0 : Spacing.XXLarge};
  }
`;
