import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LoginMutationVariables = Types.Exact<{
  data: Types.LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutations';
  Login?: {
    __typename?: 'UserAuthenticationType';
    token: string;
    user: {
      __typename?: 'DoctorType';
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      crm: string;
      uf: string;
      rememberMe?: boolean | null;
      repTerritory?: string | null;
      repTerritoryAdult?: string | null;
      newsletterOptIn?: boolean | null;
      smsOptIn?: boolean | null;
    };
  } | null;
};

export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'LoginInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorFields' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uf' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rememberMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repTerritory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repTerritoryAdult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletterOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsOptIn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
