import { AdministrationPlacesRoute } from '@app/modules/administration-places/administration-places.route';
import { ApplicationStore } from '@app/modules/app/application.store';
import { GuardContainerView } from '@app/modules/authentication';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { LoginRoute } from '@app/modules/authentication/login.route';
import { UpdatePasswordRoute } from '@app/modules/authentication/update-password.route';
import { ContactRoute } from '@app/modules/contact/contact.route';
import { VacinaHomeRoute } from '@app/modules/home/vacina-home.route';
import { ImunocardHomeRoute } from '@app/modules/home/imunocard-home.route';
import CookieConsentDatasource, { CookieConsentDataSourceObserver } from '@app/resource/cookie-consent.datasource';
import { FlashWrapper } from '@components/obj.flash-wrapper/flash-wrapper.component';
import { GlobalStyled } from '@components/obj.globals';
import { GridProps } from '@components/obj.grid';
import { ApolloClient } from 'apollo-client';
import { create } from 'mobx-persist';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Container } from 'typedi';
import { ShingrixRoute } from '../administration-places/shingrix/shingrix.route';
import { TermsRoute } from '../terms/terms.route';
import { MaintenanceRoute, MAINTENANCE_PATH } from '../maintenance/maintenance.route';
import { GtmLoad } from './components/gtm-load.component';
import { HotjarLoad } from './components/hotjar-load.component';
import { OptOutUserRoute } from '../authentication/opt-out-user.route';
import { SignupRoute } from '@app/modules/authentication/signup.route';
import { RecommendationHistoryRoute } from '@app/modules/recommendation-history/recommendation-history.route';
import { ImunocardAppointmentRoute } from '@app/modules/appointment-imunocard/appointment-imunocard.route';
import { DefaultRecommendationRoute } from '@app/modules/default-recommendation/default-recommendation.route';
import { RecommendationConfirmationRoute } from '../appointment-imunocard/recommendation-confirmation.route';
import { FrequentQuestionsRoute } from '../frequent-questions/frequent-questions.route';
import { Router } from '@app/core/route/router';
import { TealiumLoad } from './components/tealium-load.component';
import { isVacinaTheme } from 'config/theme';

export class App extends React.Component implements CookieConsentDataSourceObserver {
  private router?: Router = Container.get(Router);
  private apolloClient?: ApolloClient<any> = Container.get(ApolloClient);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);

  private isStoreHydrated = false;

  async componentDidMount() {
    CookieConsentDatasource.subscribeObserver(this);
    await this.setupStoreHydration();

    if (this.shouldRedirectToMaintenance) {
      this.router.replace(MAINTENANCE_PATH);
      return;
    }

    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    let token = urlObj.searchParams.get('token');

    if (token) {
      if (!token.startsWith('Bearer ')) {
        token = `Bearer ${token}`;
      }
      this.authenticationStore?.updateToken(token);
      urlObj.searchParams.delete('token');
      const updatedUrl = urlObj.toString();
      this.authenticationStore?.setLastUrlPathWithToken(urlObj.pathname);
      window.history.replaceState({}, document.title, updatedUrl);
    }
  }

  componentWillUnmount() {
    CookieConsentDatasource.unsubscribeObserver(this);
  }

  render() {
    const theme = process.env.THEME || 'vacina';
    const Routes = {
      vacina: this.shouldRedirectToMaintenance
        ? [MaintenanceRoute]
        : [
            AdministrationPlacesRoute,
            ContactRoute,
            VacinaHomeRoute,
            LoginRoute,
            OptOutUserRoute,
            ShingrixRoute,
            TermsRoute,
            UpdatePasswordRoute,
          ],
      imunocard: [
        AdministrationPlacesRoute,
        ContactRoute,
        ImunocardHomeRoute,
        LoginRoute,
        RecommendationHistoryRoute,
        SignupRoute,
        ImunocardAppointmentRoute,
        DefaultRecommendationRoute,
        RecommendationConfirmationRoute,
        FrequentQuestionsRoute,
        TermsRoute,
        UpdatePasswordRoute,
      ],
    };

    const currentRoutes = Routes[theme] || [];

    return (
      <>
        <ThemeProvider theme={GridProps}>
          <ApolloProvider client={this.apolloClient as any}>
            <>
              <GlobalStyled />

              {/* PS: there are two ways of showing a flash message. You can use
            this flash wrapper, which is a gloabl flash message. You can also
            show a flash message by adding a 'Notification' component in the
            context you want to use it */}
              <FlashWrapper />

              <Switch>
                {currentRoutes}
                <GuardContainerView />
              </Switch>
            </>
          </ApolloProvider>
        </ThemeProvider>
        {!isVacinaTheme && <TealiumLoad />}
        <GtmLoad />
        <HotjarLoad />
      </>
    );
  }

  onFunctionalCookieChanged = () => {
    this.setupStoreHydration();
  };

  private setupStoreHydration = async () => {
    if (CookieConsentDatasource.acceptedFunctional) {
      const hydrate = create();
      await hydrate('authenticationStore', this.authenticationStore).then(() => {
        this.isStoreHydrated = true;
        this.authenticationStore?.setHydrated(true);
        console.log('authentication store hydrated');
      });
      await hydrate('applicationStore', this.applicationStore).then(() => {
        this.isStoreHydrated = true;
        console.log('application store hydrated');
      });
    } else if (this.isStoreHydrated === true) {
      this.authenticationStore.logOut();
      this.applicationStore.cleanStore();
    }
  };

  private shouldRedirectToMaintenance = process.env.THEME === 'vacina' && process.env.ENV === 'production';
}
