import { InputValue } from '@components/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faCheckSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isVacinaTheme } from 'config/theme';
import styled from 'styled-components';

export const checkboxHeightVacinaTheme: number = 32;
export const checkboxHeightImunocardTheme: number = 50;

export const CheckboxFieldStyled = styled.span`
  display: ${isVacinaTheme ? 'block' : 'flex'};
  position: relative;
  align-items: center;
  margin-bottom: ${isVacinaTheme ? Spacing.Large : '0'};

  @media (max-width: 768px) {
    padding: ${isVacinaTheme ? '0' : `${Spacing.Small} 0`};
  }
`;

export const CheckboxFieldTextStyled = styled(InputValue)<{ paddingLeft?: string }>`
  padding-left: ${({ paddingLeft }) => paddingLeft || (isVacinaTheme ? '48px' : '65px')};
  line-height: ${isVacinaTheme ? `${checkboxHeightVacinaTheme}px` : 1.5};

  z-index: 2;
  word-break: break-word;
  cursor: pointer;
  font-size: ${isVacinaTheme ? FontSize.Medium : FontSize.Small};
  font-weight: ${isVacinaTheme ? FontWeight.Bold : FontWeight.Normal};
  display: ${isVacinaTheme ? 'block' : 'flex'};
  align-items: center;
`;

export const DefaultCheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckSquare })`
  position: absolute;
  font-size: ${isVacinaTheme ? `${checkboxHeightVacinaTheme}px` : Spacing.XLarge};
  color: ${Color.Accessory};
  z-index: 1;
  opacity: 0;
`;

export const DefaultCheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faSquare })`
  position: absolute;
  font-size: ${isVacinaTheme ? `${checkboxHeightVacinaTheme}px` : Spacing.XLarge};
  color: ${Color.Secondary};
  z-index: 0;
  opacity: 1;
`;

export const CheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckCircle })`
  position: absolute;
  font-size: ${isVacinaTheme ? checkboxHeightVacinaTheme : checkboxHeightImunocardTheme}px;
  color: ${isVacinaTheme ? Color.Success : Color.Info};
  z-index: 1;
  opacity: 0;
`;

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faPlusCircle })`
  position: absolute;
  font-size: ${checkboxHeightVacinaTheme}px;
  z-index: 0;
  opacity: 1;
  color: ${Color.GrayXDark};
`;

export const ImunocardCheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faPlus })`
  position: absolute;
  font-size: ${FontSize.Medium};
  z-index: 0;
  opacity: 1;
  color: ${Color.NeutralGrayMedium};
  background: ${Color.NeutralGrayXSoft};
  border-radius: 50%;
  padding: ${Spacing.Large};
`;

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${CheckboxCheckedStyled}, &:checked + ${DefaultCheckboxCheckedStyled} {
    opacity: 1;

    ~ ${CheckboxUncheckedStyled}, ~ ${DefaultCheckboxUncheckedStyled}, ~ ${ImunocardCheckboxUncheckedStyled} {
      opacity: 0;
    }
  }

  &:checked + ${DefaultCheckboxCheckedStyled} {
    opacity: 1;

    ~ ${DefaultCheckboxUncheckedStyled} {
      opacity: 0;
    }
  }
`;
