import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { FontFamily, FontSize, FontWeight } from '@components/obj.constants';
import { LinkButtonProps } from './link-button.component';
import { isVacinaTheme } from 'config/theme';

export const LinkButtonStyled = styled.a`
  user-select: none;
  display: ${(props: LinkButtonProps) => (props.expanded ? 'block' : 'inline-block')};
  min-height: ${(props: LinkButtonProps) => (props.noHeight ? '0' : '50px')};
  text-align: ${(props: LinkButtonProps) => props.textAlign || 'center'};
  padding: ${(props: LinkButtonProps) => (props.noPadding ? '0' : '10px')};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Medium};
  cursor: pointer;
  color: ${(props: LinkButtonProps) => (props.light ? Color.White : Color.Primary)};
  text-decoration: underline;
  ${isVacinaTheme && `line-height: ${Spacing.XLarge};`}

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    height: 100%;
  }

  &:hover {
    color: ${(props: LinkButtonProps) => (props.light ? Color.Gray : Color.Secondary)};
  }

  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }

  @media print and (max-width: 148mm) {
    font-size: ${FontSize.Small};
  }
`;

export const LinkButtonContentStyled = styled.span`
  word-break: break-word;
  & .fa {
    padding-right: ${Spacing.Small};
  }
`;
