import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const StepperStyled = styled.div`
  padding: ${Spacing.Large};
  background-color: white;
  border-bottom: 1px solid ${Color.GrayLight};
  @media only screen and (min-width: 48em) {
    padding: ${Spacing.XLarge};
  }
`;
