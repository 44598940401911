import { Col, Row } from '@components/obj.grid';
import * as React from 'react';
import { HowItWorksCellImunocard } from './how-it-works-cell-imunocard.component';
import { HomePublicType } from '@app/modules/home/imunocard-home.view';
import { doctorPublicDescriptions, generalPublicDescriptions } from './descriptions';

export interface HowItWorksRowImunocardProps {
  homePublicType: number;
  loggedIn: boolean;
}

export class HowItWorksRowImunocard extends React.Component<HowItWorksRowImunocardProps, any> {
  constructor(props: HowItWorksRowImunocardProps) {
    super(props);
  }

  render() {
    const descriptions = this.props.homePublicType === HomePublicType.Doctor ? doctorPublicDescriptions : generalPublicDescriptions;

    return (
      <Row center='xs'>
        <Col xs={12} sm={12} md={8}>
          {descriptions.map((item, index) => (
            <HowItWorksCellImunocard
              key={index}
              img={item.img}
              title={item.title}
              description={item.description}
            />
          ))}
        </Col>
      </Row>
    );
  }
}
