import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { H2 } from '@components/atm.typography';

export const HomeImunocardBodyStyled = styled.p`
  color: ${Color.White};
  text-align: left;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  margin: 0;
  font-weight: ${FontWeight.Lighter};
  line-height: ${Spacing.XLarge};
`;

export const HomeImunocardH2Styled = styled(H2)`
  color: ${Color.White};
  font-size: ${FontSize.Large};
  font-weight: ${FontWeight.Bold};
  line-height: ${Spacing.XLarge};
  text-align: center;
`;
