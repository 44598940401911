import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import * as React from 'react';
import { Route } from 'react-router';
import { ImunocardAppointmentView } from './appointment-imunocard.view';
import { isVacinaTheme } from 'config/theme';

export const IMUNOCARD_APPOINTMENT_PATH = '/recomendacao';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Recomendações personalizadas`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para orientações personalizadas de vacinação',
    },
  ],
};

const ImunocardAppointment = SEO(config)(ImunocardAppointmentView);

export const ImunocardAppointmentRoute = (
  <Route path={IMUNOCARD_APPOINTMENT_PATH} exact={false} component={ImunocardAppointment} key='imunocard-appointment' />
);
