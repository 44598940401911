import styled from 'styled-components';
import { Border, Color, Spacing } from '@components/obj.constants';

export const ContentWrapperStyled = styled.div`
  background-color: ${Color.White};
  border-radius: ${Border.radiusLarge};
  margin: ${Spacing.XXLarge} auto;
  width: fit-content;
  max-width: 650px;
  padding: ${Spacing.Large};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: ${Spacing.Medium};
    margin: ${Spacing.XLarge} auto;
    max-width: 90%;
  }
`;
