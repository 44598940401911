import { Col, Row } from '@components/obj.grid';
import * as React from 'react';
import {
  HowItWorksCellBodyStyled,
  HowItWorksCellImunocardStyled,
  HowItWorksH3Styled,
  HowItWorksImageCellImunocardStyled,
} from './how-it-works-cell-imunocard.style';

export interface HowItWorksCellImunocardProps {
  img: string;
  title: string;
  description: string;
}

export class HowItWorksCellImunocard extends React.Component<HowItWorksCellImunocardProps, any> {
  constructor(props: HowItWorksCellImunocardProps) {
    super(props);
  }

  render() {
    return (
      <HowItWorksCellImunocardStyled>
        <Row end={'md'}>
          <Col xs={12} lg={5}>
            <HowItWorksImageCellImunocardStyled $img={this.props.img}/>
          </Col>
          <Col xs={12} lg={7}>
            <HowItWorksH3Styled>{this.props.title}</HowItWorksH3Styled>
            <HowItWorksCellBodyStyled center={true}>{this.props.description}</HowItWorksCellBodyStyled>
          </Col>
        </Row>
      </HowItWorksCellImunocardStyled>
    );
  }
}
