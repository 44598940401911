import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@components/obj.constants';

export const PaginationContainer = styled.div`
  display: flex;
  gap: ${Spacing.XSmall};
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Large};
`;

export const PageButton = styled.button<{ selected: boolean }>`
  position: relative;
  border-radius: ${Spacing.XSmall};
  padding: ${Spacing.XSmall} ${Spacing.Medium};
  border: none;
  background-color: ${({ selected }) => (selected ? Color.Primary : 'transparent')};
  color: ${({ selected }) => (selected ? Color.White : Color.Black)};
  font-size: ${FontSize.Small};
  aspect-ratio: 1;
  min-height: ${Spacing.XXLarge};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  transition:
    background-color 0.3s,
    color 0.3s;
`;

export const Ellipsis = styled.span`
  font-size: ${FontSize.Small};
  color: ${Color.NeutralGrayStrong};
`;

export const SelectedIndicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Color.Primary};
  z-index: -1;
`;
