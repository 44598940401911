import styled, { css } from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { isVacinaTheme } from 'config/theme';

export const DisplayWhite = styled.p`
  font-size: ${FontSize.XLarge};
  color: ${Color.White};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin-bottom: ${Spacing.XXLarge};
  margin-top: ${Spacing.XXLarge};
  text-align: center;

  @media only screen and (min-width: 48em) {
    font-size: ${FontSize.XXLarge};
  }
`;

export const FeaturedH2 = styled.h2`
  color: ${Color.Secondary};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin-top: ${Spacing.XXXLarge};
  margin-bottom: ${Spacing.XXXLarge};
  text-align: center;

  @media only screen and (min-width: 48em) {
    font-size: ${FontSize.XXLarge};
  }
`;

export const H2Imunocard = styled.h2`
  color: ${Color.NeutralStrong};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: ${Spacing.XXXLarge};
  margin: 0;
`;

export const H1 = styled.h1`
  color: ${Color.Secondary};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${isVacinaTheme ? FontWeight.Bold : FontWeight.Normal};
  margin-bottom: 0;
  margin-top: ${isVacinaTheme ? Spacing.XXLarge : Spacing.XLarge};
  word-break: break-word;

  @media only screen and (min-width: 48em) {
    margin-top: ${isVacinaTheme ? Spacing.XXXLarge : Spacing.XLarge};
    font-size: ${isVacinaTheme ? FontSize.XXLarge : FontSize.XLarge};
  }
`;

export const H2Style = `
  color: ${Color.Secondary};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${Spacing.Large};
  margin-top: ${Spacing.XXLarge};

  @media only screen and (min-width: 48em) {
    font-size: ${FontSize.XLarge};
  }
`;

export const H2 = styled.h2`
  ${H2Style};

  @media print {
    font-size: ${FontSize.Small};
    font-family: ${FontFamily.Primary};
    font-weight: ${FontWeight.Bold};
    margin-top: ${Spacing.Large};
    margin-bottom: 0;
    text-transform: uppercase;
  }
`;

export interface TypographyProps {
  $light?: boolean;
  center?: boolean;
}

export const H3 = styled.h3<TypographyProps>`
  color: ${(props: TypographyProps) => (props.$light ? Color.White : Color.Secondary)};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin: 0;

  @media print and (max-width: 148mm) {
    font-size: ${FontSize.Small};
  }
`;

export const H4 = styled.h4<TypographyProps>`
  color: ${(props: TypographyProps) => (props.$light ? Color.White : Color.GrayXDark)};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  text-decoration: none;
  margin: 0;
`;

export const BodyStyle = css<TypographyProps>`
  color: ${(props: TypographyProps) => (props.$light ? Color.White : Color.GrayXDark)};
  text-align: ${(props: TypographyProps) => (props.center ? 'center' : 'inherit')};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  margin: 0;
  line-height: ${Spacing.XXLarge};
`;

export const Body = styled.p<TypographyProps>`
  ${BodyStyle}

  @media print {
    font-size: ${FontSize.XSmall};
    line-height: normal;
  }

  & + & {
    margin-top: ${Spacing.Small};
  }
`;

export const Quote = styled.q`
  quotes: '“' '”' '‘' '’';
  color: ${Color.Secondary};

  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  @media all and (min-width: 48em) {
    font-size: ${FontSize.Large};
  }

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
`;

export const Caption = styled.label`
  color: ${isVacinaTheme ? Color.GrayDark : Color.NeutralGrayStrong};
  font-size: ${isVacinaTheme ? FontSize.Medium : FontSize.Small};
  font-family: ${isVacinaTheme ? FontFamily.Secondary : FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  display: block;
  ${isVacinaTheme
    ? `margin-top: ${Spacing.Small};`
    : `line-height: ${Spacing.Large};
    margin: 0;
  `}
`;

export const PhoneNumber = styled.label`
  color: ${Color.Secondary};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  margin-top: ${Spacing.Small};

  @media print {
    font-size: ${FontSize.XSmall};
  }
`;

export const StepperActive = styled.label`
  color: ${Color.Secondary};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.Small};
`;

export const StepperInactive = styled.label`
  color: ${Color.Gray};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.Small};
  font-size: ${FontSize.XSmall};

  @media all and (min-width: 48em) {
    font-size: ${FontSize.Medium};
  }
`;

export const StatusProgress = styled.label`
  color: ${Color.GrayDark};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.Small};
`;

export const NavLink = styled.a`
  color: ${Color.White};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};

  @media (max-width: 900px) {
    font-size: ${FontSize.Small};
  }
`;

export const Distance = styled.label`
  color: ${Color.GrayDark};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  margin-top: ${Spacing.Small};
`;

export const InputLabel = styled.label`
  color: ${isVacinaTheme ? Color.GrayXDark : Color.NeutralStrong};
  display: block;
  font-size: ${isVacinaTheme ? FontSize.Medium : FontSize.Small};
  font-family: ${isVacinaTheme ? FontFamily.Secondary : FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin-bottom: ${isVacinaTheme ? Spacing.XSmall : Spacing.Small};
  text-align: left;
`;

export const InputValue = styled.label`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
`;

export const InputPlaceholder = styled.label`
  color: ${Color.Gray};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
`;

export const InputCaption = styled.label`
  color: ${isVacinaTheme ? Color.Gray : Color.NeutralGrayStrong};
  font-size: ${isVacinaTheme ? FontSize.Small : FontSize.XSmall};
  font-family: ${isVacinaTheme ? FontFamily.Secondary : FontFamily.Primary};
  font-weight: 300;
  margin-top: ${isVacinaTheme ? Spacing.XSmall : Spacing.Small};
  display: block;
  text-align: left;
  ${!isVacinaTheme && `line-height: ${Spacing.Large};`}
`;

export const InputCaptionError = styled(InputCaption)`
  color: ${Color.Alert};
`;

export const BadgeText = styled.label`
  color: ${Color.Secondary};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  display: block;
`;

export const Label = styled.label`
  color: ${Color.GrayDark};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
`;

export const RecomendationVacineTitle = styled.p`
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${Spacing.Small};

  @media print {
    margin-top: ${Spacing.Small};
    margin-bottom: ${Spacing.XSmall};
    font-size: ${FontSize.XSmall};
  }
`;

export const RecomendationVacineDescription = styled.p`
  color: ${Color.Black};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  margin-top: 0;
`;

export const Display = styled(H1)`
  font-family: ${FontFamily.Primary};
  margin-top: ${Spacing.XLarge};
`;

export const ListItem = styled.li`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Secondary};
  margin: 0;
  line-height: ${Spacing.XXLarge};
`;

export const TooltipText = styled.p`
  color: ${Color.White};
  font-size: ${FontSize.Small};
  font-family: ${isVacinaTheme ? FontFamily.Secondary : FontFamily.Primary};
  line-height: ${isVacinaTheme ? Spacing.XXLarge : Spacing.XLarge};
  margin-top: 0;
  margin-bottom: 0;
`;
