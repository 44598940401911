import { H4 } from '@components/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { Grid } from '@components/obj.grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// value measured empirically
const desktopToMobileMenuBreakpoint = `1300px`;

export const HeaderImunocardStyled = styled.div<{ $home: boolean }>`
  min-height: 90px;
  background: ${Color.Secondary};
  padding: 0;
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    padding: 0 ${Spacing.Large};
    justify-content: space-between;
  }

  @media print {
    display: none;
  }
`;

export const HeaderImunocardLogoLinkStyled = styled.a`
  cursor: pointer;
  padding-left: ${Spacing.XSmall};
  padding-right: ${Spacing.XSmall};
  align-self: center;
`;

export const HeaderImunocardLogoStyled = styled.img`
  height: 20px;
`;

export const HeaderImunocardLinkContainerStyled = styled.div<{ $end: boolean}>`
  height: 40px;
  margin-top: ${Spacing.Medium};

  ${props => props.$end && `
    margin-left: auto;
  `}

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    display: none;
  }
`;

export const HeaderImunocardHamburguerContainerStyled = styled.div`
  display: none;

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    display: flex;
  }
`;

export const HeaderImunocardHamburguerIconStyled = styled(FontAwesomeIcon)`
  font-size: ${FontSize.Medium};
  padding-right: 0;
`;

export const HeaderImunocardSelectedLineStyled = styled.div`
  width: 30px;
  height: 4px;
  background-color: ${Color.CallToAction};
  margin: auto;
  border-radius: 16px;
`;

const recommendationMenuWidth = 330;

export const HeaderImunocardRecommendationDropdownStyled = styled.div`
  position: absolute;
  z-index: 99;
  vertical-align: bottom;
  min-width: 0;
  max-height: 0;
  width: auto;
  right: -50%;
  vertical-align: bottom;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  opacity: 1;
  border-radius: ${Spacing.Small};
`;

export const HeaderImunocardRecommendationDropdownListStyled = styled.a`
  display: inline-block;
  min-height: 44px;
  padding: ${Spacing.Large};
  cursor: pointer;
  text-decoration: none;
  min-width: ${recommendationMenuWidth}px;
  background-color: ${Color.Primary};

  &:hover {
    background-color: ${Color.Accessory2};
  }
`;

export const HeaderImunocardSelectedStyled = styled.div<{ $isRecommendation: boolean }>`
  width: auto;
  display: inline-block;
  margin-left: ${Spacing.XLarge};
  position: relative;

  ${props => props.$isRecommendation && `
    &:hover ${HeaderImunocardRecommendationDropdownStyled}{
      max-height: 800px !important;
    }
  `}
`;

const menuWidth = 220;

export const HeaderImunocardDropdownStyled = styled.div`
  position: absolute;
  z-index: 99;
  vertical-align: bottom;
  min-width: 0;
  max-height: 0;
  width: ${menuWidth}px;
  right: -15%;
  vertical-align: bottom;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  opacity: 1;
  border-radius: ${Spacing.Small};
`;

export const HeaderImunocardDropdownListStyled = styled.a`
  display: inline-block;
  min-height: 44px;
  padding: ${Spacing.Large};
  cursor: pointer;
  text-decoration: none;
  min-width: 250px;
  background-color: ${Color.Primary};

  &:hover {
    background-color: ${Color.Accessory2};
  }
`;

export const HeaderImunocardProfileStyled = styled.div<{ $home: boolean }>`
  margin-top: ${props => (props.$home ? '0' : Spacing.Medium)};
  &:hover ${HeaderImunocardDropdownStyled} {
    max-height: 800px !important;
  }
`;

export const HeaderImunocardHideableMenuItemText = styled.div`
  display: initial;
  margin-left: ${Spacing.Small};
  margin-right: ${Spacing.Small};

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const HeaderImunocardHamburguerDropdownStyled = styled.div<{ $hamburguerMenuOpened: boolean }>`
  position: absolute;
  z-index: 8;
  vertical-align: bottom;
  min-width: 0;
  max-height: ${props => (props.$hamburguerMenuOpened ? '800px' : '0')};
  width: ${menuWidth}px;
  right: 30px;
  vertical-align: bottom;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height ${props => (props.$hamburguerMenuOpened ? '0.4s' : '0')} ease-in-out;
  opacity: 1;
  border-radius: ${Spacing.Small};
  top: 60px;
`;

export const HeaderImunocardHamburguerDropdownListStyled = styled.a`
  display: flex;
  min-height: 44px;
  padding: ${Spacing.Large};
  cursor: pointer;
  text-decoration: none;
  min-width: 220px;
  background-color: ${Color.Primary};
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${Color.Accessory2};
  }
`;

export const HeaderImunocardHamburguerOpacityStyled = styled.div<{ $hamburguerMenuOpened: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  background-color: #000;
  opacity: ${props => (props.$hamburguerMenuOpened ? '0.5' : '0')};
  transition: all 0.4s ease-out;
  visibility: ${props => (props.$hamburguerMenuOpened ? 'visible' : 'hidden')};
`;

export const HeaderImunocardH4Styled = styled(H4)<{ $recommendationMobileMenuOpened: boolean, $mobileSubmenu: boolean}>`
  font-size: ${props => (props.$recommendationMobileMenuOpened && props.$mobileSubmenu ? FontSize.XSmall : FontSize.Small)};
  line-height: ${Spacing.XLarge};
  font-weight: ${FontWeight.Normal};

  ${props => props.$recommendationMobileMenuOpened && props.$mobileSubmenu && `
    font-weight: ${FontWeight.Normal};
  `}

  &:hover {
    font-weight: ${FontWeight.Bold};
  }
`;

export const HeaderImunocardMiddleItemsStyled = styled.div`
  display: inline-block;
  margin-top: ${Spacing.Medium};
`;

export const HeaderImunocardGridStyled = styled(Grid)`
  padding: 0 ${Spacing.Large};
  @media all and (max-width: ${desktopToMobileMenuBreakpoint}) {
    padding: 0 0 ${Spacing.Large} 0;
  }
`;

export const HeaderSegmentedControlStyled = styled.div`
  display: none;

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    padding: 0 ${Spacing.Large};
    display: flex;
  }
`;

export const HeaderImunocardContentContainer = styled.div`
  background: ${Color.Secondary};
`;

export const HeaderImunocardMenuIconStyled = styled(FontAwesomeIcon)`
  margin-left: ${Spacing.Small};
`;
