// tslint:disable:prefer-template
// tslint:disable:no-increment-decrement
import { debounce } from '@app/core/patterns/debounce';
import { hasWindow } from '@components/utils';
import * as React from 'react';
import { CarouselNavigationButtonStyled, CarouselStyled, CarouselWrapperStyled } from './carousel.component.style';

type CarouselPosition = 'previousPrevious' | 'previous' | 'current' | 'next' | 'nextNext';

interface SiblingFrames {
  previousPrevious: React.ReactInstance;
  previous: React.ReactInstance;
  current: React.ReactInstance;
  next: React.ReactInstance;
  nextNext: React.ReactInstance;
}

interface CarouselProps {
  auto?: boolean;
  /** it is not recommended to use loop if width < 1 && frames.length <= 2 */
  loop?: boolean;
  interval?: number;
  duration?: number;
  children?: any;
  onIndexChanged?: any;
  additionalArrowTranslationY?: number;
  scrollXOffset?: number;
  showNavigationButton?: boolean;
  disablePan?: boolean;
  /** between 0 and 1 */
  width: number;
  /** this props set how much an item on the border will be translated in the carousel */
  borderItemsXTranslation: number;
  /** the scale of other items when not current between 0 and 1 */
  scale: number;
  onClick?: () => void;
  isImunocard?: boolean;
}

interface CarouselState {
  frames: any[];
  maxHeight: number;
  currentIndex: number;
}

export class Carousel extends React.Component<CarouselProps, CarouselState> {
  public static defaultProps: Partial<CarouselProps> = {
    auto: false,
    loop: true,
    interval: 6000,
    duration: 500,
    borderItemsXTranslation: 0,
    scale: 1,
  };

  static getDerivedStateFromProps(nextProps: CarouselProps, prevState: CarouselState) {
    if (prevState.frames.length === nextProps.children.length) {
      return null;
    }

    return { frames: nextProps.children };
  }

  public state;
  private mounted: boolean = false;
  private wrapper: any;
  private touchDown: boolean = false;

  private frameWidth: number = 0;
  private slideTimeoutID: any;
  private startX: number;
  private deltaX: number;
  private movingFrames: SiblingFrames;

  private debouncedHandleWindowResize: () => void;

  constructor(props: CarouselProps) {
    super(props);

    this.state = {
      frames: [].concat(props.children || []),
      maxHeight: 0,
      currentIndex: 0,
    };

    this.mounted = false;

    if (!props.loop && props.auto) {
      console.warn('[Carousel] Auto-slide only works in loop mode.');
    }
    this.debouncedHandleWindowResize = debounce(this.resetViewState);
  }

  componentDidMount() {
    this.mounted = true;
    this.prepareAutoSlide();

    if (hasWindow()) {
      window.addEventListener('mouseup', this.onTouchEnd, false);
      window.addEventListener('touchend', this.onTouchEnd, false);
      window.addEventListener('resize', this.onWindowResize, false);
    }

    // Hide all frames to compute maxHeight
    let maxHeight = 0;
    for (let i = 0; i < this.state.frames.length; i++) {
      const frame = this.refs['f' + i] as React.HTMLAttributes<HTMLElement>;
      if (i > 0) {
        frame.style.opacity = 0;
      }

      const { height } = hasWindow() ? window.getComputedStyle(frame as unknown as Element) : { height: '0' };

      const parsedHeight = parseFloat(height.split('px')[0]);
      if (parsedHeight > maxHeight) {
        maxHeight = parsedHeight;
      }
    }

    this.setState({ maxHeight }, () => this.prepareSiblingFrames());
  }

  render() {
    const { frames } = this.state;
    const arrowPaddingMultiplier = 0.5;

    const minHeight = Math.max(
      Math.max(...Object.keys(this.refs).map(key => (this.refs[key] as HTMLElement).offsetHeight)),
      0,
    );

    return (
      <CarouselWrapperStyled $showNavigationButton={this.props.showNavigationButton}>
        {this.props.showNavigationButton && (
          <CarouselNavigationButtonStyled
            additionalarrowtranslation={this.props.additionalArrowTranslationY}
            additionalpadding={this.props.borderItemsXTranslation * arrowPaddingMultiplier}
            disabled={this.shouldDisableButton(false)}
            onClick={this.handleNavigationButtonClick(false)}
            isImunocard={this.props.isImunocard}
          />
        )}
        <CarouselStyled
          // tslint:disable-next-line: jsx-no-lambda
          ref={it => (this.wrapper = it)}
          onTouchStart={this.onTouchStart}
          onMouseDown={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onMouseMove={this.onTouchMove}
          $minHeight={minHeight}
        >
          {frames.map((frame, i) => {
            return (
              <div
                ref={'f' + i}
                key={i}
                style={
                  i === 0
                    ? { position: 'relative', width: `${this.props.width * 100}%`, overflow: 'hidden' }
                    : { position: 'absolute', top: 0, width: `${this.props.width * 100}%`, overflow: 'hidden' }
                }
              >
                {frame}
              </div>
            );
          })}
        </CarouselStyled>
        {this.props.showNavigationButton && (
          <CarouselNavigationButtonStyled
            additionalarrowtranslation={this.props.additionalArrowTranslationY}
            additionalpadding={this.props.borderItemsXTranslation * arrowPaddingMultiplier}
            disabled={this.shouldDisableButton(true)}
            onClick={this.handleNavigationButtonClick(true)}
            isImunocard={this.props.isImunocard}
          />
        )}
      </CarouselWrapperStyled>
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    this.clearAutoTimeout();

    if (hasWindow()) {
      window.removeEventListener('mouseup', this.onTouchEnd, false);
      window.removeEventListener('touchend', this.onTouchEnd, false);
      window.removeEventListener('resize', this.onWindowResize, false);
    }
  }

  onTouchStart = (e: any) => {
    if (this.props.disablePan) {
      return;
    }

    if (this.state.frames.length < 2) {
      return;
    }

    this.touchDown = true;

    this.clearAutoTimeout();
    this.updateFrameSize();
    this.prepareSiblingFrames();

    const { pageX } = (e.touches && e.touches[0]) || e;
    this.startX = pageX;
    this.deltaX = 0;
  };

  onTouchMove = (e: any) => {
    if ((e.touches && e.touches.length > 1) || !this.touchDown) {
      return;
    }

    this.clearAutoTimeout();

    const { pageX } = (e.touches && e.touches[0]) || e;
    const deltaX = pageX - this.startX;
    if (this.props.scrollXOffset) {
      if (Math.abs(deltaX) > this.props.scrollXOffset) {
        this.deltaX = deltaX;
      }
    } else {
      this.deltaX = deltaX;
    }

    // when reach frames edge in non-loop mode, reduce drag effect.
    if (!this.props.loop) {
      if (this.state.currentIndex === this.state.frames.length - 1) {
        if (this.deltaX < 0) {
          this.deltaX /= 3;
        }
      }
      if (this.state.currentIndex === 0) {
        if (this.deltaX > 0) {
          this.deltaX /= 3;
        }
      }
    }

    this.moveFramesBy(this.deltaX);
  };

  onWindowResize = () => {
    this.debouncedHandleWindowResize();
  };

  onTouchEnd = () => {
    if (!this.touchDown) {
      return;
    }
    this.touchDown = false;

    const direction = this.decideEndPosition();
    if (direction) {
      this.transitFramesTowards(direction);
    }
    setTimeout(() => this.prepareAutoSlide(), this.props.duration);
  };

  autoSlide = (rel: 'next' | 'previous') => {
    this.clearAutoTimeout();
    this.transitFramesTowards(rel === 'previous' ? 'right' : 'left');

    // prepare next move after animation
    setTimeout(() => this.prepareAutoSlide(), this.props.duration);
  };

  next = () => {
    const { frames } = this.state;

    if (!this.props.loop && this.state.currentIndex === frames.length - 1) {
      return;
    }
    this.autoSlide('next');
  };

  previous = () => {
    if (!this.props.loop && this.state.currentIndex === 0) {
      return;
    }
    this.autoSlide('previous');
  };

  decideEndPosition() {
    const { frames } = this.state;
    const { loop } = this.props;

    if (!loop) {
      if (this.state.currentIndex === 0 && this.deltaX > 0) {
        return 'origin';
      }
      if (this.state.currentIndex === frames.length - 1 && this.deltaX < 0) {
        return 'origin';
      }
    }
    if (Math.abs(this.deltaX) < (this.props.borderItemsXTranslation || 50)) {
      if (this.props.onClick) {
        this.props.onClick();
      }
      return 'origin';
    }
    return this.deltaX > 0 ? 'right' : 'left';
  }

  moveFramesBy(deltaX: number) {
    const { previousPrevious, previous, current, next, nextNext } = this.movingFrames;
    this.transform(current, deltaX, 0, 'current');

    const frameCount = this.state.frames.length;
    switch (frameCount) {
      case 3:
        const deltaToMakeNextOrPreviousDisappear = this.props.borderItemsXTranslation;

        if (deltaX > deltaToMakeNextOrPreviousDisappear) {
          this.transform(previousPrevious, deltaX - 2 * this.frameWidth, 0, 'previousPrevious');
        } else {
          this.transform(next, deltaX + this.frameWidth, 0, 'next');
        }

        if (deltaX < -deltaToMakeNextOrPreviousDisappear) {
          this.transform(nextNext, deltaX + 2 * this.frameWidth, 0, 'nextNext');
        } else {
          this.transform(previous, deltaX - this.frameWidth, 0, 'previous');
        }
        break;
      case 4:
        this.transform(previous, deltaX - this.frameWidth, 0, 'previous');
        this.transform(next, deltaX + this.frameWidth, 0, 'next');

        if (deltaX > 0) {
          this.transform(previousPrevious, deltaX - 2 * this.frameWidth, 0, 'previousPrevious');
        } else {
          this.transform(nextNext, deltaX + 2 * this.frameWidth, 0, 'nextNext');
        }
        break;
      default:
        this.transform(previousPrevious, deltaX - 2 * this.frameWidth, 0, 'previousPrevious');
        this.transform(previous, deltaX - this.frameWidth, 0, 'previous');
        this.transform(next, deltaX + this.frameWidth, 0, 'next');
        this.transform(nextNext, deltaX + 2 * this.frameWidth, 0, 'nextNext');
        break;
    }
  }

  prepareAutoSlide() {
    if (this.state.frames.length < 2) {
      return;
    }

    this.resetViewState();

    // auto slide only available in loop mode
    if (this.mounted && this.props.loop && this.props.auto) {
      this.slideTimeoutID = setTimeout(this.autoSlide, this.props.interval);
    }
  }

  resetViewState() {
    this.clearAutoTimeout();
    this.updateFrameSize();
    this.prepareSiblingFrames();
  }

  clearAutoTimeout() {
    clearTimeout(this.slideTimeoutID);
  }

  updateFrameSize() {
    if (this.wrapper) {
      const { width } = hasWindow() ? window.getComputedStyle(this.wrapper) : { width: '0' };
      this.frameWidth = parseFloat(width.split('px')[0]);
    }
  }

  prepareSiblingFrames() {
    this.movingFrames = {
      previousPrevious: this.refs['f' + this.getFrameId('previousPrevious')],
      previous: this.refs['f' + this.getFrameId('previous')],
      current: this.refs['f' + this.getFrameId()],
      next: this.refs['f' + this.getFrameId('next')],
      nextNext: this.refs['f' + this.getFrameId('nextNext')],
    };

    if (!this.props.loop) {
      if (this.state.currentIndex === 0) {
        this.movingFrames.previousPrevious = undefined;
        this.movingFrames.previous = undefined;
      }
      if (this.state.currentIndex === 1) {
        this.movingFrames.previousPrevious = undefined;
      }
      if (this.state.currentIndex === this.state.frames.length - 2) {
        this.movingFrames.nextNext = undefined;
      }
      if (this.state.currentIndex === this.state.frames.length - 1) {
        this.movingFrames.next = undefined;
        this.movingFrames.nextNext = undefined;
      }
    }

    /**
     * previousPrevious and nextNext are only needed if the width is less than 100% and there are more than 2 items
     */
    if (this.props.width === 1 || this.state.frames.length === 2) {
      this.movingFrames.previousPrevious = undefined;
      this.movingFrames.nextNext = undefined;
    }

    this.movingFrames = this.movingFrames;

    // prepare frames position
    this.transform(this.movingFrames.previous, -this.frameWidth, 0, 'previous');
    this.transform(this.movingFrames.current, 0, 0, 'current');
    this.transform(this.movingFrames.next, this.frameWidth, 0, 'next');

    return this.movingFrames;
  }

  getFrameId(pos?: CarouselPosition): number {
    const { frames } = this.state;
    const total = frames.length;
    switch (pos) {
      case 'previousPrevious':
        return (this.state.currentIndex - 2 + total) % total;
      case 'previous':
        return (this.state.currentIndex - 1 + total) % total;
      case 'next':
        return (this.state.currentIndex + 1) % total;
      case 'nextNext':
        return (this.state.currentIndex + 2) % total;
      default:
        return this.state.currentIndex;
    }
  }

  transitFramesTowards(direction: string) {
    const { previousPrevious, previous, current, next, nextNext } = this.movingFrames;
    const { duration } = this.props;

    let newCurrentId = this.state.currentIndex;
    switch (direction) {
      case 'left':
        this.transform(current, -this.frameWidth, 0, 'current', duration);
        this.transform(next, 0, 0, 'next', duration);
        this.transform(nextNext, this.frameWidth, 0, 'nextNext', duration);
        newCurrentId = this.getFrameId('next');
        break;
      case 'right':
        this.transform(previousPrevious, -1 * this.frameWidth, 0, 'previousPrevious', duration);
        this.transform(previous, 0, 0, 'previous', duration);
        this.transform(current, this.frameWidth, 0, 'current', duration);
        newCurrentId = this.getFrameId('previous');
        break;
      default:
        // back to origin
        this.transform(previous, -this.frameWidth, 0, 'previous', this.isOnScreen(previous) ? duration : 0);
        this.transform(current, 0, 0, 'current', this.isOnScreen(current) ? duration : 0);
        this.transform(next, this.frameWidth, 0, 'next', this.isOnScreen(next) ? duration : 0);
    }

    if (this.props.onIndexChanged) {
      this.props.onIndexChanged(newCurrentId);
    }
    this.setState({ currentIndex: newCurrentId });
  }

  private shouldDisableButton = (next: boolean) => {
    if (this.props.loop) {
      return false;
    }
    const limit = next ? this.state.frames.length - 1 : 0;
    return this.state.currentIndex === limit;
  };

  private handleNavigationButtonClick = (next: boolean) => () => {
    if (this.shouldDisableButton(next)) {
      return;
    }
    if (next) {
      this.transform(this.movingFrames.nextNext, 2 * this.frameWidth, 0, 'nextNext');
      setTimeout(() => this.next());
    } else {
      this.transform(this.movingFrames.previousPrevious, -2 * this.frameWidth, 0, 'previousPrevious');
      setTimeout(() => this.previous());
    }
  };

  private isOnScreen = el => {
    if (!el) {
      return false;
    }

    const x = el.getBoundingClientRect().x;
    return -this.frameWidth < x && x < this.frameWidth;
  };

  private transform = (el, x, y, selection: CarouselPosition, duration = 0) => {
    if (!el) {
      return;
    }

    // animation
    el.style.transitionDuration = `${duration}ms`;
    el.style.webkitTransitionDuration = `${duration}ms`;

    const scale = this.getScale(x);

    const translateXDueToWidth = ((1 - this.props.width) / 2) * this.frameWidth;
    const translateXDueToScale = ((1 - this.props.scale) / 2) * el.offsetWidth;
    const deltaTranslated = Math.abs(this.frameWidth - Math.abs(x));
    const percentageTranslated = deltaTranslated / this.frameWidth;
    const newX =
      x +
      (x < 0 ? 1 : -1) *
        (1 - percentageTranslated) *
        (translateXDueToScale + this.props.borderItemsXTranslation + translateXDueToWidth) +
      translateXDueToWidth;

    el.style.opacity = (percentageTranslated * 0.5 + 0.5) * 1;
    el.style.transform = `translate(${newX}px, ${y}px) scale(${scale})`;
    el.style.webkitTransform = `translate(${newX}px, ${y}px) translateZ(0) scale(${scale})`;

    el.style.zIndex = this.getZIndex(selection);
  };

  private getZIndex = (selection: CarouselPosition) => {
    switch (selection) {
      case 'next':
        return 1;
      case 'current':
        return 2;
      case 'previous':
        return 1;
      default:
        return 1;
    }
  };

  private getScale = (x: number) => {
    if (this.props.scale === 1) {
      return 1;
    }
    return 1 - (1 - this.props.scale) * (1 - Math.abs(this.frameWidth - Math.abs(x)) / this.frameWidth);
  };
}
