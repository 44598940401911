import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Table, TableIconContainer, TBody, TD, TH, THead, TR } from '@components/mol.table';
import { H2, H3 } from '@components/atm.typography';
import { Separator } from '@components/obj.grid';
import { Pagination } from '@components/mol.pagination';
import { Hbox } from '@components/obj.box';
import { ContentWrapper, WhiteContainer } from '@components/obj.white-container';
import { SortSelect } from '@components/atm.sort-select';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import {
  DoctorRecommendationsQuery,
  DoctorRecommendationsQueryVariables,
  GetDoctorImunocardCountQuery,
} from '@app/resource/graphql/generated';
import { PropsBase } from '@app/core/base/props.base';
import { OrderByEnum, OrderDirectionEnum, RecommendationKind } from '@app/resource/graphql/base-schema';
import { HeaderSmart } from '@app/components/header.smart-component';
import { MenuItems } from '@components/mol.header/header.model';
import { AuthenticationStore } from '../authentication/authentication.store';
import Container from 'typedi';
import { reaction } from 'mobx';
import { IconContainer } from '@components/atm.icon-container';
import { ImageModal } from '@components/obj.image-modal';
import { CardCarousel } from '@components/mol.card';
import { DataValue } from 'react-apollo';
import { CardListWrapper, RecommendationCard } from '@components/mol.recommendation-card';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { Redirect } from 'react-router';

interface RecommendationHistoryState {
  currentPage: number;
  orderBy: OrderByEnum;
  orderDirection: OrderDirectionEnum;
  isModalOpen: boolean;
  selectedImageUrl: string | null;
}

type RecommendationHistoryViewProps = PropsBase & {
  doctorRecommendations: DataValue<DoctorRecommendationsQuery, DoctorRecommendationsQueryVariables>;
  getDoctorImunocardCount: DataValue<GetDoctorImunocardCountQuery>;
  authenticationStore?: AuthenticationStore;
};

const ITEMS_PER_PAGE = 10;

@GraphQL('doctor-recommendations.query', {
  name: 'doctorRecommendations',
  options: {
    variables: {
      data: {
        pageSize: ITEMS_PER_PAGE,
      },
    },
    errorPolicy: 'all',
  },
})
@GraphQL('get-doctor-imunocard-count.query', {
  name: 'getDoctorImunocardCount',
  options: {
    errorPolicy: 'all',
  },
})
@Observer()
export class RecommendationHistoryView extends React.Component<
  RecommendationHistoryViewProps,
  RecommendationHistoryState
> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private reactionDisposers = [];

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      orderBy: OrderByEnum.CREATION_DATE,
      orderDirection: OrderDirectionEnum.DESC,
      isModalOpen: false,
      selectedImageUrl: null,
    };

    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handlePrintClick = this.handlePrintClick.bind(this);

    this.reactionDisposers.push(
      reaction(
        () => this.authenticationStore?.token,
        token => {
          if (token) {
            // Workaround to refetch data after token is retrieved from store
            // in initial load, the token is not yet available
            this.refetchData();
          }
        },
      ),
    );
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  handleSelectChange = (value: string): void => {
    const [orderBy, orderDirection] = value.split('-');
    this.setState(
      {
        orderBy: orderBy as OrderByEnum,
        orderDirection: orderDirection as OrderDirectionEnum,
        currentPage: 1,
      },
      this.refetchData,
    );
  };

  refetchData = (): void => {
    const { currentPage, orderBy, orderDirection } = this.state;
    this.props.doctorRecommendations.refetch?.({
      data: {
        orderBy: orderBy as OrderByEnum,
        orderDirection: orderDirection as OrderDirectionEnum,
        page: currentPage,
        pageSize: ITEMS_PER_PAGE,
      },
    });
    this.props.getDoctorImunocardCount?.refetch?.();
  };

  handlePageChange = (page: number): void => {
    this.setState({ currentPage: page }, this.refetchData);
  };

  handleDownload(recommendationUrl: string) {
    fetch(recommendationUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erro ao baixar o arquivo: ${response.status} ${response.statusText}`);
        }
        if (response.headers.get('Content-Type') !== 'image/png') {
          throw new Error('Resposta não é uma imagem PNG válida.');
        }
        return response.blob();
      })
      .then(blob => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `recomendacao.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao baixar o arquivo:', error);
        alert('Não foi possível baixar o documento.');
      });
  }

  handlePrint(recommendationUrl: string) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc) {
      iframeDoc.write(`
        <html>
          <head>
            <style>
              body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
              img { max-width: 100%; height: auto; }
            </style>
          </head>
          <body>
            <img src="${recommendationUrl}" alt="Recomendação">
          </body>
        </html>
      `);
      iframeDoc.close();
    }

    iframe.onload = () => {
      try {
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();
      } catch (error) {
        console.error('Erro ao imprimir:', error);
      } finally {
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 1000);
      }
    };
  }

  handleDownloadClick = (recommendationUrl: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    this.handleDownload(recommendationUrl);
  };

  handlePrintClick = (recommendationUrl: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    this.handlePrint(recommendationUrl);
  };

  handleOpenModalClick = (recommendationUrl: string) => () => {
    this.setState({
      isModalOpen: true,
      selectedImageUrl: recommendationUrl,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      selectedImageUrl: null,
    });
  };

  render() {
    const { doctorRecommendations, getDoctorImunocardCount } = this.props;

    const loadingRecommendations = doctorRecommendations?.loading;
    const loadingCount = getDoctorImunocardCount?.loading;
    const errorRecommendations = doctorRecommendations?.error;
    const errorCount = getDoctorImunocardCount?.error;

    const recommendations = doctorRecommendations?.DoctorRecommendations?.recommendations || [];
    const totalPages = doctorRecommendations?.DoctorRecommendations?.totalPages || 0;
    const imunocardCount = getDoctorImunocardCount?.GetDoctorImunocardCount?.imunocardCount || 0;
    const activeImunocardCount = getDoctorImunocardCount?.GetDoctorImunocardCount?.activeImunocardCount || 0;

    if (this.authenticationStore?.isHydrated && this.authenticationStore?.loggedIn === false) {
      return <Redirect to={LOGIN_PATH} />;
    }

    return (
      <>
        <HeaderSmart selectedItem={MenuItems.recomendacoes} home={false} logged={this.authenticationStore?.loggedIn} />
        <WhiteContainer isResponsive={true} hasPadding={true}>
          <ContentWrapper>
            <H2>Recomendações Ativas</H2>
            {!loadingCount && !errorCount && (
              <CardCarousel
                cards={[
                  {
                    title: 'Cartões recebidos',
                    value: imunocardCount,
                    info: 'Número de Imunocards associados ao seu CRM.',
                  },
                  {
                    title: 'Cartões ativados',
                    value: activeImunocardCount,
                    info: 'Número de Imunocards que foram ativados por pacientes.',
                  },
                  {
                    title: 'Recomendações recebidas',
                    value: doctorRecommendations?.DoctorRecommendations?.totalRecommendations || 0,
                    info: 'Número de recomendações recebidas por pacientes.',
                  },
                ]}
              />
            )}
            <Separator />
            <H3>Recomendações Criadas</H3>
            <Separator />
            {loadingRecommendations || errorRecommendations ? (
              <p>
                {loadingRecommendations
                  ? 'Carregando recomendações...'
                  : 'Erro ao carregar dados, tente novamente mais tarde.'}
              </p>
            ) : (
              <SortSelect
                value={`${this.state.orderBy}-${this.state.orderDirection}`}
                options={[
                  { value: 'CREATION_DATE-DESC', label: 'Mais recente' },
                  { value: 'CREATION_DATE-ASC', label: 'Mais antiga' },
                  { value: 'NAME-ASC', label: 'Ordem alfabética (A-Z)' },
                ]}
                onChange={this.handleSelectChange}
              />
            )}
          </ContentWrapper>
          {!loadingRecommendations && !errorRecommendations && (
            <>
              <Separator />
              <Table>
                <THead>
                  <TR>
                    <TH>Nome do paciente</TH>
                    <TH>Número Imunocard</TH>
                    <TH>Tipo</TH>
                    <TH>Data de criação</TH>
                    <TH />
                  </TR>
                </THead>
                <TBody>
                  {recommendations.length > 0 ? (
                    recommendations.map(recommendation => (
                      <TR key={recommendation.id} onClick={this.handleOpenModalClick(recommendation.recommendationUrl)}>
                        <TD>{recommendation.patientName || 'Não informado'}</TD>
                        <TD>{recommendation.imunocard || 'Sem cartão associado'}</TD>
                        <TD>{mapRecommendationType[recommendation.type] || 'Personalizado'}</TD>
                        <TD>{new Date(recommendation.createdAt).toLocaleDateString('pt-BR')}</TD>
                        <TD>
                          <TableIconContainer>
                            <Hbox>
                              <Hbox.Item $noGrow={true}>
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  onClick={this.handleDownloadClick(recommendation.recommendationUrl)}
                                />
                              </Hbox.Item>
                              <Hbox.Separator />
                              <Hbox.Separator />
                              <Hbox.Item $noGrow={true}>
                                <FontAwesomeIcon
                                  icon={faPrint}
                                  onClick={this.handlePrintClick(recommendation.recommendationUrl)}
                                />
                              </Hbox.Item>
                            </Hbox>
                          </TableIconContainer>
                        </TD>
                      </TR>
                    ))
                  ) : (
                    <TR>
                      <TD colSpan={5}>Nenhuma recomendação encontrada.</TD>
                    </TR>
                  )}
                </TBody>
              </Table>
              <ImageModal
                isOpen={this.state.isModalOpen}
                imageUrl={this.state.selectedImageUrl}
                onClose={this.handleCloseModal}
              >
                <IconContainer>
                  <FontAwesomeIcon
                    icon={faDownload}
                    onClick={
                      this.state.selectedImageUrl ? this.handleDownloadClick(this.state.selectedImageUrl) : undefined
                    }
                    style={{ cursor: 'pointer' }}
                  />
                  <FontAwesomeIcon
                    icon={faPrint}
                    onClick={
                      this.state.selectedImageUrl ? this.handlePrintClick(this.state.selectedImageUrl) : undefined
                    }
                    style={{ cursor: 'pointer' }}
                  />
                </IconContainer>
              </ImageModal>
            </>
          )}
          <Separator />
          <CardListWrapper>
            {recommendations.length > 0 &&
              recommendations.map(recommendation => (
                <RecommendationCard
                  key={recommendation.id}
                  patientName={recommendation.patientName || 'Não informado'}
                  imunocard={recommendation.imunocard || 'Sem cartão associado'}
                  type={mapRecommendationType[recommendation.type] || 'Personalizado'}
                  onDownload={this.handleDownload.bind(this, recommendation.recommendationUrl)}
                  onPrint={this.handlePrint.bind(this, recommendation.recommendationUrl)}
                  onOpenModal={this.handleOpenModalClick(recommendation.recommendationUrl)}
                />
              ))}
          </CardListWrapper>

          <Pagination
            currentPage={this.state.currentPage}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
          />
        </WhiteContainer>
      </>
    );
  }
}

const mapRecommendationType: Record<RecommendationKind, string> = {
  [RecommendationKind.CUSTOM]: 'Personalizado',
  [RecommendationKind.DEFAULT]: 'Padrão',
};

export default RecommendationHistoryView;
