import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import RecommendationHistoryView from './recommendation-history.view';
import { isVacinaTheme } from 'config/theme';

export const RECOMMENDATION_HISTORY_PATH = '/historico';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Histórico de Prescrições`,
  meta: [
    {
      name: 'description',
      content: 'Visualize e gerencie o histórico de prescrições realizadas pelos médicos.',
    },
  ],
};

const RecommendationHistory = SEO(config)(RecommendationHistoryView);

export const RecommendationHistoryRoute = (
  <Route
    path={RECOMMENDATION_HISTORY_PATH}
    exact={true}
    component={RecommendationHistory}
    key='RecommendationHistory'
  />
);
