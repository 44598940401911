import * as React from 'react';
import { TooltipText } from '@components/atm.typography';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ImunocardTooltipCloseIconStyled, ImunocardTooltipCloseWrapStyled, ImunocardTooltipTextStyled } from './imunocard-tooltip.style';

export interface ImunocardTooltipProps {
  opened?: boolean;
  onClose?: () => void;
  message?: string | JSX.Element;
  position?: { top: number; left: number };
}

export const ImunocardTooltip = React.forwardRef<HTMLDivElement, ImunocardTooltipProps> (
  ({ opened, onClose, message, position }, ref) => {
    const top = position?.top ?? 0;
    const left = position?.left ?? 0;

    return (
      <ImunocardTooltipTextStyled
        ref={ref}
        $opened={opened}
        $top={top}
        $left={left}
      >
        <TooltipText>{message}</TooltipText>
        <ImunocardTooltipCloseWrapStyled>
          <ImunocardTooltipCloseIconStyled icon={faXmark} onClick={onClose} />
        </ImunocardTooltipCloseWrapStyled>
      </ImunocardTooltipTextStyled>
    );
  }
);
