import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllRecommendationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllRecommendationsQuery = {
  __typename?: 'Queries';
  Recommendations?: Array<{
    __typename?: 'RecommendationType';
    id: string;
    age?: string | null;
    vaccines?: Array<{
      __typename?: 'VaccineType';
      id: string;
      name: string;
      brand?: string | null;
      laboratory?: string | null;
      vaccineFamilyId?: number | null;
      injectionCount: number;
      priority?: number | null;
      specialNeed?: string | null;
    } | null> | null;
  } | null> | null;
};

export const AllRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllRecommendations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vaccines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'laboratory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vaccineFamilyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'injectionCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specialNeed' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllRecommendationsQuery, AllRecommendationsQueryVariables>;
