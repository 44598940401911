import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { ShingrixView } from './shingrix.view';
import { isVacinaTheme } from 'config/theme';

export const SHINGRIX_ROUTE = '/centros/shingrix';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Shingrix`,
  meta: [
    {
      name: 'description',
      content: 'Encontre o centro de vacinação mais próximo a você!',
    },
  ],
};
const Shingrix = SEO(config)(ShingrixView);

export const ShingrixRoute = <Route path={SHINGRIX_ROUTE} exact={true} component={Shingrix} key='shingrix' />;
