import { Border, Color, FontSize, Spacing } from '@components/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const OrderModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Small};
`;

export const ListStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.Medium};
`;

export const ListItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.Large};
  background-color: ${Color.White};
  border: 1px solid ${Color.NeutralGrayXSoft};
  border-radius: ${Border.radius};
  flex-grow: 1;
  flex-grow: 1;
  min-width: 0;
  gap: ${Spacing.Small};
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: ${Spacing.Medium};
  }
`;

export const VaccineTextContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: calc(100% - 100px);

  @media (max-width: 768px) {
    font-size: ${FontSize.Small};
  }
`;

export const NumberCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${Color.Acessory5};
  color: ${Color.Black};
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: ${Spacing.Small};
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: ${FontSize.Small};
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${Spacing.Small};
  flex-shrink: 0;

  @media (max-width: 768px) {
    gap: ${Spacing.XSmall};
  }
`;

export const MoveButton = styled.button`
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: ${Color.White};
  font-size: ${FontSize.Medium};
  padding: ${Spacing.Large};
  border-radius: 50%;
  border: 1px solid ${Color.Primary};
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    border-color: ${Color.NeutralGrayMedium};
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: ${Spacing.Medium};
    font-size: ${FontSize.Small};
  }
`;

export const IconStyled = styled(FontAwesomeIcon)`
  color: ${Color.Primary};

  ${MoveButton}:hover & {
    color: ${Color.Primary};
  }

  ${MoveButton}:disabled & {
    color: ${Color.NeutralGrayMedium};
  }
`;
