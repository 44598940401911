import * as React from 'react';
import { Route } from 'react-router';
import { LoginData } from '@app/modules/authentication/login.data';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const LOGIN_PATH = '/login';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Login`,
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const Login = SEO(config)(LoginData);

export const LoginRoute = <Route path={LOGIN_PATH} exact={true} component={Login} key='login' />;
