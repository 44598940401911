import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@components/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export const ImunocardTooltipStyled = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const ImunocardTooltipTextStyled = styled.div<{ $opened: boolean, $top: number, $left: number }>`
  max-width: 240px;
  background-color: ${Color.Primary};
  text-align: left;
  border-radius: 6px;
  border-color: ${Color.White};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: ${Spacing.Medium} 0 ${Spacing.Medium} ${Spacing.Large};
  position: absolute;
  z-index: 1;
  white-space: normal;
  top: ${props => (props.$top ? `${props.$top}px` : '0')};
  left: ${props => (props.$left ? `${props.$left}px` : '0')};\
  visibility: ${props => (props.$opened ? 'visible' : 'hidden')};

  opacity: ${props => (props.$opened ? '1' : '0')};
  transform: ${props => (props.$opened ? 'scale(1)' : 'scale(0.0)')};
  transition:
    transform 0.5s ease-in-out,
    opacity 0.25s ease-in-out;

  display: flex;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 15%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px;
    border-style: solid;
    border-color: ${Color.Primary} transparent transparent transparent;

    @media only screen and (max-width: 48em) {
      left: 85%;
      border-color: ${Color.Primary} transparent transparent transparent;
    }
  }
`;

export const ImunocardTooltipCloseWrapStyled = styled.a`
  text-align: center;
  cursor: pointer;
  padding: 0 ${Spacing.Small};
  pointer-events: auto;
`;

export const ImunocardTooltipCloseIconStyled = styled(FontAwesomeIcon).attrs({ icon: faXmark })`
  color: ${Color.White};
  font-size: ${FontSize.Medium};
`;
