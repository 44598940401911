import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Age } from '@app/model/age.model';
import { VaccineFamily } from '@app/model/vaccine-family.model';
import { Vaccine } from '@app/model/vaccine.model';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { VaccineWithTooltip } from '@components/mol.vaccine-list/vaccine-list.component';
import * as React from 'react';
import { ChildProps } from 'react-apollo';
import { Container } from 'typedi';
import { VaccineListContainer } from './vaccine-list.container';
import { VaccineByFamilyQuery } from '@app/resource/graphql/generated';

export interface VaccineChoiceContainerProps {
  vaccineFamily: VaccineFamily;
  age: string;
  onVaccineClick?: (item: Vaccine, checked: boolean) => void;
}

interface VaccineListContainerState {
  key?: number;
}

@GraphQL('vaccine-by-family.query', {
  options: props => {
    const families = props.vaccineFamily.id;
    const variables = {
      data: {
        families,
      },
    };
    return { variables, errorPolicy: 'all' };
  },
})
export class VaccineListContainerData extends React.Component<
  ChildProps<VaccineChoiceContainerProps, VaccineByFamilyQuery>,
  VaccineListContainerState
> {
  private readonly appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const age = this.appointmentStore.findAgeByText(this.props.age);
    const vaccines = this.getFilteredVaccines(age).map(this.mapToVaccineWithTooltip);
    const selectedVaccineTuple = this.appointmentStore.selectedVaccinesTuple.find(
      v => v.age.getAgeText() === this.props.age && v.familyId === this.props.vaccineFamily.id,
    );
    const selectedIndex = selectedVaccineTuple && vaccines.findIndex(v => v.id === selectedVaccineTuple.vaccine.id);
    const title = `${age.getAgeText()} - ${this.props.vaccineFamily.name}`;
    return <VaccineListContainer {...this.props} title={title} vaccines={vaccines} selectedIndex={selectedIndex} />;
  }

  private getFilteredVaccines = (age: Age) => {
    const vaccines =
      this.props.data.VaccineByFamily && this.props.data.VaccineByFamily.length > 0
        ? this.props.data.VaccineByFamily[0].vaccines
        : [];
    const ageInMonths =
      age.type === 'month' ? age : { minAge: age.minAge * 12, maxAge: age.maxAge * 12, type: 'month' };
    return vaccines.filter(item => item.ages.some(a => a >= ageInMonths.minAge && a <= ageInMonths.maxAge));
  };

  private mapToVaccineWithTooltip = (vaccine: Vaccine): VaccineWithTooltip => {
    if (!vaccine) {
      return null;
    }
    return {
      ...vaccine,
      tooltip: this.getTooltipForVaccine(vaccine),
    };
  };

  private getTooltipForVaccine = (vaccine: Vaccine): JSX.Element => {
    // POG_ALERT: the tooltip text is coupled with the vaccine name (something
    // set in the database) and the vaccine laboratory.  Therefore, if the name
    // of a vaccine (or the laboratory) changes, then it is important to change
    // this method.  The name of a vaccine_family has never changed since the
    // beginning of this project so this code is good enough for now.  If this
    // tooltip dictionary starts to grow (more than 2 items) then it may be a
    // better solution to save this tooltip text in the database and send it
    // from the API (or store this info on the database).
    if (vaccine.name === 'VACINA TRÍPLICE VIRAL' && vaccine.laboratory === 'PNI' && vaccine.ages.includes(240)) {
      // tslint:disable: max-line-length
      // https://www.notion.so/taqtile/No-calend-rio-de-20-59-anos-anos-tem-uma-vacina-Sarampo-Caxumba-e-Rub-ula-Dos-20-39-anos-s-o-2-inje-a30e4f5f478f4926b134cf5fd46d995f
      return (
        <p>
          Segundo recomendação do PNI devem ser feitas: <br />
          • 2 doses (20 a 29 anos)
          <br />• 1 dose (30 a 59 anos)
        </p>
      );
    }

    return null;
  };
}
