import { Age, DefinedAge } from '@app/model/age.model';
import { Vaccine } from '@app/model/vaccine.model';
import { ImmunocompromisedComorbidities } from './immunocompromised-choice.utils';
import { VaccinePriority } from './priority-choice.utils';

export enum PatientProfileKey {
  Premature,
  Infant,
  Child,
  Teenager,
  Adult,
  Pregnant,
  Senior,
  Occupational,
  Immunocompromised,
  YoungAdult,
}

export interface PatientProfileValue {
  id: PatientProfileKey;
  name: string;
  age?: string;
  ageObj: Age[];
  calendarCode?: string;
  enabled: boolean;
}

/**
 * Build all profiles using arrays construction
 * https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Map#Relação_com_objetos_Array
 */
export const allProfilesMap = new Map<PatientProfileKey, PatientProfileValue>([
  [
    PatientProfileKey.Premature,
    {
      id: PatientProfileKey.Premature,
      name: 'Prematuro',
      age: '0 - 9 meses',
      enabled: false,
      ageObj: [],
    },
  ],

  [
    PatientProfileKey.Infant,
    {
      id: PatientProfileKey.Infant,
      name: 'Lactente',
      age: '0 - 23 meses',
      enabled: true,
      ageObj: [
        new DefinedAge(0, 0, 'month'),
        new DefinedAge(1, 1, 'month'),
        new DefinedAge(2, 2, 'month'),
        new DefinedAge(3, 3, 'month'),
        new DefinedAge(4, 4, 'month'),
        new DefinedAge(5, 5, 'month'),
        new DefinedAge(6, 6, 'month'),
        new DefinedAge(7, 7, 'month'),
        new DefinedAge(8, 8, 'month'),
        new DefinedAge(9, 9, 'month'),
        new DefinedAge(10, 11, 'month'),
        new DefinedAge(12, 12, 'month'),
        new DefinedAge(13, 14, 'month'),
        new DefinedAge(15, 15, 'month'),
        new DefinedAge(16, 17, 'month'),
        new DefinedAge(18, 18, 'month'),
        new DefinedAge(19, 23, 'month'),
      ],
    },
  ],

  [
    PatientProfileKey.Child,
    {
      id: PatientProfileKey.Child,
      name: 'Criança',
      age: '2 - 10 anos',
      enabled: true,
      ageObj: [
        new DefinedAge(2, 2, 'year'),
        new DefinedAge(3, 3, 'year'),
        new DefinedAge(4, 4, 'year'),
        new DefinedAge(5, 5, 'year'),
        new DefinedAge(6, 6, 'year'),
        new DefinedAge(7, 7, 'year'),
        new DefinedAge(8, 8, 'year'),
        new DefinedAge(9, 9, 'year'),
        new DefinedAge(10, 10, 'year'),
      ],
    },
  ],

  [
    PatientProfileKey.Teenager,
    {
      id: PatientProfileKey.Teenager,
      name: 'Adolescente',
      age: '11 - 19 anos',
      enabled: true,
      ageObj: [
        new DefinedAge(11, 11, 'year'),
        new DefinedAge(12, 12, 'year'),
        new DefinedAge(13, 13, 'year'),
        new DefinedAge(14, 14, 'year'),
        new DefinedAge(15, 15, 'year'),
        new DefinedAge(16, 16, 'year'),
        new DefinedAge(17, 17, 'year'),
        new DefinedAge(18, 18, 'year'),
        new DefinedAge(19, 19, 'year'),
      ],
    },
  ],

  [
    PatientProfileKey.YoungAdult,
    {
      id: PatientProfileKey.YoungAdult,
      name: 'Adulto',
      age: '20 - 49 anos',
      enabled: true,
      ageObj: [new DefinedAge(20, 49, 'year')],
      calendarCode: 'PM-BR-ABX-WCNT-210008 | SET/21',
    },
  ],

  [
    PatientProfileKey.Adult,
    {
      id: PatientProfileKey.Adult,
      name: 'Adulto',
      age: '50 - 59 anos',
      enabled: true,
      ageObj: [new DefinedAge(50, 59, 'year')],
      calendarCode: 'PM-BR-ABX-WCNT-210008 | SET/21',
    },
  ],

  [
    PatientProfileKey.Senior,
    {
      id: PatientProfileKey.Senior,
      name: 'Idoso',
      age: '60 anos ou mais',
      enabled: true,
      ageObj: [new DefinedAge(60, 60, 'year')],
      calendarCode: 'PM-BR-ABX-OGM-210001 | FEV/21',
    },
  ],

  [
    PatientProfileKey.Pregnant,
    {
      id: PatientProfileKey.Pregnant,
      name: 'Gestante',
      age: null,
      enabled: false,
      ageObj: [],
    },
  ],

  [
    PatientProfileKey.Occupational,
    {
      id: PatientProfileKey.Occupational,
      name: 'Ocupacional',
      age: null,
      enabled: false,
      ageObj: [],
    },
  ],

  // TODO: Immunocompromised - dis-comment to launch this feature
  // [
  //   PatientProfileKey.Immunocompromised,
  //   {
  //     id: PatientProfileKey.Immunocompromised,
  //     name: 'Pacientes especiais',
  //     age: null,
  //     enabled: true,
  //     ageObj: [],
  //   },
  // ],
]);

////////////////////////////////////////////////////////////////////////////////////////////////////
export enum AppointmentStep {
  ImmunocompromisedChoice = 'escolha-de-comorbidade',
  ProfileChoice = 'escolha-de-calendario',
  AgeChoice = 'escolha-de-idade',
  VaccineChoiceByAge = 'escolha-de-vacinas',
  VaccineChoiceBySpecialNeed = 'escolha-de-vacinas-pacientes-especiais',
  PriorityChoice = 'escolha-de-prioridade',
  Recommendation = 'recomendacao',
  AdministrationPlaces = 'centros',
}

export interface MapBounds {
  northeast: google.maps.LatLngLiteral;
  southwest: google.maps.LatLngLiteral;
}
export function getCenterOfBound(bounds?: MapBounds) {
  if (!bounds) {
    return {
      latitude: 0,
      longitude: 0,
    };
  }
  return {
    latitude: (bounds.northeast.lat + bounds.southwest.lat) / 2,
    longitude: (bounds.northeast.lng + bounds.southwest.lng) / 2,
  };
}

export interface AppointmentRecommendationVaccine {
  id: string;
  name: string;
  brand?: string;
  laboratory?: string;
  injectionCount: number;
  vaccineFamilyId?: number;
  description?: string;
  specialNeed?: ImmunocompromisedComorbidities | string;
  priority?: VaccinePriority;
}

export interface AppointmentRecommendation {
  id: string;
  age?: string;
  vaccines?: AppointmentRecommendationVaccine[];
}

export interface AppointmentVaccineAge {
  age: Age;
  vaccine: Vaccine;
}

export interface AppointmentVaccineTuple {
  vaccine: AppointmentRecommendationVaccine;
  /**
   * this can be undefined when the vaccine belongs to the "special needs"
   * scenario
   */
  age?: Age;
  familyId?: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * The context for which the quick recommendation is being made
 */
export type QuickRecommendationForContext = 'none' | 'profile' | 'age' | 'comorbidity' | 'comorbidity_priority';
