import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { ImunocardHomeView } from '@app/modules/home/imunocard-home.view';
import * as React from 'react';
import { Route } from 'react-router';
import { HOME_PATH } from './home.path';

const config: SEOConfig = {
  title: 'Imunocard',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const Home = SEO(config)(ImunocardHomeView);

export const ImunocardHomeRoute = <Route path={HOME_PATH} exact={true} component={Home} key='home' />;
