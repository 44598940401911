import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import * as React from 'react';
import { Route } from 'react-router';
import { DefaultRecommendationView } from './default-recommendation.view';
import { isVacinaTheme } from 'config/theme';

export const DEFAULT_RECOMMENDATION_PATH = '/recomendacao-padronizada';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Recomendações padronizadas`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para gerenciar recomendações padronizadas de vacinação',
    },
  ],
};

const DefaultRecommendation = SEO(config)(DefaultRecommendationView);

export const DefaultRecommendationRoute = (
  <Route
    path={DEFAULT_RECOMMENDATION_PATH}
    exact={false}
    component={DefaultRecommendation}
    key='default-recommendation'
  />
);
