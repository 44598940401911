import { Spacing } from '@components/obj.constants';
import { Grid } from '@components/obj.grid';
import styled from 'styled-components';

export const HomeImunocardGridStyled = styled(Grid)<{ $noPadding: boolean, $showNavigationButton: boolean }>`
  padding: ${props => (props.$noPadding ? 0 : `${Spacing.XXXLarge} ${Spacing.Large}`)};
  @media all and (max-width: 930px) {
    padding: 0 ${props => props.$showNavigationButton ? Spacing.XXXLarge : Spacing.Large};
  }
`;
