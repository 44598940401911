// tslint:disable: max-line-length
import { HeaderSmart } from '@app/components//header.smart-component';
import { Col, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import {
  CardImgStyled,
  HeaderImunocardButtonStyled,
  HeaderImunocardH1Styled,
  HeroImunocardStyled,
} from './home-header-imunocard.style';
import { HomeImunocardBodyStyled, HomeImunocardGridStyled } from '@components/pag.home';
import { HomePublicType } from '@app/modules/home/imunocard-home.view';

// tslint:enable: max-line-length

export interface HomeHeaderProps {
  loggedIn: boolean;
  onSignUpClick?: () => void;
  onStartRecommendationClick?: () => void;
  onMobile?: boolean;
  onSegmentControlIndex?: (index: number) => void;
  homePublicType: number;
  onFrequentQuestionsClick?: () => void;
}

export class HomeHeaderImunocard extends React.Component<HomeHeaderProps, any> {
  render() {
    return (
      <header>
        <HeroImunocardStyled>
          <HeaderSmart
            home={true}
            logged={this.props.loggedIn}
            onSegmentControlIndex={this.props.onSegmentControlIndex}
          />
          <HomeImunocardGridStyled>
            <Row $align='start' middle='xs'>
              <Col xs={12} sm={8}>
                {this.props.homePublicType === HomePublicType.Doctor ? (
                  <>
                    <HeaderImunocardH1Styled>
                      Gerencie as recomendações vacinais de seus pacientes adultos com agilidade e praticidade.
                    </HeaderImunocardH1Styled>
                    <Separator />
                    <HomeImunocardBodyStyled>
                      O imunocard é a solução para a jornada vacinal que reúne tudo o que você e seu paciente precisam,
                      em qualquer lugar e a qualquer hora.
                    </HomeImunocardBodyStyled>
                    <Separator />
                    <Separator />
                    <HeaderImunocardButtonStyled
                      $kind='callToAction'
                      $small={true}
                      onClick={this.props.loggedIn ? this.props.onStartRecommendationClick : this.props.onSignUpClick}
                    >
                      Recomendar vacinas
                    </HeaderImunocardButtonStyled>
                    <Separator />
                  </>
                ) : (
                  <>
                    <HeaderImunocardH1Styled>
                      Acesse suas recomendações de vacinação de forma simples e rápida
                    </HeaderImunocardH1Styled>
                    <Separator />
                    <HomeImunocardBodyStyled>
                      Com o Imunocard, você e seu médico têm todas as informações vacinais necessárias em um só lugar, a
                      qualquer momento e onde estiver.
                    </HomeImunocardBodyStyled>
                    <Separator />
                    <Separator />
                    <HeaderImunocardButtonStyled
                      $kind='callToAction'
                      $small={true}
                      onClick={this.props.onFrequentQuestionsClick}
                    >
                      Tire suas dúvidas aqui
                    </HeaderImunocardButtonStyled>
                    <Separator />
                    <Separator />
                  </>
                )}
              </Col>
              <Col xs={12} sm={4}>
                <CardImgStyled src={'assets/img/img_home_header_card.png'} />
              </Col>
            </Row>
          </HomeImunocardGridStyled>
        </HeroImunocardStyled>
      </header>
    );
  }
}
