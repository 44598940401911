import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import * as React from 'react';
import { RadioField } from '.';
import { RadioFieldGroupStyled } from './radio-field-group.component.style';

export interface RadioFieldGroupProps extends FieldPropsInterface {
  onValueChange?: (value: any) => void;
  value?: any;
  responsiveDirection?: boolean;
  withGap?: boolean;
}

export interface RadioFieldGroupState extends FieldStateInterface {
  value: any;
}

export class RadioFieldGroup extends Field<RadioFieldGroupProps, RadioFieldGroupState> {
  constructor(props: RadioFieldGroupProps) {
    super(props);
    this.state = { value: this.props.value, errors: [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps: RadioFieldGroupProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleValueChange = (value: any) => {
    this.setState({ value });

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  render() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child.type === RadioField) {
        return React.cloneElement(child, {
          key: `RadioField_${i}`,
          name: this.props.name,
          onValueChange: this.handleValueChange,
          checked: this.state.value === child.props.value,
        });
      }
      return child;
    });

    return (
      <RadioFieldGroupStyled responsiveDirection={this.props.responsiveDirection} withGap={this.props.withGap}>
        {wrapped}
      </RadioFieldGroupStyled>
    );
  }
}
