import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import * as React from 'react';
import { Container } from 'typedi';
import { HeaderSmart } from '@app/components/header.smart-component';
import { MenuItems } from '@components/mol.header/header.model';
import { AuthenticationStore } from '../authentication/authentication.store';
import { Redirect, Route, Switch } from 'react-router';
import { DefaultRecommendatilListView } from './default-recommendation-list.view';
import { DefaultRecommendationVaccinesView } from './default-recommendation-vaccines.view';
import { isVacinaTheme } from 'config/theme';

export const DEFAULT_RECOMMENDATION_PATH = '/recomendacao-padronizada';

type DefaultRecommendationViewProps = PropsBase;

interface NestedRoute {
  subPath: string;
  component: React.ComponentType<any>;
  step?: number;
  title?: string;
}

@Observer()
export class DefaultRecommendationView extends React.Component<DefaultRecommendationViewProps> {
  private imunocardAppointmentRoute: NestedRoute[] = [
    { title: 'Imunocard', subPath: 'gerenciar-recomendacoes', component: DefaultRecommendatilListView, step: 1 },
    { title: 'Vacinas', subPath: 'selecao-de-vacinas', component: DefaultRecommendationVaccinesView, step: 2 },
  ];

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  private searchParams: string = '';

  constructor(props: DefaultRecommendationViewProps) {
    super(props);

    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const urlObj = new URL(currentUrl);
      if (urlObj.search.includes('token')) {
        this.searchParams = urlObj.search;
      }
    }
  }

  render() {
    return (
      <div>
        <HeaderSmart
          selectedItem={MenuItems.recomendacoes}
          home={false}
          logged={this.authenticationStore?.loggedIn || false}
        />

        {isVacinaTheme && <CookieConsentContainer />}

        <Switch>
          {this.imunocardAppointmentRoute.map(route => (
            <Route
              key={route.subPath}
              path={`${DEFAULT_RECOMMENDATION_PATH}/${route.subPath}`}
              component={route.component}
              exact={true}
              location={{ pathname: `${DEFAULT_RECOMMENDATION_PATH}/${route.subPath}`, search: this.searchParams, state: {}, hash: '' }}
            />
          ))}
          <Redirect
            from={DEFAULT_RECOMMENDATION_PATH}
            to={{
              pathname: `${DEFAULT_RECOMMENDATION_PATH}/${this.imunocardAppointmentRoute[0]?.subPath}`,
              search: this.searchParams,
            }}
          />
        </Switch>
      </div>
    );
  }
}

