import { Row } from '@components/obj.grid';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export interface TestimonialContainerProps {
  $isLeft: boolean;
}

export const TestimonialRowStyled = styled(Row)`
  padding-bottom: ${Spacing.XXXLarge};
`;

export const TestimonialListContainerStyled = styled.section`
  position: relative;

  // to disable horizontal scroll due to Drop image
  overflow-x: hidden;
  overflow-y: hidden;

  background-color: ${Color.BackgroundSecondary};
`;

const increasePercentage = 2;
export const TestimonialContainerStyled = styled.div<TestimonialContainerProps>`
  right: 0;
  width: 100%;
  padding: ${Spacing.XLarge};
  position: relative;
  z-index: 2;
  text-align: left;

  @media only screen and (min-width: 48em) {
    margin: 0 ${Spacing.Medium};
    background-color: ${Color.White};
    border-color: ${Color.GrayLight};
    border-radius: ${Spacing.Small};
    border-width: 1px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    width: ${100 + increasePercentage * 2}%;

    right: ${props => (props.$isLeft ? '0' : `${increasePercentage}%`)};
  }
`;

export const TestimonialImgStyled = styled.img`
  width: 100%;
  max-width: 30vw;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
  z-index: 1;
`;

export const TestimonialNameStyled = styled.p`
  margin-top: ${Spacing.Large};
  margin-bottom: 0;
  color: ${Color.Primary};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
`;
