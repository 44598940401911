import * as React from 'react';
import {
  CardContainer,
  CardInfoIcon,
  CardNumber,
  CardTitle,
  CardWrapper,
  CarouselIndicators,
  Indicator,
} from './card.component.style';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImunocardTooltip } from '@components/mol.tooltip';
import { useTooltip } from '@components/mol.tooltip/tooltip.hook';

interface CardProps {
  title: string;
  value: number;
  info?: string;
  isActive?: boolean;
  isMobile?: boolean;
}

export const Card: React.FunctionComponent<CardProps> = ({ title, value, info, isActive, isMobile }) => {
  const { isTooltipOpen, tooltipPosition, toggleTooltip, iconRef, tooltipRef } = useTooltip(isActive, isMobile);

  return (
    <>
      <CardContainer>
        <CardTitle>
          {title}
          <ImunocardTooltip
            ref={tooltipRef}
            opened={isTooltipOpen}
            onClose={toggleTooltip}
            message={info}
            position={tooltipPosition}
          />
          <CardInfoIcon ref={iconRef} onClick={toggleTooltip}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </CardInfoIcon>
        </CardTitle>
        <CardNumber>{value}</CardNumber>
      </CardContainer>
    </>
  );
};

export const CardCarousel: React.FunctionComponent<{ cards: CardProps[] }> = ({ cards }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = () => {
    if (!carouselRef.current) return;
    const { scrollLeft, clientWidth } = carouselRef.current;
    const index = Math.round(scrollLeft / clientWidth);
    setActiveIndex(index);
  };

  return isMobile ? (
    <>
      <CardWrapper ref={carouselRef} onScroll={handleScroll} isMobile={isMobile}>
        {cards.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            value={card.value}
            info={card.info}
            isActive={index === activeIndex}
            isMobile={isMobile}
          />
        ))}
      </CardWrapper>
      <CarouselIndicators>
        {cards.map((_, index) => (
          <Indicator key={index} active={index === activeIndex} />
        ))}
      </CarouselIndicators>
    </>
  ) : (
    <CardWrapper isMobile={isMobile}>
      {cards.map((card, index) => (
        <Card key={index} title={card.title} value={card.value} info={card.info} />
      ))}
    </CardWrapper>
  );
};
