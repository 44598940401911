import * as React from 'react';
import { Route } from 'react-router';
import { MaintenanceView } from '@app/modules/maintenance/maintenance.view';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const MAINTENANCE_PATH = '/maintenance';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Maintenance`,
  meta: [
    {
      name: 'description',
      content: 'Site em manutenção',
    },
  ],
};
const Maintenance = SEO(config)(MaintenanceView);

export const MaintenanceRoute = (
  <Route path={MAINTENANCE_PATH} exact={true} component={Maintenance} key='maintenance' />
);
