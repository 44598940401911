import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { OptOutUserData } from './opt-out-user.data';
import { isVacinaTheme } from 'config/theme';

export const OPT_OUT_USER_PATH = '/opt-out-doutor';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Opt out de médico`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Opt out de médico no Vacina Certa.',
    },
  ],
};
const OptOutUser = SEO(config)(OptOutUserData);

export const OptOutUserRoute = <Route path={OPT_OUT_USER_PATH} exact={true} component={OptOutUser} key='optOutUser' />;
