import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllUserDataRequestMutationVariables = Types.Exact<{ [key: string]: never }>;

export type AllUserDataRequestMutation = {
  __typename?: 'Mutations';
  AllUserDataRequest?: {
    __typename?: 'DoctorType';
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    crm: string;
    uf: string;
    rememberMe?: boolean | null;
    repTerritory?: string | null;
    repTerritoryAdult?: string | null;
    newsletterOptIn?: boolean | null;
    smsOptIn?: boolean | null;
  } | null;
};

export const AllUserDataRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AllUserDataRequest' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllUserDataRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uf' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rememberMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repTerritory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repTerritoryAdult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newsletterOptIn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'smsOptIn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllUserDataRequestMutation, AllUserDataRequestMutationVariables>;
