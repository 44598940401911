import * as React from 'react';
import { Route } from 'react-router';
import { UpdatePasswordView } from '@app/modules/authentication/update-password.view';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const UPDATE_PASSWORD_PATH = '/atualizar-senha';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Atualizar senha`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const UpdatePassword = SEO(config)(UpdatePasswordView);

export const UpdatePasswordRoute = (
  <Route path={UPDATE_PASSWORD_PATH} exact={true} component={UpdatePassword} key='updatePassword' />
);
