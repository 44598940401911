import * as React from 'react';
import {
  ActionsContainer,
  IconStyled,
  ListItemStyled,
  ListStyled,
  MoveButton,
  NumberCircle,
  OrderModalStyled,
  VaccineTextContainer,
} from './order-modal.component.style';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface OrderModalProps<T extends { id: string }> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onChange: (orderedItems: T[]) => void;
}

interface OrderModalState<T> {
  orderedItems: T[];
}

export class OrderModal<T extends { id: string }> extends React.Component<OrderModalProps<T>, OrderModalState<T>> {
  constructor(props: OrderModalProps<T>) {
    super(props);

    this.state = {
      orderedItems: [...props.items],
    };

    this.handleMoveUp = this.handleMoveUp.bind(this);
    this.handleMoveDown = this.handleMoveDown.bind(this);
  }

  moveItem(index: number, direction: 'up' | 'down') {
    const { orderedItems } = this.state;
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    if (newIndex < 0 || newIndex >= orderedItems.length) return;

    const newOrderedItems = [...orderedItems];
    [newOrderedItems[index], newOrderedItems[newIndex]] = [orderedItems[newIndex], orderedItems[index]];

    this.setState({ orderedItems: newOrderedItems });
    this.props.onChange(newOrderedItems);
  }

  handleMoveUp(index: number) {
    this.moveItem(index, 'up');
  }

  handleMoveDown(index: number) {
    this.moveItem(index, 'down');
  }

  render() {
    return (
      <OrderModalStyled>
        {this.state.orderedItems.map((item, index) => (
          <ListStyled key={item.id}>
            <NumberCircle>{index + 1}</NumberCircle>
            <ListItemStyled>
              <VaccineTextContainer>{this.props.renderItem(item)}</VaccineTextContainer>
              <ActionsContainer>
                <MoveButton disabled={index === 0} onClick={this.handleMoveUp.bind(this, index)}>
                  <IconStyled icon={faArrowUp} />
                </MoveButton>
                <MoveButton
                  disabled={index === this.state.orderedItems.length - 1}
                  onClick={this.handleMoveDown.bind(this, index)}
                >
                  <IconStyled icon={faArrowDown} />
                </MoveButton>
              </ActionsContainer>
            </ListItemStyled>
          </ListStyled>
        ))}
      </OrderModalStyled>
    );
  }
}
