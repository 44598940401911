import * as React from 'react';
import { ReactNode } from 'react';
import { ValidationError } from './validators';
import { Field, FieldStateInterface } from './field.component';

export interface FormProps extends React.PropsWithChildren {
  name?: string;
  onChange?: any;
  onSubmit: (formData: FormData<unknown>) => unknown;
}

export interface FormError extends ValidationError {
  field: string;
}
export interface FormData<T = { [fieldName: string]: any }> {
  data: T;
  errors: FormError[];
}

export class Form extends React.Component<FormProps, any> {
  private fields: Field<any, FieldStateInterface>[] = [];

  constructor(props) {
    super(props);
  }

  attachToForm = (field: Field<any, FieldStateInterface>) => {
    this.fields.push(field);
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData = await this.mapFormData();
    this.props.onSubmit(formData);
  };

  async mapFormData(): Promise<FormData> {
    const formData: FormData = {
      data: {},
      errors: [],
    };

    const promises = [];
    this.fields.forEach(field => {
      // TODO: TEMPLATE
      // HACK ALERT: our form input animates when its visibility changes
      // therefore, we erase the errors and then set them in order
      // to chage the visibility of the form input and trigger the animation
      field.eraseErrors(() => {
        if (field.validate) {
          promises.push(field.validate(field.state.value));
        }
      });
    });

    return Promise.all(promises).then(() => {
      this.fields.map(field => {
        formData.data[field.props.name] = field.state.value;

        field.state.errors.map(error => {
          formData.errors.push({
            field: field.props.name,
            errorName: error.errorName,
            errorMessage: error.errorMessage,
          });
        });
      });
      return formData;
    });
  }

  mapChildren(children: ReactNode) {
    return React.Children.map(children, (child: any) => {
      if (child && child.props) {
        const childProps = { children: this.mapChildren(child.props.children) };
        if (child.props.name) {
          childProps.attachToForm = this.attachToForm;
        }

        return React.cloneElement(child, childProps);
      }
      return child;
    });
  }

  render() {
    const children = this.mapChildren(this.props.children);

    return (
      <form onSubmit={this.handleSubmit} name={this.props.name}>
        {children}
      </form>
    );
  }
}
