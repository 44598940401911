import * as React from 'react';
import {
  DropdownIcon,
  OptionItem,
  OptionsContainer,
  SelectStyled,
  SortSelectWrapper,
} from './sort-select.component.style';

interface SortSelectProps {
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
}

export const SortSelect: React.FC<SortSelectProps> = ({ value, options, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const selectedOption = options.find(option => option.value === value);

  const handleOptionClick = React.useCallback(
    (optionValue: string) => {
      onChange(optionValue);
      setIsOpen(false);
    },
    [onChange],
  );

  const toggleDropdown = React.useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const renderOption = React.useCallback(
    (option: { value: string; label: string }) => {
      const handleClick = () => {
        handleOptionClick(option.value);
      };

      return (
        <OptionItem key={option.value} isSelected={option.value === value} onClick={handleClick}>
          <span>{option.label}</span>
          <span className='tick-icon'>✓</span>
        </OptionItem>
      );
    },
    [handleOptionClick, value],
  );

  return (
    <SortSelectWrapper ref={dropdownRef}>
      <SelectStyled onClick={toggleDropdown}>
        <span>{`Ordenar por: ${selectedOption?.label || ''}`}</span>
        <DropdownIcon isOpen={isOpen}>▾</DropdownIcon>
      </SelectStyled>
      {isOpen && <OptionsContainer>{options.map(renderOption)}</OptionsContainer>}
    </SortSelectWrapper>
  );
};
