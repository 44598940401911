import { Body, H3 } from '@components/atm.typography';
import * as React from 'react';
import {
  MediaObjectContentStyled,
  MediaObjectDisabledIconStyled,
  MediaObjectStyled,
} from './media-object.component.style';
import SvgManutencao from '@app/components/svg/manutenção';

export interface MediaObjectProps {
  name: string;
  icon?: JSX.Element;
  caption?: string;
  enabled?: boolean;
  onClick: () => void;
  minHeight?: string;
}

export class MediaObject extends React.Component<MediaObjectProps, any> {
  constructor(props: MediaObjectProps) {
    super(props);
  }

  render() {
    return (
      <MediaObjectStyled disabled={!this.props.enabled} onClick={this.handleOnClick} $minHeight={this.props.minHeight}>
        {this.props.icon}
        <MediaObjectContentStyled addMargin={!!this.props.icon}>
          <H3>{this.props.name}</H3>
          {this.props.caption && <Body>{this.props.caption}</Body>}
        </MediaObjectContentStyled>
        {this.props.enabled || (
          <MediaObjectDisabledIconStyled>
            {' '}
            <SvgManutencao />{' '}
          </MediaObjectDisabledIconStyled>
        )}
      </MediaObjectStyled>
    );
  }

  private handleOnClick = () => {
    if (!this.props.enabled) {
      return;
    }
    this.props.onClick();
  };
}
