import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Button } from '@components/atm.button';
import { H1, H3, InputLabel } from '@components/atm.typography';
import { Col, Row, Separator } from '@components/obj.grid';
import { ButtonWrapper, ContentWrapper, WhiteContainer } from '@components/obj.white-container';
import { AlertStyled, RecommendationCommentTextStyled } from './vaccine-selection.view.style';
import { TextField } from '@components/atm.text-field';
import { LineSeparator } from '@components/obj.line-separator';
import { Hbox } from '@components/obj.box';
import { Spacing } from '@components/obj.constants';
import { Form } from '@components/obj.form';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import Container from 'typedi';
import { GraphQLDocsService } from '@app/core/service/graphql-docs.service';
import { RecommendationCreateMutationVariables } from '@app/resource/graphql/generated';
import { Notification } from '@components/atm.notification';
import { RecommendationInput } from '@app/resource/graphql/base-schema';
import { ImunocardAppointmentStore } from '@app/modules/appointment/imunocard-appointment.store';

interface LocationState {
  patientName?: string;
  imunocardNumber: string;
  recommendationUrl?: string;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  createRecommendation: (params: { variables: RecommendationCreateMutationVariables }) => Promise<any>;
}

interface State {
  patientName: string;
  recommendationId: string | null;
  loading: boolean;
  creationError: boolean;
  errorMessage?: string;
}

const documentNode = Container.get(GraphQLDocsService).getDocument('all-recommendations.query');

@GraphQL('recommendation-create.mutation', {
  name: 'createRecommendation',
  options: { refetchQueries: [{ query: documentNode }] },
})
@Observer()
export class RecommendationReviewView extends React.Component<Props, State> {
  private imunocardAppointmentStore?: ImunocardAppointmentStore = Container.get(ImunocardAppointmentStore);
  constructor(props: Props) {
    super(props);

    this.state = {
      patientName: '',
      recommendationId: null,
      loading: false,
      creationError: false,
      errorMessage: 'Erro inesperado ao criar recomendação',
    };
  }

  handleSubmit = async (formData: any) => {
    if (!formData.errors || Object.keys(formData.errors).length === 0) {
      this.setState({ loading: true });

      try {
        const input: RecommendationInput = {
          imunocard: this.imunocardAppointmentStore.imunocard,
          age: this.imunocardAppointmentStore.ageGroup,
          crm: '',
          uf: '',
          vaccines: this.imunocardAppointmentStore.vaccines.map((vaccine, index) => ({
            vaccineId: vaccine.vaccine.id,
            order: index,
            comment: vaccine.vaccine.recommendationComment || '',
          })),
        };

        const result = await this.props.createRecommendation({ variables: { data: input } });

        if (result.data?.RecommendationCreate?.id) {
          this.setState({ recommendationId: result.data.RecommendationCreate.id });
        }

        this.props.history.push('/confirmacao-da-recomendacao', {
          imunocardNumber: this.imunocardAppointmentStore.imunocard,
          patientName: this.state.patientName,
          recommendationUrl: result.data?.RecommendationCreate?.recommendationUrl,
        });
        this.imunocardAppointmentStore.cleanStore();
      } catch (error) {
        const errorMessage = error.message.replace('GraphQL error: ', '');
        this.setState({ creationError: true, errorMessage });
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handlePatientNameChange = (value: string) => {
    this.setState({ patientName: value });
  };

  render() {
    const imunocardNumber = this.imunocardAppointmentStore.imunocard ?? '';
    const ageGroupSelected = this.imunocardAppointmentStore.ageGroup ?? '';
    const selectedVaccines = this.imunocardAppointmentStore.vaccines ?? [];

    if (!imunocardNumber || !ageGroupSelected || !selectedVaccines || selectedVaccines.length === 0) {
      return <Redirect to='/recomendacao/selecao-de-vacinas' />;
    }

    return (
      <WhiteContainer>
        <H1>Revise a sua recomendação</H1>
        <Separator />
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={12} sm={6}>
              <InputLabel>Nome do paciente (opcional):</InputLabel>
              <TextField
                type='text'
                value={this.state.patientName}
                placeholder='Digite o nome do paciente'
                onValueChange={this.handlePatientNameChange}
              />
            </Col>
          </Row>
          <Separator />
          <LineSeparator />
          <Separator />
          <H3>Informações gerais</H3>
          <Separator />
          <Hbox $hAlign='space-between' style={{ flexWrap: 'wrap' }}>
            <span>
              <strong>Paciente:</strong> {this.state.patientName}
            </span>
            <span>
              <strong>Data:</strong> {new Date().toLocaleDateString('pt-BR')}
            </span>
          </Hbox>
          <span>
            <strong>Imunocard:</strong> {imunocardNumber}
          </span>
          <Separator />
          <Separator />
          <H3>{ageGroupSelected}</H3>
          <Separator />
          <ContentWrapper>
            <Row>
              <Col xs={12} sm={8}>
                {selectedVaccines.map((vaccine, index) => (
                  <div key={index} style={{ display: 'flex', gap: Spacing.Small }}>
                    <strong>{index + 1}.</strong>
                    <div>
                      <strong>{vaccine.vaccineFamily.name}</strong>
                      <p>{vaccine.vaccine.description}</p>
                      <p>
                        {/* TODO: add dose count when available */}
                        {/* {vaccine.vaccine.injectionCount === 1
                          ? 'Dose única'
                          : `${vaccine.vaccine.injectionCount} doses`}{' '}
                        •  */}
                        {vaccine.vaccine.isAdministeredOnPublicClinics ? 'Público' : 'Privado'}
                      </p>

                      {vaccine.vaccine.isLiveAttenuated && <AlertStyled>⚠️ Vacina viva atenuada</AlertStyled>}

                      {vaccine.vaccine.recommendationComment && (
                        <RecommendationCommentTextStyled
                          title={vaccine.vaccine.recommendationComment}
                          fullComment={true}
                        >
                          <strong>Nota:</strong> {vaccine.vaccine.recommendationComment}
                        </RecommendationCommentTextStyled>
                      )}
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </ContentWrapper>
          <Separator />
          <Separator />
          {this.state.creationError && (
            <Notification
              type='error'
              message={this.state.errorMessage}
            />
          )}
          <ButtonWrapper>
            <Button kind='callToAction' type='submit' loading={this.state.loading}>
              {'Criar recomendação'}
            </Button>
          </ButtonWrapper>
        </Form>
      </WhiteContainer>
    );
  }
}
