import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';


export const DefaultRecommendationIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${Spacing.XXLarge};
  margin: ${Spacing.XXLarge} 0;
`;

export const DefaultRecommendationStyled = styled.div<{ $isNew: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  align-content: center;
  min-width: 480px;
  margin-bottom: ${Spacing.Large};
  border-radius: ${Spacing.Small};
  border: 2px solid ${Color.GrayLight};

  @media (max-width: 48em) {
    min-width: 0;
  }

  :hover {
    background-color: ${props => props.$isNew ? '' : Color.GrayXLight}
  }
`;

export const DefaultRecommendationContentWrapper = styled.div`
  width: 100%;
  background-color: inherit;
`;

export const DefaultRecommendationContentStyled = styled.div<{ $isNew: boolean }>`
  margin-left: 0;
  padding: ${props => props.$isNew ? `0 ${Spacing.Large}` : Spacing.Large};
`;

