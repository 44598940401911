import * as React from 'react';
import { Button } from '@components/atm.button';
import { H1 } from '@components/atm.typography';
import { Separator } from '@components/obj.grid';
import { ButtonWrapper, WhiteContainer } from '@components/obj.white-container';
import { RadioField, RadioFieldGroup } from '@components/atm.radio';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { PropsBase } from '@app/core/base/props.base';
import { Redirect } from 'react-router-dom';

import {
  ListCategoriesRecommendationQuery,
  ListCategoriesRecommendationQueryVariables,
} from '@app/resource/graphql/generated';
import { ImunocardAppointmentStore } from '@app/modules/appointment/imunocard-appointment.store';
import Container from 'typedi';

interface AgeSelectionViewState {
  ageGroupSelected: string | null;
  imunocardNumber: string | null;
  redirectToHome: boolean;
}

@GraphQL('list-categories-recommendation.query', {
  options: {
    errorPolicy: 'all',
  },
})
@Observer()
export class AgeSelectionView extends React.Component<
  PropsBase<ListCategoriesRecommendationQuery, ListCategoriesRecommendationQueryVariables>,
  AgeSelectionViewState
> {
  private imunocardAppointmentStore?: ImunocardAppointmentStore = Container.get(ImunocardAppointmentStore);
  constructor(props) {
    super(props);
    const imunocardNumber = this.imunocardAppointmentStore.imunocard || null;
    const ageGroupSelected = this.imunocardAppointmentStore.ageGroup || null;

    this.state = {
      ageGroupSelected,
      imunocardNumber,
      redirectToHome: !imunocardNumber,
    };
  }

  handleSubmit = (formData: any) => {
    const { imunocardNumber, ageGroupSelected } = this.state;

    if (!imunocardNumber) {
      return;
    }

    if (formData.errors.length === 0) {
      this.checkAndResetVaccines(ageGroupSelected);
      this.imunocardAppointmentStore.setAgeGroup(ageGroupSelected);
      this.props.history.push('/recomendacao/selecao-de-vacinas');
    }
  };

  checkAndResetVaccines = (ageGroupSelected: string) => {
    const { ageGroup, vaccines } = this.imunocardAppointmentStore;

    if (ageGroup && ageGroup !== ageGroupSelected && vaccines.length > 0) {
      this.imunocardAppointmentStore.resetVaccines();
    }
  };

  handleValueChange = (value: string) => {
    this.setState({ ageGroupSelected: value });
  };

  render() {
    const { data } = this.props;
    const { ageGroupSelected, redirectToHome } = this.state;
    const { loading, error } = data;

    if (redirectToHome) {
      return <Redirect to='/recomendacao/numero-do-imunocard' />;
    }

    const ageCategories = data?.ListCategoriesRecommendation?.categories || [];

    return (
      <WhiteContainer>
        <H1>Selecione a faixa etária</H1>
        <Separator />
        <Separator />

        {!ageCategories.length && loading && <p>Carregando dados...</p>}
        {!ageCategories.length && error && <p>Erro ao carregar dados, tente novamente mais tarde.</p>}
        {ageCategories.length && (
          <Form onSubmit={this.handleSubmit}>
            <FieldValidator
              name='ageGroup'
              validators={[Validators.Required('Selecione uma faixa etária.')]}
              defaultValue={ageGroupSelected}
            >
              <RadioFieldGroup
                onValueChange={this.handleValueChange}
                value={ageGroupSelected}
                responsiveDirection={true}
                withGap={true}
              >
                {ageCategories.map(category => (
                  <RadioField key={category.id} withBorder={true} value={category.category} name='ageGroup'>
                    {category.category}
                  </RadioField>
                ))}
              </RadioFieldGroup>
            </FieldValidator>
            <Separator />
            <ButtonWrapper>
              <Button kind='primary' type='submit'>
                Continuar
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </WhiteContainer>
    );
  }
}
