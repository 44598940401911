import { Spacing } from '@components/obj.constants';
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export interface RowProps {
  $mb?: boolean;
  noGutter?: boolean;
  $align?: string;
}

export const Separator = styled.div`
  padding-top: ${Spacing.Large};
`;

export const AutoMargin = styled.div`
  margin: auto;
`;

export const Row = styled<RowProps>(FlexRow)`
  margin-bottom: ${(props: RowProps) => (props.$mb ? Spacing.Large : '0px')};

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;`;
    } else {
      return '';
    }
  }}

  ${(props: RowProps) => {
    if (props.$align) {
      return `text-align: ${props.$align};`;
    } else {
      return '';
    }
  }}

  @media print {
    /* "display: flex" is not working (https://github.com/ipython/ipython/issues/5115/#issue-27492027) */
    display: block;
  }
`;

export const Grid = styled(FlexGrid)``;

export const Col = styled(FlexCol)``;

export const GridProps = {
  flexboxgrid: {
    gridSize: 12,
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
    },
  },
};

export const rowMargin = GridProps.flexboxgrid.outerMargin + GridProps.flexboxgrid.gutterWidth / 2;
