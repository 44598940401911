import CookieConsentDatasource, { CookieConsentDataSourceObserver } from '@app/resource/cookie-consent.datasource';
import * as React from 'react';
/* tslint:disable:all */

/**
 * loads hotjar script if possible (if user allows the cookie policy)
 * 		- LGPD: if user doesnt accept the cookie policy, then this component also cleans the created cookies/data
 * @returns
 */
export const HotjarLoad: React.FunctionComponent = () => {
  React.useEffect(() => {
    const loadHotjarIfPossible = () => {
      const hjid = process.env.HOTJAR_ID;

      if (!CookieConsentDatasource.acceptedPerformance) {
        localStorage.removeItem('_hjid');
        CookieConsentDatasource.eraseCookie('_hjid');
        CookieConsentDatasource.eraseCookie('_hjFirstSeen');
        CookieConsentDatasource.eraseCookie('_hjAbsoluteSessionInProgress');
        CookieConsentDatasource.eraseCookie('_hjIncludedInPageviewSample');
        return;
      }

      if (!hjid) {
        return;
      }

      // <!-- Hotjar Tracking Code for https://www.vacinacerta.com.br/ -->
      (function (h: any, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    };
    const observer: CookieConsentDataSourceObserver = {
      onPerformanceCookieChanged: loadHotjarIfPossible,
    };
    CookieConsentDatasource.subscribeObserver(observer);
    loadHotjarIfPossible();
    return () => CookieConsentDatasource.unsubscribeObserver(observer);
  }, []);
  return null;
};
