import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AuthenticationStore, OPT_OUT_USER_MAP_KEY } from '@app/modules/authentication/authentication.store';
import { NotificationType } from '@components/atm.notification';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Container } from 'typedi';
import { HOME_PATH } from '../home/home.path';
import { OptOutUserView } from './opt-out-user.view';
import { OptOutUserMutation } from '@app/resource/graphql/generated';
import { OptOutUserInput } from '@app/resource/graphql/base-schema';

export type OptOutUserDataProps = PropsBase<OptOutUserMutation>;

@GraphQL('opt-out-user.mutation')
@Observer()
export class OptOutUserData extends React.Component<OptOutUserDataProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  render() {
    if (!this.authenticationStore.isAdminUser()) {
      return <Redirect to={HOME_PATH} />;
    }

    const submitLoading = this.authenticationStore.loadingMap.get(OPT_OUT_USER_MAP_KEY);
    const notification = {
      message:
        this.authenticationStore.successMessageMap.get(OPT_OUT_USER_MAP_KEY) ||
        this.authenticationStore.errorMessageMap.get(OPT_OUT_USER_MAP_KEY),
      type:
        (this.authenticationStore.successMap.get(OPT_OUT_USER_MAP_KEY) && 'success') ||
        ((this.authenticationStore.errorMessageMap.get(OPT_OUT_USER_MAP_KEY) && 'error') as NotificationType),
    };

    return <OptOutUserView onSubmit={this.callMutation} submitLoading={submitLoading} notification={notification} />;
  }

  componentWillUnmount() {
    this.authenticationStore.resetErrorState();
  }

  private callMutation = (input: OptOutUserInput) => {
    this.authenticationStore.optOutUser(input, this.props.mutate);
  };
}
