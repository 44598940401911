import { Border, Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { SelectProps } from './select.component';

export const SelectWrapperStyled = styled.div`
  border-radius: ${Border.radius};
  border-width: 1px;
  border-color: ${Color.NeutralGrayStrong};
  border-style: solid;
  overflow: hidden;
`;

export const SelectStyled = styled.select<SelectProps>`
  width: 97%;
  height: 50px;
  padding: ${Spacing.Small};
  border: 0;
  outline: none;
  background-color: ${Color.White};
  align-self: stretch;
  font-family: ${FontFamily.Secondary};
  font-size: ${FontSize.Small};
`;
