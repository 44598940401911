import { H2 } from '@components/atm.typography';
import { Col, Row } from '@components/obj.grid';
import * as React from 'react';
import { FindUserForm, IFindUserFormProps } from '../org.find-user/find-user.component';

export type ForgotPasswordProps = IFindUserFormProps;

export const ForgotPassword = (props: ForgotPasswordProps) => {
  return (
    <Row center='xs'>
      <Col xs={12}>
        <Row center='xs' $mb={true}>
          <Col xs={12}>
            <H2>Esqueceu a senha?</H2>
          </Col>
        </Row>
        <FindUserForm {...props} />
      </Col>
    </Row>
  );
};
