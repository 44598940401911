export const doctorPublicDescriptions = [
  {
    img: 'assets/img/img_how_it_works_1.png',
    title: 'Padronize recomendações vacinais por faixa etária',
    description: 'De forma simples e intuitiva, no computador ou pelo celular, padronize suas recomendações para uma faixa etária específica ou crie recomendações personalizadas para cada paciente.',
  },
  {
    img: 'assets/img/img_how_it_works_2.png',
    title: 'Entregue um cartão Imunocard para seu paciente',
    description: 'Cuidado, atenção e orientações que vão além do básico. Ofereça a cada paciente adulto um cartão Imunocard e promova a imunização de maneira proativa.',
  },
  {
    img: 'assets/img/img_how_it_works_3.png',
    title: 'Incentive o acesso ao Imunocard pelos seus pacientes',
    description: 'O paciente cadastrado poderá acessar sua recomendação pelo WhatsApp por meio do QR Code indicado no cartão, além de consumir outros serviços e benefícios.',
  },
  {
    img: 'assets/img/img_how_it_works_4.png',
    title: 'Aproveite uma variedade de recursos adicionais para facilitar sua rotina',
    description: 'Confira os centros de vacinação mais próximos, consulte o calendário oficial da SBIm, respostas para dúvidas mais frequentes sobre vacinação e explore diversos outros recursos pensados para você.',
  },
];

export const generalPublicDescriptions = [
  {
    img: 'assets/img/img_how_it_works_general_1.png',
    title: 'Converse com o seu médico sobre o cartão Imunocard',
    description: 'Mais do que cuidado: atenção e orientação personalizada. Durante a consulta, converse com o seu médico sobre como incluir o Imunocard na sua jornada vacinal.',
  },
  {
    img: 'assets/img/img_how_it_works_general_2.png',
    title: 'Cadastre-se pelo QR Code e acesse sua recomendação pelo WhatsApp',
    description: 'Acompanhe sua recomendação vacinal diretamente no celular. Todas as informações estarão reunidas em um só lugar, facilitando o seu acesso e garantindo que você tenha tudo o que precisa.',
  },
  {
    img: 'assets/img/img_how_it_works_general_3.png',
    title: 'Conte com uma variedade de recursos adicionais',
    description: 'Encontre os centros de vacinação mais próximos, receba alertas sobre vacinas pendentes e descubra benefícios exclusivos — tudo de forma rápida e sem complicação.',
  },
];
