import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { Border, Color, FontFamily, Spacing } from '@components/obj.constants';
import { InputValue } from '@components/atm.typography';
import { faCircle as faSolidCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { isVacinaTheme } from 'config/theme';

const radioSize: number = 24;

export const RadioFieldStyled = styled.span<{ isSelected?: boolean; withBorder?: boolean }>`
  display: flex;
  position: relative;
  direction: row;
  margin: ${Spacing.XSmall} 0;

  ${({ withBorder, isSelected }) =>
    withBorder &&
    css`
      flex-direction: column;
      align-items: flex-start;
      border: 2px solid ${isSelected ? Color.Info : Color.NeutralGrayXSoft};
      background-color: ${isSelected ? Color.Acessory3 : Color.White};
      border-radius: ${Border.radiusLarge};
      padding: ${Spacing.XLarge} ${Spacing.Medium};
      min-width: 212px;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: ${Spacing.XSmall};
        padding: ${Spacing.Large} ${Spacing.Small};
      }
    `}
`;

export const RadioFieldTextStyled = styled(InputValue)`
  padding-left: 32px;
  line-height: ${radioSize}px;
  z-index: 2;
  font-family: ${FontFamily.Primary};
`;

export const RadioCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faSolidCircle })<{ isSelected?: boolean }>`
  position: absolute;
  font-size: 14px;
  color: ${({ isSelected }) => (isSelected && !isVacinaTheme ? 'transparent' : Color.Accessory)};
  z-index: 1;
  opacity: 0;
  left: 5px;
  top: 5px;
`;

export const RadioUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCircle })<{ isSelected?: boolean }>`
  position: absolute;
  font-size: ${radioSize}px;
  z-index: 0;
  color: ${Color.GrayXDark};
  ${({ isSelected }) =>
    isSelected &&
    !isVacinaTheme &&
    css`
      color: ${Color.Accessory};
      box-shadow: 0 0 0 5px ${Color.Accessory};
      border-radius: 50%;
      background-color: ${Color.White};
      font-size: 16px;
      margin-left: ${Spacing.XSmall};
      margin-top: ${Spacing.XSmall};
    `}
`;

export const RadioFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${RadioCheckedStyled} {
    opacity: 1;
  }
`;
