import { Spacing } from '@components/obj.constants';
import styled, { css, keyframes } from 'styled-components';

interface ModalStyledProps {
  $opened: boolean;
}

export const ModalStyled = styled.div<ModalStyledProps>`
  position: fixed;
  visibility: ${props => (props.$opened ? 'visible' : 'hidden')};
  z-index: 99;
`;

const modalOpenAnimation = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const modalCloseAnimation = keyframes`
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
`;

export const ModalOpacityStyled = styled.div<ModalStyledProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: #000;
  opacity: ${props => (props.$opened ? '0.5' : '0')};
  transition: all 0.2s ease-out;
  visibility: ${props => (props.$opened ? 'visible' : 'hidden')};
`;

const modalOpenAnimationCss = css`
  ${modalOpenAnimation} 0.2s ease-out forwards;
`;

const modalCloseAnimationCss = css`
  ${modalCloseAnimation} 0.2s ease-out forwards;
`;

export const ModalBoxStyled = styled.div<{
  $small: boolean;
  $large: boolean;
  $noAnimation: boolean;
  $nextAnimationIsOpen: boolean;
}>`
  position: fixed;
  top: 80px;

  width: ${props => (props.$small ? '360px' : props.$large ? '700px' : '90%')};
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);

  z-index: 10;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  animation: ${props => {
    if (props.$noAnimation) return 'none';
    return props.$nextAnimationIsOpen ? modalOpenAnimationCss : modalCloseAnimationCss;
  }};
`;

export const ModalBoxBodyStyled = styled.div<{ $maxHeight: string; $noGutter: boolean }>`
  max-height: ${props => props.$maxHeight || '80vh'};
  overflow-y: scroll;
  padding: ${props => (props.$noGutter ? '0' : Spacing.XLarge)};
`;
