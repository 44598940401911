import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DoctorRecommendationsQueryVariables = Types.Exact<{
  data: Types.DoctorRecommendationInput;
}>;

export type DoctorRecommendationsQuery = {
  __typename?: 'Queries';
  DoctorRecommendations?: {
    __typename?: 'DoctorRecommendationResponse';
    totalRecommendations: number;
    totalPages: number;
    recommendations: Array<{
      __typename?: 'DoctorRecommendationType';
      id: string;
      imunocard?: string | null;
      patientName?: string | null;
      type?: Types.RecommendationKind | null;
      createdAt: any;
      recommendationUrl?: string | null;
    } | null>;
  } | null;
};

export const DoctorRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DoctorRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorRecommendationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DoctorRecommendations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DoctorRecommendationType' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRecommendations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DoctorRecommendationType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorRecommendationType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imunocard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendationUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DoctorRecommendationsQuery, DoctorRecommendationsQueryVariables>;
