// tslint:disable: max-line-length
import styled, { css, keyframes } from 'styled-components';

export interface FadeProps {
  $show: boolean;
  $duration?: number;
}

/**
 * This fade animates only when `opacity` changes
 */
export const Fade = styled.div<FadeProps>`
  border: 0 solid transparent;
  pointer-events: ${props => (props.$show ? 'inherit' : 'none')};
  opacity: ${props => (props.$show ? 1 : 0)};
  transition: opacity ${props => (props.$duration ? props.$duration : '0.3s')};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface ForcedFadeProps {
  $show: boolean;
  $duration?: string;
}

const animationRule = css<ForcedFadeProps>`
  animation: ${props => (props.$show ? fadeIn : fadeOut)} ${props => props.$duration || '.3s'} linear forwards;
`;

/**
 * This fade will happen everytime, which is different from `Fade` (component) which animates only when `opacity` changes
 */
export const ForcedFade = styled.div<ForcedFadeProps>`
  border: 0;
  opacity: 0;
  pointer-events: ${props => (props.$show ? 'inherit' : 'none')};
  ${animationRule};
`;
