// tslint:disable: max-line-length
import {
  ImmunocompromisedComorbidities,
  mapImmunocompromisedComorbiditiesToString,
} from '@app/modules/appointment/immunocompromised-choice.utils';
import { VaccinePriority } from '@app/modules/appointment/priority-choice.utils';
import { Body, H3 } from '@components/atm.typography';
import { ClickableWrapperStyled } from '@components/atm.wrapper/wrapper.component.style';
import { Hbox, Separator } from '@components/obj.box';
import { useClientRect } from '@components/obj.custom-hooks/client-rect.hook';
import { hasDocument } from '@components/utils';
import * as React from 'react';
import {
  QuickRecommendationFixedMenuContentStyled,
  QuickRecommendationFixedMenuHeaderChevronStyled,
  QuickRecommendationFixedMenuHeaderStyled,
  QuickRecommendationFixedMenuItemPlusStyled,
  QuickRecommendationFixedMenuWrapperPlaceholderStyled,
  QuickRecommendationFixedMenuWrapperStyled,
} from './quick-recommendation-fixed-menu.styled';
// tslint:enable: max-line-length

interface QuickRecommendationVaccine {
  id: string;
  name: string;
  laboratory?: string;
  brand?: string;
  vaccineFamilyId?: number;
  injectionCount: number;
  specialNeed?: string | ImmunocompromisedComorbidities;
  priority?: VaccinePriority;
}

export interface QuickRecommendation {
  id: string;
  age?: string;
  vaccines?: QuickRecommendationVaccine[];
}

export interface IQuickRecommendationFixedMenuProps {
  bottom?: number;
  age?: string;
  hideAgesOnItems?: boolean;
  recommendations: QuickRecommendation[];
  onItemClick: (recommendation: QuickRecommendation) => void;
}

const QuickRecommendationFixedMenu: React.FunctionComponent<IQuickRecommendationFixedMenuProps> = props => {
  const [rect, ref] = useClientRect();
  const [expanded, setExpanded] = React.useState(false);
  const [hide, setHide] = React.useState(true);
  const [contentHeight, setContentHeight] = React.useState(0);

  // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
  const node = React.useRef<any>();
  const handleClick = e => {
    if (node.current && !node.current.contains(e.target)) {
      setExpanded(false);
    }
  };
  React.useEffect(() => {
    if (hasDocument()) {
      // add when mounted
      document.addEventListener('mousedown', handleClick);
    }
    // return function to be called when unmounted
    return () => {
      if (hasDocument()) {
        document.removeEventListener('mousedown', handleClick);
      }
    };
  }, []);

  React.useEffect(() => {
    if (rect) {
      setContentHeight(rect.height);
    }
  }, [rect]);

  React.useEffect(() => {
    setTimeout(() => {
      if (setHide) {
        setHide(contentHeight === 0);
      }
    }, 300);
  }, [contentHeight]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClickHOF = (recommendation: QuickRecommendation) => () => {
    setExpanded(!expanded);
    props.onItemClick(recommendation);
  };

  if (!props.recommendations || !props.recommendations.length) {
    return null;
  }

  return (
    <>
      <QuickRecommendationFixedMenuWrapperPlaceholderStyled />
      <QuickRecommendationFixedMenuWrapperStyled
        y={expanded ? `${-props.bottom}px` : `${contentHeight - props.bottom}px`}
        hide={hide}
        ref={node}
      >
        <QuickRecommendationFixedMenuHeaderStyled onClick={handleToggle}>
          <Hbox>
            <Hbox.Item>
              <Body $light={true}>
                Últimas recomendações {props.age ? `(${props.age})` : ''} <br />
              </Body>
            </Hbox.Item>
            <Hbox.Item $noGrow={true} $vAlign={'center'}>
              <QuickRecommendationFixedMenuHeaderChevronStyled rotate={expanded ? 'true' : undefined} />
            </Hbox.Item>
          </Hbox>
        </QuickRecommendationFixedMenuHeaderStyled>
        <QuickRecommendationFixedMenuContentStyled ref={ref}>
          {props.recommendations.map((r, index) => {
            const vaccineWithSpecialNeed = r.vaccines.find(v => v.specialNeed);
            const specialNeed =
              vaccineWithSpecialNeed && (vaccineWithSpecialNeed.specialNeed as ImmunocompromisedComorbidities);
            const specialNeedName = mapImmunocompromisedComorbiditiesToString(specialNeed);
            return (
              <ClickableWrapperStyled key={r.id} onClick={handleClickHOF(r)}>
                <H3>
                  {' '}
                  <QuickRecommendationFixedMenuItemPlusStyled /> Recomendação {index + 1}{' '}
                  {!props.hideAgesOnItems && r.age && `(${r.age})`} {specialNeedName && `(${specialNeedName})`}
                </H3>
                <Separator />
                {r.vaccines.map(v => (
                  <Body key={v.id}>
                    {v.name} {v.brand ? `| ${v.brand}` : ''}
                  </Body>
                ))}
              </ClickableWrapperStyled>
            );
          })}
        </QuickRecommendationFixedMenuContentStyled>
      </QuickRecommendationFixedMenuWrapperStyled>
    </>
  );
};

QuickRecommendationFixedMenu.defaultProps = {
  bottom: 0,
};

export default QuickRecommendationFixedMenu;
