// tslint:disable: max-line-length
import { Quote } from '@components/atm.typography';
import { Col } from '@components/obj.grid';
import { RelativeContainer } from '@components/obj.relative-container';
import * as React from 'react';
import { Drop } from '../atm.drop/drop.component';
import {
  TestimonialContainerStyled,
  TestimonialImgStyled,
  TestimonialNameStyled,
  TestimonialRowStyled,
} from './testimonial-row.style';
import { Hbox } from '@components/obj.box';
// tslint:enable: max-line-length

export interface TestimonialRowProps {
  img: string;
  message: string;
  name: string;
  reverse: boolean;
  hideDrop?: boolean;
}

export class TestimonialRow extends React.Component<TestimonialRowProps> {
  constructor(props: TestimonialRowProps) {
    super(props);
  }

  render() {
    const reverse = this.props.reverse;
    return (
      <TestimonialRowStyled $middle='xs' center='xs' reverse={reverse}>
        <>
          {this.props.hideDrop || (
            <RelativeContainer>
              <Drop reverse={reverse} />
            </RelativeContainer>
          )}
        </>
        <Col xs={10} sm={2}>
          <Hbox $hAlign={'center'}>
            <Hbox.Item $noGrow={true}>
              <TestimonialImgStyled src={this.props.img} />
            </Hbox.Item>
          </Hbox>
        </Col>
        <Col xs={10} sm={5}>
          <TestimonialContainerStyled $isLeft={reverse}>
            <Quote>{this.props.message}</Quote>
            <TestimonialNameStyled>{this.props.name}</TestimonialNameStyled>
          </TestimonialContainerStyled>
        </Col>
      </TestimonialRowStyled>
    );
  }
}
