import styled from 'styled-components';
import { Spacing } from '@components/obj.constants';

export const LogoStyled = styled.img<{ $mt: boolean; $onlyPrint: boolean }>`
  height: 44px;
  margin-top: ${props => (props.$mt ? Spacing.XXXLarge : 0)};
  display: ${props => (props.$onlyPrint ? 'none' : 'inline-block')};

  @media print {
    display: inline-block;
    text-align: center;
  }
`;
