import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { Component } from 'react';
import * as React from 'react';
import { Container } from 'typedi';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { AccountStore } from '@app/modules/account/account.store';
import { HOME_PATH } from '@app/modules/home/home.path';

// TODO: Add redirect field 'to' parameter
export default function guard(WrappedComponent: new (...args) => Component) {
  return class extends Component<RouteComponentProps<any>> {
    private searchParams: string = '';

    constructor(props: RouteComponentProps<any>) {
      super(props);
      if (typeof window !== 'undefined') {
        const currentUrl = window.location.href;
        const urlObj = new URL(currentUrl);
        if (urlObj.search.includes('token')) {
          this.searchParams = urlObj.search;
        }
      }
    }

    componentDidMount() {
      Container.get(AccountStore).routeAfterLogin = this.props.location.pathname;
    }

    render() {
      return Container.get(AuthenticationStore).loggedIn ? (
        <WrappedComponent />
      ) : (
        <Switch>
          <Redirect
            from='*'
            to={{
              pathname: HOME_PATH,
              search: this.searchParams,
            }}
        />
        </Switch>
      );
    }
  };
}
