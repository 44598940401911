import * as React from 'react';
import { Loading } from '@components/atm.loading';

import { ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled, ButtonType } from './button.component.style';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export interface ButtonProps extends React.PropsWithChildren {
  disabled?: boolean;
  expanded?: boolean;
  loading?: boolean;
  onClick?: (ev?: React.MouseEvent<HTMLElement>) => any;
  outlined?: boolean;
  kind?: ButtonType;
  small?: boolean;
  width?: string;
  /**
   * why is it necessary? https://github.com/indigotech/eok-weekly/issues/114
   * https://www.w3schools.com/tags/att_button_type.asp
   */
  type?: 'button' | 'submit' | 'reset'; // default-value: "button"
}

export class Button extends React.Component<ButtonProps, any> {
  static defaultProps = {
    type: 'button',
  };

  constructor(props: ButtonProps) {
    super(props);
  }

  render() {
    const { loading, onClick, disabled, expanded, outlined, kind, width, small } = this.props;
    const loadingValue: any = loading ? 'true' : undefined;
    return (
      <ButtonStyled
        onClick={onClick ? onClick : null}
        disabled={loading || disabled}
        $expanded={expanded}
        $outlined={outlined}
        $kind={kind}
        $width={width}
        $small={small}
        $loading={loading}
      >
        <ButtonContentStyled $loading={loadingValue}>{this.props.children}</ButtonContentStyled>
        <ButtonSpinnerStyled $loading={loadingValue}>
          <Loading type={faSpinner} />
        </ButtonSpinnerStyled>
      </ButtonStyled>
    );
  }
}
