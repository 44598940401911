// tslint:disable: max-line-length
import { LinkButton } from '@components/atm.button';
import { Col, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import {
  FooterGridStyled,
  FooterIconStyled,
  FooterPartenersStyled,
  FooterPoweredFontStyled,
  FooterStyled,
} from './footer.style';
import { H3 } from '@components/atm.typography';
import { isVacinaTheme } from 'config/theme';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Color } from '@components/obj.constants';
// tslint:enable: max-line-length

export interface FooterProps {
  onTermsClick?: (e) => void;
  onPrivacyPolicyClick?: (e) => void;
  onContactClick?: (e) => void;
  onGskSiteClick?: (e) => void;
}

export class Footer extends React.Component<FooterProps> {
  render() {
    return (
      <FooterStyled>
        <FooterGridStyled fluid={true}>
          <Row>
            <Col xs={12} sm={5}>
              <Row between='xs' $mb={true}>
                <Col sm={4} xs={12} $smOffset={0}>
                  {isVacinaTheme ? (
                    <img src='/assets/img/img_logo_color.png' width='225px' height='44px' />
                  ) : (
                    <>
                      <img src='/assets/img/img-imunocard-logo-white.png' width='190px' height='28px' />
                      <Separator />
                    </>
                  )}
                </Col>
              </Row>
              <Row center='xs' >
                <Col xs={12}>
                  <FooterPoweredFontStyled>
                    Este site é destinado a visitantes que buscam informações sobre
                    <br /> os negócios da GSK Brasil.
                    <br />
                    <br /> Conteúdo destinado ao público em geral, por favor consulte o seu
                    <br /> médico. NP-BR-SGX-WCNT-240004 JANEIRO/2025
                    <br />
                    <br /> © 2021-2025 GSK plc. Todos os direitos reservados. As marcas
                    <br /> registradas são de propriedade ou licenciadas para o grupo de
                    <br /> empresas da GSK.
                    <br />
                    <br /> GSK Brasil. Estrada dos Bandeirantes 8464 – Rio de Janeiro – RJ
                    <br /> CEP 22783-110. CNPJ 33.247743/0001-10
                  </FooterPoweredFontStyled>
                  <Separator />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={3}>
              <Row center='xs' $mb={true}>
                <Col xs={12}>
                  <H3 $light={!isVacinaTheme}>Entre em contato</H3>
                  <Separator />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <LinkButton
                    light={!isVacinaTheme}
                    noPadding={true}
                    onClick={this.props.onContactClick}
                  >
                    Fale conosco
                  </LinkButton>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <LinkButton
                    light={!isVacinaTheme}
                    noPadding={true}
                    onClick={this.props.onGskSiteClick}
                  >
                    Site GSK
                  </LinkButton>
                  <FooterIconStyled icon={faArrowUpRightFromSquare} color={isVacinaTheme ? Color.Primary : Color.White}/>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4}>
              <Row center='xs' $mb={true}>
                <Col xs={12}>
                  <H3 $light={!isVacinaTheme}>Termos e políticas</H3>
                  <Separator />
                </Col>
              </Row>
              <Row start='xs'>
                <Col xs={12}>
                  <LinkButton
                    light={!isVacinaTheme}
                    noPadding={true}
                    onClick={this.props.onTermsClick}
                  >
                    Termos e políticas
                  </LinkButton>
                </Col>
              </Row>
              <Row start='xs'>
                <Col xs={12}>
                  <LinkButton
                    light={!isVacinaTheme}
                    noPadding={true}
                    onClick={this.props.onPrivacyPolicyClick}
                  >
                    Políticas de privacidade
                  </LinkButton>
                  <FooterIconStyled icon={faArrowUpRightFromSquare} color={isVacinaTheme ? Color.Primary : Color.White}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <a href='https://br.gsk.com/' target='_blank'>
                <FooterPartenersStyled src='/assets/img/img_gsk_logo.png' />
              </a>
            </Col>
          </Row>
          <Separator />
        </FooterGridStyled>
      </FooterStyled>
    );
  }
}
