import * as ColorFunc from 'color';
import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { NavLink } from '@components/atm.typography';
import { MenuButtonProps } from '@components/atm.button';
import { isVacinaTheme } from 'config/theme';

export const MenuButtonStyled = styled(NavLink)`
  display: inline-block;
  min-height: 44px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    height: 100%;
  }

  &:hover {
    color: ${(props: MenuButtonProps) => (props.$disableHover ? '' : isVacinaTheme ? ColorFunc(Color.White).darken(0.2).hsl().string() : Color.CallToAction)};
  }
`;

export const MenuButtonContentStyled = styled.span`
  & .fa {
    padding-right: ${Spacing.Large};
  }
`;
