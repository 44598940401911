import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import * as React from 'react';
import { Route } from 'react-router';
import { UpdateUserDataMutation } from './update-user.data.mutation';
import { isVacinaTheme } from 'config/theme';
export const UPDATE_USER_PATH = '/seus-dados';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Seus dados`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Veja seus dados no Vacina Certa.',
    },
  ],
};
const UpdateUser = SEO(config)(UpdateUserDataMutation);

export const UpdateUserRoute = <Route path={UPDATE_USER_PATH} exact={true} component={UpdateUser} key='updateUser' />;
