// tslint:disable: max-line-length
import { DateStringService } from '@app/core/service/date-string.service';
import { AdministrationPlacesStore } from '@app/modules/administration-places/administration-places.store';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import {
  RecommendationNotesArea,
  RecommendationNotesAreaRow,
  RecommendationNotesPlaceholderText,
  RecommendationVaccineGrid,
} from '@app/modules/appointment/recommendation.style';
import { RecommendationViewState } from '@app/modules/appointment/recommendation.view-state';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { LinkButton } from '@components/atm.button';
import { Logo } from '@components/atm.logo';
import {
  Body,
  H2,
  H3,
  PhoneNumber,
  RecomendationVacineDescription,
  RecomendationVacineTitle,
} from '@components/atm.typography';
import { Signature } from '@components/mol.recomendation-signature';
import { Color } from '@components/obj.constants';
import { Frame } from '@components/obj.frame';
import { Col, Row } from '@components/obj.grid';
import { Else, ElseIf, If } from '@components/obj.if';
import { LineSeparator } from '@components/obj.line-separator';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import * as React from 'react';
import { ReactNode } from 'react';
import { Container } from 'typedi';
import { AppointmentRecommendationVaccine, PatientProfileKey } from '../appointment.store.model';
import { AdministrationPlaceKind } from '@app/resource/graphql/base-schema';
// tslint:enable: max-line-length

export const RecommendationDivId = 'recommendation';

interface Writter {
  writeAsPdfMake: () => Content;
  writeAsJSX: () => JSX.Element;
}

// It writes the medical recommenstaion whether in jsx or markdown (for e-mail)
export class RecommendationWriter {
  private sections: Writter[];

  constructor() {
    this.sections = [
      new HeaderWritter(),
      new AllVaccinesWritter(),
      new CurrentSuggestedVaccinesWritter(),
      new PreviousAgesVaccinesWritter(),
      new AdministrationPlacesWritter(),
      new NotesAreaWritter(),
      new SignatureWritter(),
      new ObservationAreaWritter(),
    ];
  }

  // https://pdfmake.github.io/docs/document-definition-object/
  writeAsPdfMake = (): TDocumentDefinitions => ({
    content: this.sections.map(s => s.writeAsPdfMake()),
    styles: {
      h1: {
        fontSize: 24,
        bold: true,
        color: Color.Secondary,
      },
      h2: {
        fontSize: 18,
        bold: true,
        color: Color.Secondary,
        margin: [0, 16],
      },
      h3: {
        fontSize: 14,
        bold: true,
        color: Color.Secondary,
        margin: [0, 8],
      },
      link: {
        bold: true,
        color: Color.Accessory,
      },
      center: {
        alignment: 'center',
      },
    },
    defaultStyle: {
      font: 'Lato',
    },
  });

  writeAsJSX = (): ReactNode => (
    <Frame id={RecommendationDivId}>
      <RecommendationVaccineGrid fluid={true}>{this.sections.map(s => s.writeAsJSX())}</RecommendationVaccineGrid>
    </Frame>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////////

enum Type {
  PdfMake,
  JSX,
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// tslint:disable: max-classes-per-file

class HeaderWritter implements Writter {
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);
  private dateStringService?: DateStringService = Container.get(DateStringService);

  writeAsPdfMake = (): Content => {
    const result = [];
    if (this.recommendationViewState.patientName) {
      result.push({ text: this.recommendationViewState.patientName, style: 'h1' });
    }
    if (this.recommendationViewState.showRecommendationDate) {
      result.push({ text: this.dateStringService.getFormattedTodayDate() });
    }
    return result;
  };

  writeAsJSX = (): JSX.Element => {
    return (
      <>
        <Row $mb={true} $align='center'>
          <Col xs={12}>
            <Logo colored={true} onlyPrint={true} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {!!this.recommendationViewState.patientName && (
              <H3 data-hj-suppress=''>{this.recommendationViewState.patientName}</H3>
            )}
            {this.recommendationViewState.showRecommendationDate && (
              <Body>{this.dateStringService.getFormattedTodayDate()}</Body>
            )}
          </Col>
        </Row>
      </>
    );
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * class to format the vaccine name
 */
class VaccineNameWritter {
  private viewState?: RecommendationViewState = Container.get(RecommendationViewState);

  getVaccineNameAsPdfMake = (vaccine: AppointmentRecommendationVaccine) => {
    return [
      vaccine.name,
      this.showBrand(vaccine.brand) ? ` - ${vaccine.brand}` : '',
      this.showLaboratory(vaccine.laboratory) ? `/${vaccine.laboratory}` : '',
      vaccine.description ? `\n${vaccine.description}` : ``,
      this.shouldShowInjectionCount() && vaccine.injectionCount
        ? `\nNúmero de doses recomendadas: ${vaccine.injectionCount} dose${vaccine.injectionCount === 1 ? '' : 's'}`
        : ``,
    ]
      .filter(item => item)
      .join('');
  };

  getVaccineNameAsJSX = (vaccine: AppointmentRecommendationVaccine) => {
    return (
      <If cond={this.showBrand(vaccine.brand)}>
        {`• ${vaccine.name} - ${vaccine.brand}/${vaccine.laboratory}`}
        <ElseIf cond={this.showLaboratory(vaccine.laboratory)} />
        {`• ${vaccine.name}/${vaccine.laboratory}`}
        <Else />
        {`• ${vaccine.name}`}
      </If>
    );
  };

  // https://www.notion.so/taqtile/Mudan-a-na-recomenda-o-n-mero-de-dosesk-37f04e5ac755473c8d6d88634b9d8aa4
  protected shouldShowInjectionCount = () => {
    const appointmentStore: AppointmentStore = Container.get(AppointmentStore);
    const profile = appointmentStore.selectedProfileId;
    return [PatientProfileKey.Adult, PatientProfileKey.Senior].includes(profile);
  };

  private showBrand = (brand: string) =>
    this.viewState.showBrandName && brand && brand !== '' && brand !== 'null' && brand !== '-';

  private showLaboratory = (laboratory: string) =>
    this.viewState.showBrandName && laboratory && laboratory !== '' && laboratory !== '-';
}

/**
 * class to write all recommended vaccines
 * this class only makes sense if  (recommendationViewState.showPatientAge ===
 * false), otherwise other classes will be used.
 * @see CurrentSuggestedVaccinesWritter and PreviousAgesVaccinesWritter
 */
class AllVaccinesWritter extends VaccineNameWritter implements Writter {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);

  private currentSuggestedVaccinesTitle = `VACINAS RECOMENDADAS`;

  writeAsPdfMake = (): Content => {
    if (this.hideThisComponent()) {
      return '';
    }

    return [
      { text: this.currentSuggestedVaccinesTitle, style: 'h2' },
      { ul: this.getAllVaccines().map(v => this.getVaccineNameAsPdfMake(v)) },
    ].filter(item => item);
  };

  writeAsJSX = (): JSX.Element => {
    if (this.hideThisComponent()) {
      return null;
    }

    return (
      <Row $mb={true}>
        <Col xs={12}>
          <H2>{this.currentSuggestedVaccinesTitle}</H2>
          {this.getAllVaccines().map(vaccine => {
            return (
              <div key={vaccine.id}>
                <RecomendationVacineTitle>{this.getVaccineNameAsJSX(vaccine)}</RecomendationVacineTitle>
                <RecomendationVacineDescription>{vaccine.description}</RecomendationVacineDescription>
                {this.shouldShowInjectionCount() && (
                  <RecomendationVacineDescription>
                    {vaccine.injectionCount
                      ? `\nNúmero de doses recomendadas: ${vaccine.injectionCount} dose${vaccine.injectionCount === 1 ? '' : 's'}`
                      : ``}
                  </RecomendationVacineDescription>
                )}
              </div>
            );
          })}
        </Col>
      </Row>
    );
  };

  private getAllVaccines = () => {
    const currentVaccines = this.appointmentStore.selectedVaccinesForSelectedAge;
    const previousVaccines = this.appointmentStore.selectedVaccinesForPreviousAges.map(
      vaccineAge => vaccineAge.vaccine,
    );
    return [...currentVaccines, ...previousVaccines];
  };

  private hideThisComponent() {
    return this.recommendationViewState.showPatientAge === true;
  }
}

/**
 * class to write the vaccines for the current age.
 * this class only makes sense if  (recommendationViewState.showPatientAge ===
 * true), otherwise other class will be used.
 * @see AllVaccinesWritter
 */
class CurrentSuggestedVaccinesWritter extends VaccineNameWritter implements Writter {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);
  private currentSuggestedVaccinesTitle = `VACINAS RECOMENDADAS`;

  writeAsPdfMake = (): Content => {
    if (
      this.recommendationViewState.showPatientAge === false ||
      this.appointmentStore.selectedVaccinesForSelectedAge.length === 0
    ) {
      return '';
    }

    const subtitle = this.getSubtitle();
    return [
      { text: this.currentSuggestedVaccinesTitle, style: 'h2' },
      subtitle && { text: subtitle, style: 'h3' },
      {
        ul: this.appointmentStore.selectedVaccinesForSelectedAge.map(v => this.getVaccineNameAsPdfMake(v)),
      },
    ].filter(item => item);
  };

  writeAsJSX = (): JSX.Element => {
    if (this.recommendationViewState.showPatientAge === false) {
      return null;
    }
    const subtitle = this.getSubtitle();
    return (
      <Row $mb={true}>
        <Col xs={12}>
          <H2>{this.currentSuggestedVaccinesTitle}</H2>
          {subtitle && <H3>{subtitle}</H3>}
          {this.appointmentStore.selectedVaccinesForSelectedAge.length > 0 ? (
            this.appointmentStore.selectedVaccinesForSelectedAge.map(vaccine => {
              return (
                <div key={vaccine.id}>
                  <RecomendationVacineTitle>{this.getVaccineNameAsJSX(vaccine)}</RecomendationVacineTitle>
                  <RecomendationVacineDescription>{vaccine.description}</RecomendationVacineDescription>
                  {this.shouldShowInjectionCount() && (
                    <RecomendationVacineDescription>
                      {vaccine.injectionCount
                        ? `\nNúmero de doses recomendadas: ${vaccine.injectionCount} dose${vaccine.injectionCount === 1 ? '' : 's'}`
                        : ``}
                    </RecomendationVacineDescription>
                  )}
                </div>
              );
            })
          ) : (
            <Body>Sem recomendações para esta faixa.</Body>
          )}
        </Col>
      </Row>
    );
  };

  private getSubtitle = () => {
    const ageText = this.appointmentStore.selectedAge.getAgeText();
    return ageText ? `Vacinas para ${ageText}` : '';
  };
}

/**
 * class to write the vaccines for the previouse age.
 * this class only makes sense if  (recommendationViewState.showPatientAge ===
 * true), otherwise other class will be used.
 * @see AllVaccinesWritter
 */
class PreviousAgesVaccinesWritter extends VaccineNameWritter implements Writter {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);
  private title = 'Vacinas em atraso';

  writeAsPdfMake = (): Content => {
    if (this.hideThisComponent()) {
      return null;
    }

    return [{ text: this.title, style: 'h3' }, this.buildVaccinesForPreviousAges(Type.PdfMake) as Content];
  };

  writeAsJSX = (): JSX.Element => {
    if (this.hideThisComponent()) {
      return null;
    }

    return (
      <Row>
        <Col xs={12}>
          <H3>{this.title}</H3>
          {this.buildVaccinesForPreviousAges(Type.JSX)}
        </Col>
      </Row>
    );
  };

  private hideThisComponent = () => {
    return (
      this.recommendationViewState.showPatientAge === false ||
      this.appointmentStore.selectedVaccinesForPreviousAges.length === 0 ||
      !this.hasSelectedAge()
    );
  };

  private buildVaccinesForPreviousAges = (type: Type) => {
    if (this.appointmentStore.selectedVaccinesForPreviousAges.length === 0) {
      switch (type) {
        case Type.PdfMake:
          return `Sem recomendações para esta faixa.`;
        case Type.JSX:
          return <Body>Sem recomendações para esta faixa.</Body>;
      }
    }

    switch (type) {
      case Type.PdfMake:
        return {
          ul: this.appointmentStore.selectedVaccinesForPreviousAges.map(vaccineAge =>
            this.getVaccineNameAsPdfMake(vaccineAge.vaccine),
          ),
        };
      case Type.JSX:
        return this.appointmentStore.selectedVaccinesForPreviousAges.map((vaccineAge, _index) => {
          const vaccine = vaccineAge.vaccine;
          return (
            <div key={vaccine.id}>
              <RecomendationVacineTitle>{this.getVaccineNameAsJSX(vaccine)}</RecomendationVacineTitle>
              <RecomendationVacineDescription>{vaccine.description}</RecomendationVacineDescription>
              {this.shouldShowInjectionCount() && (
                <RecomendationVacineDescription>
                  {vaccine.injectionCount
                    ? `\nNúmero de doses recomendadas: ${vaccine.injectionCount} dose${vaccine.injectionCount === 1 ? '' : 's'}`
                    : ``}
                </RecomendationVacineDescription>
              )}
            </div>
          );
        });
    }
  };

  private hasSelectedAge() {
    const selectedAge = this.appointmentStore.selectedAge.getAgeText();
    return selectedAge;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

class AdministrationPlacesWritter implements Writter {
  private administrationRepository?: AdministrationPlacesStore = Container.get(AdministrationPlacesStore);

  writeAsPdfMake = (): Content => {
    if (this.admPlaceCount === 0) {
      return null;
    }

    return [
      { text: `LOCAIS DE VACINAÇÃO`, style: 'h2' },
      this.privateCount > 0 ? { text: `Privados`, style: 'h3' } : null,
      this.privateCount > 0 ? (this.buildClinics(AdministrationPlaceKind.PRIVATE, Type.PdfMake) as Content) : null,
      this.publicCount > 0 ? { text: `Públicos`, style: 'h3' } : null,
      this.publicCount > 0 ? (this.buildClinics(AdministrationPlaceKind.PUBLIC, Type.PdfMake) as Content) : null,
    ].filter(item => item);
  };

  writeAsJSX = (): JSX.Element => {
    return (
      <Row>
        <Col xs={12}>
          <LineSeparator />
          {this.admPlaceCount > 0 && (
            <>
              <H2>LOCAIS DE VACINAÇÃO</H2>
              <Row $mb={true}>
                {this.privateCount > 0 && (
                  <Col xs={6}>
                    <H3>Privados</H3>
                    {this.buildClinics(AdministrationPlaceKind.PRIVATE, Type.JSX)}
                  </Col>
                )}
                {this.publicCount > 0 && (
                  <Col xs={6}>
                    <H3>Públicos</H3>
                    {this.buildClinics(AdministrationPlaceKind.PUBLIC, Type.JSX)}
                  </Col>
                )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    );
  };

  private buildClinics = (clinicType: AdministrationPlaceKind, type: Type) => {
    const clinics =
      clinicType === AdministrationPlaceKind.PRIVATE
        ? this.administrationRepository.selectedPrivateAdministrationPlaces.AdministrationPlacesForPosition
        : this.administrationRepository.selectedPublicAdministrationPlaces.AdministrationPlacesForPosition;

    switch (type) {
      case Type.PdfMake:
        return {
          ol: clinics.map(clinic => [clinic.name, this.formatAddress(clinic), clinic.phoneNumber].join('\n')),
        };
      case Type.JSX:
        return clinics.map(clinic => (
          <div key={clinic.id}>
            <RecomendationVacineTitle>{clinic.name}</RecomendationVacineTitle>
            <Body>{this.formatAddress(clinic)}</Body>
            <PhoneNumber>{clinic.phoneNumber}</PhoneNumber>
          </div>
        ));
    }
  };

  private formatAddress = (item: { street?: string; neighborhood?: string; complement?: string }) =>
    !item.neighborhood
      ? item.street
      : `${item.street} - ${item.neighborhood} ${item.complement ? ` - ${item.complement}` : ''}`;

  private get store() {
    return this.administrationRepository;
  }

  private get privateCount() {
    return this.store.selectedPrivateAdministrationPlaces.AdministrationPlacesForPosition.length;
  }
  private get publicCount() {
    return this.store.selectedPublicAdministrationPlaces.AdministrationPlacesForPosition.length;
  }
  private get admPlaceCount() {
    return this.privateCount + this.publicCount;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

class NotesAreaWritter implements Writter {
  writeAsPdfMake = (): Content => {
    return [
      { text: 'NOTAS', style: 'h2' },
      {
        table: {
          widths: ['*'],
          body: [['\n\n\n\n\n\n\n\n']],
        },
      },
    ];
  };

  writeAsJSX = (): JSX.Element => {
    return (
      <RecommendationNotesAreaRow>
        <Col xs={12}>
          <H2>NOTAS</H2>
          <RecommendationNotesArea>
            <RecommendationNotesPlaceholderText>
              Área dedicada a observações por escrito
            </RecommendationNotesPlaceholderText>
          </RecommendationNotesArea>
        </Col>
      </RecommendationNotesAreaRow>
    );
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

const vacinasLink = 'www.casadevacinasgsk.com.br';
const centrosLink = 'www.vacinacerta.com.br/centros';

class ObservationAreaWritter implements Writter {
  writeAsPdfMake = (): Content => {
    return {
      margin: [0, 16],
      columns: [
        {
          text: ['Saiba mais sobre as vacinas: ', { text: vacinasLink, link: vacinasLink, style: 'link' }],
        },
        {
          text: [
            'E encontre o centro de vacinação mais conveniente para você: ',
            { text: centrosLink, link: centrosLink, style: 'link' },
          ],
        },
      ],
    };
  };

  writeAsJSX = (): JSX.Element => {
    return (
      <div>
        <LineSeparator />
        <Body>
          {' '}
          Saiba mais sobre as vacinas:{' '}
          <LinkButton href='https://www.casadevacinasgsk.com.br/' target='_blank'>
            {vacinasLink}
          </LinkButton>{' '}
        </Body>
        <Body>
          {' '}
          E encontre o centro de vacinação mais conveniente para você:{' '}
          <LinkButton href='/centros' target='_blank'>
            {centrosLink}
          </LinkButton>{' '}
        </Body>
      </div>
    );
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// tslint:disable: max-classes-per-file

class SignatureWritter implements Writter {
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  writeAsPdfMake = (): Content => {
    return [
      {
        canvas: [
          {
            type: 'line',
            x1: 100,
            y1: 5,
            x2: 595 - 2 * 40 - 100,
            y2: 5,
            lineWidth: 1,
            lineColor: '#B3B3B3',
          },
        ],
        margin: [0, 32, 0, 0],
      },
      { text: `Dr(a). ${this.authenticationStore.name}`, style: 'center' },
      { text: `CRM: ${this.authenticationStore.crm}-${this.authenticationStore.uf}`, style: 'center' },
    ];
  };

  writeAsJSX = (): JSX.Element => {
    return (
      <Row>
        <Col xs={12}>
          <Signature>
            {this.authenticationStore.name && <Body data-hj-suppress=''>Dr(a). {this.authenticationStore.name}</Body>}
            {this.authenticationStore.crm && this.authenticationStore.uf && (
              <Body data-hj-suppress=''>
                CRM: {this.authenticationStore.crm}-{this.authenticationStore.uf}
              </Body>
            )}
          </Signature>
        </Col>
      </Row>
    );
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
