import * as React from 'react';

import { MenuButtonContentStyled, MenuButtonStyled } from './menu-button.component.style';

export interface MenuButtonProps extends React.PropsWithChildren {
  href?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  $disableHover?: boolean;
}

export class MenuButton extends React.Component<MenuButtonProps, any> {
  constructor(props: MenuButtonProps) {
    super(props);
  }

  render() {
    return (
      <MenuButtonStyled
        onClick={this.props.onClick ? this.props.onClick : null}
        href={this.props.href ? this.props.href : null}
        {...this.props}
      >
        <MenuButtonContentStyled>{this.props.children}</MenuButtonContentStyled>
      </MenuButtonStyled>
    );
  }
}
