import { ClickableWrapperStyled } from '@components/atm.wrapper/wrapper.component.style';
import { Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const MediaObjectStyled = styled(ClickableWrapperStyled) <{ $minHeight: string }>`
  width: 100%;
  position: relative;
  display: flex;
  align-content: center;
  ${props => props.$minHeight ? `min-height: ${props.$minHeight}` : ''}
`;

export const MediaObjectContentStyled = styled.div<{ addMargin: boolean }>`
  margin-left: ${props => props.addMargin ? Spacing.Large : 0};
`;

export const MediaObjectDisabledIconStyled = styled.div`
  position: absolute;
  top: ${Spacing.XSmall};
  right: ${Spacing.XSmall};
`;
