import * as React from 'react';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { PropsBase } from '@app/core/base/props.base';
import { VaccineByFamilyQuery } from '@app/resource/graphql/generated';
import { VaccineFamily } from '@app/model/vaccine-family.model';
import { Vaccine } from '@app/model/vaccine.model';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import Container from 'typedi';
import { Age } from '@app/model/age.model';
import { Separator } from '@components/obj.grid';
import { ButtonWrapper, ContentWrapper, WhiteContainer } from '@components/obj.white-container';
import { Button, LinkButton } from '@components/atm.button';
import { Body, H2, InputLabel } from '@components/atm.typography';
import { RadioField, RadioFieldGroup } from '@components/atm.radio';
import { TextField } from '@components/atm.text-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { LineSeparator } from '@components/obj.line-separator';
import { Spacing } from '@components/obj.constants';
import { AlertStyled, RecomendationTextStyled } from './vaccine-selection.view.style';
import { FieldValidator, Form } from '@components/obj.form';

export interface VaccineListProps extends PropsBase<VaccineByFamilyQuery> {
  vaccineFamily: VaccineFamily;
  age: string;
  ageGroupSelected: string;
  onVaccineConfirm?: (item: Vaccine, checked: boolean) => void;
  selectedVaccine?: Vaccine;
}

interface VaccineListViewState {
  showMore: boolean;
  selectedVaccineId: string | null;
  ageGroupSelected: string;
}

@GraphQL('vaccine-by-family.query', {
  options: props => {
    const families = props.vaccineFamily.id;
    const variables = {
      data: {
        families,
      },
    };
    return { variables, errorPolicy: 'all' };
  },
})
export class VaccineListView extends React.Component<VaccineListProps, VaccineListViewState> {
  private readonly appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      selectedVaccineId: props.selectedVaccine ? props.selectedVaccine.id : null,
      ageGroupSelected: props.ageGroupSelected || '',
    };
  }

  private getFilteredVaccines = (age: Age) => {
    const vaccines =
      this.props.data.VaccineByFamily && this.props.data.VaccineByFamily.length > 0
        ? this.props.data.VaccineByFamily[0].vaccines
        : [];
    const ageInMonths =
      age.type === 'month' ? age : { minAge: age.minAge * 12, maxAge: age.maxAge * 12, type: 'month' };
    return vaccines.filter(item => item.ages.some(a => a >= ageInMonths.minAge && a <= ageInMonths.maxAge));
  };

  handleOptionChange = (value: string) => {
    this.setState({ selectedVaccineId: value });
  };

  handleShowMoreClick = () => {
    this.setState(prevState => ({ showMore: !prevState.showMore }));
  };

  handleConfirm = (formData: any) => {
    const { selectedVaccineId } = this.state;

    if (!selectedVaccineId) return;

    const selectedVaccine = this.getFilteredVaccines(this.appointmentStore.findAgeByText(this.props.age)).find(
      vaccine => vaccine.id === selectedVaccineId,
    );

    if (selectedVaccine && this.props.onVaccineConfirm) {
      const notes = formData.data.recommendationComment || '';
      const vaccineWithComment = { ...selectedVaccine, recommendationComment: notes };

      this.props.onVaccineConfirm(vaccineWithComment, true);
    }
  };

  render() {
    const { age } = this.props;
    const vaccines = this.getFilteredVaccines(this.appointmentStore.findAgeByText(age));
    const { showMore, selectedVaccineId } = this.state;

    const vaccineOptions = vaccines.map(vaccine => ({
      id: vaccine.id,
      name: `${vaccine.name}${vaccine.brand ? ` / ${vaccine.brand}` : ''}`,
      description: vaccine.description,
      dose:
        vaccine.injectionCount === 1
          ? 'Dose única'
          : vaccine.injectionCount > 1
            ? `${vaccine.injectionCount} doses`
            : 'Informação de doses não disponível',
      type: vaccine.isAdministeredOnPublicClinics
        ? 'Público'
        : vaccine.isAdministeredOnPrivateClinics
          ? 'Privado'
          : 'Indefinido',
      alert: vaccine.isLiveAttenuated ? '⚠️ Vacina viva atenuada' : null,
      comment: vaccine.comment || '',
      recommendationComment: vaccine.recommendationComment || '',
    }));

    return (
      <Form onSubmit={this.handleConfirm}>
        <WhiteContainer>
          <H2>
            {this.state.ageGroupSelected} - {this.props.vaccineFamily.name}
          </H2>
        </WhiteContainer>
        <LineSeparator />

        <WhiteContainer>
          <Body>Selecione a vacina desejada:</Body>
          <Separator />
          <RadioFieldGroup name='vaccineSelection' onValueChange={this.handleOptionChange} value={selectedVaccineId}>
            {vaccineOptions.map(option => (
              <RadioField key={option.id} value={option.id} withBorder={true} name='vaccineSelection'>
                <div style={{ marginLeft: Spacing.Medium }}>
                  <strong>{option.name}</strong>
                </div>
                <div>
                  <RecomendationTextStyled>
                    <p>{option.description}</p>
                    <p>
                      {/* TODO: add dose count when available */}
                      {/* {option.dose} • */}
                      {option.type}
                    </p>
                    {option.alert && <AlertStyled>{option.alert}</AlertStyled>}
                  </RecomendationTextStyled>
                </div>
              </RadioField>
            ))}
          </RadioFieldGroup>
          <Separator />
          <InputLabel>Adicione observações (opcional):</InputLabel>
          <FieldValidator name='recommendationComment' validators={[]}>
            <TextField type='text' />
          </FieldValidator>
          <Separator />
          <LinkButton textAlign='left' noPadding={true} expanded={true} onClick={this.handleShowMoreClick}>
            Orientações gerais
            <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: Spacing.Small }} />
          </LinkButton>
          {showMore && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  vaccineOptions.length > 0
                    ? vaccineOptions[0].comment || 'Nenhuma observação disponível.'
                    : 'Nenhuma vacina disponível para exibir observações.',
              }}
            />
          )}
        </WhiteContainer>
        <LineSeparator />
        <ContentWrapper>
          <ButtonWrapper>
            <Button kind='primary' type='submit'>
              Confirmar
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </Form>
    );
  }
}
