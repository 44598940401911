import BrazilStateSelect from '@app/components/brazil-state-select.component';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ApplicationStore } from '@app/modules/app/application.store';
import { Button } from '@components/atm.button';
import { Notification, NotificationType } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { Display, InputLabel } from '@components/atm.typography';
import { MenuItems } from '@components/mol.header/header.model';
import { FieldValidator, Form, FormData, Validators } from '@components/obj.form';
import { Col, Grid, Row } from '@components/obj.grid';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStore } from '../appointment/appointment.store';
import { UpdatePasswordAsAdminInput } from '@app/resource/graphql/base-schema';

export interface ResetDoctorPasswordFormData {
  crm: string;
  uf: string;
}

export interface ResetDoctorPasswordViewProps {
  submitLoading: boolean;
  notification: {
    type: NotificationType;
    message?: string;
  };
  onSubmit: (data: UpdatePasswordAsAdminInput) => void;
}

@Observer()
export class ResetDoctorPasswordView extends React.Component<ResetDoctorPasswordViewProps, any> {
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    this.analyticsService.pageView('resetar_senhar_doutor');
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Grid>
          <Row $middle='xs'>
            <Col xs={12}>
              <Row center='xs'>
                <Col xs={12} sm={8} md={6}>
                  <Row center='xs' $mb={true}>
                    <Col xs={12}>
                      <Display>Resetar senha de médico</Display>
                    </Col>
                  </Row>
                  <Row start='xs' $mb={true}>
                    {this.props.notification && this.props.notification.message && this.props.notification.type && (
                      <Col xs={12}>
                        <Notification {...this.props.notification} />
                      </Col>
                    )}
                  </Row>

                  <Row $mb={true}>
                    <Col xs={12}>
                      <InputLabel>CRM</InputLabel>
                      <FieldValidator
                        name={'crm' as keyof ResetDoctorPasswordFormData}
                        validators={[
                          Validators.Required('Campo obrigatório'),
                          Validators.CrmRegex(`O CRM deve ter somente números.`),
                        ]}
                      >
                        <TextField type={'custom'} options={{ mask: '9999999' }} />
                      </FieldValidator>
                    </Col>
                  </Row>

                  <Row $mb={true}>
                    <Col xs={12}>
                      <InputLabel>Estado</InputLabel>
                      <FieldValidator
                        name={'uf' as keyof ResetDoctorPasswordFormData}
                        validators={[Validators.Required('Campo obrigatório')]}
                      >
                        <BrazilStateSelect />
                      </FieldValidator>
                    </Col>
                  </Row>

                  <Row center='xs' $mb={true}>
                    <Col xs={12}>
                      <Button kind='primary' type='submit' expanded={true} loading={this.props.submitLoading}>
                        Resetar senha
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </Form>
    );
  }

  private handleSubmit = (formData: FormData<ResetDoctorPasswordFormData>) => {
    this.analyticsService.event('reset_senha_doutor', 'click');
    if (formData.errors.length === 0) {
      this.props.onSubmit({
        crm: formData.data.crm,
        uf: formData.data.uf,
      });
    }
  };
}
