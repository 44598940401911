// tslint:disable: max-line-length
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { AccountStore } from '@app/modules/account/account.store';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import {
  AuthenticationStore,
  DELETE_USER_MAP_KEY,
} from '@app/modules/authentication/authentication.store';
import { Carousel } from '@components/atm.carousel';
import { Notification } from '@components/atm.notification';
import { OverflowXContainer } from '@components/obj.overflow-container';
import { HomeImunocardGridStyled, HomeImunocardH2Styled } from '@components/pag.home';
import { FooterContainerView } from '@components/pag.home/obj.footer/footer.container';
import { reaction } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { UserCreateMutation } from '@app/resource/graphql/generated';
import { HomeHeaderImunocard } from '@components/pag.home/obj.home-header/home-header-imunocard.component';
import { ImunocardContentContainer } from '@components/pag.home/imunocard-content-container.style';
import { HowItWorksOverflowImunocardSection } from '@components/pag.home/obj.how-it-works/how-it-works-row-imunocard.style';
import { HowItWorksRowImunocard } from '@components/pag.home/obj.how-it-works/how-it-works-row-imunocard.component';
import { TestimonialListContainerImunocardStyled } from '@components/pag.home/obj.testimonial-row/testimonial-row-imunocard.style';
import { TestimonialRowImunocard } from '@components/pag.home/obj.testimonial-row/testimonial-row-imunocard.component';
import { IMUNOCARD_APPOINTMENT_PATH } from '@app/modules/appointment-imunocard/appointment-imunocard.route';
import { FREQUENT_QUESTIONS_PATH } from '../frequent-questions/frequent-questions.route';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { isVacinaTheme } from 'config/theme';
// tslint:enable: max-line-length

export const HowItWorksId = 'como-funciona';

export type ImunocardHomeViewProps = PropsBase<UserCreateMutation>;

// Hotjar
declare var hj: any;

export enum HomePublicType {
  Doctor = 0,
  GeneralPublic = 1,
}

@GraphQL('user-create.mutation')
@Observer()
export class ImunocardHomeView extends React.Component<ImunocardHomeViewProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private router?: Router = Container.get(Router);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private mobileService?: MobileService = Container.get(MobileService);

  private reactionDisposers = [];

  constructor(props) {
    super(props);

    this.reactionDisposers.push(reaction(() => this.authenticationStore.loggedIn, this.onLogin));
    this.state = { signUpErrorMsg: '', homePublicType: HomePublicType.Doctor };
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    if (this.accountStore.showChangePasswordSuccessMessage) {
      console.log('Change password success');
      this.accountStore.showChangePasswordSuccessMessage = false;
      // TODO - FRONT - Render change password success notification
    }

    this.analyticsService.pageView('home');
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    const successMessage = this.authenticationStore.successMessageMap.get(DELETE_USER_MAP_KEY);

    return (
      <div>
        {isVacinaTheme && <CookieConsentContainer />}
        {successMessage && <Notification type='success' message={successMessage} />}
        <OverflowXContainer>{this.renderHeader()}</OverflowXContainer>
        <OverflowXContainer>
          <ImunocardContentContainer>
            {this.renderHowItWorks()}
            {/* {this.renderTestimonial()} */} {/* TODO - FRONT - Uncomment when testimonial is ready with real data*/}
          </ImunocardContentContainer>
          <FooterContainerView />
        </OverflowXContainer>
      </div>
    );
  }

  onLogin = () => {
    if (this.authenticationStore.loggedIn) {
      this.setState({ homePublicType: HomePublicType.Doctor });
    }
  };

  private onTopSignUpClick = () => {
    this.router.push(LOGIN_PATH);
    this.analyticsService.event('home_cadastrar_cta', 'click', true);
  };

  private onRecommendVaccinesClick = () => {
    this.router.push(IMUNOCARD_APPOINTMENT_PATH);
  };

  private onFrequentQuestionsClick = () => {
    this.router.push(FREQUENT_QUESTIONS_PATH);
  };

  private onSegmentControlClick = (index: number) => {
    if (this.state.homePublicType !== index) {
      this.setState({ homePublicType: index });
    }
  };

  private renderHeader = () => {
    return (
      <HomeHeaderImunocard
        loggedIn={this.authenticationStore.loggedIn}
        onSignUpClick={this.onTopSignUpClick}
        onStartRecommendationClick={this.onRecommendVaccinesClick}
        onMobile={this.mobileService.onMobile}
        onSegmentControlIndex={this.onSegmentControlClick}
        homePublicType={this.state.homePublicType}
        onFrequentQuestionsClick={this.onFrequentQuestionsClick}
      />
    );
  };

  private renderHowItWorks = () => {
    return (
      <HowItWorksOverflowImunocardSection>
        <HomeImunocardH2Styled>Como funciona</HomeImunocardH2Styled>

        <HomeImunocardGridStyled>
          <HowItWorksRowImunocard
            homePublicType={this.state.homePublicType}
            loggedIn={this.authenticationStore.loggedIn}
          />
        </HomeImunocardGridStyled>
      </HowItWorksOverflowImunocardSection>
    );
  };

  private renderTestimonial = () => {
    // tslint:disable: max-line-length
    const testimonials = [
      {
        img: 'assets/img/img_doctor_maria_oliveira.png',
        message:
          '"Com o Imunocard, consigo acessar facilmente as recomendações vacinais atualizadas, o que me permite oferecer orientações mais precisas e personalizadas. A plataforma facilita o meu trabalho, tornando o atendimento mais prático, ágil e eficaz, garantindo que meus pacientes recebam as informações corretas no momento certo, tudo de forma simples e acessível."',
        name: 'Dra. Maria Oliveira, Cardiologista',
        occupation: 'CRM XX-XX.XXX',
      },
    ];

    const testimonialsPatient = [
      {
        img: 'assets/img/img_doctor_maria_oliveira.png',
        message:
          '"Com o Imunocard, tenho acesso rápido e fácil às minhas recomendações vacinais atualizadas, o que me ajuda a manter minha saúde em dia. A plataforma torna tudo mais prático e acessível, garantindo que eu receba as informações corretas no momento certo, de forma simples e conveniente.”',
        name: 'Maria Oliveira',
        occupation: 'Paciente',
      },
    ];
    // tslint:enable: max-line-length

    const filteredTestimonials =
      this.state.homePublicType === HomePublicType.Doctor ? testimonials : testimonialsPatient;

    return (
      <TestimonialListContainerImunocardStyled>
        <HomeImunocardH2Styled>Depoimentos</HomeImunocardH2Styled>
        <Carousel
          key={this.state.homePublicType}
          loop={filteredTestimonials.length > 1}
          auto={true}
          showNavigationButton={filteredTestimonials.length > 1}
          interval={14000}
          duration={300}
          width={1}
          scale={1}
          borderItemsXTranslation={0}
          isImunocard={true}
        >
          {filteredTestimonials.map((item, index) => (
            <TestimonialRowImunocard
              key={index}
              img={item.img}
              message={item.message}
              name={item.name}
              occupation={item.occupation}
              showNavigationButton={filteredTestimonials.length > 1}
            />
          ))}
        </Carousel>
      </TestimonialListContainerImunocardStyled>
    );
  };
}
