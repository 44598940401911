import { Body, H3 } from '@components/atm.typography';
import * as React from 'react';
import {
  DefaultRecommendationContentStyled,
  DefaultRecommendationContentWrapper,
  DefaultRecommendationIconWrapper,
  DefaultRecommendationStyled
} from './default-recommendation-card.component.style';
import { ButtonWrapper } from '@components/obj.white-container';
import { Button } from '@components/atm.button';
import { Hbox } from '@components/obj.box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';

export interface DefaultRecommendationCardProps {
  name: string;
  caption?: string;
  isNew?: boolean;
  onEditClick?: () => void;
  onEraseClick?: () => void;
  onCreateClick?: () => void;
}

export const DefaultRecommendationCard: React.FC<DefaultRecommendationCardProps> = ({
  name,
  caption,
  isNew,
  onEditClick,
  onEraseClick,
  onCreateClick
}) => {
  return (
    <DefaultRecommendationStyled $isNew={isNew}>
      <DefaultRecommendationContentWrapper>
        <DefaultRecommendationContentStyled $isNew={isNew}>
          <Hbox>
            <Hbox.Item $vAlign={'center'} $noGrow={true}>
              <H3>{name}</H3>
              {caption && <Body>{caption}</Body>}
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item $vAlign={'center'}>
              {isNew ? (
                <ButtonWrapper>
                  <Button kind='primary' type='submit' onClick={onCreateClick}>
                    Padronizar
                  </Button>
                </ButtonWrapper>
              ) : (
                <DefaultRecommendationIconWrapper>
                  <FontAwesomeIcon
                    icon={faPencil}
                    onClick={onEditClick}
                    size='lg'
                    style={{ cursor: 'pointer' }}
                  />
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    onClick={onEraseClick}
                    size='lg'
                    style={{ cursor: 'pointer' }}
                  />
                </DefaultRecommendationIconWrapper>
              )}
            </Hbox.Item>
          </Hbox>
        </DefaultRecommendationContentStyled>
      </DefaultRecommendationContentWrapper>
    </DefaultRecommendationStyled>
  );
};
