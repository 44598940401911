import { HistoryToken } from '@app/core/route/router';
import { App } from '@app/modules/app/app';
import * as React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Router } from 'react-router';
import { Container } from 'typedi';

export function bootstrap() {
  const history = Container.get(HistoryToken);
  const rootContainer = document.getElementById('root');

  if (module.hot) {
    const root = createRoot(rootContainer);
    root.render(
      <Router history={history}>
        <App />
      </Router>,
    );
  } else {
    hydrateRoot(
      rootContainer,
      <Router history={history}>
        <App />
      </Router>,
    );
  }
}
