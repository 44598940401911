import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AuthenticationStore, FORGOT_PASSWORD_MAP_KEY } from '@app/modules/authentication/authentication.store';
import { ForgotPasswordMutation } from '@app/resource/graphql/generated';
import { FormData } from '@components/obj.form';
import { FindUserFormData } from '@components/org.find-user/find-user.component';
import { ForgotPassword } from '@components/org.forgot-password/forgot-password.component';
import * as React from 'react';
import { Container } from 'typedi';

export interface ForgotPasswordDataProps extends PropsBase<ForgotPasswordMutation> {
  email?: string;
}

@GraphQL('forgot-password.mutation')
@Observer()
export class ForgotPasswordData extends React.Component<ForgotPasswordDataProps> {
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  constructor(props) {
    super(props);
    this.authenticationStore.resetMapItem(FORGOT_PASSWORD_MAP_KEY);
  }

  render() {
    return (
      <ForgotPassword
        loading={this.authenticationStore.loadingMap.get(FORGOT_PASSWORD_MAP_KEY)}
        onSubmit={this.callMutation}
        errorMessage={this.authenticationStore.errorMessageMap.get(FORGOT_PASSWORD_MAP_KEY)}
        initialEmail={this.props.email}
      />
    );
  }

  private callMutation = (formData: FormData<FindUserFormData>) => {
    this.analyticsService.event('esqueci_senha_enviar', 'click');
    this.authenticationStore.forgotPassword(
      {
        email: formData.data.email,
        uf: formData.data.state,
        crm: formData.data.crm,
      },
      this.props.mutate,
    );
  };
}
