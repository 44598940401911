import { FontSize, Spacing } from '@components/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const CloseWrapStyled = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  color: #757575;
  cursor: pointer;

  padding: ${Spacing.Large};
  pointer-events: auto;

  @media all and (min-width: 48em) {
    padding: ${Spacing.XLarge};
  }
`;

export const CloseIconStyled = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })`
  font-size: ${FontSize.Large};
`;
