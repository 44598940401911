import * as React from 'react';
import { Ellipsis, PageButton, PaginationContainer, SelectedIndicator } from './pagination.component.styled';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const MAX_SHOWN_PAGES = 6;

export const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  if (currentPage < 1 || currentPage > totalPages) {
    console.warn(`Invalid current page: ${currentPage}. Must be between 1 and ${totalPages}`);
    return null;
  }

  const pagesToShow = React.useMemo(() => {
    if (totalPages <= MAX_SHOWN_PAGES) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }

    const pages: (number | string)[] = [1];

    if (currentPage > 3) {
      pages.push('...');
    }

    const start = Math.max(2, currentPage - 1);
    const end = Math.min(totalPages - 1, currentPage + 1);

    for (let i = start; i <= end; i++) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }

    if (currentPage < totalPages - 2) {
      pages.push('...');
    }

    if (!pages.includes(totalPages)) {
      pages.push(totalPages);
    }

    return pages;
  }, [currentPage, totalPages]);

  const handlePageChange = React.useCallback(
    (page: number) => {
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    },
    [onPageChange, totalPages],
  );

  const createPageClickHandler = React.useCallback((page: number) => () => handlePageChange(page), [handlePageChange]);

  return (
    <PaginationContainer>
      {pagesToShow.map((item, index) => {
        const isSelected = item === currentPage;

        return (
          <React.Fragment key={index}>
            {item === '...' ? (
              <Ellipsis>...</Ellipsis>
            ) : (
              <PageButton
                onClick={createPageClickHandler(item as number)}
                disabled={isSelected}
                selected={isSelected}
                aria-current={isSelected ? 'page' : undefined}
              >
                {isSelected && <SelectedIndicator />}
                {item}
              </PageButton>
            )}
          </React.Fragment>
        );
      })}
    </PaginationContainer>
  );
};

export default Pagination;
