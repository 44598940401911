import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';

const desktopToMobileMenuBreakpoint = `1300px`;

export const SegmentedStyled = styled.span`
  display: inline-block;
  position: relative;
  border-color: ${Color.White};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Spacing.Medium};
  & ~ * {
    margin-top: ${Spacing.Medium};
  }
  @media all and (max-width: ${desktopToMobileMenuBreakpoint}) {
    display: flex;
    width: 100%
  }
`;

export const SegmentedItemStyled = styled.button<{ selected?: boolean }>`
  background-color: ${props => (props.selected ? Color.PrimarySoft : 'transparent')};
  border-style: hidden;
  width: auto;
  min-height: 37px;
  color: ${Color.White};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.XSmall};
  line-height: ${FontSize.Small};
  border-radius: ${Spacing.Small};
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:first-child {
    margin: ${Spacing.XSmall};
  }

  &:last-child {
    margin: ${Spacing.XSmall};
  }

  padding: 10px ${Spacing.Small};
  @media all and (min-width: 48em) {
    padding: 10px ${Spacing.Small};
  }

  @media all and (max-width: ${desktopToMobileMenuBreakpoint}) {
    flex: 1;
  }
`;
