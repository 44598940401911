import * as React from 'react';
import { WhiteContainerWrapper } from './white-container.componet.style';

interface WhiteContainerProps {
  children: React.ReactNode;
  isResponsive?: boolean;
  hasPadding?: boolean;
}

export const WhiteContainer: React.FunctionComponent<WhiteContainerProps> = ({
  children,
  isResponsive = false,
  hasPadding = false,
}) => {
  return (
    <WhiteContainerWrapper isResponsive={isResponsive} hasPadding={hasPadding}>
      {children}
    </WhiteContainerWrapper>
  );
};
