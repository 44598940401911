import { Body } from '@components/atm.typography';
import { Border, Color, FontFamily, Spacing } from '@components/obj.constants';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ColorFunc from 'color';
import styled from 'styled-components';
import { NotificationProps } from './notification.component';

const notificationColors = {
  success: Color.Success,
  error: Color.Alert,
  info: Color.Info,
  warning: Color.Warning,
};

export const NotificationStyled = styled.div<NotificationProps>`
  display: flex;
  position: relative;
  direction: row;

  background-color: ${(props: NotificationProps) => ColorFunc(notificationColors[props.type]).lighten(0.7).string()};
  border-color: ${(props: NotificationProps) => ColorFunc(notificationColors[props.type]).lighten(0.4).hsl().string()};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
  display: ${props => (props.hidden ? 'none' : 'inline-flex')};
  ${props =>
    props.type === 'error' &&
    `
    border-radius: ${Border.radius};
    background: ${Color.FeedbackErrorSoft};
  `}
`;

export const NotificationMessageStyled = styled(Body)<NotificationProps>`
  padding: ${Spacing.Large};
  white-space: pre-wrap;
  width: 100%;
  font-family: ${FontFamily.Primary};
`;

export const NofificationCloseStyled = styled(FontAwesomeIcon).attrs({ icon: faTimes })`
  align-self: center;
  font-size: 20px;
  margin: ${Spacing.XLarge};
  color: ${(props: NotificationProps) => notificationColors[props.type]};
`;
