import * as React from 'react';

export interface LoadingGifProps {
  size?: number;
}

export const AppIcon = {
  DropPurple: () => <img src='assets/img/ic_drop.png' width='24px' />,
  VacinePurple: () => <img src='assets/img/ic_vacina_purple.png' width='24px' />,
  VacineGray: () => <img src='assets/img/ic_vacina_gray.png' width='24px' />,
  VacineInfo: () => <img src='assets/img/ic_vaccine_info.png' width='24px' />,
  LoadingGif: (props: LoadingGifProps) => <img src='assets/img/img_loading.gif' width={props.size} />,
  LoadingCleanGif: () => <img src='assets/img/img_loading_clean.gif' />,
  LoadingTooltipArrow: () => <img src='assets/img/img_tooltip_arrow.png' width='40px' />,
};
