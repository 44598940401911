import * as React from 'react';
import { AppIcon } from '@components/atm.app-icon';
import { Button } from '@components/atm.button';
import { TooltipText } from '@components/atm.typography';
import { TooltipArrowImageStyled, TooltipButtonStyled, TooltipTextStyled } from './tooltip.component.style';

export interface TooltipProps {
  opened?: boolean;
  onClose?: () => void;
  message?: string | JSX.Element;
}

interface TooltipState {
  opened?: boolean;
}

// TODO: TEMPLATE
export class Tooltip extends React.Component<TooltipProps, TooltipState> {
  constructor(props: TooltipProps) {
    super(props);
    this.state = { opened: this.props.opened ? true : false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.opened !== this.state.opened) {
      this.setState({ opened: nextProps.opened });
    }
  }

  open() {
    this.setState({ opened: true });
  }

  close = () => {
    this.setState({ opened: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <TooltipTextStyled $opened={this.state.opened}>
        <TooltipText>{this.props.message}</TooltipText>
        <TooltipButtonStyled>
          <Button kind='secondary' small={true} onClick={this.close}>
            Entendi
          </Button>
        </TooltipButtonStyled>
        <TooltipArrowImageStyled>
          <AppIcon.LoadingTooltipArrow />
        </TooltipArrowImageStyled>
      </TooltipTextStyled>
    );
  }
}
