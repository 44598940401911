import * as React from 'react';
import { ValidationError } from './validators';

export interface FieldPropsInterface extends React.PropsWithChildren {
  name?: string;
  attachToGroup?: any;
  attachToForm?: any;
  onValueChange?: any;
  onFocusChange?: (focus: boolean, value: string) => void;
}
export interface FieldStateInterface {
  value?: string;
  errors: ValidationError[];
  showPassword?: boolean;
}

export abstract class Field<P extends FieldPropsInterface, S extends FieldStateInterface> extends React.Component<
  P,
  S
> {
  constructor(props: P) {
    super(props);

    if (this.props.attachToGroup) {
      this.props.attachToGroup(this);
    }

    if (this.props.attachToForm) {
      this.props.attachToForm(this);
    }
  }

  eraseErrors?(callback?: () => void) {
    this.setState(
      {
        errors: [],
      },
      callback,
    );
  }

  validate?(value: string): Promise<any>;
}
