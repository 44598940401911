import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AccountStore } from '@app/modules/account/account.store';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AuthenticationStore, CHANGE_PASSWORD_MAP_KEY } from '@app/modules/authentication/authentication.store';
import { Button } from '@components/atm.button';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { H2, InputLabel } from '@components/atm.typography';
import { MenuItems } from '@components/mol.header/header.model';
import { PasswordLength } from '@components/obj.constants';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { reaction } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStore } from '../appointment/appointment.store';
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { ChangePasswordInput } from '@app/resource/graphql/base-schema';
import { ContentWrapper } from '@components/obj.content-wrapper';
import { isVacinaTheme } from 'config/theme';

export interface ChangePasswordViewProps {
  onSubmit: (data: ChangePasswordInput) => void;
}

@Observer()
export class ChangePasswordView extends React.Component<ChangePasswordViewProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  private reactionDisposers = [];

  constructor(props) {
    super(props);

    this.state = { errorMessage: '', passwordValue: '' };
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.appointmentStore.reset = true;
    this.reactionDisposers.push(
      reaction(() => this.authenticationStore.successMap.get(CHANGE_PASSWORD_MAP_KEY), this.handleSuccess),
    );
  }

  componentDidMount() {
    this.analyticsService.pageView('alterar_senha');
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    return (
      <ContentWrapper>
        <Form onSubmit={this.handleSubmit}>
          {isVacinaTheme && <CookieConsentContainer />}
          <Grid>
            <Row $middle='xs'>
              <Col xs={12}>
                <Row center='xs'>
                  <Col xs={12} sm={8} md={6}>
                    <Row center='xs' $mb={true}>
                      <Col xs={12}>
                        <H2>Alterar senha</H2>
                      </Col>
                    </Row>

                    <If
                      cond={
                        this.authenticationStore.errorMap.get(CHANGE_PASSWORD_MAP_KEY) || this.state.errorMessage !== ''
                      }
                    >
                      <Row start='xs' $mb={true}>
                        <Col xs={12}>
                          <Notification
                            type='error'
                            message={this.authenticationStore.errorMessageMap.get(CHANGE_PASSWORD_MAP_KEY)}
                          />
                        </Col>
                      </Row>
                    </If>
                    <If cond={this.accountStore.showChangePasswordSuccessMessage}>
                      <Row start='xs' $mb={true}>
                        <Col xs={12}>
                          <Notification type='success' message='Senha alterada com sucesso!' />
                        </Col>
                      </Row>
                    </If>

                    <Row start='xs' $mb={true}>
                      <Col xs={12}>
                        <InputLabel>Senha atual</InputLabel>
                        <FieldValidator name='password' validators={[Validators.Required('Campo é obrigatório')]}>
                          <TextField type='password' />
                        </FieldValidator>
                      </Col>
                    </Row>
                    <Row start='xs' $mb={true}>
                      <Col xs={12}>
                        <InputLabel>Nova senha</InputLabel>
                        <FieldValidator
                          name='newPassword'
                          validators={[
                            Validators.Required('Campo é obrigatório'),
                            Validators.MaxLength(
                              PasswordLength.Max,
                              `A senha deve ter menos de ${PasswordLength.Max} caracteres`,
                            ),
                            Validators.MinLength(
                              PasswordLength.Min,
                              `A senha deve ter pelo menos ${PasswordLength.Min} caracteres`,
                            ),
                            Validators.PasswordRegex(`A senha deve ter pelo menos uma letra e um número.`),
                          ]}
                          validatorPlaceholder={`A senha deve ter entre ${PasswordLength.Min} e
                          ${PasswordLength.Max} caracteres e pelo menos uma letra, um número e um caracter especial.`}
                        >
                          <TextField
                            type='password'
                            onValueChange={this.onPasswordChange}
                            maxLength={PasswordLength.Max}
                          />
                        </FieldValidator>
                      </Col>
                    </Row>
                    <Row start='xs' $mb={true}>
                      <Col xs={12}>
                        <InputLabel>Repetir nova senha</InputLabel>
                        <FieldValidator
                          name='confirmNewPassword'
                          validators={[
                            Validators.Required('Campo é obrigatório'),
                            Validators.IsNotEqualToField(this.state.passwordValue, 'Senhas devem ser iguais'),
                          ]}
                        >
                          <TextField type='password' maxLength={PasswordLength.Max} />
                        </FieldValidator>
                      </Col>
                    </Row>
                    <Row center='xs' $mb={true}>
                      <Col xs={12}>
                        <Button
                          kind='primary'
                          type='submit'
                          expanded={true}
                          loading={this.authenticationStore.loadingMap.get(CHANGE_PASSWORD_MAP_KEY)}
                        >
                          Alterar senha
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Form>
        <Separator />
      </ContentWrapper>
    );
  }

  private onPasswordChange = (value: any) => {
    this.setState({ passwordValue: value });
  };

  private handleSubmit = formData => {
    this.analyticsService.event('alterar_senha_alterar', 'click');
    if (formData.errors.length === 0) {
      this.props.onSubmit({
        newPassword: formData.data.newPassword,
        oldPassword: formData.data.password,
      });
    }
  };

  private handleSuccess = () => {
    if (this.authenticationStore.successMap.get(CHANGE_PASSWORD_MAP_KEY)) {
      this.accountStore.showChangePasswordSuccessMessage = true;
      this.authenticationStore.successMap.set(CHANGE_PASSWORD_MAP_KEY, false);
    }
  };
}
