import * as ColorFunc from 'color';
import styled from 'styled-components';
import { Border, Color, Spacing } from '@components/obj.constants';
import { FontFamily, FontSize, FontWeight } from '@components/obj.constants';

const buttonColors = {
  primary: Color.Primary,
  secondary: Color.Secondary,
  neutral: Color.Neutral,
  callToAction: Color.CallToAction,
  accessory: Color.Accessory,
  alert: Color.Alert,
  light: Color.White,
};

const textColors = {
  callToAction: Color.Secondary,
  alert: Color.Black,
};

export type ButtonType = 'primary' | 'secondary' | 'neutral' | 'callToAction' | 'light' | 'alert';

interface ButtonStyledProps {
  $expanded: boolean;
  $outlined: boolean;
  $kind: ButtonType;
  $width: string;
  $small: boolean;
  $loading: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  background-color: ${props => (props.$outlined ? 'transparent' : buttonColors[props.$kind])};
  border-color: ${props => (!props.$outlined ? 'transparent' : buttonColors[props.$kind])};
  border-style: solid;
  border-radius: ${Border.radius};
  border-width: 2px;
  width: ${props => (props.$expanded ? '100%' : props.$width || 'auto')};
  min-height: ${props => (props.$small ? '30px' : '50px')};
  opacity: ${props => (props.disabled || props.$loading ? 0.5 : 1)};
  /* // this asymetrical padding is necessary due to the assymetry of the font */
  padding: ${props => (props.$width ? '10px' : `10px 16px 8px`)};
  color: ${props => (props.$outlined ? buttonColors[props.$kind] : (textColors[props.$kind] ?? Color.White))};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${props => (props.$small ? FontSize.Small : FontSize.Medium)};
  cursor: pointer;
  position: relative;
  letter-spacing: 1px;

  &:focus {
    outline: 0;
  }

  &:active {
    background-color: ${props =>
      props.$outlined ? 'transparent' : ColorFunc(buttonColors[props.$kind]).darken(0.2).hsl().string()};
  }

  &:hover {
    background-color: ${props =>
      !props.$outlined
        ? props.$kind === 'secondary'
          ? ColorFunc(Color.Secondary).darken(0.2).hsl().string()
          : ColorFunc(buttonColors[props.$kind]).darken(0.2).hsl().string()
        : ColorFunc(buttonColors[props.$kind]).darken(0.2).alpha(0.05).hsl().string()};
  }

  & + & {
    margin-left: ${Spacing.Small};
  }
`;

export const ButtonContentStyled = styled.div<{ $loading: boolean }>`
  display: ${props => (props.$loading ? 'none' : 'block')};
  visibility: ${props => (props.$loading ? 'hidden' : 'visible')};

  & .fa {
    padding-right: ${Spacing.Small};
  }
`;

export const ButtonSpinnerStyled = styled.span<{ $loading: boolean }>`
  display: ${props => (props.$loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 10px);
  top: calc(50% - 10px);
`;
