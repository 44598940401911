import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { LoadingFrame } from '@components/mol.loading-frame';
import { Placeholder } from '@components/mol.placeholder';
import { Col, Grid, Row } from '@components/obj.grid/grid.component';
import * as React from 'react';
import { Container } from 'typedi';
import { AuthenticationStore } from './authentication.store';
import { DoctorQuery } from '@app/resource/graphql/generated';

export type DoctorDataProps = Partial<PropsBase<DoctorQuery>> & React.PropsWithChildren;

@GraphQL('doctor.query')
@Observer()
export class FetchUserDataQuery extends React.Component<DoctorDataProps> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: DoctorDataProps) {
    if (nextProps.data && nextProps.data.error && nextProps.data.error.graphQLErrors) {
      if (nextProps.data.error.graphQLErrors.some(e => e.name === 'NotFoundError')) {
        this.authenticationStore.logOut();
        return;
      }
    }

    this.authenticationStore.updateDoctor(nextProps.data.Doctor);
  }

  render() {
    if (this.props.data && this.props.data.error) {
      return (
        <Grid>
          <Row>
            <Col xs={12}>
              <Placeholder type='serverError' />
            </Col>
          </Row>
        </Grid>
      );
    }
    if (!this.authenticationStore.name) {
      return <LoadingFrame showLoading={true} />;
    }
    return this.props.children;
  }
}
