import * as React from 'react';
import { Route } from 'react-router';
import { SignupData } from '@app/modules/authentication/signup.data';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { isVacinaTheme } from 'config/theme';

export const SIGNUP_PATH = '/cadastro';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Cadastro`,
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const Signup = SEO(config)(SignupData);

export const SignupRoute = <Route path={SIGNUP_PATH} exact={true} component={Signup} key='signup' />;
