import * as React from 'react';
import { LogoStyled } from './logo.component.style';

interface LogoProps {
  colored?: boolean;
  mt?: boolean;
  onlyPrint?: boolean;
}

export const Logo = (props: LogoProps) => {
  return props.colored ? (
    <LogoStyled $mt={props.mt} $onlyPrint={props.onlyPrint} src='/assets/img/img_logo_color.png' />
  ) : (
    <LogoStyled $mt={props.mt} $onlyPrint={props.onlyPrint} src='/assets/img/img_logo.png' />
  );
};
