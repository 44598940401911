import { Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const RadioFieldGroupStyled = styled.div<{ responsiveDirection?: boolean; withGap?: boolean }>`
  display: flex;
  flex-direction: ${({ responsiveDirection }) => (responsiveDirection ? 'row' : 'column')};
  width: 100%;
  gap: ${({ withGap }) => (withGap ? Spacing.Large : '0px')};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;
