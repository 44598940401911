import * as React from 'react';

import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import { SelectStyled, SelectWrapperStyled } from './select.component.style';

export interface SelectProps extends FieldPropsInterface, React.InputHTMLAttributes<HTMLSelectElement> {}

export class Select extends Field<SelectProps, FieldStateInterface> {
  constructor(props: SelectProps) {
    super(props);
    this.state = { value: null, errors: [] };
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue as string });
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.defaultValue);
      }
    }
  }

  render() {
    const { attachToGroup, attachToForm, onValueChange, type, onChange, ...other } = this.props;

    return (
      <SelectWrapperStyled>
        <SelectStyled
          type={type}
          onChange={this.changeText}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...other}
        >
          {this.props.children}
        </SelectStyled>
      </SelectWrapperStyled>
    );
  }

  changeText = (event: React.FormEvent<HTMLSelectElement>) => {
    const value: string = event.currentTarget.value;
    if (this.state && value === this.state.value) {
      return;
    }
    this.setState({ value });
    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  private handleFocus = () => {
    this.props.onFocusChange(true, this.state.value);
  };

  private handleBlur = () => {
    this.props.onFocusChange(false, this.state.value);
  };
}
