import { VaccineFamilyFieldsFragment } from '@app/resource/graphql/generated';
import { observable } from 'mobx';
import { Service } from 'typedi';

@Service()
export class VaccineChoiceBySpecialNeeViewState {
  @observable
  vaccineModalOpened: boolean = false;

  @observable
  selectedVaccineFamily: VaccineFamilyFieldsFragment = null;

  dispose() {
    this.vaccineModalOpened = false;
  }
}
