import BrazilStateSelect from '@app/components/brazil-state-select.component';
import { Button } from '@components/atm.button';
import { Notification } from '@components/atm.notification';
import { RadioField, RadioFieldGroup } from '@components/atm.radio';
import { TextField } from '@components/atm.text-field';
import { InputLabel } from '@components/atm.typography';
import { FieldValidator, Form, FormData, Validators } from '@components/obj.form';
import { Col, Row, Separator } from '@components/obj.grid';
import * as React from 'react';

export interface IFindUserFormProps {
  loading?: boolean;
  onSubmit?: (formData: FormData<FindUserFormData>) => void;
  errorMessage?: string;
  initialEmail?: string;
}

export interface FindUserFormData {
  email?: string;
  crm?: string;
  state?: string;
}

enum FindUserChoice {
  ByEmail = 'email',
  ByUfAndCRM = 'uf-crm',
}

export const FindUserForm: React.FunctionComponent<IFindUserFormProps> = props => {
  const handleSubmit = formData => {
    console.log('🚀 ~ file: find-user.component.tsx:32 ~ handleSubmit ~ formData:', formData);
    if (formData.errors.length === 0 && props.onSubmit) {
      props.onSubmit(formData);
    }
  };

  const [choice, setChoice] = React.useState(FindUserChoice.ByEmail);

  return (
    <Form onSubmit={handleSubmit} key={choice}>
      <Row $mb={true}>
        <Col xs={12}>
          <InputLabel>Resetar senha usando:</InputLabel>
          <RadioFieldGroup onValueChange={setChoice} value={choice}>
            <RadioField value={FindUserChoice.ByEmail}>E-mail</RadioField>
            <RadioField value={FindUserChoice.ByUfAndCRM}>CRM + Estado</RadioField>
          </RadioFieldGroup>
        </Col>
      </Row>

      {choice === FindUserChoice.ByEmail && (
        <Row start='xs' $mb={true}>
          <Col xs={12}>
            <InputLabel>E-mail</InputLabel>
            <FieldValidator
              name='email'
              validators={[Validators.Required('Campo obrigatório'), Validators.EmailRegex('O e-mail é inválido')]}
            >
              <TextField type='text' placeholder='Seu e-mail registrado' defaultValue={props.initialEmail} />
            </FieldValidator>
          </Col>
        </Row>
      )}

      {choice === FindUserChoice.ByUfAndCRM && (
        <>
          <Row $mb={true}>
            <Col xs={12}>
              <InputLabel>CRM</InputLabel>
              <FieldValidator
                name='crm'
                validators={[
                  Validators.Required('Campo obrigatório'),
                  Validators.CrmRegex(`O CRM deve ter somente números.`),
                ]}
              >
                <TextField type={'custom'} options={{ mask: '9999999' }} />
              </FieldValidator>
            </Col>
          </Row>

          <Row $mb={true}>
            <Col xs={12}>
              <InputLabel>Estado</InputLabel>
              <FieldValidator name='state' validators={[Validators.Required('Campo obrigatório')]}>
                <BrazilStateSelect />
              </FieldValidator>
            </Col>
          </Row>
        </>
      )}
      <Row start='xs'>
        {props.errorMessage && (
          <Col xs={12}>
            <Notification type='error' message={props.errorMessage} />
            <Separator />
          </Col>
        )}
      </Row>
      <Row center='xs' $mb={true}>
        <Col xs={12}>
          <Button kind='primary' type='submit' expanded={true} loading={props.loading}>
            Enviar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
