import styled from 'styled-components';
import { Border, Color, Spacing } from '@components/obj.constants';

interface WhiteContainerWrapperProps {
  isResponsive?: boolean;
  hasPadding?: boolean;
}

export const WhiteContainerWrapper = styled.div<WhiteContainerWrapperProps>`
  background-color: ${Color.White};
  border-radius: ${Border.radiusLarge};
  margin: ${Spacing.XXLarge} auto;
  max-width: ${({ isResponsive }) => (isResponsive ? '75%' : '720px')};
  width: 100%;
  padding: ${({ hasPadding }) => (hasPadding ? '0' : `0 ${Spacing.XLarge}`)};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: 768px) {
    margin: ${Spacing.XLarge} auto;
    max-width: 90%;
  }
`;

export const ContentWrapper = styled.div`
  margin: 0 ${Spacing.XLarge};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: ${Spacing.XXLarge} 0;

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;
