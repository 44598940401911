import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { ImunocardListContainerStyled } from '@components/obj.list-container/imunocard-list-container.component.style';

interface RecommendationCommentProps {
  fullComment?: boolean;
}

export const AlertStyled = styled.p`
  background-color: ${Color.WarningSoft};
  color: ${Color.WarningStrong};
  padding: ${Spacing.XSmall};
  border-radius: ${Border.radiusLarge};
`;

export const PencilIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Color.Primary};
  font-size: ${FontSize.Large};
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${ImunocardListContainerStyled}:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: ${FontSize.Medium};
  }
`;

export const RecommendationCommentTextStyled = styled.p<RecommendationCommentProps>`
  overflow: hidden;
  ${({ fullComment }) =>
    fullComment
      ? `
        white-space: normal;
        word-break: break-word;
      `
      : `
        white-space: nowrap;
        text-overflow: ellipsis;
      `}
`;

export const RecomendationTextStyled = styled.p<{ marginLeft?: string }>`
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin-left: ${({ marginLeft }) => marginLeft || Spacing.Medium};
`;
