import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllVaccineFamiliesByAgeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllVaccineFamiliesByAgeQuery = {
  __typename?: 'Queries';
  AllVaccineFamiliesByAge?: Array<{
    __typename?: 'VaccineFamilyByAgeResponse';
    months: number;
    families: Array<{ __typename?: 'VaccineFamilyType'; id: string; name: string } | null>;
  } | null> | null;
};

export const AllVaccineFamiliesByAgeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllVaccineFamiliesByAge' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllVaccineFamiliesByAge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'months' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'families' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VaccineFamilyFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VaccineFamilyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VaccineFamilyType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllVaccineFamiliesByAgeQuery, AllVaccineFamiliesByAgeQueryVariables>;
