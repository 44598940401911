import { Observer } from '@app/core/decorator/observer.decorator';
import * as React from 'react';
import { PropsBase } from '@app/core/base/props.base';
import { WhiteContainer } from '@components/obj.white-container/white-container.component';
import { Body, H1, H3 } from '@components/atm.typography';
import { Col, Grid, Row } from '@components/obj.grid/grid.component';
import { DefaultRecommendationCard } from '@components/mol.default-recommendation-card/default-recommendation-card.component';
import { Separator } from '@components/obj.box';
import { DefaultRecommendationListGrid } from './default-recommendation-list.style';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { AuthenticationStore } from '../authentication/authentication.store';
import Container from 'typedi';
import { reaction } from 'mobx';
import {
  AllDefaultRecommendationsQuery,
  DefaultRecommendationDeleteMutation,
  DefaultRecommendationDeleteMutationVariables,
  ListCategoriesRecommendationQuery,
} from '@app/resource/graphql/generated';
import { DataValue, MutationFunction } from 'react-apollo';
import { Vaccine } from '@app/model/vaccine.model';
import { Button } from '@components/atm.button';
import { Modal } from '@components/obj.modal';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { Redirect } from 'react-router';

type DefaultRecommendationListViewProps = PropsBase & {
  listCategoriesRecommendation: DataValue<ListCategoriesRecommendationQuery>;
  allDefaultRecommendations: DataValue<AllDefaultRecommendationsQuery>;
  deleteDefaultRecommendation: MutationFunction<DefaultRecommendationDeleteMutation, DefaultRecommendationDeleteMutationVariables>;
};

export interface DefaultRecommendationListState {
  modalOpen: boolean;
  recommendationIdToDelete: string;
}

@GraphQL('list-categories-recommendation.query', {
  name: 'listCategoriesRecommendation',
  options: {
    errorPolicy: 'all',
  },
})
@GraphQL('all-default-recommendations.query', {
  name: 'allDefaultRecommendations',
  options: {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  },
})
@GraphQL('default-recommendation-delete.mutation', {
  name: 'deleteDefaultRecommendation',
  options: {
    errorPolicy: 'all',
  },
})
@Observer()
export class DefaultRecommendatilListView extends React.Component<DefaultRecommendationListViewProps, DefaultRecommendationListState> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private reactionDisposers = [];

  constructor(props) {
    super(props);

    this.state = { modalOpen: false, recommendationIdToDelete: '' };

    this.reactionDisposers.push(
      reaction(
        () => this.authenticationStore?.loggedIn,
        () => {
          // Workaround to refetch data after token is retrieved from store
          // in initial load, the token is not yet available
          // add timeout without it was not triggering the refetch
          setTimeout(() => {
            this.refetchData();
          }, 1000);
        },
      ),
    );
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  refetchData = (): void => {
    this.props.allDefaultRecommendations.refetch();
  };

  render() {
    const { listCategoriesRecommendation, allDefaultRecommendations } = this.props;

    const errorListCategories = listCategoriesRecommendation.error;
    const loadingListCategories = listCategoriesRecommendation.loading;
    const errorAllDefaultRecommendations = allDefaultRecommendations.error;
    const loadingAllDefaultRecommendations = allDefaultRecommendations.loading;
    const categories = listCategoriesRecommendation?.ListCategoriesRecommendation?.categories;
    const defaultRecommendations = allDefaultRecommendations?.DefaultRecommendations;

    if (this.authenticationStore?.isHydrated && this.authenticationStore?.loggedIn === false) {
      return <Redirect to={LOGIN_PATH} />;
    }

    return (
      <WhiteContainer isResponsive={true} hasPadding={false}>
        <H1>Gerenciar recomendações padronizadas</H1>
        <Separator />
        {loadingListCategories || errorListCategories || errorAllDefaultRecommendations || loadingAllDefaultRecommendations || !defaultRecommendations ? (
          <p>{loadingListCategories || loadingAllDefaultRecommendations ? 'Carregando recomendações...' : 'Erro ao carregar dados, tente novamente mais tarde.'}</p>
        ) : (
          <DefaultRecommendationListGrid fluid={true}>
            <Row start='xs'>
              {categories.map(category => {
                const recommendation = defaultRecommendations.find(
                  rec => rec.categoryRecommendationId === category.id
                );
                return (
                  <Col xs={12} lg={6} key={category.id}>
                    <DefaultRecommendationCard
                      name={category.category}
                      isNew={!recommendation}
                      caption={recommendation ? '' : null} // TODO: server precisa retornar data
                      onEditClick={this.handleEditClick(category.category, recommendation?.vaccines, recommendation?.id)}
                      onCreateClick={this.handleCreateClick(category.id, category.category)}
                      onEraseClick={this.handleEraseClick(recommendation?.id)}
                    />
                  </Col>
                );
              })}
            </Row>
            <ConfirmModal
              open={this.state.modalOpen}
              loading={false}
              onClose={this.handleCloseModal}
              onConfirm={this.callDeleteMutation}
            />
          </DefaultRecommendationListGrid>
        )}
      </WhiteContainer>
    );
  }

  private handleEditClick = (ageGroupSelected: string, vaccines: Vaccine[], recommendationId: string) => () =>  {
    this.props.history.push('/recomendacao-padronizada/selecao-de-vacinas', {
      vaccines,
      ageGroupSelected,
      recommendationId,
      isDefaultRecommendation: true,
    });
  };

  private handleCreateClick = (categoryId: string, ageGroupSelected: string) => () => {
    this.props.history.push('/recomendacao-padronizada/selecao-de-vacinas', {
      categoryId,
      ageGroupSelected,
      isDefaultRecommendation: true,
    });
  }

  private handleEraseClick = (recommendationId: string) => () => {
    this.setState({ modalOpen: true, recommendationIdToDelete: recommendationId });
  }

  private handleCloseModal = () => {
    this.setState({ modalOpen: false });
  }

  private callDeleteMutation = async () => {
    return this.props
      .deleteDefaultRecommendation({ variables: { data: { id: this.state.recommendationIdToDelete } }})
      .then(() => {
        this.refetchData();
        this.setState({ modalOpen: false });
      })
      .catch(() => {
        console.error('Erro ao apagar recomendação padronizada');
      });
  }
}

interface ConfirmModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = props => {
  return (
    <Modal noGutter={true} small={true} opened={props.open} onClose={props.onClose}>
      <Grid fluid={true}>
        <Row $mb={true}>
          <Col xs={12}>
            <Separator />
            <H3>Você tem certeza?</H3>
            <Body>Você tem certeza que deseja apagar essa recomendação?</Body>
          </Col>
        </Row>
        <Row $mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onConfirm} kind='alert' loading={props.loading}>
              Apagar
            </Button>
          </Col>
        </Row>
        <Row $mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onClose} kind='secondary'>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};

