import { H1 } from '@components/atm.typography';
import { Color, FontWeight, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { ButtonStyled } from '@components/atm.button/button.component.style';

export const HeroImunocardStyled = styled.div`
  background: ${Color.Secondary};
`;

export const CardImgStyled = styled.img`
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-top: ${Spacing.XLarge};
  }
`;

export const HeaderImunocardButtonStyled = styled(ButtonStyled)`
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    margin-top: ${Spacing.XLarge};
  }
`;

export const HeaderImunocardH1Styled = styled(H1)`
  color: ${Color.White};
  font-weight: ${FontWeight.Normal};
  line-height: ${Spacing.XXXLarge};
  @media only screen and (max-width: 768px) {
    line-height: ${Spacing.XXLarge};
  }
`;
