import { Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { TextAreaFieldProps } from './text-area-field.component';
import { isVacinaTheme } from 'config/theme';

export const TextAreaStyled = styled.textarea<TextAreaFieldProps>`
  resize: vertical;
  border-radius: 22px;
  border-width: 1px;
  border-color: ${isVacinaTheme ? Color.GrayLight : Color.NeutralGrayStrong};
  align-self: stretch;
  padding: ${Spacing.Small};
  font-family: ${FontFamily.Secondary};
  font-size: ${FontSize.Small};
  width: 100%;
  min-height: 50px;
  border-style: solid;
  :focus {
    outline: none;
  }
`;
