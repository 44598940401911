import { AppIcon } from '@components/atm.app-icon';
import { RadioFieldProps } from '@components/atm.radio';
import { Body, H3 } from '@components/atm.typography';
import { Tooltip } from '@components/mol.tooltip';
import { Hbox } from '@components/obj.box';
import { FieldStateInterface } from '@components/obj.form';
import { Col, Separator } from '@components/obj.grid';
import * as React from 'react';
import {
  VaccineItemMouthDropIconStyled,
  VaccineNameColStyled,
  VaccinePlaceColStyled,
  VaccinePlaceRowStyled,
  VaccineTooltipStyled,
  VacineItemBulletStyled,
  VacineItemCheckContainerStyled,
  VacineItemCheckedStyled,
  VacineItemDoseTextStyled,
  VacineItemIconPlaceStyled,
  VacineItemInfoContainerStyled,
  VacineItemInputValueStyled,
  VacineItemPlaceContainerStyled,
  VacineItemPlaceStyled,
  VacineItemQuantityIconStyled,
  VacineItemRowStyled,
  VacineItemStyled,
  VacineItemTextContainerStyled,
  VacineItemUncheckedStyled,
  VacineItemWrapperStyled,
  VacineLabelStyled,
} from './vacine-item.component.style';

export interface VacineItemProps extends RadioFieldProps {
  title?: string;
  description?: string;
  mouthDropCount?: number;
  injectionCount?: number;
  hasPublic?: boolean;
  hasPrivate?: boolean;
  brand?: string;
  laboratory?: string;
  comment?: string;
  tooltip?: JSX.Element;
}

interface VacineItemPropsState extends FieldStateInterface {
  checked: boolean;
  showModal: boolean;
  clinicTooltipVisible: boolean;
  mouthDropTooltipVisible: boolean;
  showTooltip: boolean;
}

export class VacineItem extends React.Component<VacineItemProps, VacineItemPropsState> {
  constructor(props: VacineItemProps) {
    super(props);
    this.state = {
      checked: this.props.checked ? true : false,
      errors: [],
      showModal: false,
      mouthDropTooltipVisible: false,
      clinicTooltipVisible: false,
      showTooltip: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: VacineItemProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  onPress = () => {
    const state = !this.state.checked;
    this.setState({ checked: state });

    if (this.props.onValueChange) {
      this.props.onValueChange(this.props.value);
    }
  };

  render() {
    return (
      <VacineItemStyled>
        <VacineItemBulletStyled
          type='checkbox'
          name={this.props.name}
          id={this.props.name + '_' + this.props.value}
          checked={this.state.checked}
          onChange={this.onPress}
        />
        <VacineItemInputValueStyled htmlFor={this.props.name + '_' + this.props.value}>
          <VacineItemWrapperStyled>
            <VacineItemRowStyled>
              <VaccineNameColStyled xs={12} sm={6} md={8}>
                <VacineItemCheckContainerStyled>
                  <VacineItemUncheckedStyled />
                  <VacineItemCheckedStyled />
                </VacineItemCheckContainerStyled>
                <VacineItemTextContainerStyled>
                  <H3>{this.getTitle()}</H3>
                  <Body>{this.props.description}</Body>
                </VacineItemTextContainerStyled>

              </VaccineNameColStyled>
              <Col xs={12} sm={6} md={4}>
                <Hbox $hAlign='flex-end'>
                  {this.props.injectionCount > 0 && (
                    <Hbox.Item $noGrow={true}>
                      <VacineItemInfoContainerStyled>
                        <VacineItemQuantityIconStyled>
                          <VacineItemDoseTextStyled
                            htmlFor={this.props.name + '_' + this.props.value}
                            title='Número de administrações através de injeção'
                          >
                            Injeções
                          </VacineItemDoseTextStyled>
                          {this.getInjectionIcons()}
                        </VacineItemQuantityIconStyled>
                      </VacineItemInfoContainerStyled>
                    </Hbox.Item>
                  )}
                  {this.props.mouthDropCount > 0 && (
                    <>
                      <Hbox.Separator />
                      <Hbox.Separator />
                      <Hbox.Separator />
                      <Hbox.Item noGrow={true}>
                        <VacineItemInfoContainerStyled>
                          <VaccineItemMouthDropIconStyled
                            onMouseEnter={this.showMouthDropTooltip}
                            onMouseLeave={this.hideMouthDropTooltip}
                          >
                            <VacineItemDoseTextStyled
                              htmlFor={this.props.name + '_' + this.props.value}
                              title='Número de administrações através de gotas'
                            >
                              Gotas
                            </VacineItemDoseTextStyled>
                            {this.getMouthDropIcons()}
                          </VaccineItemMouthDropIconStyled>
                        </VacineItemInfoContainerStyled>
                      </Hbox.Item>
                    </>
                  )}
                </Hbox>
              </Col>
            </VacineItemRowStyled>
            <VaccinePlaceRowStyled end='xs'>
              <VaccinePlaceColStyled>
                <Separator />
                <VacineItemPlaceContainerStyled
                  onMouseEnter={this.showClinicTooltip}
                  onMouseLeave={this.hideClinicTooltip}
                >
                  {this.getPrivate()}
                  {this.getPublic()}
                </VacineItemPlaceContainerStyled>
              </VaccinePlaceColStyled>
            </VaccinePlaceRowStyled>
          </VacineItemWrapperStyled>
        </VacineItemInputValueStyled>
        {this.props.tooltip && (
          <VaccineTooltipStyled>
            <Tooltip
              opened={this.state.showTooltip}
              onClose={this.hideInjectionTooltip}
              message={this.props.tooltip}
            />
          </VaccineTooltipStyled>
        )}
      </VacineItemStyled>
    );
  }

  getTitle() {
    let title = this.props.title;

    if (this.props.brand != null && this.props.brand != null && this.props.brand !== '-') {
      title = `${title} - ${this.props.brand}`;
    }

    if (this.props.laboratory != null && this.props.laboratory != null && this.props.laboratory !== '-') {
      title = `${title} / ${this.props.laboratory}`;
    }

    return title;
  }

  getPrivate() {
    // TODO: User Icon Component
    if (this.props.hasPrivate) {
      return (
        <VacineItemPlaceStyled>
          <VacineItemIconPlaceStyled>
            <img
              src='/assets/img/ic_clinica.png'
              width='24px'
              title={'Disponibilidade locais de vacinação.'}
            />
          </VacineItemIconPlaceStyled>
          <VacineLabelStyled
            htmlFor={this.props.name + '_' + this.props.value}
            title={'Disponibilidade de locais de vacinação.'}
          >
            Privado
          </VacineLabelStyled>
        </VacineItemPlaceStyled>
      );
    } else {
      return null;
    }
  }

  getPublic() {
    // TODO: User Icon Component
    if (this.props.hasPublic) {
      return (
        <VacineItemPlaceStyled>
          <VacineItemIconPlaceStyled>
            <img
              src='/assets/img/ic_sus.png'
              width='24px'
              title={'Disponibilidade em locais de vacinação.'}
            />
          </VacineItemIconPlaceStyled>
          <VacineLabelStyled
            htmlFor={this.props.name + '_' + this.props.value}
            title={'Disponibilidade em locais de vacinação.'}
          >
            Público
          </VacineLabelStyled>
        </VacineItemPlaceStyled>
      );
    } else {
      return null;
    }
  }

  getMouthDropIcons() {
    const icons = [];
    if (this.props.mouthDropCount > 0) {
      for (let i = 0; i < this.props.mouthDropCount; i++) {
        icons.push(<AppIcon.DropPurple key={i} />);
      }
    }
    return icons;
  }

  getInjectionIcons() {
    const icons = [];
    if (this.props.injectionCount > 0) {
      for (let i = 0; i < this.props.injectionCount; i++) {
        icons.push(<AppIcon.VacinePurple key={i} />);
      }
    }
    return icons;
  }

  private showMouthDropTooltip = () => {
    this.setState({ mouthDropTooltipVisible: true });
  };

  private hideMouthDropTooltip = () => {
    this.setState({ mouthDropTooltipVisible: false });
  };

  private showClinicTooltip = () => {
    this.setState({ clinicTooltipVisible: true });
  };

  private hideClinicTooltip = () => {
    this.setState({ clinicTooltipVisible: false });
  };

  private hideInjectionTooltip = () => {
    this.setState({ showTooltip: false });
  };

}
