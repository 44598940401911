import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@components/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AccordionContentProps {
  expanded: boolean;
  maxHeight: string;
}

interface AccordionIconProps {
  expanded: boolean;
}

export const AccordionItemWrapper = styled.div`
  border-bottom: 1px solid ${Color.NeutralGrayXSoft};
  padding: ${Spacing.XLarge} 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${Spacing.XLarge};
  cursor: pointer;
  box-sizing: border-box;
`;

export const AccordionTitle = styled.h3`
  font-size: ${FontSize.Medium};
  font-weight: bold;
  color: ${Color.NeutralGrayStrong};
  flex-grow: 1;
  margin: 0;
  line-height: 1.3;

  @media (max-width: 780px) {
    font-size: ${FontSize.Small};
  }
`;

export const AccordionIcon = styled(FontAwesomeIcon)<AccordionIconProps>`
  font-size: ${FontSize.Medium};
  color: ${Color.NeutralGrayMedium};
  transition: transform 0.3s ease;
  transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-left: ${Spacing.XSmall};

  @media (max-width: 780px) {
    font-size: ${FontSize.Small};
  }
`;

export const AccordionContent = styled.div<AccordionContentProps>`
  overflow: hidden;
  max-height: ${({ maxHeight }) => maxHeight};
  transition: max-height 0.3s ease;
  padding: ${({ expanded }) => (expanded ? `0 ${Spacing.Large}` : '0px')};
  box-sizing: border-box;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
`;
