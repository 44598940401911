export enum MenuItems {
  consulta,
  centros,
  conectados,
  casaDeVacinas,
  sbimCalendar,
  perfil,
  optOut,
  referencias,
  recomendacoes,
}
