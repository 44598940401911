import { Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const MIN_HEIGHT = 0;
const padding = Spacing.Large;
export const ShowMoreBoxStyled = styled.div`
  position: relative;
  padding-bottom: ${padding};
`;

interface ShowMoreBoxCollapsibleWrapperStyledProps {
  $contentHeight: number;
  $minHeight: number;
  $expanded: boolean;
}
export const ShowMoreBoxCollapsibleWrapperStyled = styled.div<ShowMoreBoxCollapsibleWrapperStyledProps>`
  max-height: ${props => (props.$expanded ? `${props.$contentHeight}px` : `${props.$minHeight}px`)};
  padding-bottom: ${props => (props.$expanded ? Spacing.Medium : '0')};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;

export const ShowMoreBoxContentStyled = styled.div`
  border: 1px solid transparent;
`;
