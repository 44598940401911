import * as React from 'react';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { PropsBase } from '@app/core/base/props.base';
import { DefaultRecommendationCreateMutation, DefaultRecommendationCreateMutationVariables, DefaultRecommendationUpdateMutation, DefaultRecommendationUpdateMutationVariables } from '@app/resource/graphql/generated';
import { MutationFunction } from 'react-apollo';
import { SelectedVaccines, VaccineSelectionView } from '../appointment-imunocard/vaccine-selection.view';
import { Vaccine } from '@app/model/vaccine.model';
import { Router } from '@app/core/route/router';
import { Container } from 'typedi';

type DefaultRecommendationVaccinesViewProps = PropsBase & {
  createDefaultRecommendation: MutationFunction<DefaultRecommendationCreateMutation, DefaultRecommendationCreateMutationVariables>;
  updateDefaultRecommendation: MutationFunction<DefaultRecommendationUpdateMutation, DefaultRecommendationUpdateMutationVariables>;
};

interface DefaultRecommendationVaccinesViewState {
  ageGroupSelected: string;
  categoryId: string;
  isDefaultRecommendation: boolean;
  isCreating: boolean;
  recommendationId?: string;
  vaccines?: Vaccine[];
}

@GraphQL('default-recommendation-create.mutation', {
  name: 'createDefaultRecommendation',
  options: {
    errorPolicy: 'all',
  },
})
@GraphQL('default-recommendation-update.mutation', {
  name: 'updateDefaultRecommendation',
  options: {
    errorPolicy: 'all',
  },
})
export class DefaultRecommendationVaccinesView extends React.Component<DefaultRecommendationVaccinesViewProps, DefaultRecommendationVaccinesViewState> {
  private router?: Router = Container.get(Router);
  constructor(props) {
    super(props);

    const ageGroupSelected = props.location?.state?.ageGroupSelected || '';
    const categoryId = props.location?.state?.categoryId || '';
    const isDefaultRecommendation = props.location?.state?.isDefaultRecommendation || false;
    const recommendationId = props.location?.state?.recommendationId || '';
    const vaccines = props.location?.state?.vaccines || [];
    const isCreating = !recommendationId;

    this.state = {
      ageGroupSelected,
      categoryId,
      isDefaultRecommendation,
      recommendationId,
      vaccines,
      isCreating,
    };
  }

  render() {
    const { vaccines, isDefaultRecommendation, ageGroupSelected, isCreating } = this.state;

    return (
      <VaccineSelectionView
        vaccines={vaccines}
        onSubmit={isCreating ? this.callCreateMutation : this.callUpdateMutation}
        isDefaultRecommendation={isDefaultRecommendation}
        ageGroupSelected={ageGroupSelected}
        history={undefined}
        location={undefined}
        match={undefined}
        ownProps={undefined}
      />
    );
  }

  private callUpdateMutation = async (selectedVaccines: SelectedVaccines[]) => {
    const vaccines = selectedVaccines.map(vaccine => {
      return {
        vaccineId: vaccine.vaccine.id,
        comment: vaccine.vaccine.recommendationComment,
        order: vaccine.vaccineOrder,
      };
    });
    await this.props
      .updateDefaultRecommendation({ variables: { data: { id: this.state.recommendationId, vaccines } }})
      .then(() => {
        this.router.push('/recomendacao-padronizada');
      })
      .catch(() => {
        console.error('Erro ao editar recomendação padronizada');
      });
  }

  private callCreateMutation = async (selectedVaccines: SelectedVaccines[]) => {
    const vaccines = selectedVaccines.map(vaccine => {
      return {
        vaccineId: vaccine.vaccine.id,
        comment: vaccine.vaccine.recommendationComment,
        order: vaccine.vaccineOrder,
      };
    });

    await this.props
      .createDefaultRecommendation({ variables: { data: { categoryRecommendationId: this.state.categoryId, vaccines } }})
      .then(() => {
        this.router.push('/recomendacao-padronizada');
      })
      .catch(() => {
        console.error('Erro ao criar recomendação padronizada');
      });
  }
}
