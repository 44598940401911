import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { NullAge } from '@app/model/age.model';
import { Vaccine } from '@app/model/vaccine.model';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AppointmentStep, AppointmentVaccineTuple } from '@app/modules/appointment/appointment.store.model';
import { Button } from '@components/atm.button';
import { CheckboxField } from '@components/atm.checkbox';
import { Body, H1 } from '@components/atm.typography';
import { ButtonFooterStyled } from '@components/mol.button-footer';
import { CheckFamilyGroup } from '@components/mol.check-family-group';
import { LoadingWrapper } from '@components/mol.loading-wrapper';
import QuickRecommendationFixedMenu from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Tooltip } from '@components/mol.tooltip';
import { ButtonsContainer } from '@components/obj.buttons-container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { ListContainer } from '@components/obj.list-container';
import { Modal } from '@components/obj.modal';
import { RelativeContainer } from '@components/obj.relative-container';
import { action } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { APPOINTMENT_PATH } from './appointment.route';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';
import { mapImmunocompromisedComorbiditiesToString } from './immunocompromised-choice.utils';
import { FamilyTooltipStyled } from './vaccine-choice-by-age.style';
import { VaccineChoiceBySpecialNeeViewState } from './vaccine-choice-by-special-need.view-state';
import { VaccineListContainer } from './vaccine-list.container';
import { VaccineBySpecialNeedQuery, VaccineFamilyFieldsFragment } from '@app/resource/graphql/generated';

const submitButtonText = 'VER RECOMENDAÇÃO';

@Observer()
export class VaccineChoiceBySpecialNeedView extends React.Component<PropsBase<VaccineBySpecialNeedQuery>, any> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private vaccineChoiceViewState?: VaccineChoiceBySpecialNeeViewState = Container.get(
    VaccineChoiceBySpecialNeeViewState,
  );
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);

  private modalRef = React.createRef<Modal>();
  private didScrollEventTracked: boolean = false;

  componentWillUnmount() {
    this.vaccineChoiceViewState.dispose();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_pacientes_especiais');
    window.addEventListener('scroll', event => this.handleScrollEvent(event));
  }

  handleScrollEvent = event => {
    if (!this.didScrollEventTracked && window.scrollY > 0 && event.type === 'scroll') {
      this.analyticsService.event('consulta_pacientes_especiais_scroll', 'scroll', true);
      this.didScrollEventTracked = true;
    }
  };

  render() {
    const vaccineTargetName = mapImmunocompromisedComorbiditiesToString(
      this.appointmentStore.selectedImmunocompromisedComorbidity,
    );

    return (
      <div>
        <Grid>
          <Row>
            <Col xs={12}>
              <RelativeContainer>
                <H1>2. Escolha de vacinas para {vaccineTargetName}</H1>

                {this.props.data.loading && (
                  <Row center='xs'>
                    <Col>
                      <LoadingWrapper type='boy-gif-clean' />
                    </Col>
                  </Row>
                )}

                <>
                  <Body>Selecione as vacinas que deseja incluir na sua recomendação.</Body>
                  <ListContainer>
                    <FamilyTooltipStyled>
                      <Tooltip
                        opened={
                          typeof this.applicationStore.showFamilyChoiceOnSpecialPatientTooltip === 'undefined'
                            ? true
                            : this.applicationStore.showFamilyChoiceOnSpecialPatientTooltip
                        }
                        onClose={this.handleTooltipCloseClick}
                        message={`Selecione as vacinas que deseja recomendar.`}
                      />
                    </FamilyTooltipStyled>

                    <VaccineCheckboxFamilyGroup
                      familiesBySpecialNeed={this.props.data}
                      value={this.appointmentStore.selectedVaccinesTuple.map(item => item.familyId)}
                      onVaccineFamilyClick={this.handleVaccineFamilyClick}
                    />
                  </ListContainer>
                </>
              </RelativeContainer>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <ButtonFooterStyled>
                <ButtonsContainer>
                  <Button kind='primary' outlined={true} expanded={true} onClick={this.goBack}>
                    Voltar
                  </Button>

                  <Button
                    kind='callToAction'
                    expanded={true}
                    onClick={this.goToRecommendationPage}
                    disabled={this.props.data.loading}
                  >
                    {submitButtonText}
                  </Button>
                </ButtonsContainer>
              </ButtonFooterStyled>
            </Col>
          </Row>

          {this.mobileService.onMobile || (
            <QuickRecommendationFixedMenuContainer quickRecommendationContext='comorbidity_priority'>
              {childProps => (
                <>
                  <QuickRecommendationFixedMenu
                    recommendations={childProps.recommendations}
                    onItemClick={childProps.handleItemClick}
                    age={this.appointmentStore.selectedAge.getAgeText()}
                    hideAgesOnItems={true}
                  />
                  <Separator />
                  <Separator />
                  <Separator />
                </>
              )}
            </QuickRecommendationFixedMenuContainer>
          )}

          <OverdueDosesChatbotContainer initialClosed={true} />
        </Grid>

        <VaccineModal
          ref={this.modalRef}
          selectedVaccinesTuple={this.appointmentStore.selectedVaccinesTuple}
          specialNeedVaccines={this.props.data.VaccineBySpecialNeed}
          specialNeedFamily={this.vaccineChoiceViewState.selectedVaccineFamily}
          open={this.vaccineChoiceViewState.vaccineModalOpened}
          onClose={this.closeVaccineModal}
          onVaccineClick={this.onVaccineClick}
        />
      </div>
    );
  }

  private goBack = () => {
    this.analyticsService.event('consulta_pacientes_especiais_voltar', 'click');
    this.props.history.goBack();
  };

  private goToRecommendationPage = () => {
    if (this.appointmentStore.selectedVaccinesTuple.length === 0) {
      alert('Selecionar pelo menos uma vacina para seguir.');
      return;
    }
    this.applicationStore.showFamilyChoiceOnSpecialPatientTooltip = false;
    this.props.history.push(`${APPOINTMENT_PATH}/${AppointmentStep.Recommendation}`);
  };

  @action
  private closeVaccineModal = () => {
    this.vaccineChoiceViewState.vaccineModalOpened = false;
  };

  private onVaccineClick = async (item: Vaccine, checked: boolean) => {
    const family = this.vaccineChoiceViewState.selectedVaccineFamily;

    if (checked) {
      this.appointmentStore.selectVaccine(new NullAge(), item, family.id);
      this.analyticsService.event(
        'consulta_escolha_vacina_adicionar',
        item.name + ' - ' + item.brand,
        false,
        item.laboratory,
        null,
        false,
      );
      this.analyticsService.event('consulta_pacientes_especiais_adicionar', 'click', true, family.name, null, false);
      await this.delay(100);
      this.modalRef.current.close();
    } else {
      this.appointmentStore.unselectVaccine(new NullAge(), item, family.id);
      this.analyticsService.event(
        'consulta_escolha_vacina_remover',
        item.name + ' - ' + item.brand,
        false,
        item.laboratory,
        null,
        false,
      );
      this.analyticsService.event('consulta_pacientes_especiais_remover', 'click', true, family.name, null, false);
    }
    this.forceUpdate();
  };

  private async delay(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private handleVaccineFamilyClick = (_groupValue: any[], value: string) => {
    const familyId = value;
    const familyAndVaccines = this.props.data.VaccineBySpecialNeed.find(item => item.family.id === familyId);
    if (!familyAndVaccines) {
      throw new Error(`Not found family for value: ${value}`);
    }
    this.vaccineChoiceViewState.selectedVaccineFamily = familyAndVaccines.family;
    this.vaccineChoiceViewState.vaccineModalOpened = true;
    this.applicationStore.showFamilyChoiceOnSpecialPatientTooltip = false;
  };

  private handleTooltipCloseClick = () => {
    this.applicationStore.showFamilyChoiceOnSpecialPatientTooltip = false;
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IVaccineCheckboxFamilyGroupProps {
  familiesBySpecialNeed: Partial<VaccineBySpecialNeedQuery>;
  value: string[];
  onVaccineFamilyClick: (groupValue: unknown[], value: string) => void;
}

const VaccineCheckboxFamilyGroup: React.FunctionComponent<IVaccineCheckboxFamilyGroupProps> = props => {
  const { familiesBySpecialNeed, value, onVaccineFamilyClick } = props;
  if (!familiesBySpecialNeed.VaccineBySpecialNeed || !familiesBySpecialNeed.VaccineBySpecialNeed.length) {
    return null;
  }
  return (
    <div>
      <CheckFamilyGroup title={''} onValueChange={onVaccineFamilyClick} value={value} vaccineCheckbox={true}>
        {familiesBySpecialNeed.VaccineBySpecialNeed.map(familyAndVaccines => {
          const itemValue = familyAndVaccines.family.id;
          return (
            <CheckboxField key={itemValue} value={itemValue}>
              {familyAndVaccines.family.name}
            </CheckboxField>
          );
        })}
      </CheckFamilyGroup>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IVaccineModalProps {
  selectedVaccinesTuple: AppointmentVaccineTuple[];
  specialNeedVaccines: VaccineBySpecialNeedQuery['VaccineBySpecialNeed'];
  specialNeedFamily: VaccineFamilyFieldsFragment;

  open: boolean;
  onClose: () => void;
  onVaccineClick?: (item: Vaccine, checked: boolean) => void;
}

const VaccineModal = React.forwardRef<Modal, IVaccineModalProps>((props, ref) => {
  const { specialNeedVaccines, specialNeedFamily, selectedVaccinesTuple } = props;
  if (!specialNeedVaccines || !specialNeedFamily) {
    return null;
  }
  const vaccines = specialNeedVaccines.find(
    familyAndVaccines => familyAndVaccines.family.id === specialNeedFamily.id,
  ).vaccines;
  const selectedVaccineTuple = selectedVaccinesTuple.find(v => v.familyId === specialNeedFamily.id);
  const selectedVaccineId = selectedVaccineTuple ? selectedVaccineTuple.vaccine.id : null;
  const selectedIndex = selectedVaccineId ? vaccines.findIndex(v => v.id === selectedVaccineId) : -1;
  return (
    <Modal ref={ref} opened={props.open} onClose={props.onClose} noGutter={true} noAnimation={true}>
      <VaccineListContainer
        vaccines={vaccines}
        title={specialNeedFamily.name}
        vaccineFamily={specialNeedFamily}
        onVaccineClick={props.onVaccineClick}
        selectedIndex={selectedIndex}
      />
    </Modal>
  );
});
