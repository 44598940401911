// tslint:disable: max-line-length
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { AccountStore } from '@app/modules/account/account.store';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import {
  AuthenticationStore,
  DELETE_USER_MAP_KEY,
  SIGN_UP_MAP_KEY,
} from '@app/modules/authentication/authentication.store';
import { Button } from '@components/atm.button';
import { Carousel } from '@components/atm.carousel';
import { Notification } from '@components/atm.notification';
import { FeaturedH2 } from '@components/atm.typography';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { OverflowXContainer } from '@components/obj.overflow-container';
import {
  HomeHeader,
  HowItWorksOverflowSection,
  HowItWorksRow,
  TestimonialListContainerStyled,
  TestimonialRow,
  TranslatedGridTopStyled,
} from '@components/pag.home';
import { FooterContainerView } from '@components/pag.home/obj.footer/footer.container';
import { reaction } from 'mobx';
import * as React from 'react';
import * as Scroll from 'react-scroll';
import { Container } from 'typedi';
import { SignupForm } from '../authentication/signup-form.component';
import { UserCreateMutation } from '@app/resource/graphql/generated';
import { DoctorInput } from '@app/resource/graphql/base-schema';
import { isVacinaTheme } from 'config/theme';
// tslint:enable: max-line-length

export const HowItWorksId = 'como-funciona';

export type VacinaHomeViewProps = PropsBase<UserCreateMutation>;

// Hotjar
declare var hj: any;

@GraphQL('user-create.mutation')
@Observer()
export class VacinaHomeView extends React.Component<VacinaHomeViewProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private router?: Router = Container.get(Router);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private mobileService?: MobileService = Container.get(MobileService);

  private scroll = Scroll.scroller;
  private reactionDisposers = [];

  constructor(props) {
    super(props);

    this.reactionDisposers.push(reaction(() => this.authenticationStore.loggedIn, this.onLogin));
    this.reactionDisposers.push(
      reaction(() => this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY), this.onErrorMapChanged),
    );
    this.state = { signUpErrorMsg: '' };
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    if (this.accountStore.scrollToSignUpForm) {
      this.accountStore.scrollToSignUpForm = false;
      this.scrollToSignUpForm();
    }

    if (this.accountStore.showChangePasswordSuccessMessage) {
      console.log('Change password success');
      this.accountStore.showChangePasswordSuccessMessage = false;
      // TODO - FRONT - Render change password success notification
    }

    this.analyticsService.pageView('home');
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    const successMessage = this.authenticationStore.successMessageMap.get(DELETE_USER_MAP_KEY);

    return (
      <div>
        {isVacinaTheme && <CookieConsentContainer />}
        {successMessage && <Notification type='success' message={successMessage} />}
        <OverflowXContainer>{this.renderHeader()}</OverflowXContainer>
        <OverflowXContainer>
          {this.renderHowItWorks()}
          {this.renderTestimonial()}
          <If cond={!this.authenticationStore.loggedIn}>{this.renderForm()}</If>
          <FooterContainerView />
        </OverflowXContainer>
      </div>
    );
  }

  onLogin = () => {
    if (this.authenticationStore.loggedIn) {
      this.router.push(APPOINTMENT_PATH);
    }
  };

  onErrorMapChanged = () => {
    if (this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)) {
      this.scrollToSignUpForm();
    }
  };

  private onSubmit = (formData: DoctorInput) => {
    this.analyticsService.event('home_cadastrar_form_button', 'click', true);

    this.authenticationStore.signUp(formData, this.props.mutate);
    this.scrollToSignUpForm();
  };

  private onTopSignUpClick = () => {
    this.scrollToSignUpForm();
    this.analyticsService.event('home_cadastrar_cta', 'click', true);
  };

  private onBottomSignUpClick = () => {
    this.scrollToSignUpForm();
    this.analyticsService.event('home_cadastrar_inferior_cta', 'click', true);
  };

  private scrollToSignUpForm() {
    this.scroll.scrollTo('signup', { smooth: false });
  }

  private onStartAppointmentClick = () => {
    this.router.push(APPOINTMENT_PATH);
    this.analyticsService.event('menu_iniciar_consulta', 'click');
  };

  private renderHeader = () => {
    return (
      <HomeHeader
        loggedIn={this.authenticationStore.loggedIn}
        onSignUpClick={this.onTopSignUpClick}
        onStartAppointmentClick={this.onStartAppointmentClick}
        onMobile={this.mobileService.onMobile}
      />
    );
  };

  private renderHowItWorks = () => {
    return (
      <HowItWorksOverflowSection>
        <FeaturedH2 id={HowItWorksId}>Conheça a ferramenta</FeaturedH2>

        <Grid>
          <HowItWorksRow
            title='Calendário SBP'
            description={`Escolha a idade do paciente e recomende as vacinas
                de acordo com o calendário oficial da SBP.`}
            img='assets/img/img_landing_age_3.jpg'
          />
          {this.authenticationStore.loggedIn || (
            <Row center={'xs'} $mb={true}>
              <Col xs={12} sm={8} md={6} lg={4}>
                <Button kind='callToAction' onClick={this.onBottomSignUpClick} expanded={true}>
                  Iniciar
                </Button>
                <Separator />
              </Col>
            </Row>
          )}
        </Grid>
      </HowItWorksOverflowSection>
    );
  };

  private renderTestimonial = () => {
    // tslint:disable: max-line-length
    const testimonials = [
      {
        img: 'assets/img/img_doctor_luiz_marcos.jpg',
        message:
          'O Vacina Certa me permite orientar e prescrever vacinas com segurança, seguindo as recomendações da SBP. Isso agrega valor à minha consulta, visto que o assunto vacinas é de grande importância para saúde pública. Médico e Paciente, seguros e satisfeitos.',
        name: 'Dr. Luiz Marcos - CRM MT 4170',
      },
      {
        img: 'assets/img/img_doctor_maira_canedo.jpg',
        message:
          'Adoro o programa Vacina Certa, pois além de nos ajudar a lembrar de todas as vacinas necessárias para as idades, nos mostra as opções de vacinas na rede pública e privada! Programa de fácil acesso, gratuito e de acordo com as recomendações da Sociedade Brasileira de Pediatria.',
        name: 'Dra. Maíra Canedo - CRM MG 63061',
      },
      // {
      //  img: 'assets/img/img-doctor-sample-circular.png',
      //  message: 'Eu tenho uma televisão que eu projeto para os pais, de forma visual as deiferenças das vacinas (qual é acelular, qual tem mais picadas) e assim os pais assimilam melhor. Ganha tempo na consulta e os pais saem mais satisfeitos, vale a pena!',
      //  name: 'Dr. José Vicente Prandini - Pediatra CRM ',
      // },
      {
        img: 'assets/img/img_doctor_ethel_mariam.jpg',
        message:
          'O Vacina Certa ajuda a lembrar das vacinas nas diferentes faixas etárias, principalmente quando há mudança no calendário vacinal, agilizando a consulta.',
        name: 'Dra. Ethel Mariam Voloch - CRM RJ 356597',
      },
      {
        img: 'assets/img/img_doctor_andre.jpg',
        message:
          'O site Vacina Certa nos proporciona mais agilidade e clareza na informação das vacinas para os pacientes, dessa forma os pais ficam cientes de suas diferenças, indicações e períodos de aplicação. A adesão à realização das vacinas com certeza é muito maior!',
        name: 'Dr. André Sérgio Malysz – CRM RS 25853',
      },
      {
        img: 'assets/img/img_doctor_julio.jpg',
        message:
          'Acho o Vacina Certa muito útil, bem visual, bom para o uso durante as consultas para melhor entendimento dos pais.',
        name: 'Dr. Júlio Carlos Reinbrecht - CRM RS 18231',
      },
    ];
    // tslint:enable: max-line-length

    return (
      <TestimonialListContainerStyled>
        <Grid>
          <FeaturedH2>Depoimentos</FeaturedH2>

          <Row>
            <Col xs={12}>
              <Carousel
                loop={true}
                auto={true}
                showNavigationButton={true}
                interval={14000}
                duration={300}
                width={1}
                scale={1}
                borderItemsXTranslation={0}
              >
                {testimonials.map((item, index) => (
                  <TestimonialRow
                    key={index}
                    img={item.img}
                    message={item.message}
                    name={item.name}
                    reverse={false}
                    hideDrop={true}
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
        </Grid>
        <TranslatedGridTopStyled />
      </TestimonialListContainerStyled>
    );
  };
  private renderForm = () => {
    const Element = Scroll.Element;

    return (
      <section>
        <Element name='signup' />
        <SignupForm
          onSubmit={this.onSubmit}
          loading={this.authenticationStore.loadingMap.get(SIGN_UP_MAP_KEY)}
          error={
            this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)
              ? this.authenticationStore.errorMessageMap.get(SIGN_UP_MAP_KEY)
              : undefined
          }
          translateUp={true}
        />
      </section>
    );
  };
}
