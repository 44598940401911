import { Color, FontSize, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const StyledModalContent = styled.div<{ $isLoading: boolean }>`
  position: relative;
  height: ${({ $isLoading }) => ($isLoading ? '250px' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledModalImage = styled.img`
  width: 100%;
  object-fit: contain;
  padding-top: ${Spacing.Large};
`;

export const StyledFallbackMessage = styled.p`
  text-align: center;
  font-size: ${FontSize.Small};
  color: ${Color.Alert};
`;

export const StyledLoadingContainer = styled.div`
  position: absolute;
`;
