// tslint:disable: max-line-length
import { Age, DefinedAge, MONTHS_AGE_BARRIER, MONTHS_IN_A_YEAR, NullAge } from '@app/model/age.model';
import { VaccineFamiliesByAge } from '@app/model/vaccine-families-by-age.model';
import * as last from 'lodash.last';
import * as orderBy from 'lodash.orderby';
import { action, computed, observable, reaction } from 'mobx';
import { persist } from 'mobx-persist';
import { Service } from 'typedi';
import { isNullOrUndefined } from 'util';
import {
  allProfilesMap,
  AppointmentRecommendation,
  AppointmentRecommendationVaccine,
  AppointmentStep,
  AppointmentVaccineAge,
  AppointmentVaccineTuple,
  PatientProfileKey,
  PatientProfileValue,
  QuickRecommendationForContext,
} from './appointment.store.model';
import { ImmunocompromisedComorbidities } from './immunocompromised-choice.utils';
import { VaccinePriority } from './priority-choice.utils';
import {
  AllRecommendationsQuery,
  AllVaccineFamiliesByAgeQuery,
  VaccineFamilyFieldsFragment,
} from '@app/resource/graphql/generated';
// tslint:enable: max-line-length

@Service()
export class AppointmentStore {
  @computed
  get selectedVaccinesForSelectedAge(): AppointmentRecommendationVaccine[] {
    return this.selectedVaccinesTuple
      .filter(vaccineByAge => this.isVaccineAgeForSelectedAge(vaccineByAge.age))
      .map(vaccineByAge => vaccineByAge.vaccine);
  }

  @computed
  get selectedVaccinesForPreviousAges(): AppointmentVaccineAge[] {
    const filteredVaccinesByAge = this.selectedVaccinesTuple.filter(vaccineByAge =>
      this.isVaccineAgeForPreviousAges(vaccineByAge.age),
    );
    const orderedByAge = orderBy(filteredVaccinesByAge, (item: AppointmentVaccineTuple) => item.age.minAge, 'desc');

    return orderedByAge;
  }

  get availableAges(): Age[] {
    return Array.from(allProfilesMap).reduce(
      (acc: Age[], curr: [PatientProfileKey, PatientProfileValue]) => acc.concat(curr[1].ageObj),
      [],
    );
  }

  @computed
  get availableMonthAgesForSelectedProfile(): Age[] {
    if (isNullOrUndefined(this.selectedProfileId)) {
      throw new Error('availableMonthAgesForSelectedProfile: !this.selectedProfile');
    }
    return allProfilesMap.get(this.selectedProfileId).ageObj.filter(item => item.type === 'month');
  }

  @computed
  get availableYearAgesForSelectedProfile(): Age[] {
    if (isNullOrUndefined(this.selectedProfileId)) {
      throw new Error('availableYearAgesForSelectedProfile: !this.selectedProfile');
    }
    return allProfilesMap.get(this.selectedProfileId).ageObj.filter(item => item.type === 'year');
  }

  @computed
  get availableAgesForSelectedProfile(): Age[] {
    return [...this.availableMonthAgesForSelectedProfile, ...this.availableYearAgesForSelectedProfile];
  }

  @computed
  get selectedAge(): Age {
    return this.availableAges[this.selectedAgeIndex] || new NullAge();
  }

  @computed
  get currentAppointmentStepNumber(): number {
    switch (this.currentAppointmentStep) {
      case AppointmentStep.ProfileChoice:
        return 1;
      case AppointmentStep.AgeChoice:
        return 1;
      case AppointmentStep.VaccineChoiceByAge:
        return 2;
      case AppointmentStep.VaccineChoiceBySpecialNeed:
        return 2;
      case AppointmentStep.Recommendation:
        return 3;
      default:
        return 1;
    }
  }

  @computed
  get lastExceptFirstVaccineFamiliesBelowSelectedAge(): VaccineFamiliesByAge[] {
    const ordered = orderBy(
      this.lastVaccineFamiliesBelowSelectedAge,
      (item: VaccineFamiliesByAge) => item.actualAge.value[1],
      'desc',
    );
    ordered.splice(0, 1);
    return ordered;
  }

  @computed
  get firstVaccineFamiliesBelowSelectedAge(): VaccineFamiliesByAge {
    return last(this.lastVaccineFamiliesBelowSelectedAge);
  }

  get lastVaccineFamiliesBelowSelectedAge(): VaccineFamiliesByAge[] {
    return this.allVaccineFamiliesBelowSelectedAge.filter(item => item.matchedAge.value[0] <= this.selectedAgeInMonths);
  }

  /**
   * get all vaccine families available for a given age.
   * For example, if the selected age is 18 months, then this method returns vaccine families for 18, 17, 16...0 months
   */
  private get allVaccineFamiliesBelowSelectedAge(): VaccineFamiliesByAge[] {
    if (!this.allVaccineFamiliesByAge.AllVaccineFamiliesByAge) {
      return [];
    }

    const rawFilteredList = this.allVaccineFamiliesByAge.AllVaccineFamiliesByAge.filter(
      vaccineFamiliesByAge => vaccineFamiliesByAge.months <= this.selectedAgeInMonths,
    );

    return rawFilteredList.map(item => ({
      matchedAge: this.findAvailableAgeForMonths(item.months),
      actualAge: this.buildActualAge(item.months),
      families: item.families,
    }));
  }

  private get selectedAgeInMonths(): number {
    if (!this.selectedAge) {
      return null;
    }
    return this.selectedAge.value[1];
  }

  @computed
  get allVaccineFamilies(): VaccineFamilyFieldsFragment[] {
    return this._allVaccineFamiliesByAge.AllVaccineFamiliesByAge.reduce((acc, curr) => {
      acc.push(...curr.families);
      return acc;
    }, [] as VaccineFamilyFieldsFragment[]);
  }
  get allVaccineFamiliesByAge() {
    return this._allVaccineFamiliesByAge;
  }
  set allVaccineFamiliesByAge(allVaccineFamiliesByAge: AllVaccineFamiliesByAgeQuery) {
    if (allVaccineFamiliesByAge && allVaccineFamiliesByAge.AllVaccineFamiliesByAge) {
      this._allVaccineFamiliesByAge = allVaccineFamiliesByAge;
      this.emptySelectedVaccinesToBeSortedIfPossible(this.selectedAge);
    }
  }
  set allRecommendations(allRecommendations: AllRecommendationsQuery) {
    this._allRecommendations = allRecommendations;
  }

  @observable
  hydrated: boolean;

  @persist
  @observable
  selectedProfileId: PatientProfileKey;

  @persist
  @observable
  selectedAgeIndex: number;

  @persist
  @observable
  selectedImmunocompromisedComorbidity: ImmunocompromisedComorbidities;

  @persist
  @observable
  selectedVaccinePriority: VaccinePriority;

  @persist
  @observable
  currentAppointmentStep: AppointmentStep = null;

  @persist('list')
  selectedVaccinesTuple = observable.array([] as AppointmentVaccineTuple[]);

  /**
   * These vaccines were selected their age is not certain. This happen when
   * you select vaccines byusing the "quick recommendation" feature
   */
  @persist('list')
  @observable
  selectedVaccinesToBeSorted: AppointmentRecommendationVaccine[] = [];

  @observable
  reset: boolean = false;

  @persist('object')
  @observable
  private _allVaccineFamiliesByAge: AllVaccineFamiliesByAgeQuery = {
    AllVaccineFamiliesByAge: [],
  };

  @persist('object')
  @observable
  private _allRecommendations: AllRecommendationsQuery = {
    Recommendations: [],
  };

  constructor() {
    reaction(() => this.reset, this.resetState);
  }

  getAllRecommendationsForCurrentContext(criteria: QuickRecommendationForContext) {
    const recommendations = this._allRecommendations.Recommendations;
    if (!recommendations) {
      return [];
    }
    switch (criteria) {
      case 'none':
        return recommendations;
      case 'profile':
        if (this.selectedProfileId === PatientProfileKey.Immunocompromised) {
          return recommendations ? recommendations.filter(item => item.vaccines.some(v => v.specialNeed)) : [];
        } else if (!isNullOrUndefined(this.selectedProfileId)) {
          const ages = allProfilesMap.get(this.selectedProfileId).ageObj.map(age => age.getAgeText());
          return recommendations ? recommendations.filter(item => ages.includes(item.age)) : [];
        }
      case 'age':
        if (!isNullOrUndefined(this.selectedAge)) {
          const age = this.selectedAge.getAgeText();
          return recommendations ? recommendations.filter(item => age === item.age) : [];
        }
      case 'comorbidity': {
        const selectedCommorbidity = this.selectedImmunocompromisedComorbidity;
        return recommendations
          ? recommendations.filter(item => item.vaccines.some(v => v.specialNeed === selectedCommorbidity))
          : [];
      }
      case 'comorbidity_priority': {
        const selectedCommorbidity = this.selectedImmunocompromisedComorbidity;
        const selectedPriority = this.selectedVaccinePriority;
        return recommendations
          ? recommendations.filter(item =>
              item.vaccines.some(v => v.specialNeed === selectedCommorbidity && v.priority === selectedPriority),
            )
          : [];
      }
      default:
        throw new Error(`2) case not handled: ${criteria}`);
    }
  }

  @action
  selectedAgeChanged = (age: Age) => {
    if (!age) {
      this.selectedAgeIndex = -1;
      return;
    }
    this.selectedAgeIndex = this.availableAges.findIndex(
      item => item.minAge === age.minAge && item.maxAge === age.maxAge && item.type === age.type,
    );
  };

  @action
  selectedImmunocompromisedComorbidityChanged = (comorbidity: ImmunocompromisedComorbidities) => {
    this.selectedImmunocompromisedComorbidity = comorbidity;
  };

  @action
  selectedVaccinePriorityChanged = (priority: VaccinePriority) => {
    this.selectedVaccinePriority = priority;
  };

  @action
  selectedProfileChanged = (profile: PatientProfileKey) => {
    this.selectedProfileId = profile;
  };

  @action
  selectedRecommendationChanged = (recommendation: AppointmentRecommendation) => {
    this.selectedVaccinesTuple.clear();

    this.selectedVaccinesToBeSorted = recommendation.vaccines;

    const selectedAge = this.availableAges.find(item => item.getAgeText() === recommendation.age);
    this.emptySelectedVaccinesToBeSortedIfPossible(selectedAge);

    const specialNeedVaccine = recommendation.vaccines.find(vaccine => vaccine.specialNeed);
    if (!recommendation.age && specialNeedVaccine) {
      this.selectedAgeChanged(selectedAge);
      const specialNeed = specialNeedVaccine.specialNeed as ImmunocompromisedComorbidities;
      this.selectedImmunocompromisedComorbidityChanged(specialNeed);
      const priority = specialNeedVaccine.priority;
      this.selectedVaccinePriorityChanged(priority);
      this.selectedProfileId = allProfilesMap.get(PatientProfileKey.Immunocompromised).id;
      return;
    }

    if (!selectedAge) {
      throw new Error(`age not found: ${JSON.stringify(recommendation)}`);
    }
    const selectedProfile = Array.from(allProfilesMap)
      .map((value: [PatientProfileKey, PatientProfileValue]) => value[1])
      .find((profile: PatientProfileValue) => profile.ageObj.find(age => age.getAgeText() === recommendation.age));

    this.selectedProfileId = selectedProfile.id;
    this.selectedAgeChanged(selectedAge);
    return;
  };

  @action
  selectVaccine(age: Age, vaccine: AppointmentRecommendationVaccine, familyId: string): void {
    const selectedVaccineIndex = this.selectedVaccinesTuple.findIndex(
      item => item.familyId === familyId && item.age.getAgeText() === age.getAgeText(),
    );
    if (selectedVaccineIndex > -1) {
      this.selectedVaccinesTuple[selectedVaccineIndex].vaccine = vaccine;
    } else {
      this.selectedVaccinesTuple.push({ age, vaccine, familyId });
    }
  }

  @action
  unselectVaccine(age: Age, vaccine: AppointmentRecommendationVaccine, familyId: string): void {
    const toBeRemoved = this.selectedVaccinesTuple.find(vaccineTuple => {
      const vaccineIsTheSame = vaccineTuple.vaccine.id === vaccine.id;
      const familiIsTheSame = vaccineTuple.familyId === familyId;
      const ageIsDefined = !isNullOrUndefined(age);
      if (ageIsDefined) {
        const ageIsTheSame =
          vaccineTuple.age &&
          vaccineTuple.age.maxAge === age.maxAge &&
          vaccineTuple.age.minAge === age.minAge &&
          vaccineTuple.age.type === age.type;
        return vaccineIsTheSame && familiIsTheSame && ageIsTheSame;
      } else {
        return vaccineIsTheSame && familiIsTheSame;
      }
    });
    this.selectedVaccinesTuple.remove(toBeRemoved);
  }

  findAgeByText(text: string): Age {
    const age = this.availableAges.find(a => a.getAgeText() === text);
    if (!age) {
      throw new Error(`cant find age for text: ${text}`);
    }
    return age;
  }

  private findAvailableAgeForMonths(months: number): Age {
    return this.availableAges.filter(age => months >= age.value[0] && months <= age.value[1])[0];
  }

  private emptySelectedVaccinesToBeSortedIfPossible(selectedAge: Age) {
    const specialNeedScenario = !selectedAge && this.selectedVaccinesToBeSorted.length;
    const otherScenario = selectedAge && this.selectedVaccinesToBeSorted.length;
    if (specialNeedScenario) {
      this.selectedVaccinesToBeSorted.forEach(vaccine =>
        this.selectVaccine(new NullAge(), vaccine, String(vaccine.vaccineFamilyId)),
      );
    } else if (otherScenario) {
      /**
       * the goal of the following code is call this.selectVaccine with the right
       * age, vaccine, vaccineFamily and then set selectedVaccinesToBeSorted to []
       */
      const vaccineFamiliesByAge = this._allVaccineFamiliesByAge.AllVaccineFamiliesByAge || [];
      const selectedAgeVaccineFamilyIndex =
        vaccineFamiliesByAge
          // ps: why not (item.months === this.selectedAgeInMonths)? there are no
          // vaccines for the selected age, for instance, for 16 months, we show 16
          // month vaccines
          .findIndex(item => item.months > this.selectedAgeInMonths) - 1;
      this.selectedVaccinesToBeSorted.forEach(vaccine => {
        let found = false;
        let indexToSearch =
          selectedAgeVaccineFamilyIndex >= 0 ? selectedAgeVaccineFamilyIndex : vaccineFamiliesByAge.length - 1;
        while (!found) {
          if (indexToSearch < 0) {
            console.error(
              `appointment.store.ts ~ not found family for vaccine ${JSON.stringify({ vaccine, age: selectedAge })}`,
            );
            found = true;
            this.selectVaccine(selectedAge, vaccine, String(vaccine.vaccineFamilyId));
            continue;
          }

          const familyIndex = vaccineFamiliesByAge[indexToSearch].families.findIndex(
            f => f.id === String(vaccine.vaccineFamilyId),
          );
          if (familyIndex !== -1) {
            const age = this.findAvailableAgeForMonths(vaccineFamiliesByAge[indexToSearch].months);
            this.selectVaccine(age, vaccine, String(vaccine.vaccineFamilyId));
            found = true;
          }
          indexToSearch--;
        }
      });
    }
    this.selectedVaccinesToBeSorted = [];
  }

  private buildActualAge(months: number): Age {
    if (months <= MONTHS_AGE_BARRIER) {
      return new DefinedAge(months, months, 'month');
    } else {
      return new DefinedAge(months / MONTHS_IN_A_YEAR, months / MONTHS_IN_A_YEAR, 'year');
    }
  }

  // TODO: dispose reaction
  private resetState = () => {
    if (this.reset) {
      this.selectedAgeIndex = null;
      this.selectedImmunocompromisedComorbidity = null;
      this.selectedVaccinePriority = null;
      this.currentAppointmentStep = AppointmentStep.AgeChoice;
      this.selectedVaccinesTuple.clear();
      this.selectedVaccinesToBeSorted = [];
    }

    this.reset = false;
  };

  private isVaccineAgeForSelectedAge(vaccineAge: Age) {
    return vaccineAge.value[0] >= this.selectedAge.value[0] && vaccineAge.value[1] <= this.selectedAge.value[1];
  }

  private isVaccineAgeForPreviousAges(vaccineAge: Age) {
    return vaccineAge.value[1] < this.selectedAge.value[0];
  }
}
