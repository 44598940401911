import * as React from 'react';
import { FieldCaption } from './field-caption.component';
import { FieldValidatorStyled } from './field-validator.component.style';
import { ValidationError, Validator } from './validators';
import { Field, FieldPropsInterface, FieldStateInterface } from './field.component';

export interface FieldValidatorProps extends FieldPropsInterface {
  validators?: Validator[];
  validatorPlaceholder?: string;
  defaultValue?: string;
}

export class FieldValidator extends Field<FieldValidatorProps, any> {
  // private control: Field<FieldPropsInterface, FieldStateInterface>;

  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue || '', errors: [] };
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.validate(this.state.value);
    }
  }

  render() {
    const wrapped = this.injectPropsToChildren();

    return (
      <FieldValidatorStyled>
        {wrapped}
        <FieldCaption
          errors={this.state.errors}
          showAll={false}
          validationPlaceholder={this.props.validatorPlaceholder}
        />
      </FieldValidatorStyled>
    );
  }

  validate(value: string): Promise<any> {
    const errors: ValidationError[] = [];
    // Pass it to each validator
    if (this.props.validators && this.props.validators.length > 0) {
      for (const validator of this.props.validators) {
        // Add to validation array if errors
        if (!validator.validationFn(value || '')) {
          errors.push({
            errorName: validator.errorName,
            errorMessage: validator.errorMessage,
          });
        }
      }
    }
    return new Promise(resolve => {
      this.setState({ errors }, resolve);
    });
  }

  private handleAttach = (_component: Field<FieldPropsInterface, FieldStateInterface>) => {
    // this.control = component ;
  };

  private handleValueChange = (onValueChange: any, value: string) => {
    if (onValueChange) {
      onValueChange(value);
    }
    if (this.state.touched) {
      this.validate(value);
    }
    this.setState({ value });
  };

  private handleFocusChange = (focus: boolean, value: string) => {
    if (!focus) {
      this.validate(value);
      this.setState({ touched: true });
    }
  };

  private injectPropsToChildren() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      const key = child.key || 'FieldValidator_' + i;

      if (child.props) {
        if (this.props.name && child.props && child.props.name) {
          console.warn(`Nested element name warning.
          Prop name='${child.props.name}' will be replaced with name='${this.props.name}'`);
        }

        return React.cloneElement(child, {
          key: child.key || key,
          name: this.props.name || child.props.name,
          attachToGroup: this.handleAttach,
          onValueChange: this.handleValueChange.bind(undefined, child.props.onValueChange),
          onFocusChange: this.handleFocusChange,
        });
      }
      return child;
    });

    return wrapped;
  }
}
