import { CookieConsentContainer } from '@app/components/cookie-consent';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AccountStore } from '@app/modules/account/account.store';
import { ADMINISTRATION_PLACES_ROUTE } from '@app/modules/administration-places/administration-places.route';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { ForgotPasswordData } from '@app/modules/authentication/forgot-password.data';
import { HOME_PATH } from '@app/modules/home/home.path';
import { Button, LinkButton } from '@components/atm.button';
import { Logo } from '@components/atm.logo';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { Body, Display, InputLabel, Label } from '@components/atm.typography';
import { HeaderLogoLinkStyled } from '@components/mol.header/header.component.style';
import { PasswordLength } from '@components/obj.constants';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { Modal } from '@components/obj.modal';
import { action, extendObservable, reaction } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { AuthenticationStore, FORGOT_PASSWORD_MAP_KEY, LOGIN_MAP_KEY } from './authentication.store';
import { LoginInput } from '@app/resource/graphql/base-schema';
import { ContentWrapper } from '@components/obj.content-wrapper';
import { SIGNUP_PATH } from '@app/modules/authentication/signup.route';
import { HeaderSmart } from '@app/components/header.smart-component';
import { isVacinaTheme } from 'config/theme';

export interface LoginViewProps {
  onSubmit: (data: LoginInput) => void;
}

// Hotjar
declare var hj: any;

@Observer()
export class LoginView extends React.Component<LoginViewProps> {
  private reactionDisposers = [];
  private email: string = '';
  private forgotPasswordVisible = false;

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private router?: Router = Container.get(Router);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
    extendObservable(this, {
      forgotPasswordVisible: false,
      setForgotPasswordVisible: action,
    });
    this.reactionDisposers.push(reaction(() => this.authenticationStore.loggedIn, this.onLogin));
    this.reactionDisposers.push(
      reaction(() => this.authenticationStore.successMap.get(FORGOT_PASSWORD_MAP_KEY), this.onForgotPasswordSuccess),
    );
  }

  componentDidMount() {
    this.analyticsService.pageView('login');
    const showForgotPassword = new URLSearchParams(window.location.search).get('forgot');
    if (showForgotPassword) {
      this.goToForgotPassword();
    }
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    return (
      <div>
        <HeaderSmart home={false} logged={this.authenticationStore?.loggedIn || false} />
        {isVacinaTheme && <CookieConsentContainer />}
        <ContentWrapper>
          <Form onSubmit={this.handleSubmit}>
            <Grid>
              <Row $middle='xs'>
                <Col xs={12}>
                  <Row center='xs'>
                    <Col xs={12} sm={6}>
                      <Row center='xs'>
                        <Col>
                          <If cond={process.env.THEME === 'vacina'}>
                            <HeaderLogoLinkStyled href={HOME_PATH}>
                              <Logo colored={true} $mt={true} />
                            </HeaderLogoLinkStyled>
                          </If>
                        </Col>
                      </Row>
                      <Row $mb={true}>
                        <Col xs={12} sm={11}>
                          <Display>Bem-vindo(a)!</Display>
                          <Body>
                            O acesso à área logada é exclusivo para médicos(as). Por favor, faça seu login para
                            continuar.
                          </Body>
                        </Col>
                      </Row>
                      <Row start='xs' $mb={true}>
                        <Col xs={12}>
                          <InputLabel>E-mail</InputLabel>
                          <FieldValidator
                            name='email'
                            validators={[
                              Validators.Required('Campo obrigatório'),
                              Validators.EmailRegex('O e-mail é inválido'),
                            ]}
                          >
                            <TextField type='text' onValueChange={this.onEmailChanged} />
                          </FieldValidator>
                        </Col>
                      </Row>
                      <Row start='xs' $mb={true}>
                        <Col xs={12}>
                          <InputLabel>Senha</InputLabel>
                          <FieldValidator name='password' validators={[Validators.Required('Campo obrigatório')]}>
                            <TextField type='password' maxLength={PasswordLength.Max} />
                          </FieldValidator>
                        </Col>
                      </Row>
                      <Row $align='right' $mb={true}>
                        <Col xs={12}>
                          <LinkButton onClick={this.goToForgotPassword}>Esqueceu a senha?</LinkButton>
                        </Col>
                      </Row>
                      <If cond={this.authenticationStore.errorMap.get(LOGIN_MAP_KEY)}>
                        <Row start='xs' $mb={true}>
                          <Col xs={12}>
                            <Notification
                              type='error'
                              message='E-mail ou senha incorretos. Por favor, tente novamente.'
                            />
                          </Col>
                        </Row>
                      </If>
                      <If
                        cond={
                          this.authenticationStore.successMap.get(FORGOT_PASSWORD_MAP_KEY) &&
                          !this.authenticationStore.errorMap.get(LOGIN_MAP_KEY) &&
                          !this.authenticationStore.errorMap.get(FORGOT_PASSWORD_MAP_KEY)
                        }
                      >
                        <Row start='xs' $mb={true}>
                          <Col xs={12}>
                            <Notification
                              type='success'
                              message={this.authenticationStore.successMessageMap.get(FORGOT_PASSWORD_MAP_KEY)}
                            />
                          </Col>
                        </Row>
                      </If>

                      <Button
                        kind='primary'
                        type='submit'
                        expanded={true}
                        loading={this.authenticationStore.loadingMap.get(LOGIN_MAP_KEY)}
                      >
                        Entrar
                      </Button>

                      <Row center='xs' $mb={true}>
                        <Col>
                          <Label>Não tem conta?</Label>
                          <LinkButton onClick={this.goToSignUp}>Cadastre-se.</LinkButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Form>
        </ContentWrapper>
        <Modal opened={this.forgotPasswordVisible} onClose={this.closeModal} small={true}>
          <ForgotPasswordData email={this.email} {...(this.props as any)} />
        </Modal>
      </div>
    );
  }

  onLogin = () => {
    if (this.authenticationStore.loggedIn) {
      if (this.authenticationStore.lastUrlPathWithToken) {
        this.router.push(this.authenticationStore.lastUrlPathWithToken);
      } else if (!this.accountStore.routeAfterLogin) {
        this.router.push(process.env.THEME === 'vacina' ? APPOINTMENT_PATH : HOME_PATH);
      } else if (this.accountStore.routeAfterLogin === ADMINISTRATION_PLACES_ROUTE) {
        this.router.push(ADMINISTRATION_PLACES_ROUTE);
        this.accountStore.routeAfterLogin = null;
      } else {
        this.router.push(process.env.THEME === 'vacina' ? APPOINTMENT_PATH : HOME_PATH);
      }
    }
  };

  private handleSubmit = formData => {
    this.analyticsService.event('login_entrar', 'click');
    if (formData.errors.length === 0) {
      this.props.onSubmit({
        email: formData.data.email,
        password: formData.data.password,
        rememberMe: true,
      });
    } else {
      if (typeof hj !== 'undefined') {
        hj('formSubmitFailed');
      }
    }
  };

  private onEmailChanged = (value: string) => {
    this.email = value;
  };

  private goToSignUp = () => {
    this.router.push(SIGNUP_PATH);
    this.analyticsService.event('login_cadastrar', 'click');
  };

  private goToForgotPassword = () => {
    this.forgotPasswordVisible = true;
    this.analyticsService.event('login_esqueci_senha', 'click');
  };

  private closeModal = () => {
    this.forgotPasswordVisible = false;
  };

  private onForgotPasswordSuccess = () => {
    if (
      !this.authenticationStore.errorMap.get(FORGOT_PASSWORD_MAP_KEY) &&
      this.authenticationStore.successMap.get(FORGOT_PASSWORD_MAP_KEY)
    ) {
      this.forgotPasswordVisible = false;
    }
  };
}
