import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ADMINISTRATION_PLACES_ROUTE } from '@app/modules/administration-places/administration-places.route';
import { ApplicationStore } from '@app/modules/app/application.store';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { CHANGE_PASSWORD_PATH } from '@app/modules/authentication/change-password.route';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { OPT_OUT_USER_PATH } from '@app/modules/authentication/opt-out-user.route';
import { RESET_DOCTOR_PASSWORD_PATH } from '@app/modules/authentication/reset-doctor-password.route';
import { RESET_DOCTOR_EMAIL_PATH } from '@app/modules/authentication/reset-email-password.route';
import { UPDATE_USER_PATH } from '@app/modules/authentication/update-user.route';
import { CONTACT_PATH } from '@app/modules/contact/contact.route';
import { HOME_PATH } from '@app/modules/home/home.path';
import { RECOMMENDATION_HISTORY_PATH } from '@app/modules/recommendation-history/recommendation-history.route';
import { IMUNOCARD_APPOINTMENT_PATH } from '@app/modules/appointment-imunocard/appointment-imunocard.route';
import { HeaderImunocard } from '@components/mol.header/header-imunocard.component';
import { Header } from '@components/mol.header/header.component';
import { MenuItems } from '@components/mol.header/header.model';
import { isVacinaTheme } from 'config/theme';
import * as React from 'react';
import { Container } from 'typedi';
import { DEFAULT_RECOMMENDATION_PATH } from '@app/modules/default-recommendation/default-recommendation.route';
import { FREQUENT_QUESTIONS_PATH } from '@app/modules/frequent-questions/frequent-questions.route';

export interface HeaderSmartProps {
  selectedItem?: MenuItems;
  home?: boolean;
  logged?: boolean;
  /** set this to true to show the "Choose calendar" button forcefully */
  forceChooseCalendarButton?: boolean;
  onSegmentControlIndex?: (index: number) => void;
}

interface HeaderSmartState {
  hamburguerMenuOpened: boolean;
  recommendationMobileMenuOpened: boolean;
}

export class HeaderSmart extends React.Component<HeaderSmartProps, HeaderSmartState> {
  private router?: Router = Container.get(Router);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props: HeaderSmartProps) {
    super(props);
    this.state = {
      hamburguerMenuOpened: false,
      recommendationMobileMenuOpened: false,
    };
  }

  render() {
    return isVacinaTheme ? (
      <Header
        selectedItem={this.props.selectedItem}
        home={this.props.home}
        logged={this.props.logged}
        role={this.authenticationStore.isAdminUser() ? 'admin' : 'doctor'}
        hamburguerMenuOpened={this.state.hamburguerMenuOpened}
        onHeaderLogoClick={this.handleHeaderLogoClick}
        onAdmPlacesClick={this.handleAdmPlacesClick}
        onCasaDeVacinasClick={this.handleCasaDeVacinasClick}
        onConectadosClick={this.handleConectadosClick}
        onSignOutClick={this.handleSignOutClick}
        onSignInClick={this.handleSignInClick}
        onStartAppointmentClick={this.handleStartAppointmentClick}
        onResetDoctorPasswordClick={this.handleResetDoctorPasswordClick}
        onResetDoctorEmailClick={this.handleResetDoctorEmailClick}
        onOptOutUserClick={this.handleOptOutUserClick}
        onChangePasswordClick={this.handleChangePasswordClick}
        onUpdateUserClick={this.handleUpdateUserClick}
        onContactClick={this.handleContactClick}
        onHamburguerIconClick={this.handleHamburguerIconClick}
        forceChooseCalendarButton={this.props.forceChooseCalendarButton}
      />
    ) : (
      <HeaderImunocard
        selectedItem={this.props.selectedItem}
        home={this.props.home}
        logged={this.props.logged}
        role={this.authenticationStore.isAdminUser() ? 'admin' : 'doctor'}
        hamburguerMenuOpened={this.state.hamburguerMenuOpened}
        onHeaderLogoClick={this.handleHeaderLogoClick}
        onAdmPlacesClick={this.handleAdmPlacesClick}
        onSignOutClick={this.handleSignOutClick}
        onSignInClick={this.handleSignInClick}
        onResetDoctorPasswordClick={this.handleResetDoctorPasswordClick}
        onResetDoctorEmailClick={this.handleResetDoctorEmailClick}
        onOptOutUserClick={this.handleOptOutUserClick}
        onChangePasswordClick={this.handleChangePasswordClick}
        onUpdateUserClick={this.handleUpdateUserClick}
        onContactClick={this.handleContactClick}
        onHamburguerIconClick={this.handleHamburguerIconClick}
        forceChooseCalendarButton={this.props.forceChooseCalendarButton}
        name={this.authenticationStore?.name}
        recommendationMobileMenuOpened={this.state.recommendationMobileMenuOpened}
        onRecommendationMobileMenuClick={this.handleRecommendationMobileMenuClick}
        onActiveRecommendationsClick={this.handleActiveRecommendationsClick}
        onVaccineRecommendationClick={this.handleVaccineRecommendationClick}
        onSegmentControlIndex={this.props.onSegmentControlIndex}
        onDefaultRecommendationClick={this.handleDefaultRecommendationClick}
        onFrequentQuestionsClick={this.handleFrequentQuestions}
      />
    );
  }

  private handleSignInClick = () => {
    this.router.push(LOGIN_PATH);
    this.analyticsService.event('menu_entrar', 'click');
    this.closeHamburguerMenu();
  };

  private handleHeaderLogoClick = () => {
    this.router.push(HOME_PATH);
    this.analyticsService.event('menu_logo', 'click');
    this.closeHamburguerMenu();
  };

  private handleAdmPlacesClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.centros;
    this.router.push(ADMINISTRATION_PLACES_ROUTE);
    this.analyticsService.event('menu_centros', 'click');
  };

  private handleCasaDeVacinasClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.casaDeVacinas;
    this.analyticsService.event('menu_casa_de_vacinas', 'click');
    window.open('https://www.casadevacinasgsk.com.br/', '_blank');
  };

  private handleConectadosClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.conectados;
    this.analyticsService.event('menu_conectados', 'click');
    window.open('https://conectadosgsk.com.br/?utm_source=vacinacerta&utm_medium=site', '_blank');
  };

  private handleSignOutClick = () => {
    this.authenticationStore.logOut();
    this.router.push(HOME_PATH);
    this.analyticsService.event('menu_sair', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleStartAppointmentClick = () => {
    if (this.props.logged) {
      this.router.push(APPOINTMENT_PATH);
    } else {
      this.router.push(LOGIN_PATH);
    }
    this.analyticsService.event('menu_iniciar_consulta', 'click');
    this.closeHamburguerMenu();
  };

  private handleUpdateUserClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.router.push(UPDATE_USER_PATH);
    this.analyticsService.event('menu_atualizar_user', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleChangePasswordClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.router.push(CHANGE_PASSWORD_PATH);
    this.analyticsService.event('menu_alterar_senha', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleResetDoctorPasswordClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.router.push(RESET_DOCTOR_PASSWORD_PATH);
    this.analyticsService.event('menu_reset_senha_doutro', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleResetDoctorEmailClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.router.push(RESET_DOCTOR_EMAIL_PATH);
    this.analyticsService.event('menu_reset_senha_doutro', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleOptOutUserClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.optOut;
    this.router.push(OPT_OUT_USER_PATH);
    this.analyticsService.event('menu_opt_out_user', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleContactClick = () => {
    this.router.push(CONTACT_PATH);
    this.analyticsService.event('menu_contato', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleFrequentQuestions = () => {
    this.router.push(FREQUENT_QUESTIONS_PATH);
    this.analyticsService.event('menu_central_de_ajuda', 'click');
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleHamburguerIconClick = () => {
    this.toggleHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleActiveRecommendationsClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.recomendacoes;
    this.router.push(RECOMMENDATION_HISTORY_PATH);
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleVaccineRecommendationClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.recomendacoes;
    this.router.push(IMUNOCARD_APPOINTMENT_PATH);
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private handleDefaultRecommendationClick = () => {
    this.applicationStore.currentMenuItem = MenuItems.recomendacoes;
    this.router.push(DEFAULT_RECOMMENDATION_PATH);
    this.closeHamburguerMenu();
    this.closeRecommendationMobileMenu();
  };

  private toggleHamburguerMenu() {
    this.analyticsService.event('menu_hambuguer_toggle', 'click');
    this.setState({ hamburguerMenuOpened: !this.state.hamburguerMenuOpened });
  }

  private closeHamburguerMenu() {
    this.setState({ hamburguerMenuOpened: false });
  }

  private handleRecommendationMobileMenuClick = () => {
    this.toogleRecommendationMobileMenu();
  };

  private toogleRecommendationMobileMenu() {
    this.setState({ recommendationMobileMenuOpened: !this.state.recommendationMobileMenuOpened });
  }

  private closeRecommendationMobileMenu() {
    this.setState({ recommendationMobileMenuOpened: false });
  }
}
