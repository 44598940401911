// tslint:disable-next-line
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^[0-9]{11}$/i;
export const BIRTH_DATE_REGEX = /^((0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/](19|20)[0-9]{2})*$/;
export const ZIPCODE_REGEXP = /^([0-9]{5}[-][0-9]{3})$/i;
export const CRM_REGEX = /^\d+$/;

export function isEmailValid(email): boolean {
  return EMAIL_REGEX.test(email);
}

export function isCrmValid(crm): boolean {
  return CRM_REGEX.test(crm);
}

export function isPhoneValid(phone?: string): boolean {
  return PHONE_REGEX.test(phone ? phone.replace(/[^0-9]/g, '') : phone);
}

export function isBirthDateValid(date): boolean {
  return BIRTH_DATE_REGEX.test(date);
}

export function isZipCodeValid(zipcode): boolean {
  return ZIPCODE_REGEXP.test(zipcode);
}

export function isPasswordValid(password): boolean {
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

  return [hasLowercase, hasUppercase, hasNumber, hasSpecial].filter(Boolean).length >= 3;
}
