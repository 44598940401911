import { isVacinaTheme } from 'config/theme';

enum BrandColor {
  DarkPurple = '#4F2748',
  Purple = '#BA54A1',
  Yellow = '#FCC74B',
  Red = '#DA4453',
  Pink = '#DD6386',
  Green = '#00C889',
  Beige = '#FEF5E2',
  LightPurple = '#FBF6FA',
}

enum ImunocardBrandColor {
  Primary = '#354F9A',
  PrimarySoft = '#5874C6',
  CallToAction = '#F8CFB9',
  CallToActionStrong = '#F29F73',
  CallToActionMedium = '#F4AF8A',
  Error = '#EF9A9E',
  Success = '#29A567',
  Acessory = '#F2F4F4',
  Acessory2 = '#283B8F',
  Acessory3 = '#FDF2EC',
  AccessoryDark = '#11193C',
}

// We used class rather than enums since enums don't support computed values
export class Color {
  public static readonly Primary = isVacinaTheme ? BrandColor.Purple : ImunocardBrandColor.Primary;
  public static readonly PrimarySoft = isVacinaTheme ? BrandColor.Purple : ImunocardBrandColor.PrimarySoft;
  public static readonly Secondary = isVacinaTheme ? BrandColor.DarkPurple : ImunocardBrandColor.AccessoryDark;
  public static readonly CallToAction = isVacinaTheme ? BrandColor.Yellow : ImunocardBrandColor.CallToAction;
  public static readonly Alert = isVacinaTheme ? BrandColor.Red : ImunocardBrandColor.Error;
  public static readonly Success = isVacinaTheme ? BrandColor.Green : ImunocardBrandColor.Success;
  public static readonly Accessory = isVacinaTheme ? BrandColor.Pink : ImunocardBrandColor.CallToActionStrong;
  public static readonly Accessory2 = isVacinaTheme ? BrandColor.Pink : ImunocardBrandColor.Acessory2;
  public static readonly Info = isVacinaTheme ? BrandColor.Pink : ImunocardBrandColor.CallToActionMedium;
  public static readonly BackgroundPrimary = isVacinaTheme ? BrandColor.Beige : ImunocardBrandColor.Acessory;
  public static readonly BackgroundSecondary = BrandColor.LightPurple;

  public static readonly Neutral = 'gray';
  public static readonly NeutralGrayXSoft = '#E5E9EA';
  public static readonly NeutralGrayStrong = '#2E3233';
  public static readonly NeutralGrayMedium = '#99A6AB';
  public static readonly NeutralStrong = '#222222';

  public static readonly Black = 'black';
  public static readonly White = 'white';

  public static readonly GrayXLight = '#F2F2F2';
  public static readonly GrayLight = '#E5E5E5';
  public static readonly Gray = '#B3B3B3';
  public static readonly GrayDark = '#686868';
  public static readonly GrayXDark = '#333333';

  public static readonly LightPink = '#FBF6FA';
  public static readonly Acessory3 = ImunocardBrandColor.Acessory3;
  public static readonly Acessory5 = '#D2E8F2';
  public static readonly WarningSoft = '#FEEDB4';
  public static readonly WarningStrong = '#65541B';
  public static readonly FeedbackError = '#892E38';
  public static readonly FeedbackErrorSoft = '#FADBDF';
}

enum VacinaFontFamily {
  Primary = 'Linotte-SemiBold, "Helvetica Neue", Helvetica, sans-serif',
  Secondary = 'Lato, "Helvetica Neue", Helvetica, sans-serif',
}

enum ImunocardFontFamily {
  Primary = 'Kanit, "Open Sans"',
  Secondary = 'Kanit, "Open Sans"',
}

export class FontFamily {
  public static readonly Primary = isVacinaTheme ? VacinaFontFamily.Primary : ImunocardFontFamily.Primary;
  public static readonly Secondary = isVacinaTheme ? VacinaFontFamily.Secondary : ImunocardFontFamily.Secondary;
}

export enum FontWeight {
  Bolder = 'bolder',
  Bold = 'bold',
  Normal = 'normal',
  Lighter = 'lighter',
}

export enum FontSize {
  XSmall = '14px',
  Small = '16px',
  Medium = '20px',
  Large = '24px',
  XLarge = '32px',
  XXLarge = '56px',
}

export enum Spacing {
  XSmall = '4px',
  Small = '8px',
  Medium = '12px',
  Large = '16px',
  XLarge = '24px',
  XXLarge = '32px',
  XXXLarge = '56px',
}

export const Navbar = {
  a: '1',
};

export const PasswordLength = {
  Min: 12,
  Max: 18,
};

export const Border = {
  radius: isVacinaTheme ? '22px' : '8px',
  radiusLarge: '16px',
};
