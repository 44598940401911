import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ListCategoriesRecommendationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListCategoriesRecommendationQuery = {
  __typename?: 'Queries';
  ListCategoriesRecommendation?: {
    __typename?: 'ListCategoriesRecommendationType';
    categories?: Array<{
      __typename?: 'CategoryRecommendationType';
      id: string;
      category?: string | null;
    } | null> | null;
  } | null;
};

export const ListCategoriesRecommendationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCategoriesRecommendation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ListCategoriesRecommendation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ListCategoriesRecommendationType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListCategoriesRecommendationType' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ListCategoriesRecommendationType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCategoriesRecommendationQuery, ListCategoriesRecommendationQueryVariables>;
