import { InputCaption } from '@components/atm.typography';
import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { Grid as FlexGrid} from 'react-styled-flexboxgrid';
import { isVacinaTheme } from 'config/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FooterStyled = styled.footer`
  padding: ${Spacing.XXXLarge} 0 0 0;
  background-color: ${isVacinaTheme ? Color.White : Color.Secondary};
`;

export const FooterPartenersStyled = styled.img`
  display: inline-block;
  height: 28px;
  vertical-align: bottom;
  margin-top: ${Spacing.Small};
`;

export const FooterPoweredFontStyled = styled(InputCaption)`
  color: ${isVacinaTheme ? Color.Primary : Color.White};
  text-align: left;
  display: block;
  margin-top: 0;
  margin-right: ${Spacing.Small};
`;

export const FooterGridStyled = styled(FlexGrid)`
  @media (min-width: 768px) {
    margin-left: 135px;
  }
`;

export const FooterIconStyled = styled(FontAwesomeIcon)`
  margin-left: ${Spacing.Small};
`;
