import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import * as React from 'react';
import { Route } from 'react-router';
import { RecommendationConfirmationView } from './recommendation-confirmation.view';
import { isVacinaTheme } from 'config/theme';

export const RECOMMENDATION_CONFIRMATION_PATH = '/confirmacao-da-recomendacao';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Confirmação da Recomendação`,
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Confirmação da recomendação de vacinação no Imunocard',
    },
  ],
};

const RecommendationConfirmation = SEO(config)(RecommendationConfirmationView);

export const RecommendationConfirmationRoute = (
  <Route
    path={RECOMMENDATION_CONFIRMATION_PATH}
    exact={true}
    component={RecommendationConfirmation}
    key='recommendation-confirmation'
  />
);
