import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { isVacinaTheme } from 'config/theme';

export const LineSeparatorStyled = styled.div`
  border: 1px solid ${isVacinaTheme ? Color.GrayLight : Color.NeutralGrayXSoft};
  position: relative;
  margin-top: ${isVacinaTheme ? Spacing.XLarge : Spacing.XSmall};
  margin-bottom: ${isVacinaTheme ? Spacing.XLarge : Spacing.XSmall};
  height: 1px;
  width: 100%;
  background-color: ${Color.GrayLight};
  @media print and (max-width: 148mm) {
    border: 0;
    height: 0;
    margin-top: ${isVacinaTheme ? Spacing.XLarge : Spacing.XSmall};
    margin-bottom: 0;
  }
`;
