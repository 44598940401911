import styled, { keyframes } from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const shake = keyframes`
0% {
  transform: translate(2px, 1px) rotate(0deg);
};
10% {
  transform: translate(-1px, -2px) rotate(-1deg);
};
20% {
  transform: translate(-3px, 0px) rotate(1deg);
};
30% {
  transform: translate(0px, 2px) rotate(0deg);
};
40% {
  transform: translate(1px, -1px) rotate(1deg);
};
50% {
  transform: translate(-1px, 2px) rotate(-1deg);
};
60% {
  transform: translate(-3px, 1px) rotate(0deg);
};
70% {
  transform: translate(2px, 1px) rotate(-1deg);
};
80% {
  transform: translate(-1px, -1px) rotate(1deg);
};
90% {
  transform: translate(2px, 2px) rotate(0deg);
};
100% {
  transform: translate(1px, -2px) rotate(-1deg);
};
`;

export const TooltipStyled = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const TooltipTextStyled = styled.div<{ $opened: boolean }>`
  max-width: 350px;
  background-color: ${Color.Secondary};
  text-align: center;
  border-radius: 6px;
  border-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: ${Spacing.Medium};
  position: absolute;
  z-index: 1;
  white-space: normal;

  opacity: ${props => (props.$opened ? '1' : '0')};
  transform: ${props => (props.$opened ? 'scale(1)' : 'scale(0.0)')};
  transition:
    transform 0.5s ease-in-out,
    opacity 0.25s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 20px;
    border-style: solid;
    border-color: ${Color.Secondary} ${Color.Secondary} transparent transparent;
  }
`;

export const TooltipArrowImageStyled = styled.div`
  position: absolute;
  margin-top: 100px;
  left: 55%;
  animation-name: ${shake};
  animation-duration: 2s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const TooltipButtonStyled = styled.div`
  float: right;
  margin-right: ${Spacing.Small};
`;
