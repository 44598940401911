import { Color, Spacing } from '@components/obj.constants';
import { isVacinaTheme } from 'config/theme';
import styled from 'styled-components';

export const SignupFormBackgroundStyled = styled.div`
  @media only screen and (min-width: 48em) {
    background-color: ${isVacinaTheme ? Color.Primary : Color.BackgroundPrimary};
  }

  @media only screen and (max-width: 48em) {
    display: block;
  }
`;

export const SignupFormStyled = styled.div<{ translateUp?: boolean }>`
  ${props => (props.translateUp ? `transform: translateY(-${Spacing.XXXLarge});` : '')}

  padding: ${Spacing.XXLarge};
  margin: ${Spacing.XXLarge} 0 128px 0;

  background-color: ${Color.White};
  border-color: ${Color.GrayLight};
  border-radius: ${Spacing.Small};
  border-width: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
