import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { ImunocardStepper } from '@components/mol.imunocard-stepper';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Container } from 'typedi';
import { ImunocardNumberView } from './imunocard-number.view';
import { AgeSelectionView } from './age-selection.view';
import { VaccineSelectionView } from './vaccine-selection.view';
import { RecommendationReviewView } from './recommendation-review.view';
import { HeaderSmart } from '@app/components/header.smart-component';
import { MenuItems } from '@components/mol.header/header.model';
import { AuthenticationStore } from '../authentication/authentication.store';
import { isVacinaTheme } from 'config/theme';

const IMUNOCARD_APPOINTMENT_PATH = '/recomendacao';

type ImunocardAppointmentViewProps = PropsBase;

interface NestedRoute {
  subPath: string;
  component: React.ComponentType<any>;
  step?: number;
  title?: string;
}

@Observer()
export class ImunocardAppointmentView extends React.Component<ImunocardAppointmentViewProps> {
  private imunocardAppointmentRoute: NestedRoute[] = [
    { title: 'Imunocard', subPath: 'numero-do-imunocard', component: ImunocardNumberView, step: 1 },
    { title: 'Faixa etária', subPath: 'escolha-da-faixa-etaria', component: AgeSelectionView, step: 2 },
    { title: 'Vacinas', subPath: 'selecao-de-vacinas', component: VaccineSelectionView, step: 3 },
    { title: 'Revisão', subPath: 'revisao', component: RecommendationReviewView, step: 4 },
  ];

  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private reactionDisposers: (() => void)[] = [];

  private searchParams: string = '';

  constructor(props: ImunocardAppointmentViewProps) {
    super(props);

    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      const urlObj = new URL(currentUrl);
      if (urlObj.search.includes('token')) {
        this.searchParams = urlObj.search;
      }
    }
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
  }

  render() {
    const currentPath = this.props.location?.pathname || '';
    const currentRoute = this.imunocardAppointmentRoute.find(route =>
      currentPath.includes(`${IMUNOCARD_APPOINTMENT_PATH}/${route.subPath}`),
    );

    const steps = this.imunocardAppointmentRoute.filter(route => route.step).map(route => route.title || '');

    return (
      <div>
        <HeaderSmart
          selectedItem={MenuItems.recomendacoes}
          home={false}
          logged={this.authenticationStore?.loggedIn || false}
        />

        {isVacinaTheme && <CookieConsentContainer />}

        {currentRoute?.step && (
          <ImunocardStepper
            currentStep={currentRoute.step}
            steps={steps}
            onMobile={this.mobileService?.onMobile || false}
            onClick={this.onStepperClick}
          />
        )}

        <Switch>
          {this.imunocardAppointmentRoute.map(route => (
            <Route
              key={route.subPath}
              path={`${IMUNOCARD_APPOINTMENT_PATH}/${route.subPath}`}
              component={route.component}
              exact={true}
              location={{ pathname: `${IMUNOCARD_APPOINTMENT_PATH}/${route.subPath}`, search: this.searchParams, state: {}, hash: '' }}
            />
          ))}
          <Redirect
            from={IMUNOCARD_APPOINTMENT_PATH}
            to={{
              pathname: `${IMUNOCARD_APPOINTMENT_PATH}/${this.imunocardAppointmentRoute[0]?.subPath}`,
              search: this.searchParams,
            }}
          />
        </Switch>
      </div>
    );
  }

  private onStepperClick = (step: number) => {
    this.analyticsService?.event(`step_${step}_click`, 'click');

    const targetRoute = this.imunocardAppointmentRoute.find(route => route.step === step);

    if (targetRoute) {
      this.props.history.push({
        pathname: `${IMUNOCARD_APPOINTMENT_PATH}/${targetRoute.subPath}`,
        search: this.searchParams,
      });
    }
  };
}

