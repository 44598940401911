import styled from 'styled-components';
import { Color } from '@components/obj.constants';
import { isVacinaTheme } from 'config/theme';

interface LoadingFrameStyledProps {
  $showLoading: boolean;
}

export const LoadingFrameStyled = styled.div<LoadingFrameStyledProps>`
  position: absolute;
  top: 40%;
  z-index: 1000;
  visibility: ${props => (props.$showLoading ? 'visible' : 'hidden')};
  transform: translateY(-50%);
  left: ${isVacinaTheme ? 'calc(50% - 145px)' : '50%'};

  @media only screen and (max-width: 48em) {
    ${!isVacinaTheme && 'left: 45%;'}
  }
`;

export const LoadingFrameOverlayStyled = styled.div<LoadingFrameStyledProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  background-color: ${Color.Black};
  opacity: 0.5;
  visibility: ${props => (props.$showLoading ? 'visible' : 'hidden')};
  transition: all 0.2s ease-out;
`;
