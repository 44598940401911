import { BodyStyle } from '@components/atm.typography';
import { Color } from '@components/obj.constants';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyled = createGlobalStyle`
    * {
      box-sizing: border-box;
      }
    html {
      scroll-behavior: smooth;
    }
    body {
      margin: 0;
      background-color: ${Color.BackgroundPrimary};
      min-height: 10px;
      @media print {
        background-color: ${Color.White};
      }
    }

    /* https://github.com/wwayne/react-tooltip#options */
    .react-tooltip {
      ${BodyStyle}

      max-width: 220px;
      @media all and (min-width: 48em) {
        max-width: 40vw;
      }
    }
  `;
