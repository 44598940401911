import { CloseIconStyled, CloseWrapStyled } from '@components/atm.close/close.styled';
import { hasDocument } from '@components/utils';
import * as React from 'react';
import { ModalBoxBodyStyled, ModalBoxStyled, ModalOpacityStyled, ModalStyled } from './modal.component.style';

export interface ModalProps extends React.PropsWithChildren {
  small?: boolean;
  large?: boolean;
  opened?: boolean;
  onClose?: () => void;
  noGutter?: boolean;
  noAnimation?: boolean;
  maxHeight?: string;
}

interface ModalState {
  opened?: boolean;
  isClosing: boolean;
}

// TODO: TEMPLATE
export class Modal extends React.Component<ModalProps, ModalState> {
  private readonly ESC_KEY = 27;
  private closeTimeout: NodeJS.Timeout | null = null;

  constructor(props: ModalProps) {
    super(props);
    this.state = {
      opened: !!this.props.opened,
      isClosing: false,
    };
  }

  componentDidMount() {
    if (!hasDocument()) {
      return;
    }
    document.addEventListener('keydown', this.onEsc, false);
  }

  componentWillUnmount() {
    if (!hasDocument()) {
      return;
    }
    document.removeEventListener('keydown', this.onEsc, false);
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.opened !== this.state.opened) {
      this.setState({ opened: nextProps.opened });
    }
  }

  open() {
    this.setState({ opened: true, isClosing: false });
  }

  close = () => {
    this.setState({ isClosing: true });
    this.closeTimeout = setTimeout(() => {
      this.setState({ opened: false, isClosing: false });
      if (this.props.onClose) {
        this.props.onClose();
      }
    }, 200);
  };

  render() {
    const isBackdropVisible = this.state.opened || this.state.isClosing;
    const isModalVisible = this.state.opened && !this.state.isClosing;
    const shouldPlayOpenAnimation = !this.state.isClosing;

    return (
      <ModalStyled $opened={isBackdropVisible}>
        <ModalOpacityStyled $opened={isModalVisible} onClick={this.close} />
        {isBackdropVisible && (
          <ModalBoxStyled
            $small={this.props.small}
            $large={this.props.large}
            $noAnimation={this.props.noAnimation}
            $nextAnimationIsOpen={shouldPlayOpenAnimation}
          >
            <CloseWrapStyled>
              <CloseIconStyled onClick={this.close} />
            </CloseWrapStyled>
            <ModalBoxBodyStyled $maxHeight={this.props.maxHeight} $noGutter={this.props.noGutter}>
              {this.props.children}
            </ModalBoxBodyStyled>
          </ModalBoxStyled>
        )}
      </ModalStyled>
    );
  }

  private onEsc = event => {
    if (event.keyCode === this.ESC_KEY) {
      this.close();
    }
  };
}
