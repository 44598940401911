import * as React from 'react';
import { ImunocardProgress } from '@components/atm.imunocard-progress';
import { StepperStyled } from './imunocard-stepper.component.style';

export interface ImunocardStepperProps {
  steps: string[];
  currentStep: number;
  onMobile?: boolean;
  onClick?: (currentStep: number) => void;
}

export const ImunocardStepper = (props: ImunocardStepperProps) => {
  return (
    <StepperStyled>
      <ImunocardProgress {...props} />
    </StepperStyled>
  );
};
