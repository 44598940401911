import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AppointmentStep } from '@app/modules/appointment/appointment.store.model';
import * as React from 'react';
import { Container } from 'typedi';
import { VaccineChoiceBySpecialNeedView } from './vaccine-choice-by-special-need.view';
import { VaccineBySpecialNeedQuery } from '@app/resource/graphql/generated';

export type VaccineChoiceDataProps = PropsBase<VaccineBySpecialNeedQuery>;

@GraphQL('vaccine-by-special-need.query', {
  options: () => {
    const appointmentStore: AppointmentStore = Container.get(AppointmentStore);
    const variables = {
      specialNeed: appointmentStore.selectedImmunocompromisedComorbidity,
    };
    return { variables, errorPolicy: 'all' };
  },
})
@Observer()
export class VaccineChoiceBySpecialNeedData extends React.Component<VaccineChoiceDataProps> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.appointmentStore.currentAppointmentStep = AppointmentStep.VaccineChoiceBySpecialNeed;
  }

  render() {
    return <VaccineChoiceBySpecialNeedView {...this.props} />;
  }
}
