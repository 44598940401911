import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStore } from './appointment.store';
import { AppointmentView } from './appointment.view';
import { AllRecommendationsQuery } from '@app/resource/graphql/generated';

export type AppointmentDataProps = PropsBase<AllRecommendationsQuery>;

@GraphQL('all-recommendations.query')
@Observer()
export class AppointmentData extends React.Component<AppointmentDataProps> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: AppointmentDataProps) {
    this.appointmentStore.allRecommendations = nextProps.data as AllRecommendationsQuery;
  }

  render() {
    return <AppointmentView {...this.props} />;
  }
}
