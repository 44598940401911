import * as React from 'react';
import { ContentWrapper, WhiteContainer } from '@components/obj.white-container';
import { Body, H1 } from '@components/atm.typography';
import { Accordion } from '@components/mol.accordion/accordion.component';
import { Col, Grid, Separator } from '@components/obj.grid';
import { HeaderSmart } from '@app/components/header.smart-component';
import { LinkButton } from '@components/atm.button';
import { Container } from 'typedi';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { Pagination } from '@components/mol.pagination';
import { Router } from '@app/core/route/router';
import { CONTACT_PATH } from '../contact/contact.route';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { PropsBase } from '@app/core/base/props.base';
import { GetFaqsQuery, GetFaqsQueryVariables } from '@app/resource/graphql/generated';
import { reaction } from 'mobx';
import { FaqCategoryType } from '@app/resource/graphql/base-schema';

const ITEMS_PER_PAGE = 10;

interface FrequentQuestionsState {
  expandedItem: number | null;
  currentPage: number;
  isLogged: boolean;
}

@GraphQL('get-faqs.query', {
  options: {
    variables: {
      category: FaqCategoryType.PUBLIC,
      page: 1,
      pageSize: ITEMS_PER_PAGE,
    },
    errorPolicy: 'all',
  },
})
@Observer()
export class FrequentQuestionsView extends React.Component<
  PropsBase<GetFaqsQuery, GetFaqsQueryVariables>,
  FrequentQuestionsState
> {
  private authenticationStore = Container.get(AuthenticationStore);
  private disposer: () => void;

  constructor(props) {
    super(props);

    this.state = {
      expandedItem: null,
      currentPage: 1,
      isLogged: this.authenticationStore.loggedIn || false,
    };
  }

  componentDidMount() {
    this.setState({ isLogged: this.authenticationStore.loggedIn });
    this.disposer = reaction(
      () => ({
        loggedIn: this.authenticationStore.loggedIn,
        currentPage: this.state.currentPage,
      }),
      ({ loggedIn }) => {
        this.setState({ isLogged: loggedIn });
        this.refetchData();
      },
    );
  }

  componentDidUpdate(prevState) {
    if (prevState.isLogged !== this.state.isLogged || prevState.currentPage !== this.state.currentPage) {
      this.refetchData();
    }
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  refetchData = (): void => {
    const { currentPage, isLogged } = this.state;
    const category = isLogged ? FaqCategoryType.DOCTOR : FaqCategoryType.PUBLIC;
    this.props.data.refetch?.({
      category,
      page: currentPage,
      pageSize: ITEMS_PER_PAGE,
    });
  };

  handleAccordionClick = (index: number) => () => {
    this.setState(prevState => ({
      expandedItem: prevState.expandedItem === index ? null : index,
    }));
  };

  handlePageChange = (page: number): void => {
    this.setState({ currentPage: page });
  };

  handleContactClick = () => {
    const router = Container.get(Router);
    router.push(CONTACT_PATH);
  };

  render() {
    const { data } = this.props;
    const { loading, error } = data;
    const { expandedItem, currentPage, isLogged } = this.state;

    const faqs = data?.GetFaqs?.faq || [];
    const totalPages = data?.GetFaqs?.totalPages || 0;

    return (
      <div>
        <HeaderSmart home={false} logged={isLogged} />
        <WhiteContainer hasPadding={true} isResponsive={true}>
          <ContentWrapper>
            <H1>Central de informações</H1>
          </ContentWrapper>
          <Separator />

          {loading ? (
            <p>Carregando perguntas frequentes...</p>
          ) : error ? (
            <p>Erro ao carregar as perguntas frequentes. Tente novamente mais tarde.</p>
          ) : (
            <>
              {faqs.map((faq, index) => (
                <Accordion
                  key={faq.id}
                  title={faq.question || ''}
                  expanded={expandedItem === index}
                  onClick={this.handleAccordionClick(index)}
                >
                  <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: faq.answer || '' }} />
                    {faq.reference && <div dangerouslySetInnerHTML={{ __html: faq.reference }} />}
                  </Col>
                </Accordion>
              ))}
              <Separator />
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
            </>
          )}
        </WhiteContainer>

        <Grid fluid={true}>
          <Col xs={12} smOffset={1}>
            <Body>
              Não encontrou o que procura?{' '}
              <LinkButton onClick={this.handleContactClick}>Entre em contato com a GSK.</LinkButton>
            </Body>
          </Col>
        </Grid>
      </div>
    );
  }
}

export default FrequentQuestionsView;
