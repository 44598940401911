import * as React from 'react';
import { NotificationMessageStyled, NotificationStyled } from './notification.component.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Color } from '@components/obj.constants';

export type NotificationType = 'success' | 'error' | 'info' | 'warnings';

export interface NotificationProps extends React.PropsWithChildren {
  type: NotificationType;
  message?: string;
  hidden?: boolean;
  onClose?: () => void;
}

interface NotificationState {
  hidden: boolean;
}

export class Notification extends React.Component<NotificationProps, NotificationState> {
  constructor(props: NotificationProps) {
    super(props);
    this.state = { hidden: !!this.props.hidden };
  }

  show = () => {
    this.setState({ hidden: false });
  };

  hide = () => {
    this.setState({ hidden: true });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <NotificationStyled hidden={this.state.hidden} type={this.props.type}>
        <NotificationMessageStyled {...this.props}>
          {this.props.type === 'error' && (
            <span>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: Color.FeedbackError }} />{' '}
            </span>
          )}
          {this.props.message}
          {this.props.children}
        </NotificationMessageStyled>
      </NotificationStyled>
    );
  }
}
