export const MONTHS_IN_A_YEAR = 12;
export const MONTHS_AGE_BARRIER = 24;

type MinAge = number;
type MaxAge = number;

export interface Age {
  minAge: MinAge;
  maxAge: MaxAge;
  type: AgeOption;
  value: [MinAge, MaxAge];
  getAgeText(): string;
}

export class DefinedAge implements Age {
  constructor(
    public minAge: MinAge,
    public maxAge: MaxAge,
    public type: AgeOption,
  ) {
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.type = type;
  }

  getAgeText(): string {
    /** SMELL: this code assumes there are no vaccines between 21 and 59 years old */
    if (this.type === 'year') {
      if (this.minAge >= 20 && this.minAge <= 49) {
        return `20-49 anos`;
      }
      if (this.minAge >= 50 && this.minAge <= 59) {
        return `50-59 anos`;
      }
    }

    if (this.minAge === this.maxAge) {
      if (this.type === 'month') {
        if (this.minAge === 0) {
          return 'Ao nascer';
        } else if (this.minAge === 1) {
          return '1 mês';
        } else if (this.minAge === 24) {
          return '2 anos';
        } else {
          return `${this.minAge} meses`;
        }
      } else if (this.type === 'year') {
        if (this.minAge === 1) {
          return '1 ano';
        } else {
          return this.minAge === 60? `${this.minAge} anos ou mais` : `${this.minAge} anos`;
        }
      }
    } else {
      if (this.type === 'month') {
        return `${this.minAge}-${this.maxAge} meses`;
      } else {
        return `${this.minAge}-${this.maxAge} anos`;
      }
    }

    return '';
  }

  get value(): [MinAge, MaxAge] {
    if (this.type === 'month') {
      return [this.minAge, this.maxAge];
    } else {
      return [this.minAge * MONTHS_IN_A_YEAR, this.maxAge * MONTHS_IN_A_YEAR];
    }
  }
}

export type AgeOption = 'month' | 'year';

export class NullAge implements Age {
  minAge: number = null;
  maxAge: number = null;
  type: AgeOption = null;

  getAgeText(): string {
    return null;
  }
  get value(): [number, number] {
    return [null, null];
  }
}
