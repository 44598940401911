import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const TestimonialListContainerImunocardStyled = styled.section`
  position: relative;

  /* to disable horizontal scroll due to Drop image */
  overflow-x: hidden;
  overflow-y: hidden;

  background-color: transparent;
  padding-bottom: ${Spacing.XXXLarge};
`;

export const TestimonialImgImunocardStyled = styled.img`
  width: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
  z-index: 1;
`;

export const TestimonialRowBodyImunocardStyled = styled.div<{ $subtext: boolean }>`
  margin: 0;
  color: ${Color.White};
  font-size: ${props => (props.$subtext ? FontSize.XSmall : FontSize.Small)};
  font-family: ${FontFamily.Primary};
  font-weight: ${props => (props.$subtext ? FontWeight.Normal : FontWeight.Bold)};
`;

export const TestimonialCellStyled = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: ${Spacing.XXXLarge};
  background-color: ${Color.Accessory2};
  border-radius: ${Spacing.XXLarge};
  margin-top: ${Spacing.XLarge};
  margin-bottom: ${Spacing.XLarge};
  @media only screen and (max-width: 75em) {
    padding: ${Spacing.XLarge};
  }
`;
