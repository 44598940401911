import BrazilStateSelect from '@app/components/brazil-state-select.component';
import { Router } from '@app/core/route/router';
import { Button, LinkButton } from '@components/atm.button';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { Body, Caption, H2Imunocard, InputLabel } from '@components/atm.typography';
import { PasswordLength } from '@components/obj.constants';
import { FieldValidator, Form, FormData, Validators } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { If } from '@components/obj.if';
import * as React from 'react';
import { DoctorInput } from '@app/resource/graphql/base-schema';
import { CheckboxField, CheckboxFieldGroup } from '@components/atm.checkbox';
import Container from 'typedi';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { LOGIN_PATH } from './login.route';
import { SignupFormBackgroundStyled, SignupFormStyled } from './signup-form.component.style';

interface SignupFormProps {
  onSubmit: (doctor: DoctorInput) => void;
  loading?: boolean;
  error?: string;
  translateUp?: boolean;
}

interface SignupFormImunocardData {
  email: string;
  password: string;
  phone: string;
  crm: string;
  state: string;
}

const termsCheckboxId = 1;
const emailCheckboxId = 2;
const smsCheckboxId = 3;

declare var hj: any;

export const SignupForm: React.FC<SignupFormProps> = props => {
  const termsFormRef = React.useRef<CheckboxField>();
  const newsletterFormRef = React.useRef<CheckboxField>();
  const smsFormRef = React.useRef<CheckboxField>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [passwordValue, setPasswordValue] = React.useState<string | undefined>();

  const analyticsService = Container.get(GoogleAnalyticsService);
  const router = Container.get(Router);

  const handleSubmit = (formData: FormData<SignupFormImunocardData>) => {
    const acceptedTerms = termsFormRef.current.props.checked;
    if (!acceptedTerms || formData.errors.length > 0) {
      if (!acceptedTerms) {
        setErrorMessage('Por favor, aceite os termos para prosseguir.');
      }
      if (typeof hj !== 'undefined') {
        hj('formSubmitFailed');
      }
      return;
    }
    setErrorMessage(undefined);

    props.onSubmit({
      email: formData.data.email,
      password: formData.data.password,
      phone: formData.data.phone || undefined,
      crm: formData.data.crm,
      uf: formData.data.state,
      newsletterOptIn: newsletterFormRef.current.props.checked,
      smsOptIn: smsFormRef.current.props.checked,
    });
  };

  const onLoginClick = () => {
    router.push(LOGIN_PATH);
    analyticsService.event('home_entrar_form_button', 'click');
  };

  const onPasswordChange = (value: string) => {
    setPasswordValue(value);
  };

  const onPrivacyPolicyClick = () => {
    analyticsService.event('footer_politicas_de_privacidade', 'click');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SignupFormBackgroundStyled>
        <Grid>
          <Row id='signup'>
            <Col xs={12} sm={8} md={6} lg={5} smOffset={2} mdOffset={3}>
              <SignupFormStyled translateUp={props.translateUp}>
                <Row>
                  <Col>
                    <H2Imunocard>Cadastro</H2Imunocard>
                    <Separator />
                    <Caption>Todos os campos são obrigatórios</Caption>
                    <Separator />
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>E-mail</InputLabel>
                    <FieldValidator
                      name='email'
                      validators={[
                        Validators.Required('Campo obrigatório'),
                        Validators.EmailRegex('O e-mail digitado não é válido'),
                      ]}
                    >
                      <TextField type='email' />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>CRM</InputLabel>
                    <FieldValidator
                      name='crm'
                      validators={[
                        Validators.Required('Campo obrigatório'),
                        Validators.CrmRegex(`O CRM deve ter somente números.`),
                      ]}
                    >
                      <TextField type='custom' options={{ mask: '9999999' }} />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>Estado</InputLabel>
                    <FieldValidator name='state' validators={[Validators.Required('Campo obrigatório')]}>
                      <BrazilStateSelect />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>Nome</InputLabel>
                    <FieldValidator name='nome' validators={[Validators.Required('Campo obrigatório')]}>
                      <TextField type='nome' />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>Telefone</InputLabel>
                    <FieldValidator
                      name='phone'
                      validators={[
                        Validators.Required('Campo obrigatório'),
                        Validators.CellphoneRegex('O celular preenchido não é válido'),
                      ]}
                    >
                      <TextField type='cel-phone' />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>Senha</InputLabel>
                    <FieldValidator
                      name='password'
                      validators={[
                        Validators.Required('Campo é obrigatório'),
                        Validators.MaxLength(
                          PasswordLength.Max,
                          `A senha deve ter menos de ${PasswordLength.Max} caracteres`,
                        ),
                        Validators.MinLength(
                          PasswordLength.Min,
                          `A senha deve ter pelo menos ${PasswordLength.Min} caracteres`,
                        ),
                        Validators.PasswordRegex(`A senha deve ter pelo menos uma letra e um número.`),
                      ]}
                      validatorPlaceholder={`A senha deve ter entre ${PasswordLength.Min} e
                            ${PasswordLength.Max} caracteres e pelo menos uma letra, um número e um caracter especial.`}
                    >
                      <TextField type='password' maxLength={PasswordLength.Max} onValueChange={onPasswordChange} />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <InputLabel>Confirmar senha</InputLabel>
                    <FieldValidator
                      name='confirmPassword'
                      validators={[
                        Validators.Required('Campo é obrigatório'),
                        Validators.IsNotEqualToField(passwordValue, 'Senhas devem ser iguais'),
                      ]}
                    >
                      <TextField type='password' maxLength={PasswordLength.Max} />
                    </FieldValidator>
                  </Col>
                </Row>

                <Row $mb={true}>
                  <Col xs={12}>
                    <CheckboxFieldGroup value={[termsCheckboxId, emailCheckboxId]}>
                      <CheckboxField
                        isDefaultStyle={true}
                        value={termsCheckboxId}
                        ref={check => (termsFormRef.current = check)}
                      >
                        <span>
                          Li e concordo com as{' '}
                          <LinkButton
                            href={'https://privacy.gsk.com/pt-br/privacy-notice/healthcare-professional/'}
                            noHeight={true}
                            noPadding={true}
                            onClick={onPrivacyPolicyClick}
                            textAlign='left'
                          >
                            políticas de segurança e privacidade
                          </LinkButton>{' '}
                          da GSK.
                        </span>
                      </CheckboxField>
                      <CheckboxField
                        isDefaultStyle={true}
                        value={emailCheckboxId}
                        ref={check => (newsletterFormRef.current = check)}
                      >
                        Aceito receber comunicações da GSK no e-mail informado.
                      </CheckboxField>
                      <CheckboxField
                        isDefaultStyle={true}
                        value={smsCheckboxId}
                        ref={check => (smsFormRef.current = check)}
                      >
                        Aceito receber comunicações da GSK no número de celular informado.
                      </CheckboxField>
                    </CheckboxFieldGroup>
                  </Col>
                </Row>
                <If cond={!!errorMessage || !!props.error}>
                  <Row $mb={true}>
                    <Col xs={12}>
                      <Notification type='error' message={props.error ?? errorMessage} />
                    </Col>
                  </Row>
                </If>

                <Button kind='primary' type='submit' loading={props.loading} expanded={true}>
                  Enviar
                </Button>

                <Row center='xs'>
                  <Col>
                    <Body>
                      Já tem uma conta? <LinkButton onClick={onLoginClick}>Entrar</LinkButton>
                    </Body>
                  </Col>
                </Row>
              </SignupFormStyled>
            </Col>
          </Row>
        </Grid>
      </SignupFormBackgroundStyled>
    </Form>
  );
};
