// tslint:disable: max-line-length
import { HeaderSmart } from '@app/components//header.smart-component';
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { ScriptLoader } from '@app/components/script-loader.component';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { EventService, MapEvents } from '@app/core/service/document-event.service';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { GskMapsService, SearchCepEventDetails } from '@app/core/service/gsk-maps.service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { checkoutFooterHeight } from '@components/mol.checkout-footer/checkout-footer.component.style';
import { MenuItems } from '@components/mol.header/header.model';
import { Border, Color } from '@components/obj.constants';
import { hasDocument } from '@components/utils';
import { observable } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { AdministrationPlacesStore } from '../administration-places.store';
import { isVacinaTheme } from 'config/theme';
// tslint:enable: max-line-length

@Observer()
export class ShingrixView extends React.Component<PropsBase> {
  private rootContainer: HTMLDivElement;

  private reactionDisposers = [];
  private checkout: boolean;

  @observable
  private height: number;

  private gskMapsService?: GskMapsService = Container.get(GskMapsService);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private eventService?: EventService = Container.get(EventService);
  private administrationPlacesListeners?: AdministrationPlacesStore = Container.get(AdministrationPlacesStore);

  constructor(props) {
    super(props);
    this.checkout = this.props.location.pathname.indexOf(APPOINTMENT_PATH) >= 0;
  }

  componentDidMount() {
    this.setupAnalyticsListeners();
    this.administrationPlacesListeners.setAdministrationPlacesListener();
    this.administrationPlacesListeners.clearPlacesListener();
    this.sendPageViewAnalytics();

    if (!hasDocument()) {
      return;
    }
    const rootContainerRect = this.rootContainer.getBoundingClientRect();
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.height = viewportHeight - rootContainerRect.top - (this.checkout ? parseInt(checkoutFooterHeight, 0) : 0);
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.removeListeners();
  }

  render() {
    return (
      <>
        {isVacinaTheme && <CookieConsentContainer />}
        {this.props.location.pathname.indexOf(APPOINTMENT_PATH) < 0 && (
          <HeaderSmart
            selectedItem={MenuItems.centros}
            home={false}
            logged={this.authenticationStore.loggedIn}
            forceChooseCalendarButton={true}
          />
        )}
        <div ref={it => (this.rootContainer = it)}>
          {this.height && <ScriptLoader src={this.gskMapsService.url} />}
          <div
            id='gsk-map'
            data-primary-color={Color.Primary}
            data-secondary-color={Color.BackgroundPrimary}
            data-border-radius={parseInt(Border.radiusLarge, 0)}
            data-background-color={Color.BackgroundPrimary}
            data-list-item-color={Color.White}
            data-height={this.height}
            data-shingrix={true}
          />
        </div>
      </>
    );
  }

  private sendPageViewAnalytics = () => {
    this.analyticsService.pageView('consulta_centros_shingrix');
  };

  private setupAnalyticsListeners = () => {
    this.eventService.addListener(MapEvents.AddItemMap, this.addItemMapListener);
    this.eventService.addListener(MapEvents.AllFilter, this.allFilterListener);
    this.eventService.addListener(MapEvents.ClinicFilter, this.clinicFilterListener);
    this.eventService.addListener(MapEvents.HomeVaccinationFilter, this.homeFilerListener);
    this.eventService.addListener(MapEvents.ItemMap, this.itemMapListener);
    this.eventService.addListener(MapEvents.PageView, this.sendMapComponentView);
    this.eventService.addListener(MapEvents.PublicFilter, this.publicFilterListener);
    this.eventService.addListener(MapEvents.RemoveItemMap, this.removeItemMapListener);
    this.eventService.addListener(MapEvents.RemoveListItem, this.removeListItemListener);
    this.eventService.addListener(MapEvents.SearchCep, this.searchCepListener);
    this.eventService.addListener(MapEvents.SendEmailList, this.sendEmailListListener);
    this.eventService.addListener(MapEvents.ShingrixFilter, this.sendShingrixListener);
  };

  private removeListeners = () => {
    this.administrationPlacesListeners.removeClearPlacesListener();
    this.administrationPlacesListeners.unsetAdministrationPlacesListener();
    this.eventService.removeListener(MapEvents.AddItemMap, this.addItemMapListener);
    this.eventService.removeListener(MapEvents.AllFilter, this.allFilterListener);
    this.eventService.removeListener(MapEvents.ClinicFilter, this.clinicFilterListener);
    this.eventService.removeListener(MapEvents.HomeVaccinationFilter, this.homeFilerListener);
    this.eventService.removeListener(MapEvents.ItemMap, this.itemMapListener);
    this.eventService.removeListener(MapEvents.PageView, this.sendMapComponentView);
    this.eventService.removeListener(MapEvents.PublicFilter, this.publicFilterListener);
    this.eventService.removeListener(MapEvents.RemoveItemMap, this.removeItemMapListener);
    this.eventService.removeListener(MapEvents.RemoveListItem, this.removeListItemListener);
    this.eventService.removeListener(MapEvents.SearchCep, this.searchCepListener);
    this.eventService.removeListener(MapEvents.SendEmailList, this.sendEmailListListener);
    this.eventService.removeListener(MapEvents.ShingrixFilter, this.sendShingrixListener);
  };

  private sendMapComponentView = () => {
    this.analyticsService.pageView('consulta_centros_map');
  };

  private searchCepListener = (event: { detail: SearchCepEventDetails }) => {
    const lastSearchAddress = event.detail.searchAddress;
    if (this.checkout) {
      this.analyticsService.event('consulta_centros_pesquisar_cep', 'click', true, lastSearchAddress);
    } else {
      this.analyticsService.event('centros_pesquisar_cep', 'click', true, lastSearchAddress);
    }
  };

  private sendEmailListListener = () => {
    if (!this.checkout) {
      this.analyticsService.event('centros_enviar_lista_email', 'click', true);
    }
  };

  private sendShingrixListener = () => {
    this.analyticsService.event('centros_shingrix_filter', 'click', true);
  };

  private addItemMapListener = () => {
    this.checkoutListener(MapEvents.AddItemMap);
  };
  private allFilterListener = () => {
    this.checkoutListener(MapEvents.AllFilter);
  };
  private clinicFilterListener = () => {
    this.checkoutListener(MapEvents.ClinicFilter);
  };
  private homeFilerListener = () => {
    this.checkoutListener(MapEvents.HomeVaccinationFilter);
  };
  private itemMapListener = () => {
    this.checkoutListener(MapEvents.ItemMap);
  };
  private publicFilterListener = () => {
    this.checkoutListener(MapEvents.PublicFilter);
  };
  private removeItemMapListener = () => {
    this.checkoutListener(MapEvents.RemoveItemMap);
  };
  private removeListItemListener = () => {
    this.checkoutListener(MapEvents.RemoveListItem);
  };

  private checkoutListener = (category: string) => {
    if (this.checkout) {
      this.analyticsService.event(`consulta_centros_${category}`, 'click', true);
    } else {
      this.analyticsService.event(`centros_${category}`, 'click', true);
    }
  };
}
