import styled from 'styled-components';
import { Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';

export const SortSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const SelectStyled = styled.div`
  background-color: ${Color.CallToAction};
  border-radius: ${Spacing.Small};
  padding: ${Spacing.Small};
  padding-right: ${Spacing.XXLarge};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  color: ${Color.NeutralStrong};
  position: relative;
`;

export const DropdownIcon = styled.span<{ isOpen: boolean }>`
  position: absolute;
  right: ${Spacing.Medium};
  transition: transform 0.2s ease;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
`;

export const OptionItem = styled.div<{ isSelected: boolean }>`
  padding: ${Spacing.Medium};
  cursor: pointer;
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  color: ${Color.NeutralStrong};
  background-color: ${Color.BackgroundPrimary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${Color.NeutralGrayXStrong};
  }

  .tick-icon {
    visibility: ${props => (props.isSelected ? 'visible' : 'hidden')};
    color: ${Color.NeutralStrong};
    font-size: ${FontSize.Small};
  }
`;
