import { Border, Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';
import * as TextInputMask from 'react-masked-text';
import styled from 'styled-components';
import { TextFieldProps } from './text-field.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isVacinaTheme } from 'config/theme';

export const TextFieldStyled = styled.input<TextFieldProps>`
  border-radius: ${Border.radius};
  border-width: 1px;
  border-color: ${isVacinaTheme ? Color.GrayLight : Color.NeutralGrayStrong};
  align-self: stretch;
  padding: ${Spacing.Small};
  font-family: ${FontFamily.Secondary};
  font-size: ${FontSize.Small};
  width: 100%;
  max-width: none;
  min-height: 50px;
  border-style: solid;
  :focus {
    outline: none;
  }
`;

export const TextFieldMaskedStyled = styled(TextInputMask.TextInputMask)<TextFieldProps>`
  border-radius: ${Border.radius};
  border-width: 1px;
  border-color: ${isVacinaTheme ? Color.GrayLight : Color.NeutralGrayStrong};
  align-self: stretch;
  padding: ${Spacing.Small};
  font-family: ${FontFamily.Secondary};
  font-size: ${FontSize.Small};
  width: 100%;
  min-height: 50px;
  border-style: solid;
  :focus {
    outline: none;
  }
`;

export const TextFieldPasswordButtonStyled = styled(FontAwesomeIcon)`
  position: absolute;
  right: ${Spacing.Medium};
  top: 50%;
  transform: translate(0, -50%);
  color: ${Color.Primary};
`;
