import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: ${Color.Black};
  background-color: ${Color.White};
  border-radius: ${Border.radiusLarge};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const THeadStyled = styled.thead`
  color: ${Color.NeutralGrayStrong};
`;

export const TBodyStyled = styled.tbody`
  color: ${Color.Black};
`;

export const THStyled = styled.th`
  padding: ${Spacing.Large} ${Spacing.XLarge};
  border-bottom: 1px solid ${Color.NeutralGrayStrong};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.XSmall};
  color: ${Color.NeutralGrayStrong};

  @media (max-width: 768px) {
    font-size: ${FontSize.XSmall};
    padding: ${Spacing.Small} ${Spacing.Large};
  }
`;

export const TDStyled = styled.td`
  padding: ${Spacing.XXLarge} ${Spacing.XLarge};
  border-bottom: 1px solid ${Color.BackgroundPrimary};
  vertical-align: middle;
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  color: ${Color.NeutralGrayStrong};

  @media (max-width: 768px) {
    font-size: ${FontSize.XSmall};
    padding: ${Spacing.Large};
  }
`;

export const TRStyled = styled.tr<{ disabled?: boolean }>`
  background-color: ${Color.White};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? Color.White : Color.BackgroundPrimary)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  @media (max-width: 768px) {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const TableIconContainer = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: ${Color.NeutralGrayStrong};

  tr:hover & {
    opacity: 1;
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`;
