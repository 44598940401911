import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';

export const CardWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'nowrap' : 'wrap')};
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'space-between')};
  gap: ${Spacing.Large};

  ${({ isMobile }) =>
    isMobile &&
    `
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: ${Spacing.Small};
    padding-bottom: ${Spacing.Medium};

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const CardContainer = styled.div`
  border: 1px solid ${Color.NeutralGrayXSoft};
  border-radius: ${Border.radius};
  text-align: center;
  background-color: ${Color.White};
  height: 110px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 95%;
    scroll-snap-align: center;
  }

  @media (min-width: 768px) {
    width: 48%;
  }

  @media (min-width: 1024px) {
    width: 32%;
  }
`;

export const CardTitle = styled.h4`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  color: ${Color.NeutralGrayStrong};
  margin-bottom: ${Spacing.XSmall};
  text-align: center;
  word-wrap: break-word;
  font-weight: normal;
  line-height: ${Spacing.XLarge};
`;

export const CardInfoIcon = styled.span`
  font-size: ${FontSize.Small};
  color: ${Color.NeutralGrayStrong};
  margin-left: ${Spacing.XSmall};
  cursor: pointer;
`;

export const CardNumber = styled.h2`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XLarge};
  color: ${Color.Black};
  margin: 0;
  font-weight: normal;
  text-align: center;
  letter-spacing: -1px;
  line-height: ${Spacing.XXLarge};
`;

export const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.Small};
`;

export const Indicator = styled.span<{ active: boolean }>`
  width: ${({ active }) => (active ? '24px' : '8px')};
  height: ${Spacing.Small};
  margin: 0 ${Spacing.XSmall};
  border-radius: ${Border.radius};
  background-color: ${({ active }) => (active ? Color.Primary : Color.NeutralGrayXSoft)};
  transition: all 0.3s ease;
`;
