import { Button } from '@components/atm.button';
import { CheckboxField } from '@components/atm.checkbox';
import { H1 } from '@components/atm.typography';
import { CheckFamilyGroup } from '@components/mol.check-family-group';
import { Form, ValidationError } from '@components/obj.form';
import { Separator } from '@components/obj.grid';
import { ImunocardListContainer } from '@components/obj.list-container';
import { ButtonWrapper, ContentWrapper, WhiteContainer } from '@components/obj.white-container';
import * as React from 'react';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { PropsBase } from '@app/core/base/props.base';
import { Age } from '@app/model/age.model';
import { VaccineFamily } from '@app/model/vaccine-family.model';
import { Vaccine } from '@app/model/vaccine.model';
import { AllVaccineFamiliesByAgeQuery } from '@app/resource/graphql/generated';
import { Modal } from '@components/obj.modal';
import { Observer } from '@app/core/decorator/observer.decorator';
import Container from 'typedi';
import { AppointmentStore } from '../appointment/appointment.store';
import { VaccineListView } from './vaccine-list.view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Hbox } from '@components/obj.box';
import { Redirect } from 'react-router';
import {
  AlertStyled,
  PencilIconStyled,
  RecomendationTextStyled,
  RecommendationCommentTextStyled,
} from './vaccine-selection.view.style';
import { OrderModal } from '@components/obj.order-modal';
import { LineSeparator } from '@components/obj.line-separator';
import { ImunocardAppointmentStore } from '@app/modules/appointment/imunocard-appointment.store';
import { FieldCaption } from '@components/obj.form/field-caption.component';

export interface VaccineSelectionProps extends PropsBase<AllVaccineFamiliesByAgeQuery> {
  isDefaultRecommendation?: boolean;
  ageGroupSelected?: string;
  vaccines?: Vaccine[];
  onSubmit?: (selectedVaccines: SelectedVaccines[]) => void;
}

interface VaccinesByAge {
  months: number;
  vaccinesFamilies: VaccineFamily[];
}

export interface SelectedVaccines {
  id: string;
  vaccineFamily: VaccineFamily;
  vaccine: Vaccine;
  vaccineOrder?: number;
}

interface VaccineChoiceViewState {
  imunocardNumber: string;
  ageGroupSelected: string;
  vaccineModalOpened: boolean;
  selectionVaccineFamily: VaccineFamily | null;
  selectedVaccines: SelectedVaccines[];
  orderModalOpened: boolean;
  errors: ValidationError[];
}

@GraphQL('all-vaccine-families-by-age.query')
@Observer()
export class VaccineSelectionView extends React.Component<VaccineSelectionProps, VaccineChoiceViewState> {
  private readonly appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private imunocardAppointmentStore?: ImunocardAppointmentStore = Container.get(ImunocardAppointmentStore);

  constructor(props) {
    super(props);

    let ageGroupSelected;
    let selectedVaccines;
    const imunocardNumber = this.imunocardAppointmentStore.imunocard || '';

    if (this.props.isDefaultRecommendation) {
      selectedVaccines =
        props.vaccines.map(vaccine => ({
          id: `${vaccine.vaccineFamilyId}-${vaccine.id}`,
          vaccineFamily: { id: vaccine.vaccineFamilyId.toString(), name: vaccine.name },
          vaccine,
          vaccineOrder: vaccine.order,
        })) || [];
      ageGroupSelected = props.ageGroupSelected || '';
      this.props.data?.refetch();
    } else {
      selectedVaccines = this.imunocardAppointmentStore.vaccines || [];
      ageGroupSelected = this.imunocardAppointmentStore.ageGroup || '';
    }

    this.state = {
      imunocardNumber,
      ageGroupSelected,
      vaccineModalOpened: false,
      selectionVaccineFamily: null,
      selectedVaccines,
      orderModalOpened: false,
      errors: [],
    };
  }

  handleSubmit = () => {
    const { selectedVaccines } = this.state;

    if (selectedVaccines.length === 0) {
      this.setState({
        errors: [{ errorName: 'Required', errorMessage: 'Selecione pelo menos uma vacina para continuar.' }],
      });
      return;
    }
    this.setState({ orderModalOpened: true, errors: [] });
  };

  render() {
    const { data } = this.props;
    const { imunocardNumber, ageGroupSelected, selectedVaccines } = this.state;

    if (!this.props.isDefaultRecommendation) {
      if (!imunocardNumber || !ageGroupSelected) {
        return <Redirect to='/recomendacao/escolha-da-faixa-etaria' />;
      }
    }

    const allVaccinesFamilies =
      data?.AllVaccineFamiliesByAge?.map(item => ({
        months: item.months,
        vaccinesFamilies: item.families,
      })) || [];
    const vaccinesSelection = this.filterVaccinesByAgeRange(allVaccinesFamilies, ageGroupSelected);
    const age: Age = this.findAvailableAgeForMonths(vaccinesSelection.months);

    return (
      <WhiteContainer isResponsive={true}>
        <H1>Selecione as vacinas que deseja incluir</H1>
        <Separator />
        <Form onSubmit={this.handleSubmit}>
          <CheckFamilyGroup key={ageGroupSelected} title={ageGroupSelected}>
            {vaccinesSelection.vaccinesFamilies.map(vaccineFamily => {
              const isSelected = selectedVaccines.some(v => v.vaccineFamily.id === vaccineFamily.id);

              return (
                <ImunocardListContainer key={vaccineFamily.id}>
                  <Hbox>
                    <CheckboxField
                      value={vaccineFamily.id}
                      checked={isSelected}
                      onValueChange={this.handleVaccineFamilyClick.bind(this, vaccineFamily)}
                    >
                      {vaccineFamily.name}
                    </CheckboxField>

                    {isSelected && (
                      <Hbox.Item>
                        <PencilIconStyled onClick={this.handleIconClick.bind(this, vaccineFamily)}>
                          <img src='/assets/icons/pencil.png' alt='Editar vacina' />
                        </PencilIconStyled>
                      </Hbox.Item>
                    )}
                  </Hbox>

                  {selectedVaccines
                    .filter(selectedVaccine => selectedVaccine.vaccineFamily.id === vaccineFamily.id)
                    .map(selectedVaccine => (
                      <div key={selectedVaccine.vaccine.id}>
                        <RecomendationTextStyled marginLeft='65px'>
                          <p>{selectedVaccine.vaccine.description}</p>
                          <p>
                            {/* TODO: add dose count when available */}
                            {/* {selectedVaccine.vaccine.injectionCount === 1
                                ? 'Dose única'
                                : `${selectedVaccine.vaccine.injectionCount} doses`}{' '}
                              •  */}
                            {selectedVaccine.vaccine.isAdministeredOnPublicClinics ? 'Público' : 'Privado'}
                          </p>
                          {selectedVaccine.vaccine.isLiveAttenuated && (
                            <AlertStyled>⚠️ Vacina viva atenuada</AlertStyled>
                          )}

                          {selectedVaccine.vaccine.recommendationComment && (
                            <RecommendationCommentTextStyled title={selectedVaccine.vaccine.recommendationComment}>
                              <FontAwesomeIcon icon={faPenToSquare} /> {selectedVaccine.vaccine.recommendationComment}
                            </RecommendationCommentTextStyled>
                          )}
                        </RecomendationTextStyled>
                      </div>
                    ))}
                </ImunocardListContainer>
              );
            })}
          </CheckFamilyGroup>
          <FieldCaption errors={this.state.errors} showAll={false} validationPlaceholder={null} />
          <ButtonWrapper>
            <Button kind='primary' type='submit'>
              Continuar
            </Button>
          </ButtonWrapper>
        </Form>

        <Modal
          opened={this.state.vaccineModalOpened}
          onClose={this.closeVaccineModal}
          noGutter={true}
          noAnimation={true}
          large={true}
        >
          <VaccineListView
            age={age.getAgeText()}
            vaccineFamily={this.state.selectionVaccineFamily}
            selectedVaccine={
              this.state.selectedVaccines.find(v => v.vaccineFamily.id === this.state.selectionVaccineFamily?.id)
                ?.vaccine
            }
            onVaccineConfirm={this.onVaccineConfirm}
            ageGroupSelected={this.state.ageGroupSelected}
            history={undefined}
            location={undefined}
            match={undefined}
            ownProps={undefined}
          />
        </Modal>

        <Modal
          opened={this.state.orderModalOpened}
          onClose={this.closeOrderModal}
          noGutter={true}
          noAnimation={true}
          large={true}
        >
          <ContentWrapper>
            <H1>Confirmar a ordem de prioridade</H1>
          </ContentWrapper>
          <LineSeparator />
          <Separator />
          <ContentWrapper>
            <OrderModal
              items={this.state.selectedVaccines}
              renderItem={this.renderVaccineItem}
              onChange={this.handleOrderChange}
            />
          </ContentWrapper>
          <Separator />
          <LineSeparator />
          <ContentWrapper>
            <ButtonWrapper>
              <Button kind='primary' type='button' onClick={this.handleConfirmOrder}>
                Confirmar
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </Modal>
      </WhiteContainer>
    );
  }

  private onVaccineConfirm = (item: Vaccine, checked: boolean, recommendationComment?: string): void => {
    const family = this.state.selectionVaccineFamily;

    if (checked) {
      this.setState(prevState => ({
        selectedVaccines: [
          ...prevState.selectedVaccines.filter(selected => selected.vaccineFamily.id !== family?.id),
          {
            id: `${family?.id}-${item.id}`,
            vaccineFamily: family!,
            vaccine: item,
            vaccineOrder: prevState.selectedVaccines.length + 1,
            recommendationComment: recommendationComment || '',
          },
        ],
        vaccineModalOpened: false,
      }));
    } else {
      this.setState(prevState => ({
        selectedVaccines: prevState.selectedVaccines.filter(selected => selected.vaccine.id !== item.id),
      }));
    }
  };

  private handleVaccineFamilyClick = (vaccineFamily: VaccineFamily, checked: boolean) => {
    if (checked) {
      this.setState({
        vaccineModalOpened: true,
        selectionVaccineFamily: vaccineFamily,
      });
    } else {
      this.setState(prevState => ({
        selectedVaccines: prevState.selectedVaccines.filter(selected => selected.vaccineFamily.id !== vaccineFamily.id),
      }));
    }
  };

  private handleIconClick(vaccineFamily: VaccineFamily) {
    this.setState({
      vaccineModalOpened: true,
      selectionVaccineFamily: vaccineFamily,
    });
  }

  private filterVaccinesByAgeRange = (allVaccines: VaccinesByAge[], ageRange: string): VaccinesByAge => {
    const ageRangeMap = {
      '20 a 49 anos': { minMonths: 240, maxMonths: 588 },
      '50 a 59 anos': { minMonths: 600, maxMonths: 708 },
      'Acima de 60 anos': { minMonths: 720, maxMonths: 720 },
    };

    const { minMonths, maxMonths } = ageRangeMap[ageRange] || { minMonths: 0, maxMonths: Infinity };

    const filteredVaccines = allVaccines.filter(vaccine => vaccine.months >= minMonths && vaccine.months <= maxMonths);
    const vaccinesSelection = filteredVaccines.flatMap(vaccine => vaccine.vaccinesFamilies);

    return {
      months: maxMonths,
      vaccinesFamilies: vaccinesSelection,
    };
  };

  private closeVaccineModal = () => {
    this.setState({ vaccineModalOpened: false });
  };

  private closeOrderModal = () => {
    this.setState({ orderModalOpened: false });
  };

  handleOrderChange = (orderedVaccines: SelectedVaccines[]) => {
    this.setState({ selectedVaccines: orderedVaccines });
  };

  private renderVaccineItem(item: SelectedVaccines): React.ReactNode {
    return <span>{item.vaccineFamily.name}</span>;
  }

  handleConfirmOrder = () => {
    const { selectedVaccines } = this.state;

    if (this.props.isDefaultRecommendation) {
      this.props.onSubmit?.(selectedVaccines);
      return;
    }

    this.setState({ orderModalOpened: false }, () => {
      this.imunocardAppointmentStore.setVaccines(selectedVaccines);
      this.props.history.push('/recomendacao/revisao');
    });
  };

  private findAvailableAgeForMonths(months: number): Age {
    return (
      this.appointmentStore.availableAges.find(age => months >= age.value[0] && months <= age.value[1]) ||
      this.appointmentStore.availableAges[0]
    );
  }
}
