import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { BadgeText, StepperInactive } from '@components/atm.typography';
import { ProgressCellProps, ProgressStatus } from './imunocaed-progress-cell.component';
import { Border, Color, FontFamily, FontSize, FontWeight } from '@components/obj.constants';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const ProgressCellStyled = styled.div<ProgressCellProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + :before {
    position: relative;
    top: 18px;
    left: -50%;
    z-index: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    content: '';

    background-image: ${props =>
      props.status === 'current' || props.status === 'future'
        ? `repeating-linear-gradient(
            to right,
            ${statusStyle(props.status).lineColor} 0,
            ${statusStyle(props.status).lineColor} 8px,
            transparent 4px,
            transparent 16px
          )`
        : statusStyle(props.status).lineColor};

    background-color: ${props => (props.status === 'past' ? statusStyle(props.status).lineColor : 'transparent')};
  }
`;

export const ProgressCellBadgeStyled = styled.div<ProgressCellProps>`
  height: 32px;
  width: 32px;
  border-radius: ${Border.radiusLarge};
  border-width: 2px;
  border-color: ${props => statusStyle(props.status).badgeBorderColor};
  background-color: ${props => statusStyle(props.status).badgeBackgroundColor};
  border-style: solid;
  align-self: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 1;
  cursor: ${props => (props.status === 'past' ? 'pointer' : 'default')};
`;

export const ProgressCellChecked = styled(FontAwesomeIcon).attrs({ icon: faCheck })<{ status: ProgressStatus }>`
  color: ${Color.White};
  display: ${props => statusStyle(props.status).checkDisplay};
  align-self: center;
  font-size: 14px;
  cursor: ${props => (props.status === 'past' ? 'pointer' : 'default')};
`;

export const ProgressCellNumberStyled = styled(BadgeText)<ProgressCellProps>`
  position: relative;
  top: -1px;
  display: ${props => statusStyle(props.status).numberDisplay};
  text-align: center;
  color: ${props => statusStyle(props.status).numberColor};
  font-size: 20px;
  cursor: ${props => (props.status === 'past' ? 'pointer' : 'default')};
`;

export const ProgressCellTextStyled = styled(StepperInactive)<ProgressCellProps>`
  text-align: center;
  color: ${props => statusStyle(props.status).textColor};
  cursor: ${props => (props.status === 'past' ? 'pointer' : 'default')};
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
  font-weight: ${FontWeight.Normal};
`;

interface ProgressStatusStyle {
  lineColor: string;
  lineStyle: string;
  badgeBorderColor: string;
  badgeBackgroundColor: string;
  checkDisplay: string;
  numberDisplay: string;
  numberColor: string;
  textColor: string;
}

function statusStyle(status: ProgressStatus): ProgressStatusStyle {
  const styles: Record<ProgressStatus, ProgressStatusStyle> = {
    past: {
      lineColor: Color.Accessory,
      lineStyle: 'solid',
      badgeBorderColor: Color.Accessory,
      badgeBackgroundColor: Color.Accessory,
      checkDisplay: 'block',
      numberDisplay: 'none',
      numberColor: Color.White,
      textColor: Color.NeutralStrong,
    },
    current: {
      lineColor: Color.NeutralGrayMedium,
      lineStyle: 'dashed',
      badgeBorderColor: Color.Primary,
      badgeBackgroundColor: Color.Primary,
      checkDisplay: 'none',
      numberDisplay: 'block',
      numberColor: Color.White,
      textColor: Color.NeutralStrong,
    },
    future: {
      lineColor: Color.NeutralGrayMedium,
      lineStyle: 'dashed',
      badgeBorderColor: Color.Primary,
      badgeBackgroundColor: Color.White,
      checkDisplay: 'none',
      numberDisplay: 'block',
      numberColor: Color.Primary,
      textColor: Color.NeutralStrong,
    },
  };

  return styles[status];
}
