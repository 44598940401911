import * as React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, LinkButton } from '@components/atm.button';
import { Body, H2 } from '@components/atm.typography';
import { Grid, Row, Separator } from '@components/obj.grid';
import { WhiteContainer } from '@components/obj.white-container';
import { ImageModal } from '@components/obj.image-modal';
import { HeaderSmart } from '@app/components/header.smart-component';
import { MenuItems } from '@components/mol.header/header.model';

interface LocationState {
  imunocardNumber?: string;
  recommendationUrl?: string;
  patientName?: string;
}

export const RecommendationConfirmationView: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { imunocardNumber, recommendationUrl, patientName } = location.state || {};
  const recommendationUrlWithName = patientName
    ? recommendationUrl + `?patientName=${encodeURIComponent(patientName)}`
    : recommendationUrl;

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function navigateToNewRecommendation() {
    history.push('/recomendacao/numero-do-imunocard');
  }

  function handleDownload(e?: React.MouseEvent) {
    e?.preventDefault();

    if (!recommendationUrlWithName) {
      alert('URL da recomendação não disponível.');
      return;
    }

    fetch(recommendationUrlWithName)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erro ao baixar o arquivo: ${response.status} ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = 'recomendacao.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao baixar o arquivo:', error);
        alert('Não foi possível baixar o documento.');
      });
  }

  function handleOpenModal(e?: React.MouseEvent) {
    e?.preventDefault();
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  if (!imunocardNumber || !recommendationUrl) {
    return <Redirect to='/recomendacao/selecao-de-vacinas' />;
  }

  return (
    <div>
      <HeaderSmart selectedItem={MenuItems.recomendacoes} home={false} logged={true} />

      <WhiteContainer>
        <Separator />
        <Separator />
        <Separator />
        <Grid fluid={true}>
          <div style={{ textAlign: 'center' }}>
            <img
              src='/assets/img/img_confirmation.png'
              alt='Confirmação'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <H2>Sua recomendação foi criada com sucesso!</H2>
            <Body>
              O documento foi associado ao cartão Imunocard <strong>{imunocardNumber}</strong>. O paciente cadastrado
              poderá ter acesso pelo WhatsApp através do QR Code.
            </Body>
            <Body>
              Se desejar, você pode <LinkButton onClick={handleOpenModal}>visualizar o arquivo gerado</LinkButton> ou{' '}
              <LinkButton onClick={handleDownload}>fazer download</LinkButton>.
            </Body>
            <Separator />
            <Separator />
          </div>
          <Row center='xs'>
            <Button kind='primary' onClick={navigateToNewRecommendation}>
              Criar uma nova recomendação
            </Button>
          </Row>
        </Grid>
        <Separator />
        <Separator />
        <Separator />
        <Separator />
        <ImageModal isOpen={isModalOpen} imageUrl={recommendationUrlWithName} onClose={handleCloseModal} />
      </WhiteContainer>
    </div>
  );
};
