import * as Types from '../base-schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllDefaultRecommendationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllDefaultRecommendationsQuery = {
  __typename?: 'Queries';
  DefaultRecommendations?: Array<{
    __typename?: 'DefaultRecommendationType';
    id: string;
    age?: string | null;
    categoryRecommendationId: string;
    vaccines?: Array<{
      __typename?: 'VaccineType';
      id: string;
      name: string;
      brand?: string | null;
      description?: string | null;
      isAdministeredOnPublicClinics: boolean;
      isAdministeredOnPrivateClinics: boolean;
      mouthDropCount: number;
      injectionCount: number;
      ages?: Array<number | null> | null;
      comment?: string | null;
      laboratory?: string | null;
      specialNeed?: string | null;
      priority?: number | null;
      isLiveAttenuated: boolean;
      recommendationComment?: string | null;
      order?: number | null;
      vaccineFamilyId?: number | null;
    } | null> | null;
  } | null> | null;
};

export const AllDefaultRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllDefaultRecommendations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'DefaultRecommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categoryRecommendationId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vaccines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VaccineFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VaccineFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VaccineType' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdministeredOnPublicClinics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdministeredOnPrivateClinics' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mouthDropCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'injectionCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ages' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'laboratory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'specialNeed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isLiveAttenuated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recommendationComment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vaccineFamilyId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllDefaultRecommendationsQuery, AllDefaultRecommendationsQueryVariables>;
