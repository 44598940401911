// tslint:disable: max-line-length
import { Button, MenuButton } from '@components/atm.button';
import { H3, H4 } from '@components/atm.typography';
import { faBars, faEllipsisV, faHospital, faStethoscope, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {
  HeaderDropdownListStyled,
  HeaderDropdownStyled,
  HeaderHamburguerContainerStyled,
  HeaderHamburguerDropdownListStyled,
  HeaderHamburguerDropdownStyled,
  HeaderHamburguerIconStyled,
  HeaderHamburguerOpacityStyled,
  HeaderHideableMenuItemText,
  HeaderLinkContainerStyled,
  HeaderLogoLinkStyled,
  HeaderLogoStyled,
  HeaderProfileStyled,
  HeaderSelectedLineStyled,
  HeaderSelectedLineWrapperStyled,
  HeaderSelectedStyled,
  HeaderStyled,
} from './header.component.style';
import { MenuItems } from '@components/mol.header/header.model';
// tslint:enable: max-line-length

export interface HeaderProps {
  selectedItem?: MenuItems;
  home?: boolean;
  logged?: boolean;
  role: 'doctor' | 'admin';
  hamburguerMenuOpened?: boolean;
  /** set this to true to show the "Choose calendar" button forcefully */
  forceChooseCalendarButton?: boolean;
  onHeaderLogoClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onAdmPlacesClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onCasaDeVacinasClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onConectadosClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onSignOutClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onSignInClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onStartAppointmentClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onChangePasswordClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onUpdateUserClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onResetDoctorPasswordClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onResetDoctorEmailClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onOptOutUserClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onContactClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onHamburguerIconClick?: (ev: React.MouseEvent<HTMLElement>) => void;
}

export const Header = (props: HeaderProps) => {
  const { leftDesktopMenu, rightDesktopSubmenu, login, rightMobileSubmenu, rightAdminSubmenu } = getMenuData(props);

  return (
    <HeaderStyled $home={props.home}>
      {/* ############################## DESKTOP menu */}
      <HeaderLogoLinkStyled onClick={props.onHeaderLogoClick}>
        <HeaderLogoStyled src='/assets/img/img_logo.png' />
      </HeaderLogoLinkStyled>
      <HeaderLinkContainerStyled>
        {leftDesktopMenu.map(m => (
          <HeaderSelectedStyled key={m.name}>
            {m.hide || (
              <MenuButton onClick={m.onClick}>
                <FontAwesomeIcon icon={m.icon} /> {m.name}
              </MenuButton>
            )}
            {m.selected && (
              <HeaderSelectedLineWrapperStyled>
                <HeaderSelectedLineStyled />
              </HeaderSelectedLineWrapperStyled>
            )}
          </HeaderSelectedStyled>
        ))}

        {props.logged ? (
          <HeaderSelectedStyled>
            <HeaderProfileStyled>
              <MenuButton>
                <FontAwesomeIcon icon={faEllipsisV} />
                <HeaderHideableMenuItemText> Mais opções </HeaderHideableMenuItemText>
              </MenuButton>
              <HeaderDropdownStyled>
                {props.role === 'admin' && (
                  <>
                    <HeaderHamburguerDropdownListStyled>
                      <H3>Administrador</H3>
                    </HeaderHamburguerDropdownListStyled>
                    {rightAdminSubmenu.map(sm => (
                      <HeaderDropdownListStyled key={sm.name} onClick={sm.onClick}>
                        <H4> {sm.name} </H4>
                      </HeaderDropdownListStyled>
                    ))}
                    <HeaderHamburguerDropdownListStyled>
                      <H3>Usuário</H3>
                    </HeaderHamburguerDropdownListStyled>
                  </>
                )}
                {rightDesktopSubmenu.map(sm => (
                  <HeaderDropdownListStyled key={sm.name} onClick={sm.onClick}>
                    <H4> {sm.name} </H4>
                  </HeaderDropdownListStyled>
                ))}
              </HeaderDropdownStyled>
            </HeaderProfileStyled>
          </HeaderSelectedStyled>
        ) : (
          <HeaderSelectedStyled>
            <Button kind='primary' onClick={login.onClick}>
              {login.name}
            </Button>
          </HeaderSelectedStyled>
        )}
      </HeaderLinkContainerStyled>

      {/* ############################## MOBILE menu */}
      <HeaderHamburguerContainerStyled>
        <MenuButton $disableHover={true} onClick={props.onHamburguerIconClick}>
          <HeaderHamburguerIconStyled icon={faBars} />
        </MenuButton>
        <HeaderHamburguerDropdownStyled $hamburguerMenuOpened={props.hamburguerMenuOpened}>
          {props.role === 'admin' && (
            <>
              <HeaderHamburguerDropdownListStyled>
                <H3>Administrador</H3>
              </HeaderHamburguerDropdownListStyled>
              {rightAdminSubmenu.map(s => (
                <HeaderHamburguerDropdownListStyled key={s.name} onClick={s.onClick}>
                  <H4> {s.name} </H4>
                </HeaderHamburguerDropdownListStyled>
              ))}
              <HeaderHamburguerDropdownListStyled>
                <H3>Usuário</H3>
              </HeaderHamburguerDropdownListStyled>
            </>
          )}
          {rightMobileSubmenu.map(
            s =>
              s.hide || (
                <HeaderHamburguerDropdownListStyled key={s.name} onClick={s.onClick}>
                  <H4> {s.name} </H4>
                </HeaderHamburguerDropdownListStyled>
              ),
          )}
        </HeaderHamburguerDropdownStyled>
      </HeaderHamburguerContainerStyled>

      {props.hamburguerMenuOpened && (
        <HeaderHamburguerOpacityStyled
          onClick={props.onHamburguerIconClick}
          $hamburguerMenuOpened={props.hamburguerMenuOpened}
        />
      )}
    </HeaderStyled>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
function getMenuData(props: HeaderProps) {
  const start: Item = {
    name: MenuNames.Start,
    icon: faStethoscope,
    onClick: props.onStartAppointmentClick,
  };
  const vaccinationCenters: Item = {
    name: MenuNames.VaccinationCenters,
    icon: faHospital,
    onClick: props.onAdmPlacesClick,
  };
  const casaDeVacinas: Item = {
    name: MenuNames.CasaDeVacinas,
    onClick: props.onCasaDeVacinasClick,
  };
  const conectados: Item = {
    name: MenuNames.Conectados,
    onClick: props.onConectadosClick,
  };
  const login: Item = {
    name: MenuNames.Login,
    onClick: props.onSignInClick,
  };
  const updateUser: Item = {
    name: MenuNames.UpdateUser,
    onClick: props.onUpdateUserClick,
  };
  const changePassword: Item = {
    name: MenuNames.ChangePassword,
    onClick: props.onChangePasswordClick,
  };
  const resetDoctorPassword: Item = {
    name: MenuNames.ResetDoctorPassword,
    onClick: props.onResetDoctorPasswordClick,
  };
  const optOutUser: Item = {
    name: MenuNames.OptOutUser,
    onClick: props.onOptOutUserClick,
  };
  const resetDoctorEmail: Item = {
    name: MenuNames.ResetDoctorEmail,
    onClick: props.onResetDoctorEmailClick,
  };
  const contact: Item = {
    name: MenuNames.Contact,
    onClick: props.onContactClick,
  };
  const logout: Item = {
    name: MenuNames.Logout,
    onClick: props.onSignOutClick,
  };

  const startMenu = (!props.logged && !props.forceChooseCalendarButton) || props.home;
  const leftDesktopMenu: DesktopItem[] = [
    { ...start, hide: startMenu, selected: props.selectedItem === MenuItems.consulta },
    { ...conectados, selected: props.selectedItem === MenuItems.conectados },
    { ...casaDeVacinas, selected: props.selectedItem === MenuItems.casaDeVacinas },
    { ...vaccinationCenters, selected: props.selectedItem === MenuItems.centros },
  ];

  const rightAdminSubmenu: Item[] = [resetDoctorEmail, resetDoctorPassword, optOutUser];

  const rightDesktopSubmenu: Item[] = [updateUser, changePassword, contact, logout].filter(m => m);

  const rightMobileSubmenu: Item[] = [
    { ...start, hide: !props.logged && !props.forceChooseCalendarButton },
    { ...login, hide: props.logged },
    { ...updateUser, hide: !props.logged },
    { ...changePassword, hide: !props.logged },
    vaccinationCenters,
    contact,
    casaDeVacinas,
    conectados,
    { ...logout, hide: !props.logged },
  ].filter(m => m);
  return { leftDesktopMenu, rightDesktopSubmenu, login, rightMobileSubmenu, rightAdminSubmenu };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

enum MenuNames {
  Start = 'Escolha do calendário',
  VaccinationCenters = 'Centros de vacinação',
  CasaDeVacinas = 'Casa de vacinas',
  Conectados = 'GSK On',
  UpdateUser = 'Alterar dados cadastrais',
  ChangePassword = 'Alterar senha',
  ResetDoctorPassword = 'Resetar senha de médico',
  ResetDoctorEmail = 'Resetar e-mail de médico',
  OptOutUser = 'Opt-out de médico',
  Contact = 'Entrar em contato',
  Login = 'Entrar',
  Logout = 'Sair',
}

interface Item {
  name: MenuNames;
  onClick: (ev?) => void;
  icon?: IconDefinition;
  hide?: boolean;
}

interface DesktopItem extends Item {
  selected?: boolean;
}
