import { CookieConsentContainer } from '@app/components/cookie-consent';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ApplicationStore } from '@app/modules/app/application.store';
import {
  AuthenticationStore,
  DELETE_USER_MAP_KEY,
  REQUEST_DATA_USER_MAP_KEY,
  UDPATE_USER_MAP_KEY,
} from '@app/modules/authentication/authentication.store';
import { Button } from '@components/atm.button';
import { CheckboxField, CheckboxFieldGroup } from '@components/atm.checkbox';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { H2, InputLabel } from '@components/atm.typography';
import { MenuItems } from '@components/mol.header/header.model';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { LineSeparator } from '@components/obj.line-separator';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStore } from '../appointment/appointment.store';
import { DeleteUserDataMutation } from './delete-user-data.data.mutation';
import { RequestAllUserDataMutation } from './request-all-user-data.data.mutation';
import { UpdateDoctorInput } from '@app/resource/graphql/base-schema';
import { ContentWrapper } from '@components/obj.content-wrapper';
import { isVacinaTheme } from 'config/theme';

export interface UpdateUserViewProps {
  onSubmit: (data: UpdateDoctorInput) => void;
}

@Observer()
export class UpdateUserView extends React.Component<UpdateUserViewProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  private newsletterFormRef;
  private smsFormRef;

  constructor(props) {
    super(props);

    this.state = { passwordValue: '' };
    this.applicationStore.currentMenuItem = MenuItems.perfil;
    this.authenticationStore.resetMapItem(UDPATE_USER_MAP_KEY);
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    this.analyticsService.pageView('atualizar_usuario');
  }

  componentWillUnmount() {
    this.authenticationStore.resetErrorState();
  }

  render() {
    const email = this.authenticationStore.email;
    const phone = (this.authenticationStore.phone || '').slice(-11);
    const smsOptIn = this.authenticationStore.smsOptIn;
    const emailOptIn = this.authenticationStore.newsletterOptIn;

    const emailCheckboxId = 1;
    const smsCheckboxId = 2;

    const successMessage =
      this.authenticationStore.successMessageMap.get(UDPATE_USER_MAP_KEY) ||
      this.authenticationStore.successMessageMap.get(REQUEST_DATA_USER_MAP_KEY) ||
      this.authenticationStore.successMessageMap.get(DELETE_USER_MAP_KEY);
    const errorMessage =
      this.authenticationStore.errorMessageMap.get(UDPATE_USER_MAP_KEY) ||
      this.authenticationStore.errorMessageMap.get(REQUEST_DATA_USER_MAP_KEY) ||
      this.authenticationStore.errorMessageMap.get(DELETE_USER_MAP_KEY);

    return (
      <ContentWrapper>
        <Form onSubmit={this.handleSubmit}>
          {isVacinaTheme && <CookieConsentContainer />}
          <Grid>
            <Row $middle='xs'>
              <Col xs={12}>
                <Row center='xs'>
                  <Col xs={12} sm={8} md={6}>
                    <Row center='xs' $mb={true}>
                      <Col xs={12}>
                        <H2>Dados cadastrais</H2>
                      </Col>
                    </Row>

                    {(successMessage || errorMessage) && (
                      <Row start='xs' $mb={true}>
                        <Col xs={12}>
                          {successMessage && <Notification type='success' message={successMessage} />}
                          {errorMessage && <Notification type='error' message={errorMessage} />}
                        </Col>
                      </Row>
                    )}

                    <Row $mb={true}>
                      <Col xs={12}>
                        <InputLabel>E-mail</InputLabel>
                        <FieldValidator
                          name='email'
                          validators={[
                            Validators.Required('Campo obrigatório'),
                            Validators.EmailRegex('O e-mail digitado não é válido'),
                          ]}
                        >
                          <TextField defaultValue={email} type='email' placeholder='' />
                        </FieldValidator>
                      </Col>
                    </Row>

                    <Row $mb={true}>
                      <Col xs={12}>
                        <InputLabel>Celular com DDD</InputLabel>
                        <FieldValidator
                          name='phone'
                          validators={[Validators.CellphoneRegex('O celular preenchido não é válido')]}
                        >
                          <TextField defaultValue={phone} type={'cel-phone'} />
                        </FieldValidator>
                      </Col>
                    </Row>

                    <Row $mb={true}>
                      <Col xs={12}>
                        <CheckboxFieldGroup
                          value={[
                            emailOptIn ? emailCheckboxId : undefined,
                            smsOptIn ? smsCheckboxId : undefined,
                          ].filter(Boolean)}
                        >
                          <CheckboxField
                            isDefaultStyle={true}
                            value={emailCheckboxId}
                            ref={check => (this.newsletterFormRef = check)}
                            paddingLeft='30px'
                          >
                            Receber comunicações da GSK nesse e-mail
                          </CheckboxField>
                          <CheckboxField
                            isDefaultStyle={true}
                            value={smsCheckboxId}
                            ref={check => (this.smsFormRef = check)}
                            paddingLeft='30px'
                          >
                            Receber comunicações e convites para eventos da GSK nesse celular
                          </CheckboxField>
                        </CheckboxFieldGroup>
                        <Separator />
                      </Col>
                    </Row>

                    <Row center='xs'>
                      <Col xs={12}>
                        <Button
                          kind='primary'
                          type='submit'
                          expanded={true}
                          loading={this.authenticationStore.loadingMap.get(UDPATE_USER_MAP_KEY)}
                        >
                          Alterar dados
                        </Button>
                      </Col>
                    </Row>

                    <Row center='xs'>
                      <Col xs={12}>
                        <LineSeparator />
                      </Col>
                    </Row>

                    <Row center='xs' $mb={true}>
                      <Col xs={12}>
                        <RequestAllUserDataMutation />
                      </Col>
                    </Row>

                    <Row center='xs' $mb={true}>
                      <Col xs={12}>
                        <DeleteUserDataMutation />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Form>
        <Separator />
      </ContentWrapper>
    );
  }

  private handleSubmit = formData => {
    if (formData.errors.length === 0) {
      this.props.onSubmit({
        email: formData.data.email,
        phone: formData.data.phone,
        newsletterOptIn: this.newsletterFormRef.props.checked,
        smsOptIn: this.smsFormRef.props.checked,
      });
    }
  };
}
