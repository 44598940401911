import ReactGA from 'react-ga4';
import { Container, Service } from 'typedi';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import CookieConsentDatasource, { CookieConsentDataSourceObserver } from '@app/resource/cookie-consent.datasource';

@Service()
export class GoogleAnalyticsService implements CookieConsentDataSourceObserver {
  constructor(private trackingId: string) {
    this.trackingId = trackingId;
    this.setupAnalytics();
    CookieConsentDatasource.subscribeObserver(this);
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/user-id?hl=pt-br&client_type=gtag
  setUserId(id: string) {
    if (!id || !this.trackingId) {
      return;
    }
    ReactGA.gtag('config', this.trackingId, { user_id: id });
  }

  // https://support.google.com/analytics/answer/6366371?hl=en
  set(location: string) {
    ReactGA.set({ location });
  }

  pageView(pageName: string, hideQueryParams?: boolean) {
    const authenticationStore = Container.get(AuthenticationStore);
    const event = {
      hitType: 'pageview',
      page: pageName,
      dimension1: authenticationStore.userType,
      dimension2: authenticationStore.repTerritory,
      dimension3: authenticationStore.userId,
      dimension4: authenticationStore.repTerritoryAdult,
      location: hideQueryParams ? window && window.location.href.split('?')[0] : undefined,
    };
    ReactGA.send(event);
  }

  /**
   *
   * @param category A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
   * @param action A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
   */
  event(
    category: string,
    action: string,
    nonInteraction?: boolean,
    label?: string,
    value?: number,
    trackUser: boolean = true,
  ) {
    const authenticationStore = Container.get(AuthenticationStore);

    // tslint:disable:max-line-length
    /** More info on analytics
     * https://analytics.google.com/analytics/web/?authuser=1#/a113136276w168518490p168614413/admin/custom-dimensions/m-content-dimensionsContent.rowShow=10&m-content-dimensionsContent.rowStart=0&m-content-dimensionsContent.sortColumnId=index&m-content-dimensionsContent.sortDescending=false&m-content.mode=LIST
     * |Custom Dimension Name                   |Index|Scope|Last Changed |State |
     * |----------------------------------------|-----|-----|-------------|------|
     * |Perfil do Usuário (Médico, Rep ou Teste)|1    |Hit  |May 25, 2018 |Active|
     * |REP (Demanda) Vinculado                 |2    |Hit  |Sep 28, 2022 |Active|
     * |Id do usuario                           |3    |Hit  |jul. 10, 2018|Active|
     * |REP (Adulto) Vinculado                  |4    |Hit  |Sep 28, 2022 |Active|
     */
    const event = {
      category,
      action,
      label,
      value,
      nonInteraction,
      dimension1: authenticationStore.userType,
      dimension2: authenticationStore.repTerritory,
      dimension3: undefined,
      dimension4: authenticationStore.repTerritoryAdult,
    };
    if (trackUser) {
      event.dimension3 = authenticationStore.userId;
      ReactGA.event(event);
    } else {
      ReactGA.event(event);
    }
  }

  onPerformanceCookieChanged = () => {
    this.setupAnalytics();
  };

  private setupAnalytics = () => {
    if (CookieConsentDatasource.acceptedPerformance) {
      if (this.trackingId) {
        ReactGA.initialize(this.trackingId);
      }
    } else {
      CookieConsentDatasource.eraseCookie('_gid');
      CookieConsentDatasource.eraseCookie('_ga');
      CookieConsentDatasource.eraseCookie('_gat');
    }
  };
}
