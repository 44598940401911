import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { FrequentQuestionsView } from './frequent-questions.view';
import { isVacinaTheme } from 'config/theme';

export const FREQUENT_QUESTIONS_PATH = '/central-de-informacoes';

const config: SEOConfig = {
  title: `${isVacinaTheme ? 'Vacina Certa' : 'Imunocard'} - Central de informações`,
  meta: [
    {
      name: 'description',
      content: 'Encontre respostas para as perguntas frequentes na Central de informações.',
    },
  ],
};

const FrequentQuestions = SEO(config)(FrequentQuestionsView);

export const FrequentQuestionsRoute = (
  <Route path={FREQUENT_QUESTIONS_PATH} exact={true} component={FrequentQuestions} key='FrequentQuestions' />
);
