import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';

export const CardContainer = styled.div`
  background-color: ${Color.White};
  padding: ${Spacing.XLarge};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Color.NeutralGrayXSoft};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h4`
  font-family: ${FontFamily.Primary};
  color: ${Color.NeutralGrayStrong};
  font-size: ${FontSize.Small};
  margin: 0;
  font-weight: normal;
`;

export const CardImunocard = styled.p`
  font-family: ${FontFamily.Primary};
  color: ${Color.NeutralGrayStrong};
  font-size: ${FontSize.Small};
  margin: ${Spacing.Small} 0;
`;

export const CardType = styled.p`
  font-family: ${FontFamily.Primary};
  color: ${Color.NeutralGrayStrong};
  font-size: ${FontSize.Small};
  margin: 0;
`;

export const CardIcon = styled.div`
  color: ${Color.NeutralGrayMedium};
  font-size: ${FontSize.Medium};
  align-self: flex-start;
  cursor: pointer;
`;

export const CardListWrapper = styled.div`
  display: none;
  flex-direction: column;
  gap: ${Spacing.Small};

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const CardDropdownMenu = styled.div<{ position: { top: number; left: number } }>`
  position: absolute;
  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;
  background-color: ${Color.White};
  border: 1px solid ${Color.NeutralGrayXSoft};
  border-radius: ${Border.radius};
  z-index: 10;
  min-width: 120px;
  padding: ${Spacing.Small} 0;
`;

export const CardDropdownItem = styled.div`
  padding: ${Spacing.Small} ${Spacing.Medium};
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${Spacing.Small};

  &:hover {
    background-color: ${Color.NeutralGrayStrong};
  }

  svg {
    font-size: 1.2em;
  }
`;
