import * as React from 'react';
import { TextField } from '@components/atm.text-field';
import { Button } from '@components/atm.button';
import { H1 } from '@components/atm.typography';
import { Col, Row, Separator } from '@components/obj.grid';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { ButtonWrapper, WhiteContainer } from '@components/obj.white-container';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import Container from 'typedi';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { Redirect } from 'react-router';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { ImunocardAppointmentStore } from '@app/modules/appointment/imunocard-appointment.store';

type ImunocardNumberViewProps = PropsBase & {
  authenticationStore?: AuthenticationStore;
};

@Observer()
export class ImunocardNumberView extends React.Component<ImunocardNumberViewProps> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private imunocardAppointmentStore?: ImunocardAppointmentStore = Container.get(ImunocardAppointmentStore);

  constructor(props: ImunocardNumberViewProps) {
    super(props);
  }

  handleSubmit = (formData: any) => {
    if (formData.errors.length === 0) {
      const imunocardNumber = formData.data.imunocardNumber;
      this.imunocardAppointmentStore.setImunocard(imunocardNumber);
      this.props.history.push('/recomendacao/escolha-da-faixa-etaria');
    }
  };

  render() {
    if (this.authenticationStore?.isHydrated && this.authenticationStore?.loggedIn === false) {
      return <Redirect to={LOGIN_PATH} />;
    }

    return (
      <WhiteContainer>
        <H1>Insira o número do Imunocard do paciente</H1>
        <Separator />
        <Separator />
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={12} sm={8} md={6}>
              <FieldValidator
                name='imunocardNumber'
                validators={[
                  Validators.Required('O número do Imunocard é obrigatório.'),
                  Validators.MinLength(6, 'O número do Imunocard deve conter 6 dígitos.'),
                  Validators.OnlyDigits('O número do Imunocard deve conter apenas dígitos.'),
                ]}
              >
                <TextField
                  type='text'
                  placeholder='Número do Imunocard'
                  maxLength={6}
                  defaultValue={this.imunocardAppointmentStore.imunocard}
                />
              </FieldValidator>
            </Col>
          </Row>
          <ButtonWrapper>
            <Button kind='primary' type='submit'>
              Continuar
            </Button>
          </ButtonWrapper>
        </Form>
      </WhiteContainer>
    );
  }
}

