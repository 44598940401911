import { Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const IconContainer = styled.div`
  position: absolute;
  top: ${Spacing.Large};
  left: ${Spacing.Large};
  display: flex;
  gap: ${Spacing.Medium};
  align-items: center;
`;
