import * as React from 'react';
import { Loading } from '@components/atm.loading';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { LoadingFrameOverlayStyled, LoadingFrameStyled } from './loading-frame.component.style';
import { isVacinaTheme } from 'config/theme';

export interface LoadingFrameProps {
  showLoading: boolean;
}

// TODO: TEMPLATE
export class LoadingFrame extends React.Component<LoadingFrameProps, any> {
  constructor(props: LoadingFrameProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <LoadingFrameOverlayStyled $showLoading={this.props.showLoading} />
        <LoadingFrameStyled $showLoading={this.props.showLoading}>
          {isVacinaTheme ? <Loading type='boy-gif' /> : <Loading type={faSpinner} size={50}/>}
        </LoadingFrameStyled>
      </div>
    );
  }
}
