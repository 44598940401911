import * as React from 'react';
import { Modal } from '@components/obj.modal';
import {
  StyledFallbackMessage,
  StyledLoadingContainer,
  StyledModalContent,
  StyledModalImage,
} from './image-modal.component.style';
import { LoadingFrame } from '@components/mol.loading-frame';

interface ImageModalProps {
  isOpen: boolean;
  imageUrl: string | null;
  onClose: () => void;
  children?: React.ReactNode;
}

export const ImageModal: React.FC<ImageModalProps> = ({ isOpen, imageUrl, onClose, children }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
    }
  }, [isOpen, imageUrl]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <Modal maxHeight='85vh' noGutter={true} large={true} opened={isOpen} onClose={onClose}>
      <StyledModalContent $isLoading={isLoading}>
        {isLoading && (
          <StyledLoadingContainer>
            <LoadingFrame showLoading={true} />
          </StyledLoadingContainer>
        )}

        {imageUrl ? (
          <StyledModalImage
            src={imageUrl}
            alt='Recomendação'
            onLoad={handleImageLoad}
            onError={handleImageError}
            style={{ display: isLoading ? 'none' : 'block' }}
          />
        ) : (
          <StyledFallbackMessage>Imagem não disponível.</StyledFallbackMessage>
        )}
        {children && <div>{children}</div>}
      </StyledModalContent>
    </Modal>
  );
};
